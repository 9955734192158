import React from "react"
import { Box } from "@mui/material"

interface IProps {
  children: string | null | undefined
}

/**
 * React Functional Component to display rich text.
 *
 * @param {IProps} props - The component props.
 * @returns {React.ReactElement} - The rendered rich text component.
 */
const ViewRichText: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children: richText } = props

  if (richText === undefined || richText === null || richText === "") {
    return <>Not Set</>
  }

  return <Box dangerouslySetInnerHTML={{ __html: richText }} />
}

export default ViewRichText
