import React from "react"
import { TableBody, TableHead, TableRow } from "@mui/material"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import { type IUseApiPagedResultsResponse } from "../../../../../shared/hooks/useApiPagedLocal"
import { ACCOUNT_ENDPOINT, type IAccount } from "../../../../../shared/models/service/IAccount"
import { ACCOUNT_ADD_URL, ACCOUNT_VIEW_URL } from "../../../config/urls"
import PageHeader from "../../../../../shared/components/pages/PageHeader"
import TableData from "../../../../../shared/components/tables/TableData"
import TableCellDate from "../../../../../shared/components/tables/TableCellDate"
import TableCellCenter from "../../../../../shared/components/tables/TableCellCenter"
import ListData from "../../../../../shared/components/lists/ListData"
import ListDataItem from "../../../../../shared/components/lists/ListDataItem"
import TableCellData from "../../../../../shared/components/tables/TableCellData"
import { type IListItem } from "../../../../../shared/models/components/IListItem"
import TableCellContact from "../../../../../shared/components/tables/TableCellContact"
import TableCellServiceUser from "../../../../../shared/components/tables/TableCellServiceUser"
import FiltersData from "../../../../../shared/components/filters/FiltersData"
import FilterSearch from "../../../../../shared/components/filters/FilterSearch"
import TableActions from "../../../../../shared/components/tables/TableActions"
import ExcelDownloadButton from "../../../../../shared/components/ExcelDownloadButton"
import type { IFilter } from "../../../../../shared/models/components/IFilter"

const repository = new RestRepository<IAccount>(ACCOUNT_ENDPOINT)
const gotoRepo = new RestRepository<IListItem>(ACCOUNT_ENDPOINT)

interface IProps {
  pagingResults: IUseApiPagedResultsResponse<IAccount> | undefined
  showHeader?: boolean
  gotoFilters?: IFilter[]
}

/**
 * The IndexPage component is a React functional component that renders a page for displaying
 * a list of accounts with various details like identifier, name, account managers, primary contact,
 * and locations. It supports pagination and filtering of account data.
 *
 * @param {IProps} props - The properties passed to the component.
 * @param {object} props.pagingResults - The paginated results containing account data to be displayed.
 * @param {boolean} props.showHeader - A flag indicating whether to show the page header.
 * @param {Function} props.gotoFilters - Callback function invoked for navigating to filters.
 *
 * @returns {React.ReactElement} The rendered component.
 */
const AccountIndex: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { pagingResults, showHeader = true, gotoFilters } = props

  return (
    <>
      {showHeader && <PageHeader title="Account" titlePlural="Accounts" toAdd={ACCOUNT_ADD_URL} />}
      {pagingResults !== undefined && (
        <>
          <TableData
            pagingResults={pagingResults}
            to={ACCOUNT_VIEW_URL}
            repository={gotoRepo}
            gotoFilters={gotoFilters}>
            <TableActions>
              <ExcelDownloadButton repository={repository} paging={pagingResults.paging} />
            </TableActions>
            <FiltersData pagingResults={pagingResults}>
              <FilterSearch />
            </FiltersData>
            <TableHead>
              <TableCellData field="identifier">Identifier</TableCellData>
              <TableCellData field="name" pagingResults={pagingResults}>
                Name
              </TableCellData>
              <TableCellData field="account_managers">Account Managers</TableCellData>
              <TableCellData field="contact">Primary Contact</TableCellData>
              <TableCellCenter field="locations">Locations</TableCellCenter>
              <TableCellCenter field="created" pagingResults={pagingResults}>
                Created
              </TableCellCenter>
            </TableHead>
            <TableBody>
              {pagingResults.data?.results.map((item: IAccount) => (
                <TableRow key={item.id}>
                  <TableCellData field="identifier">{item.identifier}</TableCellData>
                  <TableCellData field="name">{item.name}</TableCellData>
                  <TableCellServiceUser field="account_managers">{item.account_managers}</TableCellServiceUser>
                  <TableCellContact field="contact">{item.contact}</TableCellContact>
                  <TableCellCenter field="locations">{item.location_count}</TableCellCenter>
                  <TableCellDate field="created">{item.created}</TableCellDate>
                </TableRow>
              ))}
            </TableBody>
          </TableData>

          <ListData pagingResults={pagingResults}>
            {pagingResults.data?.results.map((item: IAccount) => (
              <ListDataItem key={item.id} to={`${ACCOUNT_VIEW_URL}/${item.id}`} title={item.name} />
            ))}
          </ListData>
        </>
      )}
    </>
  )
}

export default AccountIndex
