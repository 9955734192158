import { Box } from "@mui/material"
import React, { useCallback, useState } from "react"
import { useSearchParams } from "react-router-dom"
import useEffectAsync from "../../hooks/useEffectAsync"

interface IUseTabPanelProps {
  initialTab?: number
  useSearchParams?: boolean
}

interface IUseTabPanelResponse {
  tab: number
  handleTabChange: (event: React.SyntheticEvent | null, newValue: number) => void
}

/**
 * This hook handles the state of the tab panel.
 *
 * @param {IUseTabPanelProps} props See IUseTabPanelProps for details.
 * @returns {IUseTabPanelResponse} the state and event handler.
 */
export const useTabPanel = (props?: IUseTabPanelProps): IUseTabPanelResponse => {
  const shouldUseSearchParams = props?.useSearchParams === undefined || props.useSearchParams

  const [searchParams, setSearchParams] = useSearchParams()
  const tabSearchParam = searchParams.get("tab")
  let initialTab = tabSearchParam !== null && shouldUseSearchParams ? Number(tabSearchParam) : 0
  if (props?.initialTab !== undefined) {
    initialTab = props.initialTab
  }
  const [tab, setTab] = useState<number>(initialTab)

  const handleTabChange = useCallback(
    (_event: React.SyntheticEvent | null, newValue: number) => {
      setTab(newValue)
      if (shouldUseSearchParams) {
        setSearchParams({ tab: `${newValue}` }, { replace: true })
      }
    },
    [shouldUseSearchParams],
  )

  return {
    tab,
    handleTabChange,
  }
}

interface ITabPanelProps {
  children?: React.JSX.Element[] | React.JSX.Element | string | Array<React.JSX.Element | boolean>
  index: number
  value: number
  onChange?: () => Promise<void>
}

/**
 * Renders and hides the contents of the tab panel.
 *
 * @param {ITabPanelProps} props See ITabPanelProps for details.
 * @returns {React.C<ITabPanelProps>} The tab panel.
 */
const TabPanel: React.FC<ITabPanelProps> = (props: ITabPanelProps): React.ReactElement => {
  const { children, value, index, onChange, ...other } = props

  useEffectAsync(async () => {
    if (value === index) {
      await onChange?.()
    }
  }, [value])

  return (
    <Box hidden={value !== index} {...other} sx={{ width: "100%" }}>
      <Box sx={{ pt: 2, pb: 2 }}>{children}</Box>
    </Box>
  )
}

export default TabPanel
