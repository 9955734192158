import React from "react"
import { Container, Grid } from "@mui/material"
import { useForm } from "react-hook-form"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import SummaryForm from "./components/SummaryForm"
import { SUMMARY_VIEW_URL } from "../../config/urls"
import useApiAdd, { type IUseApiAddProps } from "../../../../shared/hooks/useApiAdd"
import FormHeader from "../../../../shared/components/forms/FormHeader"
import FormBox from "../../../../shared/components/forms/FormBox"
import FormErrors from "../../../../shared/components/forms/FormErrors"
import { SUMMARY_ENDPOINT, type ISummary } from "../../../../shared/models/service/ISummary"

const repository = new RestRepository<ISummary>(SUMMARY_ENDPOINT)
const redirectView = SUMMARY_VIEW_URL

/**
 * This page will allow adding of a summary.
 *
 * @returns {React.FC} the account add page.
 */
const AddPage: React.FC = (): React.ReactElement => {
  const form = useForm()

  const props: IUseApiAddProps<ISummary> = { apiFunction: repository.add, setError: form.setError, redirectView }
  const apiAdd = useApiAdd<ISummary>(props)

  return (
    <Container fixed>
      <Grid container spacing={2}>
        <FormHeader title="Add Summary" loading={apiAdd.saving} errors={form.formState.errors} />
        <FormErrors connectionError={apiAdd.connectionError} />
        <FormBox form={form} onSubmit={apiAdd.handleAdd}>
          <SummaryForm form={form} />
        </FormBox>
      </Grid>
    </Container>
  )
}

export default AddPage
