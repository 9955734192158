import React, { type ChangeEvent, useCallback, useState } from "react"
import { MenuItem, Tabs, TextField, useMediaQuery, useTheme } from "@mui/material"
import { type ISelectItem } from "../../models/components/ISelectItem"
import useEffectInit from "../../hooks/useEffectInit"

interface IProps {
  children: Array<React.JSX.Element | boolean>
  value: number
  onChange: (event: React.SyntheticEvent | null, newValue: number) => void
}

/**
 * Tabs List component.
 *
 * @param {IProps} props - The component properties.
 * @returns {React.FC<IProps>} The rendered component.
 */
const TabsList: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children, value, onChange } = props
  const isSmall = useMediaQuery(useTheme().breakpoints.down("md"))

  const [options, setOptions] = useState<ISelectItem[]>([])

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onChange(null, Number(event.target.value))
  }, [])

  useEffectInit(async () => {
    const theChildren = children?.filter(c => typeof c !== "boolean") as React.JSX.Element[]
    const options = theChildren?.map(child => ({
      key: child.props.value,
      value: child.props.label,
    }))
    setOptions(options)
  }, [])

  return (
    <>
      {isSmall ? (
        <TextField select value={value} label="Options" onChange={handleChange} fullWidth>
          {options.map(option => {
            return (
              <MenuItem key={option.key} value={option.key}>
                {option.value}
              </MenuItem>
            )
          })}
        </TextField>
      ) : (
        <Tabs value={value} onChange={onChange}>
          {children}
        </Tabs>
      )}
    </>
  )
}

export default TabsList
