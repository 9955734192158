import { useCallback } from "react"

export enum StoragePrefixes {
  paging = "PR_PAGING_",
  columns = "PR_COLUMNS_",
}

interface ILocalStorageHelpersResponse {
  clearByPrefix: (prefix: StoragePrefixes) => void
}

/**
 * Functions to interact with the localStorage API.
 *
 * @returns {ILocalStorageHelpersResponse} helper functions
 */
const useLocalStorageHelpers = (): ILocalStorageHelpersResponse => {

  /**
   * Clears the localStorage items that have keys starting with a given prefix.
   *
   * @param {StoragePrefixes} prefix - The prefix that the keys of localStorage items should start with.
   */
  const clearByPrefix = useCallback((prefix: StoragePrefixes): void => {
    if (typeof window !== 'undefined') {
      Object.keys(localStorage)
        .filter(key => key.startsWith(prefix as string))
        .forEach(key => {
          localStorage.removeItem(key);
        });
    }
  }, [])

  return {
    clearByPrefix
  }
}


export default  useLocalStorageHelpers