import { type IInspection } from "./IInspection"
import { type IBuilding } from "./IBuilding"
import { type IMainModel } from "./IMainModel"
import { type TRiskWriterFormData } from "../../components/risk_writer/models/TRiskWriterFormData"

export const INSPECTION_BUILDING_ENDPOINT = "inspection_buildings"

export interface IInspectionBuilding extends IMainModel {
  id: number
  inspection: IInspection
  building: IBuilding
  data: TRiskWriterFormData | null
  created: string
  updated: string
}
