import React from "react"
import { type UseFormReturn } from "react-hook-form/dist/types"
import { Grid } from "@mui/material"
import FhMuiHiddenField from "../../../../../shared/components/forms/FhMuiHiddenField"
import FhMuiTextField from "../../../../../shared/components/forms/FhMuiTextField"
import FhMuiRichTextField from "../../../../../shared/components/forms/FhMuiRichTextField"
import { type IRecommendation } from "../../../../../shared/models/service/IRecommendation"
import { requiredRule } from "../../../../../shared/utilities/form_utility"
import useLoadFormData from "../../../../../shared/hooks/useLoadFormData"
import { PriorityTypes } from "../../../../../config/config"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import { type IListItem } from "../../../../../shared/models/components/IListItem"
import { type IServiceUser, SERVICE_USER_ENDPOINT } from "../../../../../shared/models/service/IServiceUser"
import SelectFilteredSingle, {
  useSelectFilteredSingle,
} from "../../../../../shared/components/forms/SelectFilteredSingle"
import { type IReportFormat, REPORT_FORMAT_ENDPOINT } from "../../../../../shared/models/service/IReportFormat"
import PaperLocal from "../../../../../shared/components/containers/PaperLocal"
import { type IPriority, PRIORITY_ENDPOINT } from "../../../../../shared/models/service/IPriority"
import {
  type IRiskScoreCategory,
  RISK_SCORE_CATEGORY_ENDPOINT
} from "../../../../../shared/models/service/IRiskScoreCategory"
import {
  type IRecommendationType,
  RECOMMENDATION_TYPE_ENDPOINT
} from "../../../../../shared/models/service/IRecommendationType"

interface IProps {
  form: UseFormReturn
  recommendation?: IRecommendation | undefined | null
  isEdit?: boolean
}

const userRepository = new RestRepository<IServiceUser | IListItem>(SERVICE_USER_ENDPOINT)
const priorityRepository = new RestRepository<IPriority | IListItem>(PRIORITY_ENDPOINT)
const reportFormatRepository = new RestRepository<IReportFormat | IListItem>(REPORT_FORMAT_ENDPOINT)
const riskScoreCategoryFormatRepository = new RestRepository<IRiskScoreCategory | IListItem>(RISK_SCORE_CATEGORY_ENDPOINT)
const recommendationTypeRepository = new RestRepository<IRecommendationType | IListItem>(RECOMMENDATION_TYPE_ENDPOINT)

/**
 * Use this forms to add or edit a recommendation.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} returns the forms.
 */
const RecommendationForm: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { recommendation, form, isEdit = false } = props

  const [owner, setOwner] = useSelectFilteredSingle("owner", form)
  const [reportFormat, setReportFormat] = useSelectFilteredSingle("report_format", form)
  const [priority, setPriority] = useSelectFilteredSingle("priority", form)
  const [riskScoreCategory, setRiskScoreCategory] = useSelectFilteredSingle("risk_score_category", form)
  const [type, setType] = useSelectFilteredSingle("type", form)

  useLoadFormData<IRecommendation>(
    (data: IRecommendation) => {
      form.setValue("id", data.id)
      form.setValue("title", data.title)
      form.setValue("body", data.body)
      form.setValue("loss_estimate", data.loss_estimate)
      form.setValue("comment", data.comment)
      form.setValue("risk_score_deduction", data.risk_score_deduction)

      setOwner(data.owner)
      setPriority(data.priority)
      setReportFormat(data.report_format)
      setRiskScoreCategory(data.risk_score_category)
      setType(data.type)
    },
    recommendation,
    isEdit,
    form.setValue,
  )

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <PaperLocal>
          {isEdit && <FhMuiHiddenField control={form.control} />}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FhMuiTextField control={form.control} name="title" rules={requiredRule()} />
            </Grid>
            <Grid item xs={12} lg={7}>
              <SelectFilteredSingle
                name="report_format"
                defaultValue={reportFormat}
                repository={reportFormatRepository}
                onChange={setReportFormat}
              />
            </Grid>
            <Grid item xs={12} lg={7}>
              <SelectFilteredSingle name="owner" defaultValue={owner} repository={userRepository} onChange={setOwner} />
            </Grid>
            <Grid item xs={12} lg={6}>
              <SelectFilteredSingle
                name="priority"
                defaultValue={priority}
                filters={[{ field: "priority_type", value: PriorityTypes.RECOMMENDATION }]}
                repository={priorityRepository}
                onChange={setPriority}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <SelectFilteredSingle
                name="type"
                defaultValue={type}
                repository={recommendationTypeRepository}
                onChange={setType}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <SelectFilteredSingle
                name="risk_score_category"
                defaultValue={riskScoreCategory}
                repository={riskScoreCategoryFormatRepository}
                onChange={setRiskScoreCategory}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <FhMuiTextField control={form.control} name="risk_score_deduction" />
            </Grid>
          </Grid>
        </PaperLocal>
      </Grid>
      <Grid item xs={12} lg={6}>
        <PaperLocal>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FhMuiRichTextField control={form.control} name="body" />
            </Grid>
            <Grid item xs={12}>
              <FhMuiRichTextField control={form.control} name="loss_estimate" />
            </Grid>
            <Grid item xs={12}>
              <FhMuiRichTextField control={form.control} name="comment" />
            </Grid>
          </Grid>
        </PaperLocal>
      </Grid>
    </Grid>
  )
}

export default RecommendationForm
