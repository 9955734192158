import { type ILocation } from "./ILocation"
import { type IAccount } from "./IAccount"
import { type IServiceUser } from "./IServiceUser"
import { type IListItem } from "../components/IListItem"
import { type IVendor } from "./IVendor"
import { type IBuilding } from "./IBuilding"
import { type IReportFormat } from "./IReportFormat"
import { type IRiskWriterData } from "../../components/risk_writer/models/IRiskWriterData"
import { type IPriority } from "./IPriority"
import { type IInspectionType } from "./IInspectionType"
import { type IRiskWriteModel } from "../components/IRiskWriteModel"
import type { TRiskWriterFormData } from "../../components/risk_writer/models/TRiskWriterFormData"

export const INSPECTION_ENDPOINT = "inspections"

export interface IInspection extends IListItem, IRiskWriteModel {
  id: number
  identifier: string
  notes: string

  account: IAccount | null
  location: ILocation
  buildings: IBuilding[]

  inspection_type: IInspectionType | null
  report_format: IReportFormat | null
  risk_writer_data: IRiskWriterData | null
  risk_writer_version: string
  risk_writer_brief_data: TRiskWriterFormData | null

  vendor: IVendor | null
  vendor_member: IServiceUser | null

  priority: IPriority | null

  vendor_due_date: string | null
  vendor_site_visit_date: string | null
  vendor_submitted_date: string | null
  vendor_submitted_by: IServiceUser | null
  vendor_returned_date: string | null

  completed: string
  completed_by: IServiceUser

  created: string
  updated: string
}
