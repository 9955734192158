import React, { useCallback, useState } from "react"
import { type IPanariskWidget, type IPanariskWidgetProps } from "../models/IPanariskWidgetProps"
import { Box, Divider, Grid, MenuItem, useTheme } from "@mui/material"
import InfoValueCard from "../../display/InfoValueCard"
import RecommendIcon from "@mui/icons-material/Recommend"
import SavedSearchIcon from "@mui/icons-material/SavedSearch"
import ReportProblemIcon from "@mui/icons-material/ReportProblem"
import useEffectInit from "../../../hooks/useEffectInit"
import { axiosInstance, BASE_URL, urlSearchParams } from "../../../utilities/request_utility"
import { useAxiosRequest } from "../../../hooks/useAxiosRequest"
import ViewLoading from "../../ViewLoading"
import ErrorMessage from "../../ErrorMessage"
import { type IPaging } from "../../../models/components/IPaging"
import WidgetMenu from "../components/WidgetMenu"

interface IOverviewStats {
  location_count: number
  inspection_count: number
  impairment_count: number
  recommendation_count: number
}

/**
 * OverviewWidget is a React functional component that provides an overview of various statistics related
 * to locations, inspections, impairments, and recommendations. This widget fetches and displays the counts
 * of these entities and provides additional functionalities like moving the widget up and down or refreshing
 * the displayed data.
 *
 * @param {IPanariskWidgetProps} props - The properties used to configure the OverviewWidget.
 * @param {Function} props.onMoveUp - A callback function to move the widget up.
 * @param {Function} props.onMoveDown - A callback function to move the widget down.
 *
 * @returns {React.ReactElement} - The rendered OverviewWidget component.
 */
const OverviewWidget: IPanariskWidget = (props: IPanariskWidgetProps): React.ReactElement => {
  const { onMoveUp, onMoveDown, onDelete } = props

  const [overviewStats, setOverviewStats] = useState<IOverviewStats | null>(null)
  const { callRequest, loading, errorMessage, error } = useAxiosRequest()

  const theme = useTheme()

  const handleRefresh = useCallback(async () => {
    await callRequest(async () => {
      const recPaging: IPaging = { filters: [{ field: "completed", value: "false" }] }
      const queryString = urlSearchParams(recPaging)
      const recUrl = `${BASE_URL}/stats/model_aggregator/${queryString}`
      const postRecData = { model_name: "InspectionRecommendation", aggregator: "Count", aggregator_field: "id" }
      const recCount = await axiosInstance.post<Record<string, number>>(recUrl, postRecData)

      const postImpData = { model_name: "LocationImpairment", aggregator: "Count", aggregator_field: "id" }
      const impCount = await axiosInstance.post<Record<string, number>>(
        `${BASE_URL}/stats/model_aggregator/`,
        postImpData,
      )

      const postInsData = { model_name: "Inspection", aggregator: "Count", aggregator_field: "id" }
      const insCount = await axiosInstance.post<Record<string, number>>(
        `${BASE_URL}/stats/model_aggregator/`,
        postInsData,
      )

      const postLocData = { model_name: "Location", aggregator: "Count", aggregator_field: "id" }
      const locCount = await axiosInstance.post<Record<string, number>>(
        `${BASE_URL}/stats/model_aggregator/`,
        postLocData,
      )

      const overviewStats1: IOverviewStats = {
        impairment_count: impCount.data.total,
        inspection_count: insCount.data.total,
        location_count: locCount.data.total,
        recommendation_count: recCount.data.total,
      }
      setOverviewStats(overviewStats1)
    })
  }, [])

  useEffectInit(async () => {
    await handleRefresh()
  }, [])

  return (
    <>
      <Grid item xs={12}>
        <ViewLoading inline loading={loading} />
        {error && <ErrorMessage error={errorMessage} />}
      </Grid>
      {overviewStats !== null && (
        <>
          <Grid item xs={12} lg={3}>
            <Box sx={{ position: "absolute" }}>
              <WidgetMenu>
                <MenuItem onClick={handleRefresh}>Refresh</MenuItem>
                <Divider />
                <MenuItem onClick={onMoveUp}>Move Up</MenuItem>
                <MenuItem onClick={onMoveDown}>Move Down</MenuItem>
                <Divider />
                <MenuItem onClick={onDelete}>Delete Widget</MenuItem>
              </WidgetMenu>
            </Box>
            <InfoValueCard
              title="Locations"
              value={overviewStats.location_count}
              color={theme.palette.success.main}
              icon={RecommendIcon}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <InfoValueCard
              title="Inspections"
              value={overviewStats.inspection_count}
              color={theme.palette.info.main}
              icon={SavedSearchIcon}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <InfoValueCard
              title="Impairments"
              value={overviewStats.impairment_count}
              color={theme.palette.error.main}
              icon={ReportProblemIcon}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <InfoValueCard
              title="Recommendations"
              value={overviewStats.recommendation_count}
              color={theme.palette.warning.main}
              icon={RecommendIcon}
            />
          </Grid>
        </>
      )}
    </>
  )
}

OverviewWidget.nameId = "overview_totals"
OverviewWidget.title = "Overview Totals"
OverviewWidget.description = "View the totals for locations, inspections, impairments and recommendations."

export default OverviewWidget
