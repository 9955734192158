import { useLocation } from "react-router-dom"
import useEffectInit from "./useEffectInit"
import type { IFilter } from "../models/components/IFilter"
import { type IUseApiPagedResultsResponse } from "./useApiPagedLocal"

/**
 * Applies a location filter to the given paging results.
 *
 * @template T should be of returnable result set.
 * @param {IUseApiPagedResultsResponse<T> | undefined} pagingResults - The paging results to apply the filter to.
 * @param {string | undefined} titlePrefix - The prefix of the filter title to match.
 *
 * @returns {void} - This function does not return a value.
 *
 * @example
 * // Usage example:
 * const results: IUseApiPagedResultsResponse<User> = ...
 * const prefix: string = "John"
 * useLocationFilter(results, prefix);
 */
const useLocationFilter = <T>(
  pagingResults: IUseApiPagedResultsResponse<T> | undefined,
  titlePrefix: string | undefined,
): void => {
  const location = useLocation()

  useEffectInit(async () => {
    if (location.state !== null) {
      let existing: IFilter[] | undefined
      if (titlePrefix !== undefined) {
        existing = pagingResults?.paging?.filters?.filter(f => f.title?.startsWith(titlePrefix) !== true)
      } else {
        existing = pagingResults?.paging?.filters
      }
      const filters: IFilter[] = [...(existing ?? []), location.state as IFilter]
      pagingResults?.handleFilter(filters)
    } else {
      await pagingResults?.call()
    }
  }, [location.state])
}

export default useLocationFilter
