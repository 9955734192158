import { useCallback, useState } from "react"
import { BlobServiceClient, type ContainerClient } from "@azure/storage-blob"
import { type TransferProgressEvent } from "@azure/core-http"
import { type IStorageFile } from "../models/service/IStorageFile"

interface IUserAzureStorage {
  uploading: boolean
  progress: number | null
  uploadFile: (storageFile: IStorageFile, fileSelected: any) => Promise<any[] | undefined>
}

/**
 * Reusable azure upload functionality.
 *
 * Todo: Add error handling here.
 *
 * @returns {IUserAzureStorage} See IUserAzureStorage for details.
 */
const useAzureStorage = (): IUserAzureStorage => {
  const [uploading, setUploading] = useState(false)
  const [progress, setProgress] = useState<number | null>(null)

  const createBlobInContainer = useCallback(
    async (containerClient: ContainerClient, storageFile: IStorageFile, file: File) => {
      // create blobClient for container
      const blobClient = containerClient.getBlockBlobClient(storageFile.filename)
      // set mimetype as determined from browser with file upload control
      const options = {
        blobHTTPHeaders: { blobContentType: file.type },
        onProgress: (progress: TransferProgressEvent) => {
          const progressValue = (progress.loadedBytes / file.size) * 100
          setProgress(progressValue)
          if (progress.loadedBytes === file.size) {
            setUploading(false)
            setProgress(null)
          }
        },
      }
      // upload file
      await blobClient.uploadData(file, options)
    },
    [],
  )

  const uploadFile = useCallback(
    async (storageFile: IStorageFile, fileSelected: File | undefined | null) => {
      if (fileSelected === undefined || fileSelected === null) return []
      setUploading(true)
      const { write_url: writeUrl, container } = storageFile
      // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
      const blobService = new BlobServiceClient(writeUrl)
      // get Container - full public read access
      const containerClient = blobService.getContainerClient(container)
      // upload file
      await createBlobInContainer(containerClient, storageFile, fileSelected)
    },
    [createBlobInContainer],
  )

  return {
    uploading,
    progress,
    uploadFile,
  }
}

export default useAzureStorage
