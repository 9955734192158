import React from "react"
import { Grid } from "@mui/material"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import { useApiPagedLocal } from "../../../../shared/hooks/useApiPagedLocal"
import { ACCOUNT_ENDPOINT, type IAccount } from "../../../../shared/models/service/IAccount"
import AccountIndex from "./components/AccountIndex"

const repository = new RestRepository<IAccount>(ACCOUNT_ENDPOINT)

/**
 * Renders the index page for the accounts.
 *
 * @returns {React.ReactElement} The rendered index page.
 */
const IndexPage: React.FC = (): React.ReactElement => {
  const pagingResults = useApiPagedLocal<IAccount>({ apiFunction: repository.findAll })

  return (
    <Grid container alignItems="center" spacing={2}>
      <AccountIndex pagingResults={pagingResults} />
    </Grid>
  )
}

export default IndexPage
