import { type IListItem } from "../components/IListItem"
import { type ILocation } from "./ILocation"
import { type IAccount } from "./IAccount"
import type IUser from "../users/IUser"
import { type IServiceUser } from "./IServiceUser"

export const CONTACT_ENDPOINT = "contacts"

export interface IContact extends IListItem {
  id: number
  first_name: string
  last_name: string
  business_name: string
  job_title: string

  primary: boolean
  verified: string
  verified_by: IUser

  service_user: IServiceUser | null

  phone: string
  phone_ext: string

  phone_2: string
  phone_2_ext: string

  email: string
  email_2: string

  notes: string
  locations: ILocation[]
  accounts: IAccount[]

  created: string
  updated: string
}
