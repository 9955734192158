import React, { useContext, useEffect } from "react"
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material"
import PrimaryButton from "../PrimaryButton"
import { PanariskAppContext } from "../../../app/PanariskApp"

interface IProps {
  title: string
  titlePlural?: string
  toAdd?: string
  toEdit?: string | undefined
  extraOptions?: React.ReactElement
}

/**
 * Renders the page header component.
 *
 * @param {IProps} props - The props object containing the necessary data for rendering.
 * @returns {React.ReactElement} The rendered page header component.
 */
const PageHeader: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { title, titlePlural, toAdd, toEdit, extraOptions } = props
  const theTitle = titlePlural ?? title

  const isSmall = useMediaQuery(useTheme().breakpoints.down("md"))
  const { setActionItem } = useContext(PanariskAppContext)

  useEffect(() => {
    if (isSmall) {
      if (toAdd !== undefined) {
        setActionItem?.(<PrimaryButton to={toAdd}>Add {title}</PrimaryButton>)
      }
      if (toEdit !== undefined) {
        setActionItem?.(
          <PrimaryButton to={toEdit} edit>
            Edit {title}
          </PrimaryButton>,
        )
      }
    }
  }, [isSmall, toAdd, toEdit, setActionItem])

  return (
    <>
      {!isSmall && (
        <Grid item xs={12}>
          <Box sx={{ p: 2, pl: 0, pr: 0 }}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs>
                <Typography variant="h4" color="textSecondary">
                  {theTitle}
                </Typography>
              </Grid>
              <Grid item>{extraOptions}</Grid>
              {toAdd !== undefined && (
                <Grid item>
                  <PrimaryButton to={toAdd}>Add {title}</PrimaryButton>
                </Grid>
              )}
              {toEdit !== undefined && (
                <Grid item>
                  <PrimaryButton to={toEdit} edit>
                    Edit {title}
                  </PrimaryButton>
                </Grid>
              )}
            </Grid>
          </Box>
        </Grid>
      )}
    </>
  )
}

export default PageHeader
