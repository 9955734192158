import React from "react"
import { Container, Grid } from "@mui/material"
import { useForm } from "react-hook-form"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import FormHeader from "../../../../shared/components/forms/FormHeader"
import AccountForm from "./components/AccountForm"
import { useApiRead } from "../../../../shared/hooks/useApiRead"
import useApiEdit, { type IUseApiEditProps } from "../../../../shared/hooks/useApiEdit"
import { ACCOUNT_ENDPOINT, type IAccount } from "../../../../shared/models/service/IAccount"
import { ACCOUNT_VIEW_URL } from "../../config/urls"
import FormBox from "../../../../shared/components/forms/FormBox"
import FormErrors from "../../../../shared/components/forms/FormErrors"

const repository = new RestRepository<IAccount>(ACCOUNT_ENDPOINT)
const redirectView = ACCOUNT_VIEW_URL

/**
 * This page will allow editing of an account.
 *
 * @returns {React.FC} the account edit page.
 */
const EditPage: React.FC = (): React.ReactElement => {
  const form = useForm()
  const apiRead = useApiRead<IAccount>({ apiFunction: repository.read })

  const props: IUseApiEditProps<IAccount> = { apiFunction: repository.edit, setError: form.setError, redirectView }
  const apiEdit = useApiEdit<IAccount>(props)

  return (
    <Container fixed>
      <Grid container spacing={2}>
        <FormHeader title="Edit Account" loading={apiEdit.saving || apiRead.loading} errors={form.formState.errors} />
        <FormErrors apiError={apiRead.error} connectionError={apiEdit.connectionError} />
        <FormBox form={form} onSubmit={apiEdit.handleEdit}>
          <AccountForm account={apiRead.data} form={form} isEdit />
        </FormBox>
      </Grid>
    </Container>
  )
}

export default EditPage
