import React, { useCallback } from "react"
import { Container, Grid } from "@mui/material"
import {
  type IInspectionRecommendation,
  INSPECTION_RECOMMENDATION_ENDPOINT,
} from "../../../../../shared/models/service/IInspectionRecommendation"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import { type FieldValues, type SubmitHandler, useForm } from "react-hook-form"
import { useApiRead } from "../../../../../shared/hooks/useApiRead"
import useApiEdit, { type IUseApiEditProps } from "../../../../../shared/hooks/useApiEdit"
import FormErrors from "../../../../../shared/components/forms/FormErrors"
import FormBox from "../../../../../shared/components/forms/FormBox"
import InspectionRecommendationForm from "../../../../../shared/pages/inspection_recommendations/components/InspectionRecommendationForm"
import { type IItemEditorProps } from "../../../../../shared/components/item_editor/ItemEditorDialog"
import useEffectAsync from "../../../../../shared/hooks/useEffectAsync"
import ViewLoading from "../../../../../shared/components/ViewLoading"

const repository = new RestRepository<IInspectionRecommendation>(INSPECTION_RECOMMENDATION_ENDPOINT)

/**
 * Represents a component for editing inspection recommendations.
 *
 * @param {IItemEditorProps} props - The props for the InspectionRecommendationEdit component.
 * @returns {React.ReactElement} The JSX element for the InspectionRecommendationEdit component.
 */
const InspectionRecommendationEdit: React.FC<IItemEditorProps> = (props: IItemEditorProps): React.ReactElement => {
  const form = useForm()
  const { itemId, onSave, requestSave, setRequestSave } = props
  const apiRead = useApiRead<IInspectionRecommendation>({ apiFunction: repository.read, itemId })

  const propsApi: IUseApiEditProps<IInspectionRecommendation> = {
    apiFunction: repository.edit,
    setError: form.setError,
  }
  const apiEdit = useApiEdit<IInspectionRecommendation>(propsApi)

  const handleSave = useCallback(
    async (data: IInspectionRecommendation) => {
      const result = await apiEdit.handleEdit(data)
      if (result !== undefined) {
        onSave()
      }
    },
    [apiEdit.handleEdit],
  )

  useEffectAsync(async () => {
    if (requestSave) {
      await form?.handleSubmit(handleSave as SubmitHandler<FieldValues>)()
      setRequestSave(false)
    }
  }, [requestSave])

  return (
    <Container fixed>
      <ViewLoading loading={apiEdit.saving || apiRead.loading} />
      <Grid container spacing={2}>
        <FormErrors apiError={apiRead.error} connectionError={apiEdit.connectionError} />
        <FormBox form={form} onSubmit={handleSave} showActionPanel={false}>
          <InspectionRecommendationForm inspectionRecommendation={apiRead.data} form={form} isEdit />
        </FormBox>
      </Grid>
    </Container>
  )
}

export default InspectionRecommendationEdit
