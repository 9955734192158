import React from "react"
import { Grid } from "@mui/material"
import ErrorMessage from "../ErrorMessage"
import { type IConnectionError } from "../../models/components/IConnectionError"

interface IProps {
  apiError?: IConnectionError | undefined
  connectionError?: IConnectionError | undefined
}

/**
 * Renders error messages based on the provided props.
 *
 * @param {IProps} props - The props object.
 * @returns {React.ReactElement} - The rendered error messages.
 */
const FormErrors: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { apiError, connectionError } = props

  return (
    <>
      {(apiError !== undefined || connectionError !== undefined) && (
        <Grid item xs={12}>
          <ErrorMessage error={apiError} />
          <ErrorMessage error={connectionError} />
        </Grid>
      )}
    </>
  )
}

export default FormErrors
