export const LOGIN_URL = "/login/"
export const PASSWORD_RESET_URL = "/password_reset/"
export const DASHBOARD_URL = "/dashboard/"
export const PROFILE_URL = "/profile/"
export const PROFILE_EDIT_URL = "/profile/edit"

export const ADDONS_INDEX_URL = "/addons/"

export const INSPECTION_INDEX_URL = "/inspections/"
export const INSPECTION_VIEW_URL = "/inspections/view"

export const INSPECTION_BUILDING_EDIT_URL = "/inspection_buildings/edit"
export const INSPECTION_BUILDING_VIEW_URL = "/inspection_buildings/view"

export const INSPECTION_RECOMMENDATION_VIEW_URL = "/inspection_recommendations/view"
export const INSPECTION_RECOMMENDATION_ADD_URL = "/inspection_recommendations/add"
export const INSPECTION_RECOMMENDATION_EDIT_URL = "/inspection_recommendations/edit"
