import { useCallback, useEffect, useRef, useState } from "react"
import { CONNECTION_ERROR, type IConnectionError } from "../models/components/IConnectionError"
import useAuth from "./useAuth"
import { useParams } from "react-router-dom"

interface IUseApiReadResponse<T> {
  call: () => Promise<void>
  loading: boolean
  data: T | undefined
  error: IConnectionError | undefined
}

export interface IUseApiReadProps<T> {
  apiFunction: (id: string | number) => Promise<T>
  dontCallOnMount?: boolean
  itemId?: number | string | undefined | null
}

/**
 * Use this hook to read from the api.
 *
 * @param {IUseApiReadProps} props See IUseApiReadProps<T> for details.
 * @returns {IUseApiReadResponse} see IUseApiReadResponse for details.
 */
export const useApiRead = <T>(props: IUseApiReadProps<T>): IUseApiReadResponse<T> => {
  const { apiFunction, dontCallOnMount, itemId } = props
  const { logoutWithConnectionError } = useAuth()
  const { id } = itemId !== undefined ? { id: itemId } : useParams()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<T | undefined>()
  const [error, setError] = useState<IConnectionError | undefined>()

  const didFirstLoad = useRef<boolean>(false)

  const call = useCallback(async (): Promise<void> => {
    if (id !== undefined && id !== null) {
      setLoading(true)
      try {
        const results = await apiFunction(id)
        setData(results)
        setError(undefined)
      } catch (reason: any) {
        setData(undefined)
        if (reason?.response !== undefined) {
          setError(reason.response as IConnectionError)
        } else {
          setError(CONNECTION_ERROR)
        }
      }
      setLoading(false)
    }
  }, [apiFunction, id, logoutWithConnectionError])

  useEffect(() => {
    if (!didFirstLoad.current && !loading && (dontCallOnMount === undefined || !dontCallOnMount)) {
      didFirstLoad.current = true
      void (async () => {
        await call()
      })()
    }
  }, [])

  return {
    call,
    loading,
    data,
    error,
  }
}
