import React, { useCallback } from "react"
import {
  Box,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { Dashboard } from "@mui/icons-material"
import { Link, useLocation } from "react-router-dom"
import { APP_VERSION, COMPANY_NAME } from "../../config/config"
import { DASHBOARD_URL } from "../../apps/admin/config/urls"

interface IProps {
  children: React.JSX.Element[] | React.JSX.Element
  margin?: number
  footer?: boolean
  noPaper?: boolean
}

/**
 * Wrapper page for RMIS.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the wrapper page.
 */
const PanariskPage: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children, margin = 2, footer = true, noPaper = false } = props

  const location = useLocation()
  const isSmall = useMediaQuery(useTheme().breakpoints.down("md"))

  const isSelected = useCallback(
    (path: string) => {
      return location.pathname.startsWith(path)
    },
    [location],
  )

  return (
    <Box sx={{ m: isSmall ? 0 : margin }}>
      <Grid container spacing={2}>
        <Grid item>
          <Paper
            sx={{
              width: 320,
              maxWidth: "100%",
            }}>
            <MenuList>
              <MenuItem component={Link} to={DASHBOARD_URL} selected={isSelected(DASHBOARD_URL)}>
                <ListItemIcon>
                  <Dashboard fontSize="small" />
                </ListItemIcon>
                <ListItemText>Dashboard</ListItemText>
              </MenuItem>
            </MenuList>
          </Paper>
        </Grid>
        <Grid item xs>
          {isSmall || noPaper ? children : <Paper>{children}</Paper>}
          {footer && (
            <Box
              sx={{
                mt: 1,
                p: 2,
                textAlign: "right",
              }}>
              © {COMPANY_NAME} {new Date().getFullYear()}
              <Box sx={{ ml: 1 }} component="small">
                v{APP_VERSION}
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default PanariskPage
