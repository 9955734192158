import React, { useCallback, useContext, useMemo, useState } from "react"
import { Button, Grid, MenuItem, TextField } from "@mui/material"
import { useFilterDataAvailable } from "./FiltersData"
import AddIcon from "@mui/icons-material/Add"
import { type IFilter } from "../../models/components/IFilter"
import { type ISelectItem } from "../../models/components/ISelectItem"
import moment from "moment"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { DATE_FORMAT, DATE_OUTPUT_FORMAT } from "../../../config/config"
import type { IPanariskAppContext } from "../../models/app/IPanariskAppContext"
import { PanariskAppContext } from "../../../app/PanariskApp"

const DATE_FILTER_TYPES = [
  {
    key: "not_set",
    value: "Not Set",
  },
  {
    key: "today",
    value: "Today",
  },
  {
    key: "has_not_occurred",
    value: "Has Not Occurred",
  },
  {
    key: "range",
    value: "Range",
  },
] as ISelectItem[]

interface IProps {
  field: string
  title: string
  children?: React.JSX.Element[]
}

/**
 * FilterDate Component
 *
 * A component that allows users to filter data based on dates.
 *
 * @param {IProps} props - The props object containing the field and title.
 * @returns {React.ReactElement} The FilterDate component.
 */
const FilterDate = (props: IProps): React.ReactElement => {
  const { field, title } = props

  const [startDate, setStartDate] = useState<moment.Moment | null>(moment())
  const [endDate, setEndDate] = useState<moment.Moment | null>(moment())

  const [dateFilterType, setDateFilterType] = useState<ISelectItem | null>(null)

  const filtersDataContext = useFilterDataAvailable(field, title)

  const { appSettings } = useContext<IPanariskAppContext>(PanariskAppContext)
  const theFormat = appSettings?.serverInfo?.date_format ?? DATE_FORMAT

  const isDateRange = useMemo(() => {
    return dateFilterType?.key === "range" ?? false
  }, [dateFilterType?.key])

  const handleAddFilter = useCallback(() => {
    if (dateFilterType !== null) {
      if (isDateRange && startDate !== null && endDate !== null) {
        const filter: IFilter = {
          title: `Range for ${title}`,
          field,
          value: `range_${startDate.format(DATE_OUTPUT_FORMAT)},${endDate.format(DATE_OUTPUT_FORMAT)}`,
          display: `${startDate.format(theFormat)} to ${endDate.format(theFormat)}`,
        }
        filtersDataContext?.addFilter(filter)
      } else {
        const filter: IFilter = { title, field, value: dateFilterType.key, display: dateFilterType.value }
        filtersDataContext?.addFilter(filter)
      }
      setDateFilterType(null)
    }
  }, [dateFilterType, field, title, isDateRange, startDate, endDate])

  const handleStartDateChange = useCallback(
    (value: moment.Moment | null) => {
      setStartDate(value)
    },
    [startDate, endDate],
  )

  const handleEndDateChange = useCallback(
    (value: moment.Moment | null) => {
      setEndDate(value)
    },
    [startDate, endDate],
  )

  const handleChangeDateFilterType = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const filterType = DATE_FILTER_TYPES.find(filterType => filterType.key === event.target.value)
    if (filterType !== undefined) {
      setDateFilterType(filterType)
    } else {
      setDateFilterType(null)
    }
  }, [])

  const dateTypes = useMemo(() => {
    return DATE_FILTER_TYPES
  }, [])

  return (
    <>
      {filtersDataContext?.selectedFilter?.value === field && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField fullWidth label={title} select onChange={handleChangeDateFilterType}>
              {dateTypes.map(dft => (
                <MenuItem key={dft.key} value={dft.key}>
                  {dft.value}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {isDateRange && (
            <>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label="Start Date"
                    onChange={handleStartDateChange}
                    value={startDate}
                    slotProps={{
                      textField: { fullWidth: true },
                      popper: { sx: { zIndex: 1500 } },
                      desktopPaper: { sx: { p: 0 } },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label="End Date"
                    onChange={handleEndDateChange}
                    value={endDate}
                    slotProps={{
                      textField: { fullWidth: true },
                      popper: { sx: { zIndex: 1500 } },
                      desktopPaper: { sx: { p: 0 } },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
            </>
          )}
          <Grid item xs />
          <Grid item>
            <Button startIcon={<AddIcon />} onClick={handleAddFilter}>
              Add Filter
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default FilterDate
