import React from "react"
import { Grid } from "@mui/material"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import { useApiPagedLocal } from "../../../../shared/hooks/useApiPagedLocal"
import { type IServiceUser, SERVICE_USER_ENDPOINT } from "../../../../shared/models/service/IServiceUser"
import ServiceUserIndex from "./components/ServiceUserIndex"

const repository = new RestRepository<IServiceUser>(SERVICE_USER_ENDPOINT)

/**
 * Renders the index page for the service users.
 *
 * @returns {React.ReactElement} The rendered index page.
 */
const IndexPage: React.FC = (): React.ReactElement => {
  const pagingResults = useApiPagedLocal<IServiceUser>({ apiFunction: repository.findAll })

  return (
    <Grid container alignItems="center" spacing={2}>
      <ServiceUserIndex pagingResults={pagingResults} />
    </Grid>
  )
}

export default IndexPage
