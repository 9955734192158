import React from "react"
import { type UseFormReturn } from "react-hook-form/dist/types"
import { Grid } from "@mui/material"
import FhMuiHiddenField from "../../../../../shared/components/forms/FhMuiHiddenField"
import FhMuiTextField from "../../../../../shared/components/forms/FhMuiTextField"
import FhMuiRichTextField from "../../../../../shared/components/forms/FhMuiRichTextField"
import { type IPolicy } from "../../../../../shared/models/service/IPolicy"
import { requiredRule } from "../../../../../shared/utilities/form_utility"
import useLoadFormData from "../../../../../shared/hooks/useLoadFormData"
import PaperLocal from "../../../../../shared/components/containers/PaperLocal"
import FhMuiDateField from "../../../../../shared/components/forms/FhMuiDateField"

interface IProps {
  form: UseFormReturn
  policy?: IPolicy | undefined | null
  isEdit?: boolean
}

/**
 * Use this forms to add or edit an policy.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} returns the forms.
 */
const PolicyForm: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { policy, form, isEdit = false } = props

  useLoadFormData<IPolicy>(
    (data: IPolicy) => {
      form.setValue("id", data.id)
      form.setValue("name", data.name)
      form.setValue("description", data.description)
      form.setValue("start_date", data.start_date)
      form.setValue("end_date", data.end_date)
    },
    policy,
    isEdit,
    form.setValue,
  )

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <PaperLocal>
          {isEdit && <FhMuiHiddenField control={form.control} />}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FhMuiTextField control={form.control} name="name" rules={requiredRule()} />
            </Grid>
            <Grid item xs={12}>
              <FhMuiDateField control={form.control} name="start_date" />
            </Grid>
            <Grid item xs={12}>
              <FhMuiDateField control={form.control} name="end_date" />
            </Grid>
          </Grid>
        </PaperLocal>
      </Grid>
      <Grid item xs={12} lg={6}>
        <PaperLocal>
          <FhMuiRichTextField control={form.control} name="description" />
        </PaperLocal>
      </Grid>
    </Grid>
  )
}

export default PolicyForm
