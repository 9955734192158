import React, { useCallback } from "react"
import { type ILocation } from "../../../../../shared/models/service/ILocation"
import { COMPANY_NAME, DEFAULT_CENTER, DEFAULT_GOOGLE_MAP_ID, ItemPrefixes } from "../../../../../config/config"
import { Box, Button, Grid, Paper } from "@mui/material"
import PageHeader from "../../../../../shared/components/pages/PageHeader"
import { LOCATION_ADD_URL, LOCATION_INDEX_URL } from "../../../config/urls"
import { Map, Marker, useMap } from "@vis.gl/react-google-maps"
import { Link, useLocation } from "react-router-dom"
import FiltersData from "../../../../../shared/components/filters/FiltersData"
import FilterSearch from "../../../../../shared/components/filters/FilterSearch"
import FilterAccount from "../../../../../shared/components/filters/FilterAccount"
import FilterLocationCity from "../../../../../shared/components/filters/FilterLocationCity"
import FilterLocationStateRegion from "../../../../../shared/components/filters/FilterLocationStateRegion"
import FilterLocationCountry from "../../../../../shared/components/filters/FilterLocationCountry"
import type { IUseApiPagedResultsResponse } from "../../../../../shared/hooks/useApiPagedLocal"
import ListData from "../../../../../shared/components/lists/ListData"
import ListDataItem from "../../../../../shared/components/lists/ListDataItem"
import ListProperty from "../../../../../shared/components/lists/ListProperty"
import ItemViewerDrawer from "../../../../../shared/components/item_viewer/ItemViewerDrawer"
import LocationInfo from "./LocationInfo"
import { useTranslation } from "react-i18next"
import useAuth, { type IProfilePatch } from "../../../../../shared/hooks/useAuth"

interface IProps {
  pagingResults: IUseApiPagedResultsResponse<ILocation> | undefined
}

const height = "calc(100vh - 200px)"
const heightList = "calc(100vh - 300px)"
const zoom = 4

/**
 * Displays a map of the locations.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the locations map.
 */
const LocationsMap: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { pagingResults } = props
  const { t } = useTranslation()
  const location = useLocation()
  const map = useMap(COMPANY_NAME)
  const { currentUser, updateProfile } = useAuth()
  const mapType = currentUser?.user.profile.map_type ?? "satellite"

  const handleMapTypeChange = useCallback(async () => {
    if (map?.getMapTypeId() !== undefined && currentUser?.user.profile.id !== undefined) {
      const patch: IProfilePatch = { id: currentUser.user.profile.id, name: "", map_type: map.getMapTypeId() }
      await updateProfile?.(patch)
    }
  }, [map, currentUser])

  return (
    <>
      <ItemViewerDrawer title={t("Location")} prefix={ItemPrefixes.location} infoView={LocationInfo} />
      <PageHeader
        title="Location"
        titlePlural="Locations"
        toAdd={LOCATION_ADD_URL}
        extraOptions={
          <Button component={Link} to={LOCATION_INDEX_URL}>
            Table
          </Button>
        }
      />
      <Grid item xs={12}>
        {pagingResults !== undefined && (
          <Box sx={{ position: "absolute", mt: 1.5, ml: 25, zIndex: 900 }}>
            <Paper sx={{ m: 0, p: 0 }}>
              <FiltersData pagingResults={pagingResults}>
                <FilterSearch />
                <FilterAccount field="accounts" />
                <FilterLocationCity field="city" />
                <FilterLocationStateRegion field="state_region" />
                <FilterLocationCountry field="country" />
              </FiltersData>
            </Paper>
          </Box>
        )}
        {pagingResults !== undefined && (
          <Box
            sx={{
              position: "absolute",
              mt: 7,
              ml: 1.5,
              zIndex: 900,
              width: 400,
              height: heightList,
              overflow: "auto",
            }}>
            <ListData pagingResults={pagingResults} show>
              {pagingResults.data?.results.map((item: ILocation) => (
                <ListDataItem
                  key={item.id}
                  to={`${location.pathname}/${ItemPrefixes.location}info/${item.id}`}
                  title={item.name}>
                  <ListProperty label="City">{item.city}</ListProperty>
                  <ListProperty label="State">{item.state_region}</ListProperty>
                </ListDataItem>
              ))}
            </ListData>
          </Box>
        )}
        <Map
          mapId={DEFAULT_GOOGLE_MAP_ID}
          style={{ flexGrow: "1", height }}
          defaultZoom={zoom}
          mapTypeId={mapType}
          onMapTypeIdChanged={handleMapTypeChange}
          defaultCenter={DEFAULT_CENTER}>
          {pagingResults?.data?.results.map((location, index) => {
            return (
              <Marker
                key={index}
                position={{
                  lat: Number(location.latitude) + Math.sin(index) / 40000,
                  lng: Number(location.longitude) + Math.sin(index) / 40000,
                }}
              />
            )
          })}
        </Map>
      </Grid>
    </>
  )
}

export default LocationsMap
