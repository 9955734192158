import React, { useEffect } from "react"
import { Grid } from "@mui/material"

import { type IUseApiReadProps, useApiRead } from "../../../../../shared/hooks/useApiRead"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import { type IRecommendation, RECOMMENDATION_ENDPOINT } from "../../../../../shared/models/service/IRecommendation"
import ViewProperty from "../../../../../shared/components/ViewProperty"
import { type IInfoViewProps } from "../../../../../shared/components/item_viewer/ItemViewerDrawer"
import ViewRichText from "../../../../../shared/components/display/ViewRichText"
import useEffectAsync from "../../../../../shared/hooks/useEffectAsync"
import ItemViewerTitle from "../../../../../shared/components/item_viewer/ItemViewerTitle"
import { RECOMMENDATION_VIEW_URL } from "../../../config/urls"
import TruncateText from "../../../../../shared/components/TruncateText"

const repository = new RestRepository<IRecommendation>(RECOMMENDATION_ENDPOINT)

interface IProps extends IInfoViewProps {
  onChange?: (recommendation: IRecommendation) => void
}

/**
 * Renders a page to view recommendations details.
 *
 * @param {IProps} props See props for details
 * @returns {React.ReactElement} The rendered component.
 */
const RecommendationInfo: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { infoId, onChange } = props
  const apiReadProps: IUseApiReadProps<IRecommendation> = {
    apiFunction: repository.read,
    itemId: infoId,
    dontCallOnMount: true,
  }
  const apiRead = useApiRead<IRecommendation>(apiReadProps)
  const { data } = apiRead

  useEffectAsync(async () => {
    if (infoId !== null && !apiRead.loading) {
      await apiRead.call()
    }
  }, [infoId])

  useEffect(() => {
    if (data !== undefined) {
      onChange?.(data)
    }
  }, [data])

  return (
    <>
      {data !== undefined && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ItemViewerTitle to={`${RECOMMENDATION_VIEW_URL}/${infoId}`}>
              <TruncateText>{data.title}</TruncateText>
            </ItemViewerTitle>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label="Report Format">{data.report_format?.name}</ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label="Type">{data.type?.name}</ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label="Priority">{data.priority?.name}</ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label="Risk Score Deduction">{data.risk_score_deduction}</ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label="Risk Score Category">{data.risk_score_category?.name}</ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label="Body" vertical>
              <ViewRichText>{data.body}</ViewRichText>
            </ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label="Loss Estimate" vertical>
              <ViewRichText>{data.loss_estimate}</ViewRichText>
            </ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label="Comment" vertical>
              <ViewRichText>{data.comment}</ViewRichText>
            </ViewProperty>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default RecommendationInfo
