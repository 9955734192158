import { type IMainModel } from "./IMainModel"

export const SETTING_ENDPOINT = "settings"

export interface ISetting extends IMainModel {
  id: number
  company_name: string
  enable_all_emails: boolean
  enable_username_password_login: boolean
  primary_color: string
  secondary_color: string
  units: string
  currency: string
  date_format: string
}
