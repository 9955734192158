import React from "react"
import { type ChangeEvent, useCallback, useState } from "react"
import { Box, Grid, IconButton, Popover } from "@mui/material"
import { type InputProps as StandardInputProps } from "@mui/material/Input/Input"
import { ColorPicker, ColorService, type IColor, useColor } from "react-color-palette"
import FhMuiTextField from "./FhMuiTextField"
import ColorizeIcon from "@mui/icons-material/Colorize"
import { type UseFormReturn } from "react-hook-form/dist/types"
import "react-color-palette/css"
import { nameToLabel } from "../../utilities/form_utility"

type TUseColorFieldResponse = [[IColor, React.Dispatch<React.SetStateAction<IColor>>], (color: string) => void]

interface IUseColorFieldProps {
  form: UseFormReturn
  field: string
  defaultColor?: string
}

/**
 * A hook that provides a color field and a method to set its value.
 *
 * @param {IUseColorFieldProps} props - The props object containing the forms, field, and defaultColor.
 * @returns {TUseColorFieldResponse} - An array containing the setColor method and the current color value.
 */
export const useColorField = (props: IUseColorFieldProps): TUseColorFieldResponse => {
  const { form, field, defaultColor = "#CC0011" } = props
  const color = useColor(defaultColor)

  const setColor = useCallback(
    (color1: string) => {
      color1 = color1 === "" || color1 === null || color1 === undefined ? defaultColor : color1
      form.setValue(field, color1)
      color[1](ColorService.convert("hex", color1))
    },
    [field, form, color],
  )

  return [color, setColor]
}

interface IProps {
  form: UseFormReturn
  colorValue: [IColor, React.Dispatch<React.SetStateAction<IColor>>]
  label?: string
  name: string
  onChange?: StandardInputProps["onChange"]
  helperText?: string
}

/**
 * A reusable text input forms field that uses mui and react hook forms.
 *
 * @param {IProps} props see IProps for details.
 * @returns {React.FC<IProps>} the input field.
 */
const FhMuiColorField: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { form, colorValue, onChange, name, label, helperText } = props

  const [color, setColor] = colorValue

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const open = Boolean(anchorEl)

  const handleColorChange = useCallback(
    (color1: IColor) => {
      setColor(color1)
      form.setValue(name, color1.hex)
    },
    [name],
  )

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setColor(ColorService.convert("hex", event.target.value))
      onChange?.(event)
    },
    [setColor],
  )

  const theLabel = nameToLabel(name, label)

  return (
    <Grid container alignItems="center">
      <Grid item xs>
        <FhMuiTextField
          control={form.control}
          label={theLabel}
          name={name}
          onChange={handleInputChange}
          helperText={helperText}
        />
      </Grid>
      <Grid item>
        <Box sx={{ pl: 2 }}>
          <IconButton onClick={handleClick} sx={{ backgroundColor: color.hex }}>
            <ColorizeIcon />
          </IconButton>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            transformOrigin={{ vertical: "center", horizontal: "left" }}>
            <Box sx={{ width: 350 }}>
              <ColorPicker color={color} onChange={handleColorChange} />
            </Box>
          </Popover>
        </Box>
      </Grid>
    </Grid>
  )
}

export default FhMuiColorField
