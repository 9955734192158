import * as React from "react"
import { type IRiskWriterSection } from "../models/IRiskWriterSection"
import { type IRiskWriterSectionData } from "../models/IRiskWriterSectionData"
import ContentTextEditor from "./content_editors/ContentTextEditor"
import ContentImageEditor from "./content_editors/ContentImageEditor"
import ContentTableEditor from "./content_editors/ContentTableEditor"

interface IProps {
  section: IRiskWriterSection
  content: IRiskWriterSectionData
  viewerOnly?: boolean
}

/**
 * Represents a content editor component.
 *
 * @param {IProps} props - The props object containing the input data.
 * @returns {React.ReactElement} The rendered content editor component.
 */
const RiskWriterContentEditor: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { section, content, viewerOnly = false } = props

  return (
    <>
      <ContentTextEditor content={content} section={section} viewerOnly={viewerOnly} />
      <ContentImageEditor content={content} section={section} viewerOnly={viewerOnly} />
      <ContentTableEditor content={content} section={section} viewerOnly={viewerOnly} />
    </>
  )
}

export default RiskWriterContentEditor
