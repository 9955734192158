import { type IListItem } from "../components/IListItem"
import { type IContact } from "./IContact"
import { type IGroup } from "../users/IGroup"

export const SERVICE_USER_ENDPOINT = "service_users"

export interface IServiceUser extends IListItem {
  id: number
  first_name: string
  last_name: string
  job_title: string

  user_groups: IGroup[]

  phone: string
  phone_ext: string

  phone_2: string
  phone_2_ext: string

  email: string
  email_2: string

  notes: string

  contact: IContact | null

  last_login: string
  verified: string | null

  has_enabled_user_account: boolean

  created: string
  updated: string
}
