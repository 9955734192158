import { type DependencyList, useEffect, useRef } from "react"

type TAsyncFunction = () => Promise<void>

/**
 * Initializes and executes an asynchronous function only on the initial render.
 *
 * @param {TAsyncFunction} asyncFunction - The asynchronous function to be executed.
 * @param {DependencyList} deps - An array of dependencies that triggers the function execution.
 *
 * @returns {void}
 */
const useEffectInit = (asyncFunction: TAsyncFunction, deps: DependencyList): void => {
  const initialLoad = useRef<boolean>(false)

  useEffect(() => {
    if (!initialLoad.current) {
      initialLoad.current = true
      void (async () => {
        await asyncFunction()
      })()
    }
  }, [...deps, initialLoad])
}

export default useEffectInit
