import { Alert, Box, CircularProgress, Grid, LinearProgress, Snackbar } from "@mui/material"
import React from "react"

interface IProps {
  loading: boolean
  message?: string | React.ReactElement
  size?: string
  tight?: boolean
  inline?: boolean
  progress?: number | null | undefined
}

/**
 * This component displays a snackbar loading indicator with optional message.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the loading indicator.
 */
const ViewLoading: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { loading, message = "Loading...", size = "0.95rem", tight = false, inline = false, progress = null } = props
  return (
    <>
      {inline ? (
        <Box sx={{ "& .MuiAlert-message": { p: tight ? 0 : 0.5 } }}>
          {loading && (
            <Alert severity="info" icon={false} sx={{ display: "block", width: "100%", p: tight ? 0.5 : 1.5 }}>
              <Grid container spacing={1} alignItems="center" sx={tight ? { p: 0 } : {}}>
                <Grid item xs>
                  {progress === null ? (
                    <CircularProgress size={size} />
                  ) : (
                    <LinearProgress variant="determinate" value={progress} />
                  )}
                </Grid>
                <Grid item>{message}</Grid>
              </Grid>
            </Alert>
          )}
        </Box>
      ) : (
        <Snackbar open={loading} sx={{ mt: 8 }} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
          <Alert severity="info" icon={false} elevation={8}>
            <Grid container spacing={2} alignItems="center" sx={{ width: progress !== null ? 400 : "inherit" }}>
              {progress === null && (
                <Grid item>
                  <CircularProgress size={size} />
                </Grid>
              )}
              <Grid item xs>
                {message}
              </Grid>
              {progress !== null && (
                <Grid item xs={12}>
                  <LinearProgress variant="determinate" value={progress} />
                </Grid>
              )}
            </Grid>
          </Alert>
        </Snackbar>
      )}
    </>
  )
}

export default ViewLoading
