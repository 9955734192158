import React from "react"
import { Grid, Paper } from "@mui/material"
import { type TooltipProps } from "recharts"
import { type IStats } from "../../../models/service/IStats"
import type { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent"
import { type IChartBuilderState } from "../models/IStatBuilderState"
import FormatNumber from "../../format/FormatNumber"

interface IProps extends TooltipProps<ValueType, NameType> {
  statBuilderStat: IChartBuilderState
}

interface ITooltipPayload {
  payload: IStats
}

/**
 statBuilderStat: IStatBuilderState
 statBuilderStat: IStatBuilderState
 * Generates a custom tooltip element based on the provided TooltipProps.
 *
 * @param {TooltipProps<ValueType, NameType>} props - The tooltip props.
 * @returns {React.ReactElement|null} - The custom tooltip component or null if there is no data to display.
 */
const ChartTooltip = (props: IProps): React.ReactElement | null => {
  const { active, payload, statBuilderStat } = props
  if (active === true && payload != null && payload.length > 0) {
    return (
      <Paper>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <strong>{(payload as ITooltipPayload[])[0].payload.label}</strong>
          </Grid>
          <Grid item>{statBuilderStat.aggregator}</Grid>
          <Grid item>
            <FormatNumber
              value={(payload as ITooltipPayload[])[0].payload.value}
              prefixUnits={statBuilderStat.aggregatorField?.units_pre}
              suffixUnits={statBuilderStat.aggregatorField?.units_post}
              twoDecimalPlaces={false}
            />
          </Grid>
        </Grid>
      </Paper>
    )
  }
  return null
}

export default ChartTooltip
