import React from "react"
import { type IUseApiPagedResultsResponse } from "../../../../../shared/hooks/useApiPagedLocal"
import PageHeader from "../../../../../shared/components/pages/PageHeader"
import { INSPECTION_RECOMMENDATION_ADD_URL, INSPECTION_RECOMMENDATION_VIEW_URL } from "../../../config/urls"
import TableData from "../../../../../shared/components/tables/TableData"
import FiltersData from "../../../../../shared/components/filters/FiltersData"
import FilterLocation from "../../../../../shared/components/filters/FilterLocation"
import { Grid, TableBody, TableHead, TableRow, type Theme } from "@mui/material"
import TableCellData from "../../../../../shared/components/tables/TableCellData"
import {
  type IInspectionRecommendation,
  INSPECTION_RECOMMENDATION_ENDPOINT,
} from "../../../../../shared/models/service/IInspectionRecommendation"
import TableCellTruncate from "../../../../../shared/components/tables/TableCellTruncate"
import TableCellDate from "../../../../../shared/components/tables/TableCellDate"
import ListData from "../../../../../shared/components/lists/ListData"
import ListDataItem from "../../../../../shared/components/lists/ListDataItem"
import ListProperty from "../../../../../shared/components/lists/ListProperty"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import { type IListItem } from "../../../../../shared/models/components/IListItem"
import { ItemPrefixes, PriorityTypes } from "../../../../../config/config"
import TableCellCenter from "../../../../../shared/components/tables/TableCellCenter"
import TableActions from "../../../../../shared/components/tables/TableActions"
import ItemEditorAddButton from "../../../../../shared/components/item_editor/ItemEditorAddButton"
import type { IFilter } from "../../../../../shared/models/components/IFilter"
import ItemDeleteDrawer from "../../../../../shared/components/item_delete/ItemDeleteDrawer"
import InspectionRecommendationInfo from "./InspectionRecommendationInfo"
import { type IMainModel } from "../../../../../shared/models/service/IMainModel"
import TableCellAction from "../../../../../shared/components/tables/TableCellAction"
import FilterPriority from "../../../../../shared/components/filters/FilterPriority"
import { type IInspection } from "../../../../../shared/models/service/IInspection"
import TableCellLocation from "../../../../../shared/components/tables/TableCellLocation"
import FilterInspection from "../../../../../shared/components/filters/FilterInspection"
import FilterRecommendationType from "../../../../../shared/components/filters/FilterRecommendationType"
import FilterRecommendationStatus from "../../../../../shared/components/filters/FilterRecommendationStatus"
import FilterRiskScoreCategory from "../../../../../shared/components/filters/FilterRiskScoreCategory"
import FilterDate from "../../../../../shared/components/filters/FilterDate"
import FilterServiceUser from "../../../../../shared/components/filters/FilterServiceUser"
import FilterSearch from "../../../../../shared/components/filters/FilterSearch"
import ExcelDownloadButton from "../../../../../shared/components/ExcelDownloadButton"

const gotoRepo = new RestRepository<IListItem>(INSPECTION_RECOMMENDATION_ENDPOINT)
const mainRepository = new RestRepository<IMainModel>(INSPECTION_RECOMMENDATION_ENDPOINT)

interface IProps {
  pagingResults: IUseApiPagedResultsResponse<IInspectionRecommendation>
  currentInspection?: IInspection
  fullPage?: boolean
  useInfoView?: boolean
  gotoFilters?: IFilter[]
  itemDelete?: boolean
}

/**
 * Renders the Inspection Recommendation Index component.
 *
 * @param {IProps} props - The props object.
 * @returns {React.ReactElement} The rendered component.
 */
const InspectionRecommendationIndex: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const {
    pagingResults,
    currentInspection,
    fullPage = true,
    useInfoView = false,
    gotoFilters,
    itemDelete = false,
  } = props
  const { data } = pagingResults

  return (
    <Grid container alignItems="center" spacing={2}>
      {fullPage && (
        <PageHeader title="Inspection Rec" titlePlural="Inspection Recs" toAdd={INSPECTION_RECOMMENDATION_ADD_URL} />
      )}
      {pagingResults !== undefined && (
        <>
          <TableData
            pagingResults={pagingResults}
            to={INSPECTION_RECOMMENDATION_VIEW_URL}
            toInfo={useInfoView}
            toInfoPrefix={ItemPrefixes.inspectionRec}
            repository={gotoRepo}
            gotoFilters={gotoFilters}
            storageSuffix="inspection/recommendations">
            {!fullPage ? (
              <TableActions>
                <ItemEditorAddButton prefix={ItemPrefixes.inspectionRec} />
              </TableActions>
            ) : (
              <TableActions>
                <ExcelDownloadButton repository={gotoRepo} paging={pagingResults.paging} />
              </TableActions>
            )}
            <FiltersData pagingResults={pagingResults}>
              <FilterSearch />
              <FilterLocation field="inspection__location" />
              <FilterPriority field="priority" priorityType={PriorityTypes.RECOMMENDATION} />
              <FilterInspection field="inspection" />
              <FilterRecommendationType field="type" />
              <FilterRecommendationStatus field="status" />
              <FilterRiskScoreCategory field="risk_score_category" />
              <FilterDate field="completed" title="Completed" />
              <FilterServiceUser field="completed_by" title="Completed By" />
            </FiltersData>
            <TableHead>
              <TableCellData field="identifier">Identifier</TableCellData>
              <TableCellData field="location" pagingResults={pagingResults}>
                Location
              </TableCellData>
              <TableCellData field="inspection" pagingResults={pagingResults}>
                Inspection
              </TableCellData>
              <TableCellData field="title">Title</TableCellData>
              <TableCellData field="body">Body</TableCellData>
              <TableCellData field="status" pagingResults={pagingResults}>
                Status
              </TableCellData>
              <TableCellData field="type" pagingResults={pagingResults}>
                Type
              </TableCellData>
              <TableCellData field="priority" pagingResults={pagingResults}>
                Priority
              </TableCellData>
              <TableCellData field="risk_score_category" pagingResults={pagingResults}>
                Risk Score Category
              </TableCellData>
              <TableCellCenter field="completed" pagingResults={pagingResults}>
                Completed
              </TableCellCenter>
              <TableCellCenter field="completed_by" pagingResults={pagingResults}>
                Completed By
              </TableCellCenter>
              {itemDelete && <TableCellAction field="action">Action</TableCellAction>}
            </TableHead>
            <TableBody>
              {data?.results.map((item: IInspectionRecommendation) => (
                <TableRow key={item.id}>
                  <TableCellData field="identifier">{item.identifier}</TableCellData>
                  <TableCellLocation field="location">{item.location}</TableCellLocation>
                  <TableCellTruncate
                    field="inspection"
                    sx={
                      item.inspection.id === currentInspection?.id
                        ? (theme: Theme) => ({ background: theme.palette.info.light })
                        : {}
                    }>
                    {item.inspection.name}
                  </TableCellTruncate>
                  <TableCellTruncate field="title">{item.title}</TableCellTruncate>
                  <TableCellTruncate field="body">{item.body}</TableCellTruncate>
                  <TableCellData field="status">{item.status?.name}</TableCellData>
                  <TableCellData field="type">{item.type?.name}</TableCellData>
                  <TableCellData field="priority">{item.priority?.name}</TableCellData>
                  <TableCellData field="risk_score_category">{item.risk_score_category?.name}</TableCellData>
                  <TableCellDate field="completed">{item.completed}</TableCellDate>
                  <TableCellData field="completed_by">{item.completed_by?.name}</TableCellData>
                  {itemDelete && (
                    <TableCellAction field="action">
                      <ItemDeleteDrawer
                        itemId={item.id}
                        onDelete={pagingResults.call}
                        infoView={InspectionRecommendationInfo}
                        repository={mainRepository}
                        name="Rec"
                        iconButton
                      />
                    </TableCellAction>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </TableData>

          <ListData pagingResults={pagingResults}>
            {data?.results.map((item: IInspectionRecommendation) => (
              <ListDataItem key={item.id} to={`${INSPECTION_RECOMMENDATION_VIEW_URL}/${item.id}`} title={item.title}>
                <ListProperty label="Status">{item.status?.name}</ListProperty>
                <ListProperty label="Type">{item.type?.name}</ListProperty>
                <ListProperty label="Priority">{item.priority?.name}</ListProperty>
              </ListDataItem>
            ))}
          </ListData>
        </>
      )}
    </Grid>
  )
}

export default InspectionRecommendationIndex
