import React, { useCallback } from "react"
import AddIcon from "@mui/icons-material/Add"
import EditIcon from "@mui/icons-material/Edit"
import { Button, IconButton, useMediaQuery, useTheme } from "@mui/material"
import { useNavigate } from "react-router-dom"

interface IProps {
  children: React.JSX.Element[] | React.JSX.Element | string[] | string
  onClick?: () => void
  edit?: boolean
  to?: string
}

/**
 * Renders a main button component.
 *
 * @param {IProps} props - The properties for the component.
 * @returns {React.ReactElement} - The rendered button component.
 */
const PrimaryButton: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children, onClick, edit = false, to } = props

  const isSmall = useMediaQuery(useTheme().breakpoints.down("md"))

  let startIcon = { startIcon: <AddIcon /> }
  if (edit) {
    startIcon = { startIcon: <EditIcon /> }
  }

  const navigate = useNavigate()

  const handleClick = useCallback(() => {
    if (to !== undefined) {
      navigate(to)
    }
    onClick?.()
  }, [onClick])

  return isSmall ? (
    <IconButton onClick={handleClick} color={isSmall ? "inherit" : "secondary"}>
      {startIcon.startIcon}
    </IconButton>
  ) : (
    <Button variant="contained" color="secondary" disableElevation {...startIcon} onClick={handleClick}>
      {children}
    </Button>
  )
}

export default PrimaryButton
