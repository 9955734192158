import React, { useContext } from "react"
import { type IInspectionBuilding, INSPECTION_BUILDING_ENDPOINT } from "../../models/service/IInspectionBuilding"
import { Container, Grid } from "@mui/material"
import { RestRepository } from "../../repositories/RestRepository"
import { useApiRead } from "../../hooks/useApiRead"
import ViewMap from "../display/ViewMap"
import ViewProperty from "../ViewProperty"
import { ItemPrefixes } from "../../../config/config"
import PageHeader from "../pages/PageHeader"
import { INSPECTION_BUILDING_EDIT_URL } from "../../../apps/admin/config/urls"
import PaperLocal from "../containers/PaperLocal"
import ItemViewerDrawer from "../item_viewer/ItemViewerDrawer"
import BuildingInfo from "../../../apps/admin/pages/buildings/components/BuildingInfo"
import InspectionInfo from "../../../apps/admin/pages/inspections/components/InspectionInfo"
import ViewBuilding from "../display/ViewBuilding"
import ViewListItems from "../display/ViewListItems"
import RiskWriterFormView from "../risk_writer/components/RiskWriterFormView"
import { INSPECTION_FILE_ENDPOINT } from "../../models/service/IFile"
import type { IPanariskAppContext } from "../../models/app/IPanariskAppContext"
import { PanariskAppContext } from "../../../app/PanariskApp"

const repository = new RestRepository<IInspectionBuilding>(INSPECTION_BUILDING_ENDPOINT)

/**
 * Renders a view page for an inspection report building.
 *
 * @returns {React.ReactElement} The rendered view page.
 */
const ViewPage: React.FC = (): React.ReactElement => {
  const apiRead = useApiRead<IInspectionBuilding>({ apiFunction: repository.read })
  const { data } = apiRead
  const { appSettings } = useContext<IPanariskAppContext>(PanariskAppContext)
  const units = appSettings?.serverInfo?.units

  return (
    <Container fixed>
      {data !== undefined && (
        <>
          <PageHeader title={`${data.building.name} Building`} toEdit={`${INSPECTION_BUILDING_EDIT_URL}/${data.id}`} />
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <ItemViewerDrawer prefix={ItemPrefixes.inspection} infoView={InspectionInfo} />
              <ItemViewerDrawer prefix={ItemPrefixes.building} infoView={BuildingInfo} />
              <PaperLocal>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ViewProperty label="Inspection">
                      <ViewListItems prefix={ItemPrefixes.inspection}>{data.inspection}</ViewListItems>
                    </ViewProperty>
                  </Grid>
                  <Grid item xs={12}>
                    <ViewProperty label="Building">
                      <ViewBuilding prefix={ItemPrefixes.building}>{data.building}</ViewBuilding>
                    </ViewProperty>
                  </Grid>
                </Grid>
              </PaperLocal>
            </Grid>
            <Grid item xs={12} lg={6}>
              <PaperLocal>
                <ViewMap place={data.building} />
              </PaperLocal>
            </Grid>
            <Grid item xs={12}>
              <RiskWriterFormView
                units={units}
                format={data.inspection.report_format?.report_writer_format?.data}
                formType="building"
                fileEndpoint={INSPECTION_FILE_ENDPOINT}>
                {data.data}
              </RiskWriterFormView>
            </Grid>
          </Grid>
        </>
      )}
    </Container>
  )
}

export default ViewPage
