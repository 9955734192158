import React from "react"
import { Grid, Typography } from "@mui/material"

interface IProps {
  children: React.JSX.Element[] | React.JSX.Element | string | number | undefined | null
  label?: string
}

/**
 * Renders a list property with just text.
 *
 * @param {IProps} props - The component props.
 * @returns {React.ReactElement} - The rendered list property just text.
 */
const ListProperty: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children, label } = props

  return (
    <>
      <Grid item>
        <Typography sx={{ display: "inline" }} component="span" variant="body2" color="text.primary">
          {label}
        </Typography>
      </Grid>
      <Grid item xs>
        {children}
      </Grid>
    </>
  )
}

export default ListProperty
