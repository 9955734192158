import { useCallback, useState } from "react"
import { type IConnectionError } from "../models/components/IConnectionError"
import { RestRepository } from "../repositories/RestRepository"
import type IProfile from "../models/users/IProfile"
import { PROFILES_ENDPOINT } from "../models/users/IProfile"
import useAuth from "./useAuth"
import { useAxiosRequest } from "./useAxiosRequest"
import useDebounce from "react-debounced"
import useEffectInit from "./useEffectInit"

const repository = new RestRepository<IProfile>(PROFILES_ENDPOINT)

interface ISaveKey<T> {
  key: string
  value: T | null
}

interface IProfileStorageResponse<T> {
  error: IConnectionError | null
  saving: boolean
  data: [T | null, (value: T | null) => void]
}

/**
 * Represents a hook function that handles profile storage.
 *
 * @param {string} storageKey - The key to store the data in storage.
 * @param {T} [defaultValue] - The default value if no data is found in storage.
 * @returns {{ error: any, saving: boolean, data: [frontendStorage: T | null, handleSaveState: (value: T) => void] }} - An object that contains error, saving, and data properties.
 *
 * @template T - The type of data to be stored.
 */
const useProfileStorage = <T>(storageKey: string, defaultValue?: T): IProfileStorageResponse<T> => {
  const [frontendStorage, setFrontendStorage] = useState<T | null>(defaultValue ?? null)

  const { currentUser, refreshUser } = useAuth()
  const itemId = currentUser?.user.profile.id
  const { callRequest, loading, errorMessage } = useAxiosRequest()

  const debounce = useDebounce()

  const handleSaveState = useCallback(
    async (value: T | null) => {
      if (itemId !== undefined) {
        setFrontendStorage(value)
        debounce(async () => {
          await callRequest(async () => {
            await repository.actionPost<ISaveKey<T>>(itemId, "save_key", { key: storageKey, value })
            await refreshUser?.()
          })
        })
      }
    },
    [itemId],
  )

  useEffectInit(async () => {
    const storedData: T | undefined = currentUser?.user?.profile?.frontend_storage?.[storageKey]
    if (storedData !== undefined) {
      setFrontendStorage(storedData)
    }
  }, [currentUser, storageKey])

  return {
    error: errorMessage,
    saving: loading,
    data: [frontendStorage, handleSaveState],
  }
}

export default useProfileStorage
