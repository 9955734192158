import { type IStorageFile } from "./IStorageFile"
import { type IFileTag } from "./IFileTag"
import { type IMainModel } from "./IMainModel"

export const ACCOUNT_FILE_ENDPOINT = "account_files"
export const SUMMARY_FILE_ENDPOINT = "summary_files"
export const POLICY_FILE_ENDPOINT = "policy_files"
export const LOCATION_FILE_ENDPOINT = "location_files"
export const INSPECTION_FILE_ENDPOINT = "inspection_files"
export const LOCATION_IMPAIRMENT_FILE_ENDPOINT = "location_impairment_files"
export const SERVICE_USER_FILE_ENDPOINT = "service_user_files"

export interface IFile extends IMainModel {
  id: number
  name: string
  ext: string
  size: number | null
  exists: boolean
  is_report: boolean
  caption?: string
  file?: IStorageFile
  file_thumbnail?: IStorageFile
  file_tags?: IFileTag[] | number[]
  created?: string
  updated?: string
}
