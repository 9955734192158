import React, { useContext } from "react"
import { type IUseApiPagedResultsResponse } from "../../../../../shared/hooks/useApiPagedLocal"
import ItemViewerDrawer from "../../../../../shared/components/item_viewer/ItemViewerDrawer"
import { ItemPrefixes } from "../../../../../config/config"
import BuildingInfo from "./BuildingInfo"
import { Grid, TableBody, TableHead, TableRow } from "@mui/material"
import TableData from "../../../../../shared/components/tables/TableData"
import { BUILDING_VIEW_URL } from "../../../config/urls"
import TableActions from "../../../../../shared/components/tables/TableActions"
import AddBuilding from "../../locations/components/AddBuilding"
import TableCellData from "../../../../../shared/components/tables/TableCellData"
import TableCellCenter from "../../../../../shared/components/tables/TableCellCenter"
import TableCellAction from "../../../../../shared/components/tables/TableCellAction"
import { BUILDING_ENDPOINT, type IBuilding } from "../../../../../shared/models/service/IBuilding"
import TableCellLocation from "../../../../../shared/components/tables/TableCellLocation"
import TableCellArea from "../../../../../shared/components/tables/TableCellArea"
import DeleteBuilding from "../../locations/components/DeleteBuilding"
import ListData from "../../../../../shared/components/lists/ListData"
import ListDataItem from "../../../../../shared/components/lists/ListDataItem"
import ListProperty from "../../../../../shared/components/lists/ListProperty"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import { type ILocation } from "../../../../../shared/models/service/ILocation"
import { type IFilter } from "../../../../../shared/models/components/IFilter"
import { type IListItem } from "../../../../../shared/models/components/IListItem"
import type { IPanariskAppContext } from "../../../../../shared/models/app/IPanariskAppContext"
import { PanariskAppContext } from "../../../../../app/PanariskApp"

const buildingRepository = new RestRepository<IBuilding>(BUILDING_ENDPOINT)
const gotoRepository = new RestRepository<IListItem>(BUILDING_ENDPOINT)

interface IProps {
  pagingResults: IUseApiPagedResultsResponse<IBuilding> | undefined
  location: ILocation
  useInfoView?: boolean
  gotoFilters?: IFilter[]
}

/**
 * React functional component that displays a building index.
 *
 * @param {IProps} props - The props object containing the required parameters.
 * @returns {React.FC<IProps>} The rendered building index.
 */
const BuildingIndex: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { pagingResults, location, useInfoView = false, gotoFilters } = props
  const { appSettings } = useContext<IPanariskAppContext>(PanariskAppContext)
  const units = appSettings?.serverInfo?.units

  return (
    <>
      {pagingResults !== undefined && (
        <>
          <ItemViewerDrawer title={"Building"} prefix={ItemPrefixes.building} infoView={BuildingInfo} />
          <Grid container alignItems="center" spacing={2}>
            <TableData
              pagingResults={pagingResults}
              to={BUILDING_VIEW_URL}
              toInfoPrefix={ItemPrefixes.building}
              toInfo={useInfoView}
              gotoFilters={gotoFilters}
              repository={gotoRepository}>
              <TableActions>
                <AddBuilding location={location} onComplete={pagingResults.call} />
              </TableActions>
              <TableHead>
                <TableCellData field="name" pagingResults={pagingResults}>
                  Name
                </TableCellData>
                <TableCellData field="occupancy">Occupancy</TableCellData>
                <TableCellData field="location">Location</TableCellData>
                <TableCellCenter field="area">
                  {`Area ${units === "US" ? "ft<sup>2</sup>" : "m<sup>2</sup>"}`}
                </TableCellCenter>
                <TableCellAction field="action">Action</TableCellAction>
              </TableHead>
              <TableBody>
                {pagingResults.data?.results.map((item: IBuilding) => (
                  <TableRow key={item.id}>
                    <TableCellData field="name">{item.name}</TableCellData>
                    <TableCellData field="occupancy">{item.occupancy?.name}</TableCellData>
                    <TableCellLocation field="location">{item.location}</TableCellLocation>
                    <TableCellArea field="area" units={units}>
                      {item.area}
                    </TableCellArea>
                    <TableCellAction field="action">
                      <DeleteBuilding
                        building={item}
                        parentId={location.id}
                        repository={buildingRepository}
                        onComplete={pagingResults.call}
                      />
                    </TableCellAction>
                  </TableRow>
                ))}
              </TableBody>
            </TableData>

            <ListData pagingResults={pagingResults}>
              {pagingResults.data?.results.map((item: IBuilding) => (
                <ListDataItem key={item.id} to={`${BUILDING_VIEW_URL}/${item.id}`} title={item.name}>
                  <ListProperty>{item.occupancy?.name}</ListProperty>
                </ListDataItem>
              ))}
            </ListData>
          </Grid>
        </>
      )}
    </>
  )
}

export default BuildingIndex
