import React, { useCallback, useEffect, useRef } from "react"
import { Container, Grid, Tab } from "@mui/material"

import { useApiRead } from "../../../../shared/hooks/useApiRead"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import PageHeader from "../../../../shared/components/pages/PageHeader"
import { type IServiceUser, SERVICE_USER_ENDPOINT } from "../../../../shared/models/service/IServiceUser"
import ViewProperty from "../../../../shared/components/ViewProperty"
import { SERVICE_USER_EDIT_URL } from "../../config/urls"
import PaperLocal from "../../../../shared/components/containers/PaperLocal"
import ViewPhone from "../../../../shared/components/display/ViewPhone"
import ViewEmail from "../../../../shared/components/display/ViewEmail"
import ViewRichText from "../../../../shared/components/display/ViewRichText"
import FormatDate from "../../../../shared/components/format/FormatDate"
import CheckMark from "../../../../shared/components/display/CheckMark"
import ItemViewerDrawer from "../../../../shared/components/item_viewer/ItemViewerDrawer"
import ContactInfo from "../contacts/components/ContactInfo"
import useApiAction, { type IUseApiActionProps } from "../../../../shared/hooks/useApiAction"
import { LoadingButton } from "@mui/lab"
import { ItemPrefixes } from "../../../../config/config"
import ViewListItems from "../../../../shared/components/display/ViewListItems"
import TabPanel, { useTabPanel } from "../../../../shared/components/tabs/TabPanel"
import ViewLoading from "../../../../shared/components/ViewLoading"
import ErrorMessage from "../../../../shared/components/ErrorMessage"
import { useTranslation } from "react-i18next"
import { useOnDemandPaged } from "../../../../shared/hooks/useApiPagedLocal"
import { type IFile, SERVICE_USER_FILE_ENDPOINT } from "../../../../shared/models/service/IFile"
import type { IMainModel } from "../../../../shared/models/service/IMainModel"
import FilesIndex from "../../../../shared/components/files/FilesIndex"
import { type IFilter } from "../../../../shared/models/components/IFilter"
import ServiceUserPermissions from "./components/ServiceUserPermissions"
import TabsList from "../../../../shared/components/tabs/TabsList"

const repository = new RestRepository<IServiceUser>(SERVICE_USER_ENDPOINT)
const filesRepository = new RestRepository<IFile | IMainModel>(SERVICE_USER_FILE_ENDPOINT)

/**
 * Renders a page to view locations impairment details.
 *
 * @returns {React.ReactElement} The rendered component.
 */
const ViewPage: React.FC = (): React.ReactElement => {
  const apiRead = useApiRead<IServiceUser>({ apiFunction: repository.read })
  const initialLoad = useRef<boolean>(false)
  const { data, error, loading } = apiRead
  const { tab, handleTabChange } = useTabPanel()
  const { t } = useTranslation()

  const filesPagingResults = useOnDemandPaged<IFile>(SERVICE_USER_FILE_ENDPOINT, [], "FILES")

  const actionProps: IUseApiActionProps<IServiceUser> = { repository, itemId: data?.id }
  const apiAction = useApiAction<IServiceUser>(actionProps)

  const handleToggleEnabled = useCallback(async () => {
    await apiAction.callAction("toggle_enabled_user")
    await apiRead.call()
  }, [apiAction.callAction])

  const handleToggleVerified = useCallback(async () => {
    await apiAction.callAction("toggle_verified")
    await apiRead.call()
  }, [apiAction.callAction])

  useEffect(() => {
    if (!initialLoad.current && data?.id !== undefined && tab === 1) {
      initialLoad.current = true
      const theFilter: IFilter[] = [
        {
          field: "service_user",
          value: data.id,
          title: "Files",
          display: data.name,
          canBeDelete: false,
        },
      ]
      filesPagingResults.handleFilter(theFilter)
    }
  }, [initialLoad, data?.id, tab])

  return (
    <Container fixed>
      <ErrorMessage error={error} />
      <ViewLoading loading={loading} />
      {data !== undefined && (
        <Grid container spacing={2}>
          <PageHeader title="Service User" titlePlural={data.name} toEdit={`${SERVICE_USER_EDIT_URL}/${data.id}`} />

          <Grid item xs={12}>
            <ViewLoading loading={apiRead.loading || apiAction.saving} />
            {apiRead?.error !== undefined && <ErrorMessage error={apiRead.error} />}
            {apiAction?.connectionError !== undefined && <ErrorMessage error={apiAction.connectionError} />}

            <TabsList value={tab} onChange={handleTabChange}>
              <Tab label={t("Overview")} value={0} />
              <Tab label={t("Files")} value={1} />
              <Tab label={t("Permissions")} value={2} />
            </TabsList>
          </Grid>

          <Grid item xs={12}>
            <TabPanel value={tab} index={0}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <ItemViewerDrawer prefix={ItemPrefixes.contact} infoView={ContactInfo} />
                  <PaperLocal>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <ViewProperty label="Job Title">{data.job_title}</ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label="Phone">
                          <ViewPhone ext={data.phone_ext}>{data.phone}</ViewPhone>
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label="Phone 2">
                          <ViewPhone ext={data.phone_2_ext}>{data.phone_2}</ViewPhone>
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label="Email">
                          <ViewEmail>{data.email}</ViewEmail>
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label="Email 2">
                          <ViewEmail>{data.email_2}</ViewEmail>
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label="Notes" vertical>
                          <ViewRichText>{data.notes}</ViewRichText>
                        </ViewProperty>
                      </Grid>
                    </Grid>
                  </PaperLocal>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <PaperLocal>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <ViewProperty label="Last Login">
                          <FormatDate value={data.last_login} />
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label="Verified">
                          <FormatDate value={data.verified} />
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label="Enabled User">
                          <CheckMark value={data.has_enabled_user_account} />
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label="Linked Contact">
                          <ViewListItems prefix={ItemPrefixes.contact}>{data.contact}</ViewListItems>
                        </ViewProperty>
                      </Grid>
                    </Grid>
                  </PaperLocal>
                  <PaperLocal sx={{ mt: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <ViewProperty label="Verified User">
                          <LoadingButton
                            onClick={handleToggleVerified}
                            color={data.verified === null ? "primary" : "error"}
                            loading={apiAction.saving}>
                            {data.verified === null ? "Verify User" : "Un-verify User"}
                          </LoadingButton>
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label="Enabled User">
                          <LoadingButton
                            onClick={handleToggleEnabled}
                            color={data.has_enabled_user_account ? "error" : "primary"}
                            loading={apiAction.saving}>
                            {data.has_enabled_user_account ? "Disable User" : "Enable User"}
                          </LoadingButton>
                        </ViewProperty>
                      </Grid>
                    </Grid>
                  </PaperLocal>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <FilesIndex
                parentId={data.id}
                parentFieldName="service_user"
                repository={filesRepository}
                pagingResults={filesPagingResults}
              />
            </TabPanel>
            <TabPanel value={tab} index={2}>
              <ServiceUserPermissions serviceUser={data} onChange={apiRead.call} />
            </TabPanel>
          </Grid>
        </Grid>
      )}
    </Container>
  )
}

export default ViewPage
