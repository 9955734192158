import _ from "lodash"
import { type ISelectItem } from "../models/components/ISelectItem"
import sanitizeHtml from "sanitize-html"

interface IRequiredResponse {
  required: {
    value: boolean
    message: string
  }
}

/**
 * Use for a forms control that is required.
 *
 * @param {string} message to be displayed on error.
 * @returns {IRequiredResponse} the required rule
 */
export const requiredRule = (message: string = "Required"): IRequiredResponse => {
  return {
    required: {
      value: true,
      message,
    },
  }
}

/**
 * Converts a given name to a label format.
 *
 * @param {string} name - The name to be converted.
 * @param {string} [label] - Optional: The label to be used. If not provided or empty, the name will be used as the label.
 * @returns {string} - The converted label.
 */
export const nameToLabel = (name: string, label?: string | null): string => {
  if (label === undefined || label === null || label === "") {
    if (name.includes(".")) {
      return _.startCase(_.last(name.split(".")))
    }
    return _.startCase(name)
  }
  return label
}

/**
 * Maps Django options to Material-UI Select options.
 *
 * @param {Record<string, Array<Record<string, string>>>} djangoOptions - The Django options to be mapped.
 * @returns {Record<string, ISelectItem[]>} - The mapped Material-UI Select options.
 */
export const djangoOptionsToMuiSelect = (
  djangoOptions: Record<string, Array<Record<string, string>>>,
): Record<string, ISelectItem[]> => {
  const selectOptions: Record<string, ISelectItem[]> = {}
  Object.keys(djangoOptions).forEach(key => {
    const record: Array<Record<string, string>> = djangoOptions[key]
    selectOptions[key] = record.map(entry => ({ key: entry[0], value: entry[1] }) satisfies ISelectItem)
  })
  return selectOptions
}

export const sanitizeOptions: sanitizeHtml.IOptions = {
  allowedTags: ["b", "br", "i", "p", "ul", "ol", "li", "u"],
  transformTags: {
    div: "p",
  },
}

/**
 * Clean HTML by removing empty <p></p> and <p><br /></p> tags,
 * and add <p> tags to the cleaned HTML if it doesn't start with a tag.
 *
 * @param {string} html - the HTML string to clean
 * @returns {string} - the cleaned HTML string
 */
export const cleanHtml = (html: string): string => {
  let cleaned = sanitizeHtml(html, sanitizeOptions).replace("<br />", "<br>")
  // This can occur when pasting from an external source. Cleaned HTML might not have any tags.
  if (!cleaned.startsWith("<p>") && !cleaned.endsWith("<ol>") && !cleaned.endsWith("<ul>")) {
    cleaned = `<p>${cleaned}</p>`
  }
  return cleaned
}
