import PrivateRoute from "../components/PrivateRoute"
import AccessDenied from "../pages/auth/AccessDenied"
import React from "react"
import { type IPageLayoutProps } from "../layouts/models/IPageLayoutProps"
import { type RouteObject } from "react-router-dom"



/**
 * Retrieves the base application name from the current URL's pathname.
 *
 * This function splits the current pathname by slashes and returns the
 * second segment as the base application name. If the pathname does not
 * contain a second segment, the function returns undefined.
 *
 * @returns {string | undefined} The base application name or undefined if not present in the pathname.
 */
export const getBaseApp = (): string | undefined => {
  return location.pathname.split("/")?.[1]
}

/**
 * Checks if the given string matches the base application path.
 *
 * @param {string} baseApp - The string to compare with the base path of the current location.
 * @returns {boolean} - Returns true if the baseApp matches the base path; otherwise, false.
 */
export const isBaseApp = (baseApp: string): boolean => {
  return baseApp === location.pathname.split("/")?.[1]
}

/**
 * Merges two arrays of route objects into a single array.
 *
 * @param {any[]} routes - The primary array of route objects.
 * @param {any[]} addonRoutes - The array of additional route objects to be merged.
 * @returns {any[]} - A new array containing all route objects from both input arrays.
 */
export const routesMerge = (routes: RouteObject[], addonRoutes: RouteObject[]): RouteObject[] => {
  return addonRoutes.concat(routes)
}

export interface IPageRoute {
  path: string
  title: string
  page: React.FC
  layout: React.FC<IPageLayoutProps>
}

/**
 * Adds a new route configuration.
 *
 * @param {IPageRoute} route - The route object containing the path and metadata.
 * @returns {any} The configured route object with the specified path and component elements.
 */
export const addRoute = (route: IPageRoute): any => {
  const { layout: Layout } = route
  return {
    path: route.path,
    element: (
      <PrivateRoute
        component={() => <Layout title={route.title} component={route.page} />}
        accessDeniedComponent={() => <Layout title="Access Denied" component={AccessDenied} />}
      />
    ),
  }
}
