import { red } from "@mui/material/colors"
import { createTheme } from "@mui/material/styles"
import { type PaletteMode, type Theme } from "@mui/material"

/**
 * Create a theme with the provided primary and secondary colors.
 *
 * @param {string} primary - The primary color value in RGBA format. Defaults to "rgba(96,28,29,.88)".
 * @param {string} secondary - The secondary color value in hex code format. Defaults to "#d9aa4a".
 * @param {PaletteMode} mode - Display the site in light or dark mode.
 * @returns {object} The created theme object with the following properties:
 *   - `palette`: An object with color definitions for primary, secondary, and error.
 *     - `primary`: An object with the main color value.
 *     - `secondary`: An object with the main color value.
 *     - `error`: An object with the main color value of red.A400.
 *   - `shape`: An object with a single property `borderRadius` set to 8.
 */
const theme = (
  primary: string = "#D77340",
  secondary: string = "#40A4D7",
  mode: PaletteMode = "light",
): Theme => {
  return createTheme({
    palette: {
      mode,
      primary: {
        main: primary,
      },
      secondary: {
        main: secondary,
      },
      error: {
        main: red.A400,
      },
    },
    shape: {
      borderRadius: 8,
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "capitalize",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: "capitalize",
          },
        },
      },
      MuiContainer: {
        styleOverrides: {
          root: ({ theme }) => theme.unstable_sx({ p: 0 }),
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: ({ theme }) => theme.unstable_sx({ p: 0 }),
          root: ({ theme }) => theme.unstable_sx({ zIndex: 1500 }),
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: ({ theme }) => theme.unstable_sx({ p: 0 }),
        },
      },
      MuiCard: {
        styleOverrides: {
          root: ({ theme }) => theme.unstable_sx({ p: 0 }),
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          popper: ({ theme }) => {
            return theme.unstable_sx({ "& .MuiPaper-root": { p: 0 } })
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: ({ theme }) => theme.unstable_sx({ p: 2 }),
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: ({ theme }) => theme.unstable_sx({ whiteSpace: "nowrap" }),
        },
      },
      MuiListSubheader: {
        styleOverrides: {
          root: {
            backgroundColor: "transparent",
            fontWeight: 700,
            lineHeight: 1.5,
            textTransform: "uppercase",
            fontFamily: '"Public Sans", sans-serif',
            color: "rgb(145, 158, 171)",
            mb: 1,
            padding: "16px 8px 8px 12px",
          },
        },
      },
    },
  })
}

export default theme
