import { type DependencyList, useEffect } from "react"

type TAsyncFunction = () => Promise<void>

/**
 * Custom hook that executes an asynchronous function as a side effect in a React functional component.
 *
 * @param {TAsyncFunction} asyncFunction - The asynchronous function to be executed.
 * @param {DependencyList} deps - An array of dependencies for the effect.
 * @returns {void}
 */
const useEffectAsync = (asyncFunction: TAsyncFunction, deps: DependencyList): void => {
  useEffect(() => {
    void (async () => {
      await asyncFunction()
    })()
  }, deps)
}

export default useEffectAsync
