import type IToken from "../models/users/IToken"
import { BASE_URL } from "../utilities/request_utility"
import type IUser from "../models/users/IUser"
import axios from "axios"

/**
 * Get user information.
 *
 * @param {IToken} token for the user to lookup.
 * @returns {Promise<IUser>} the user info.
 */
export const me = async (token: IToken): Promise<IUser> => {
  const config = { headers: { Authorization: `Bearer ${token.access}` } }
  const { data: users } = await axios.get(`${BASE_URL}/users/me/`, config)
  return users[0]
}
