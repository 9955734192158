import React, { useCallback, useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material"
import HtmlToolTip from "./HtmlToolTip"
import DialogControls from "./DialogControls"

interface IProps {
  buttonText: string
  buttonIcon?: React.JSX.Element | undefined
  showIconAndText?: boolean
  title?: string
  message: string | React.ReactNode
  onYes: (event: React.MouseEvent) => void
}

/**
 * This component displays an alert before doing something.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the alert component.
 */
const AlertDialog: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { buttonText, buttonIcon, showIconAndText = false, onYes, title = "Confirm", message } = props

  const [open, setOpen] = useState<boolean>(false)

  const handleOpen = useCallback((event: React.MouseEvent) => {
    event.stopPropagation()
    setOpen(true)
  }, [])

  const handleClose = useCallback((event: React.MouseEvent) => {
    event.stopPropagation()
    setOpen(false)
  }, [])

  const handleYes = useCallback(
    async (event: React.MouseEvent) => {
      event.stopPropagation()
      setOpen(false)
      onYes(event)
    },
    [onYes],
  )

  return (
    <>
      {buttonIcon !== undefined && !showIconAndText ? (
        <HtmlToolTip title={buttonText}>
          <IconButton color="primary" onClick={handleOpen} size="small">
            {buttonIcon}
          </IconButton>
        </HtmlToolTip>
      ) : (
        <Button size="small" startIcon={showIconAndText ? buttonIcon : undefined} onClick={handleOpen} variant="text">
          {buttonText}
        </Button>
      )}
      <Dialog open={open} onClose={handleClose} sx={{ zIndex: 1500 }} aria-labelledby={title} aria-hidden="false" aria-modal="true">
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{message}</DialogContent>
        <DialogActions>
          <DialogControls onSave={handleYes} onCancel={handleClose} buttonLabel="Yes, I'm Sure" />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AlertDialog
