import { type IOccupancy } from "./IOccupancy"
import { type ILocation } from "./ILocation"
import { type IMainModel } from "./IMainModel"

export const BUILDING_ENDPOINT = "buildings"

export interface IBuilding extends IMainModel {
  id: number
  name: string
  occupancy: IOccupancy | null
  location: ILocation
  longitude: number
  latitude: number
  zoom: number
  area: number | null
  notes: string | null
  created: string
  updated: string
}
