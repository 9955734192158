import React from "react"
import { Grid } from "@mui/material"
import { type ILocation, LOCATION_ENDPOINT } from "../../../../shared/models/service/ILocation"
import LocationIndex from "./components/LocationIndex"
import LocationsMap from "./components/LocationsMap"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import { type IUseApiPagedResultsProps, useApiPagedLocal } from "../../../../shared/hooks/useApiPagedLocal"
import { useParams } from "react-router-dom"

const repository = new RestRepository<ILocation>(LOCATION_ENDPOINT)

/**
 * Renders the index page for the locations.
 *
 * @returns {React.ReactElement} The rendered index page.
 */
const IndexPage: React.FC = (): React.ReactElement => {
  const props: IUseApiPagedResultsProps<ILocation> = { apiFunction: repository.findAll }
  const pagingResults = useApiPagedLocal<ILocation>(props)
  const { display } = useParams()

  return (
    <Grid container spacing={2}>
      {display === undefined && <LocationIndex pagingResults={pagingResults} />}
      {display === "map" && <LocationsMap pagingResults={pagingResults} />}
    </Grid>
  )
}

export default IndexPage
