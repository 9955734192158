import type React from "react";
import { useCallback } from "react"
import { type SelectOptions } from "../../../config/config"
import { useSelectOptions } from "../../hooks/useSelectOptions"

interface IUseSelectOptionsResponse {
  getSelectValue: (keyLookup: string | number) => string
}

/**
 * Retrieves the selected value from a list of select options.
 *
 * @param {SelectOptions} selectOption - The list of select options.
 * @returns {IUseSelectOptionsResponse} - An object containing the `getSelectValue` function.
 */
export const useViewSelectOption = (selectOption: SelectOptions): IUseSelectOptionsResponse => {
  const theSelectOptions = useSelectOptions(selectOption)

  const getSelectValue = useCallback((keyLookup: string | number) => {
    const firstMatch = theSelectOptions.find(option => `${option.key}` === `${keyLookup}`)
    return `${firstMatch?.value !== undefined ? firstMatch.value : "Not Set"}`
  }, [])
  
  return { getSelectValue }
}

interface IProps {
  keyLookup: string | number | null | undefined
  selectOption: SelectOptions
  showKey?: boolean
}

/**
 * Renders the value of the first select option that matches the given
 * key lookup.
 *
 * @param {IProps} props - The props object.
 * @returns {React.ReactElement} The rendered value of the first select option that matches the key lookup.
 */
const ViewSelectOption: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { keyLookup, selectOption, showKey = false } = props
  const theSelectOptions = useSelectOptions(selectOption)
  const firstMatch = theSelectOptions.find(option => option.key === keyLookup)

  return (
    <>
      {showKey && keyLookup}
      {firstMatch?.value !== undefined ? firstMatch.value : "Not Set"}
    </>
  )
}

export default ViewSelectOption
