import { BASE_URL, getBaseApp } from "../utilities/request_utility"
import { useCallback, useMemo } from "react"
import type ICurrentUser from "../models/users/ICurrentUser"
import type IToken from "../models/users/IToken"
import type ICredentials from "../models/users/ICredentials"
import * as UsersRepository from "../repositories/users_repository"
import { type IConnectionError } from "../models/components/IConnectionError"
import axios from "axios"
import useLocalStorage from "beautiful-react-hooks/useLocalStorage"
import { useLocation, useNavigate } from "react-router-dom"
import { PANARISK_USER_KEY } from "../../config/config"
import { LOGIN_URL } from "../../apps/admin/config/urls"
import { useTranslation } from "react-i18next"
import { type IMainModel } from "../models/service/IMainModel"
import { RestRepository } from "../repositories/RestRepository"
import { PROFILES_ENDPOINT } from "../models/users/IProfile"
import { useAxiosRequest } from "./useAxiosRequest"

export interface IProfilePatch extends IMainModel {
  map_type?: string
  auto_save_risk_writer?: boolean
}

const repository = new RestRepository<IProfilePatch>(PROFILES_ENDPOINT)

const isBrowser = typeof window !== `undefined`

interface IUseAuth {
  loginWithGoogleToken: ((accessToken: string | undefined) => Promise<ICurrentUser>) | null
  loginWithMsalToken: ((accessToken: string | undefined) => Promise<ICurrentUser>) | null
  loginWithCredentials: ((accessToken: ICredentials) => Promise<ICurrentUser>) | null
  resetPassword: ((username: string) => Promise<boolean>) | null
  updateProfile: ((profile: IProfilePatch) => Promise<void>) | null
  changePasswordWithToken: ((password: string, token: string) => Promise<boolean>) | null
  logout: (() => void) | null
  clearUser: (() => void) | null
  refreshUser: (() => Promise<ICurrentUser | null>) | null
  isUserInGroups: ((groupNames: string[] | undefined, user?: ICurrentUser | null | undefined) => boolean) | null
  logoutWithConnectionError: ((reason: IConnectionError) => void) | null
  isLoggedIn: boolean | null
  loading: boolean
  currentUser: ICurrentUser | undefined | null
}

/**
 * Hook for managing authentication.
 *
 * @returns {IUseAuth} the auth hook.
 */
const useAuth = (): IUseAuth => {
  if (typeof window === "undefined") {
    return {
      loginWithGoogleToken: null,
      loginWithMsalToken: null,
      loginWithCredentials: null,
      resetPassword: null,
      changePasswordWithToken: null,
      logout: null,
      clearUser: null,
      refreshUser: null,
      updateProfile: null,
      isUserInGroups: null,
      logoutWithConnectionError: null,
      isLoggedIn: null,
      loading: false,
      currentUser: null,
    }
  }

  const { i18n } = useTranslation()
  const storageKey = `${PANARISK_USER_KEY}_${getBaseApp()}`
  const [currentUser, setCurrentUser] = useLocalStorage<ICurrentUser | null>(storageKey, null)
  const location = useLocation()
  const navigate = useNavigate()
  const axiosRequest = useAxiosRequest()

  const loadUserWithToken = useCallback(
    async (token: IToken): Promise<ICurrentUser> => {
      const user = await UsersRepository.me(token)
      const currentUser1: ICurrentUser = { user, token }
      setCurrentUser(currentUser1)
      await i18n.changeLanguage(user.profile.language)
      return currentUser1
    },
    [setCurrentUser],
  )

  const loginWithCredentials = useCallback(async (credentials: ICredentials): Promise<ICurrentUser> => {
    const { data } = await axios.post(`${BASE_URL}/token/`, credentials)
    return await loadUserWithToken(data as IToken)
  }, [])

  const resetPassword = useCallback(async (username: string): Promise<boolean> => {
    const { data } = await axios.post(`${BASE_URL}/rest-auth/reset_password_email/`, { username })
    return data
  }, [])

  const changePasswordWithToken = useCallback(async (password: string, token: string): Promise<boolean> => {
    const { data } = await axios.post(`${BASE_URL}/rest-auth/change_password_token/`, {
      password,
      token,
    })
    return data
  }, [])

  const loginWithGoogleToken = useCallback(
    async (accessToken: string | undefined): Promise<ICurrentUser> => {
      // Need reset authorization token for login.
      const { data } = await axios.post(`${BASE_URL}/rest-auth/google/`, { access_token: accessToken })
      return await loadUserWithToken(data as IToken)
    },
    [loadUserWithToken],
  )

  const loginWithMsalToken = useCallback(
    async (accessToken: string | undefined): Promise<ICurrentUser> => {
      // Need reset authorization token for login.
      const { data } = await axios.post(`${BASE_URL}/rest-auth/microsoft/`, { access_token: accessToken })
      return await loadUserWithToken(data as IToken)
    },
    [loadUserWithToken],
  )

  const refreshUser = useCallback(async (): Promise<ICurrentUser | null> => {
    if (currentUser?.token !== undefined) {
      return await loadUserWithToken(currentUser.token)
    }
    return null
  }, [currentUser])

  const updateProfile = useCallback(async (profile: IProfilePatch) => {
    if (currentUser?.user.profile.id !== undefined) {
      await axiosRequest.callRequest(async () => {
        await repository.patch(profile, currentUser.user.profile.id)
        await refreshUser()
      })
    }
  }, [currentUser])


  const logout = useCallback(async () => {
    if (!isBrowser) {
      return
    }
    setCurrentUser(null)
    navigate(LOGIN_URL, { state: { refer: location.pathname } })
  }, [])

  const clearUser = useCallback(async () => {
    setCurrentUser(null)
  }, [])

  /**
   * Logout a user if there is an authentication error.
   */
  const logoutWithConnectionError = useCallback(
    async (connectionError: IConnectionError) => {
      if (connectionError.code === "user_not_found" || connectionError.code === "token_not_valid") {
        await logout()
      }
    },
    [logout],
  )

  /**
   * Check to see if the user is logged in.
   */
  const isLoggedIn = useMemo(() => {
    if (!isBrowser) {
      return false
    }
    return currentUser?.token !== undefined
  }, [currentUser])

  /**
   * Check to see if a user is in the specified groups.
   * If user is not passed then the currentUser is used.
   */
  const isUserInGroups = useCallback(
    (groupNames: string[] | undefined, user?: ICurrentUser | null | undefined) => {
      if (user === undefined || user === null) {
        user = currentUser
      }
      if (user?.user?.groups === undefined || groupNames === undefined) {
        return false
      }
      for (const group of user.user.groups) {
        for (const groupName of groupNames) {
          if (group.name === groupName) {
            return true
          }
        }
      }
      return false
    },
    [currentUser],
  )

  return {
    loginWithGoogleToken,
    loginWithMsalToken,
    loginWithCredentials,
    resetPassword,
    changePasswordWithToken,
    logout,
    clearUser,
    refreshUser,
    updateProfile,
    isUserInGroups,
    logoutWithConnectionError,
    isLoggedIn,
    loading: axiosRequest.loading,
    currentUser,
  }
}

export default useAuth
