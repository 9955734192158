import React, { useCallback } from "react"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import { Box, List } from "@mui/material"
import { Link, useLocation } from "react-router-dom"
import HtmlToolTip from "../../components/HtmlToolTip"

interface IProps {
  url: string
  name: string
  open: boolean
  icon?: React.ComponentType
  children?: React.JSX.Element | React.JSX.Element[] | boolean | undefined
}

/**
 * Renders a navigation menu link.
 *
 * @param {IProps} props - The properties for the navigation menu link.
 * @returns {React.ReactElement} The rendered navigation menu link.
 */
const NavMenuLink: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { url, name, open, icon: Icon, children } = props

  const location = useLocation()
  const isSelected = useCallback(
    (path: string) => {
      return location.pathname.startsWith(path) || path.startsWith(location.pathname)
    },
    [location],
  )

  return (
    <>
      <HtmlToolTip title={!open && name} placement="right-end">
        <ListItemButton
          component={Link}
          to={url}
          selected={isSelected(url)}
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
            ml: 1,
            mt: 0.5,
            mb: 0.5,
          }}>
          {Icon !== undefined && (
            <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : "auto", justifyContent: "center", color: "inherit" }}>
              <Icon />
            </ListItemIcon>
          )}
          <ListItemText primary={name} sx={{ opacity: open ? 1 : 0 }} />
        </ListItemButton>
      </HtmlToolTip>
      {children !== undefined && (
        <List component="div" disablePadding>
          <Box sx={{ pl: 3 }}>{children}</Box>
        </List>
      )}
    </>
  )
}

export default NavMenuLink
