import React, { type ChangeEvent, useCallback, useMemo, useState } from "react"
import DrawerRight from "../../containers/DrawerRight"
import FilterIcon from "@mui/icons-material/FilterAlt"
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  List,
  Popper,
  type PopperProps,
  TextField,
  Typography,
} from "@mui/material"
import type { IComboItem } from "../../../models/components/IComboItem"
import { findOperationByString, type ITableFilter, Operation } from "../utilities/table_utilities"
import { type IColumn } from "../models/IRiskWriterTable"
import { Clear } from "@mui/icons-material"
import ListItem from "@mui/material/ListItem"
import DeleteIcon from "@mui/icons-material/Delete"

interface IProps {
  columns: IColumn[] | undefined
  currentFilters: ITableFilter[]
  onChange: (filters: ITableFilter[]) => void
}

// eslint-disable-next-line require-jsdoc
const RiskWriterTableFilters: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { columns, currentFilters, onChange } = props

  const [selectedFilter, setSelectedFilter] = useState<IComboItem | null>(null)
  const [column, setColumn] = useState<IComboItem | null>(null)
  const [value, setValue] = useState<string>("")

  const handleFilter = useCallback((_event: any, comboItem: IComboItem | null) => {
    setSelectedFilter(comboItem)
  }, [])

  const handleColumn = useCallback((_event: any, comboItem: IComboItem | null) => {
    setColumn(comboItem)
  }, [])

  const handleValue = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }, [])

  const filtersToShowComboItems: IComboItem[] = useMemo(() => {
    return Object.entries(Operation).map(([key]) => ({ label: key, value: key }))
  }, [])

  const columnsToShowComboItems: IComboItem[] = useMemo(() => {
    return columns?.map((column: IColumn) => ({ label: column.title, value: column.name })) ?? []
  }, [columns])

  const handleApplyFilter = useCallback(() => {
    const operation = findOperationByString(selectedFilter?.value as string)
    if (selectedFilter?.value !== undefined && column?.value !== undefined && operation !== undefined) {
      const filter: ITableFilter = { operation, column_name: column.value as string, value }
      onChange([...currentFilters, filter])
    }
  }, [currentFilters, selectedFilter?.value, column?.value, value])

  const handleDeleteFilter = useCallback(
    (index: number) => () => {
      onChange([...currentFilters.filter((_, i) => i !== index)])
    },
    [currentFilters],
  )

  const handleClear = useCallback(() => {
    onChange([])
  }, [])

  /**
   * This is needed so the autocomplete can be used in right drawer on the report writer.
   * See public/index.html for styling.
   */
  const PopperSelectFilter = useCallback((props: PopperProps) => {
    return <Popper {...props} id="AutocompletePopper" />
  }, [])

  return (
    <DrawerRight title="Filters" icon={<FilterIcon />}>
      <Grid container spacing={2} sx={{ mt: 1 }} alignItems="center">
        <Grid item xs={12}>
          <Autocomplete
            fullWidth
            autoHighlight
            PopperComponent={PopperSelectFilter}
            onChange={handleFilter}
            value={selectedFilter}
            renderInput={params => <TextField {...params} autoFocus label="Filter" />}
            options={filtersToShowComboItems}
          />
        </Grid>
        {selectedFilter !== null && (
          <>
            <Grid item xs={12}>
              <Autocomplete
                fullWidth
                autoHighlight
                PopperComponent={PopperSelectFilter}
                onChange={handleColumn}
                value={column}
                renderInput={params => <TextField {...params} autoFocus label="Column" />}
                options={columnsToShowComboItems}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth label="Value" value={value} onChange={handleValue} />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "right" }}>
              <Button onClick={handleApplyFilter}>Add Filter</Button>
            </Grid>
          </>
        )}
      </Grid>
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            <Typography variant="h5">Current Filters</Typography>
          </Grid>
          <Grid item>
            <Button startIcon={<Clear />} onClick={handleClear}>
              Clear All
            </Button>
          </Grid>
        </Grid>
        <List>
          {currentFilters?.map((filter: ITableFilter, index: number) => (
            <ListItem
              key={index}
              secondaryAction={
                <IconButton edge="end" onClick={handleDeleteFilter(index)}>
                  <DeleteIcon />
                </IconButton>
              }>
              {filter.column_name} - {filter.operation.valueOf()} - {filter.value}
            </ListItem>
          ))}
        </List>
        {(currentFilters?.length === undefined || currentFilters.length === 0) && (
          <Alert severity="info">No filters created.</Alert>
        )}
      </Box>
    </DrawerRight>
  )
}

export default RiskWriterTableFilters
