import React, { useContext } from "react"
import { TableCell } from "@mui/material"
import FormatNumber from "../format/FormatNumber"
import type { IPanariskAppContext } from "../../models/app/IPanariskAppContext"
import { PanariskAppContext } from "../../../app/PanariskApp"
import { SelectOptions } from "../../../config/config"
import { useSelectOptions } from "../../hooks/useSelectOptions"

interface IProps {
  children: number | null | undefined
  twoDecimalPlaces?: boolean
  field?: string
}

/**
 * Renders a TableCell with formatted currency value.
 *
 * @param {IProps} props - The component props.
 * @returns {React.ReactElement} - The rendered TableCell with formatted currency value.
 */
const TableCellCurrency: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children, twoDecimalPlaces = false } = props

  const { appSettings } = useContext<IPanariskAppContext>(PanariskAppContext)
  const currency = appSettings?.serverInfo?.currency
  const theSelectOptions = useSelectOptions(SelectOptions.CURRENCY_SYMBOLS)
  const firstMatch = theSelectOptions.find(option => option.key === currency)

  return (
    <TableCell sx={{ textAlign: "right" }}>
      <FormatNumber
        value={children}
        prefixUnits={firstMatch?.value !== undefined ? firstMatch.value : undefined}
        twoDecimalPlaces={twoDecimalPlaces}
      />
    </TableCell>
  )
}

export default TableCellCurrency
