import { type IMainModel } from "./IMainModel"
import type { IListItem } from "../components/IListItem"

export const REST_API_KEY_ENDPOINT = "rest_api_keys"

export const restPermissions: IListItem[] = [
  { id: "account", name: "Account" },
  { id: "account_file", name: "Account File" },
  { id: "building", name: "Building" },
  { id: "contact", name: "Contact" },
  { id: "file_tag", name: "File Tag" },
  { id: "inspection", name: "Inspection" },
  { id: "inspection_building", name: "Inspection Building" },
  { id: "inspection_recommendation", name: "Inspection Recommendation" },
  { id: "location_file", name: "Location File" },
  { id: "location_impairment_file", name: "Location Impairment Impairment" },
  { id: "location_impairment", name: "Location Impairment" },
  { id: "location", name: "Location" },
  { id: "occupancy", name: "Occupancy" },
  { id: "policy_file", name: "Policy File" },
  { id: "policy", name: "Policy" },
  { id: "recommendation", name: "Recommendation" },
  { id: "report_format", name: "Report Format" },
  { id: "service_user_file", name: "Service User File" },
  { id: "service_user", name: "Service User" },
  { id: "summary_brief", name: "Summary Brief" },
  { id: "summary_file", name: "Summary File" },
  { id: "summary", name: "Summary" },
  { id: "vendor", name: "Vendor" },
]

export interface IRestApiKey extends IMainModel {
  id: number
  name: string
  prefix: string
  api_key?: string
  created: string
  expiry_date: string
  revoked: boolean

  account_create: boolean
  account_read: boolean
  account_update: boolean
  account_delete: boolean

  building_create: boolean
  building_read: boolean
  building_update: boolean
  building_delete: boolean

  contact_create: boolean
  contact_read: boolean
  contact_update: boolean
  contact_delete: boolean
}

