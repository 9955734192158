import { Alert, Grid, IconButton, Snackbar } from "@mui/material"
import React, { useCallback } from "react"
import { type IConnectionError } from "../models/components/IConnectionError"
import TruncateText from "./TruncateText"
import { CopyToClipboard } from "react-copy-to-clipboard"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"

interface IProps {
  error: IConnectionError | null | undefined
}

/**
 * Displays the error message returned from a connection request.
 *
 * @param {IProps} props see IProps for details.
 * @returns {React.FC<IProps>} the component.
 */
const ErrorMessage: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { error } = props
  const [open, setOpen] = React.useState(false)

  const handleCopy = useCallback(() => {
    setOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  return error !== null && error !== undefined ? (
    <Grid container alignItems="center">
      <Grid item xs>
        <Alert
          severity="error"
          action={
            <CopyToClipboard text={Object.values(error.data).join(", ")} onCopy={handleCopy}>
              <IconButton size="small">
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            </CopyToClipboard>
          }>
          <TruncateText num={50}>{"Issues: " + Object.values(error.data).join(", ")}</TruncateText>
        </Alert>
        <Snackbar
          open={open}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={1000}
          onClose={handleClose}>
          <Alert severity="info">Error copied.</Alert>
        </Snackbar>
      </Grid>
    </Grid>
  ) : (
    <></>
  )
}

export default ErrorMessage
