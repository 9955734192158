import React, { useCallback, useEffect, useState } from "react"
import { type UseFormReturn } from "react-hook-form/dist/types"
import { Box, Button, Grid, Typography } from "@mui/material"
import FhMuiHiddenField from "../../../components/forms/FhMuiHiddenField"
import { type IInspectionRecommendation } from "../../../models/service/IInspectionRecommendation"
import useLoadFormData from "../../../hooks/useLoadFormData"
import SelectFilteredSingle, {
  useSelectFilteredSingle,
} from "../../../components/forms/SelectFilteredSingle"
import { type IListItem } from "../../../models/components/IListItem"
import { RestRepository } from "../../../repositories/RestRepository"
import FhMuiTextField from "../../../components/forms/FhMuiTextField"
import FhMuiRichTextField from "../../../components/forms/FhMuiRichTextField"
import { type IInspection, INSPECTION_ENDPOINT } from "../../../models/service/IInspection"
import PaperLocal from "../../../components/containers/PaperLocal"
import { PriorityTypes } from "../../../../config/config"
import FormatNumberAndError from "../../../components/format/FormatNumberAndError"
import DrawerRight from "../../../components/containers/DrawerRight"
import { type IRecommendation, RECOMMENDATION_ENDPOINT } from "../../../models/service/IRecommendation"
import RecommendationInfo from "../../../../apps/admin/pages/recommendations/components/RecommendationInfo"
import TruncateText from "../../../components/TruncateText"
import { type IPriority, PRIORITY_ENDPOINT } from "../../../models/service/IPriority"
import {
  type IRiskScoreCategory,
  RISK_SCORE_CATEGORY_ENDPOINT
} from "../../../models/service/IRiskScoreCategory"
import {
  type IRecommendationType,
  RECOMMENDATION_TYPE_ENDPOINT
} from "../../../models/service/IRecommendationType"
import {
  type IRecommendationStatus,
  RECOMMENDATION_STATUS_ENDPOINT
} from "../../../models/service/IRecommendationStatus"

interface IProps {
  form: UseFormReturn
  inspectionRecommendation?: IInspectionRecommendation | undefined | null
  isEdit?: boolean
  parentInspection?: IInspection
}

const inspectionRepository = new RestRepository<IInspection | IListItem>(INSPECTION_ENDPOINT)
const recommendationRepository = new RestRepository<IListItem>(RECOMMENDATION_ENDPOINT)
const priorityRepository = new RestRepository<IPriority | IListItem>(PRIORITY_ENDPOINT)
const riskScoreCategoryFormatRepository = new RestRepository<IRiskScoreCategory | IListItem>(RISK_SCORE_CATEGORY_ENDPOINT)
const recommendationTypeRepository = new RestRepository<IRecommendationType | IListItem>(RECOMMENDATION_TYPE_ENDPOINT)
const recommendationStatusRepository = new RestRepository<IRecommendationStatus | IListItem>(RECOMMENDATION_STATUS_ENDPOINT)

/**
 * Use this forms to add or edit a recommendation.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} returns the forms.
 */
const InspectionRecommendationForm: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { inspectionRecommendation, parentInspection, form, isEdit = false } = props

  const [inspection, setInspection] = useSelectFilteredSingle("inspection", form)
  const [priority, setPriority] = useSelectFilteredSingle("priority", form)
  const [recListItem, setRecListItem] = useState<IListItem | null>(null)
  const [recommendation, setRecommendation] = useState<IRecommendation | null>(null)
  const [riskScoreCategory, setRiskScoreCategory] = useSelectFilteredSingle("risk_score_category", form)
  const [status, setStatus] = useSelectFilteredSingle("status", form)
  const [type, setType] = useSelectFilteredSingle("type", form)

  useLoadFormData<IInspectionRecommendation>(
    (data: IInspectionRecommendation) => {
      form.setValue("id", data.id)
      form.setValue("title", data.title)
      form.setValue("body", data.body)
      form.setValue("loss_estimate", data.loss_estimate)
      form.setValue("comment", data.comment)
      form.setValue("reply", data.reply)

      form.setValue("loss_estimate_before", Number(data.loss_estimate_before))
      form.setValue("loss_estimate_after", Number(data.loss_estimate_after))
      form.setValue("cost_to_complete", Number(data.cost_to_complete))

      form.setValue("risk_score_deduction", data.risk_score_deduction)

      setStatus(data.status)
      setType(data.type)
      setRiskScoreCategory(data.risk_score_category)

      setPriority(data.priority)
      setInspection(data.inspection)
    },
    inspectionRecommendation,
    isEdit,
    form.setValue,
  )

  const handleUseRecommendation = useCallback(() => {
    if (recommendation !== null) {
      form.setValue("title", recommendation.title)
      form.setValue("body", recommendation.body)
      form.setValue("loss_estimate", recommendation.loss_estimate)
      form.setValue("comment", recommendation.comment)

      setPriority(recommendation.priority)
      setRiskScoreCategory(recommendation.risk_score_category)
      setType(recommendation.type)

      form.setValue("risk_score_deduction", recommendation.risk_score_deduction)
    }
  }, [recommendation])

  useEffect(() => {
    if (parentInspection !== undefined) {
      setInspection(parentInspection)
    }
  }, [parentInspection])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs />
          <Grid item>
            <DrawerRight title="Recommendations">
              <Box>
                <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
                  <Grid item xs={12}>
                    <SelectFilteredSingle
                      name="recommendation"
                      defaultValue={recListItem}
                      repository={recommendationRepository}
                      onChange={setRecListItem}
                    />
                  </Grid>
                  <Grid item xs />
                  <Grid item>
                    <Button onClick={handleUseRecommendation} disabled={recommendation === null}>
                      Use Recommendation
                    </Button>
                  </Grid>
                </Grid>
                {recListItem?.id !== undefined && (
                  <RecommendationInfo infoId={recListItem.id} onChange={setRecommendation} />
                )}
              </Box>
            </DrawerRight>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={6}>
        {isEdit && <FhMuiHiddenField control={form.control} />}
        <PaperLocal>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {parentInspection !== undefined ? (
                <Typography variant="subtitle2">
                  <TruncateText num={40}>{parentInspection.name}</TruncateText>
                </Typography>
              ) : (
                <SelectFilteredSingle
                  name="inspection"
                  defaultValue={inspection}
                  repository={inspectionRepository}
                  onChange={setInspection}
                />
              )}
            </Grid>
          </Grid>
        </PaperLocal>
        <PaperLocal sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FhMuiTextField control={form.control} name="title" />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FhMuiTextField control={form.control} name="loss_estimate_before" defaultValue={0} formatter={FormatNumberAndError} />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FhMuiTextField control={form.control} name="loss_estimate_after" defaultValue={0} formatter={FormatNumberAndError} />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FhMuiTextField control={form.control} name="cost_to_complete" defaultValue={0} formatter={FormatNumberAndError} />
            </Grid>
            <Grid item xs={12} lg={6}>
              <SelectFilteredSingle
                name="status"
                defaultValue={status}
                repository={recommendationStatusRepository}
                onChange={setStatus}
              />
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12} lg={6}>
              <SelectFilteredSingle
                name="priority"
                defaultValue={priority}
                filters={[{ field: "priority_type", value: PriorityTypes.RECOMMENDATION }]}
                repository={priorityRepository}
                onChange={setPriority}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <SelectFilteredSingle
                name="type"
                defaultValue={type}
                repository={recommendationTypeRepository}
                onChange={setType}
              />
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12} lg={6}>
              <SelectFilteredSingle
                name="risk_score_category"
                defaultValue={riskScoreCategory}
                repository={riskScoreCategoryFormatRepository}
                onChange={setRiskScoreCategory}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <FhMuiTextField control={form.control} name="risk_score_deduction" />
            </Grid>
          </Grid>
        </PaperLocal>
      </Grid>
      <Grid item xs={12} lg={6}>
        <PaperLocal>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FhMuiRichTextField control={form.control} name="body" />
            </Grid>
            <Grid item xs={12}>
              <FhMuiRichTextField control={form.control} name="loss_estimate" />
            </Grid>
            <Grid item xs={12}>
              <FhMuiRichTextField control={form.control} name="comment" />
            </Grid>
            <Grid item xs={12}>
              <FhMuiRichTextField control={form.control} name="reply" />
            </Grid>
          </Grid>
        </PaperLocal>
      </Grid>
    </Grid>
  )
}

export default InspectionRecommendationForm
