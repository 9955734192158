import React from "react"
import ReactDOM from "react-dom/client"
// import reportWebVitals from "./reportWebVitals"
import PanariskSuite from "./pr_core_frontend/src/panarisk/app/PanariskSuite"


const rootElement = document.getElementById("root")
if (rootElement !== null) {
    const root = ReactDOM.createRoot(rootElement)

    root.render(
        <React.StrictMode>
            <PanariskSuite />
        </React.StrictMode>,
    )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
