import React from "react"

import "../../support/i18n"
import { isBaseApp } from "../shared/utilities/routing_utility"
import PanariskApp from "./PanariskApp"
import { theRouter as adminRouter } from "../apps/admin/config/routes"
import { navMenu as adminNavMenu } from "../apps/admin/config/menus"
import { theRouter as inspectRouter } from "../apps/inspect/config/routes"
import { navMenu as inspectNavMenu } from "../apps/inspect/config/menus"
import { theRouter as recsRouter } from "../apps/recs/config/routes"
import { navMenu as recsNavMenu } from "../apps/recs/config/menus"

// Uncomment to list all routes
// const blah = recsRouter({ basename: "/recs" }).routes.map(route => {
//   return `${route.path}`
// })
// console.log(blah.join("\n"))

/**
 * Renders the PanariskSuite component based on the base application type.
 * Depending on the base application (admin, inspect, recs),
 * it returns the corresponding PanariskApp component with appropriate
 * router, side menu, and site name.
 *
 * @returns {React.ReactElement} The PanariskSuite component rendering different PanariskApp components.
 */
const PanariskSuite: React.FC = (): React.ReactElement => {
  return (
    <>
      {isBaseApp("admin") && (
        <PanariskApp router={adminRouter({ basename: "/admin" })} sideMenu={adminNavMenu()} siteName="Admin" />
      )}
      {isBaseApp("inspect") && (
        <PanariskApp router={inspectRouter({ basename: "/inspect" })} sideMenu={inspectNavMenu()} siteName="Inspect" />
      )}
      {isBaseApp("recs") && (
        <PanariskApp router={recsRouter({ basename: "/recs" })} sideMenu={recsNavMenu()} siteName="Recs" />
      )}
    </>
  )
}

export default PanariskSuite
