import React, { useContext } from "react"
import { type ControllerFieldState } from "react-hook-form"
import { type ControllerRenderProps } from "react-hook-form/dist/types/controller"
import FormatNumber from "./FormatNumber"
import type { IPanariskAppContext } from "../../models/app/IPanariskAppContext"
import { PanariskAppContext } from "../../../app/PanariskApp"
import { useSelectOptions } from "../../hooks/useSelectOptions"
import { SelectOptions } from "../../../config/config"

export interface IFormatNumberAndErrorProps {
  field: ControllerRenderProps
  fieldState: ControllerFieldState
  prefixUnits?: string
  suffixUnits?: string
}

/**
 * Show the number formatted for a field and any error that might be associated with the field.
 *
 * @param {IFormatNumberAndErrorProps} props See IProps for details.
 * @returns {React.FC<IFormatNumberAndErrorProps>} the component.
 */
const FormatNumberAndError: React.FC<IFormatNumberAndErrorProps> = (
  props: IFormatNumberAndErrorProps,
): React.ReactElement => {
  const { field, fieldState, prefixUnits, suffixUnits = "" } = props

  let thePrefixUnits = prefixUnits
  if (prefixUnits === undefined) {
    const { appSettings } = useContext<IPanariskAppContext>(PanariskAppContext)
    const currency = appSettings?.serverInfo?.currency
    const theSelectOptions = useSelectOptions(SelectOptions.CURRENCY_SYMBOLS)
    const firstMatch = theSelectOptions.find(option => option.key === currency)
    thePrefixUnits = firstMatch?.value ?? "$"
  }

  return (
    <>
      <FormatNumber value={field.value} prefixUnits={thePrefixUnits} suffixUnits={suffixUnits} />
      {fieldState?.error !== undefined ? fieldState.error?.message : ""}
    </>
  )
}

export default FormatNumberAndError
