import React from "react"
import { type UseFormReturn } from "react-hook-form/dist/types"
import { Grid } from "@mui/material"
import FhMuiHiddenField from "../../../../../shared/components/forms/FhMuiHiddenField"
import FhMuiTextField from "../../../../../shared/components/forms/FhMuiTextField"
import FhMuiRichTextField from "../../../../../shared/components/forms/FhMuiRichTextField"
import { type IServiceUser } from "../../../../../shared/models/service/IServiceUser"
import { requiredRule } from "../../../../../shared/utilities/form_utility"
import useLoadFormData from "../../../../../shared/hooks/useLoadFormData"
import PaperLocal from "../../../../../shared/components/containers/PaperLocal"
import FhMuiPhoneField from "../../../../../shared/components/forms/FhMuiPhoneField"

interface IProps {
  form: UseFormReturn
  serviceUser?: IServiceUser | undefined | null
  isEdit?: boolean
}

/**
 * Use this forms to add or edit an serviceUser.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} returns the forms.
 */
const ServiceUserForm: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { serviceUser, form, isEdit = false } = props

  useLoadFormData<IServiceUser>(
    (data: IServiceUser) => {
      form.setValue("id", data.id)
      form.setValue("first_name", data.first_name)
      form.setValue("last_name", data.last_name)
      form.setValue("job_title", data.job_title)
      form.setValue("phone", data.phone)
      form.setValue("phone_ext", data.phone_ext)
      form.setValue("phone_2", data.phone_2)
      form.setValue("phone_2_ext", data.phone_2_ext)
      form.setValue("email", data.email)
      form.setValue("email_2", data.email_2)
      form.setValue("notes", data.notes)
    },
    serviceUser,
    isEdit,
    form.setValue,
  )

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <PaperLocal>
          {isEdit && <FhMuiHiddenField control={form.control} />}
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <FhMuiTextField control={form.control} name="first_name" rules={requiredRule()} />
            </Grid>
            <Grid item xs={12} lg={6}>
              <FhMuiTextField control={form.control} name="last_name" />
            </Grid>
            <Grid item xs={12}>
              <FhMuiTextField control={form.control} name="job_title" />
            </Grid>
            <Grid item xs={12}>
              <FhMuiTextField control={form.control} name="email" rules={requiredRule()} />
            </Grid>
            <Grid item xs={12}>
              <FhMuiTextField control={form.control} name="email_2" />
            </Grid>
            <Grid item xs={12} lg>
              <FhMuiPhoneField control={form.control} name="phone" />
            </Grid>
            <Grid item>
              <FhMuiTextField control={form.control} name="phone_ext" />
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12} lg>
              <FhMuiPhoneField control={form.control} name="phone_2" />
            </Grid>
            <Grid item>
              <FhMuiTextField control={form.control} name="phone_2_ext" />
            </Grid>
          </Grid>
        </PaperLocal>
      </Grid>
      <Grid item xs={12} lg={6}>
        <PaperLocal>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FhMuiRichTextField control={form.control} name="notes" />
            </Grid>
          </Grid>
        </PaperLocal>
      </Grid>
    </Grid>
  )
}

export default ServiceUserForm
