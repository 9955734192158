import React, { useContext } from "react"
import AppHelmet from "../components/AppHelmet"
import { COMPANY_NAME } from "../../config/config"
import { PanariskAppContext } from "../../app/PanariskApp"

interface IProps {
  path?: string
  children?: React.JSX.Element
  component?: React.ComponentType<IProps>
  title?: string
}

/**
 * Login layout for the site.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the login layout.
 */
const LoginLayout: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children, component: Component, title } = props
  const { siteName } = useContext(PanariskAppContext)

  return (
    <>
      <AppHelmet title={`${COMPANY_NAME} | ${siteName} - ${title}`} />
      {children}
      {Component !== undefined && <Component {...props} />}
    </>
  )
}

export default LoginLayout
