import React from "react"
import { TableCell } from "@mui/material"

interface IProps {
  children: string | undefined | null
  field?: string
}

/**
 * Renders a TableCell aligned center.
 *
 * @param {IProps} props - The component props.
 * @returns {React.ReactElement} - The rendered TableCell aligned center.
 */
const TableCellColor: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children } = props

  return <TableCell sx={{ backgroundColor: children, textAlign: "center" }}>{children}</TableCell>
}

export default TableCellColor
