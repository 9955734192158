import React from "react"
import { type ILocationImpairment, LOCATION_IMPAIRMENT_ENDPOINT } from "../../models/service/ILocationImpairment"
import { RestRepository } from "../../repositories/RestRepository"
import { type IUseApiPagedResultsProps, useApiPagedLocal } from "../../hooks/useApiPagedLocal"
import { Grid } from "@mui/material"
import ImpairmentIndex from "./components/ImpairmentIndex"

const repository = new RestRepository<ILocationImpairment>(LOCATION_IMPAIRMENT_ENDPOINT)

/**
 * Renders the index page component.
 *
 * @returns {React.ReactElement} The rendered index page component.
 */
const IndexPage: React.FC = (): React.ReactElement => {
  const props: IUseApiPagedResultsProps<ILocationImpairment> = { apiFunction: repository.findAll }
  const pagingResults = useApiPagedLocal<ILocationImpairment>(props)

  return (
    <Grid container alignItems="center" spacing={2}>
      <ImpairmentIndex pagingResults={pagingResults} />
    </Grid>
  )
}

export default IndexPage
