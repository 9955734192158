import React from "react"
import Top5ListWidget from "../../../../shared/components/widgets/prebuilt/Top5ListWidget"
import { type IPanariskWidget } from "../../../../shared/components/widgets/models/IPanariskWidgetProps"
import DashboardPage from "../../../../shared/pages/dashboard/DashboardPage"

const WIDGETS: IPanariskWidget[] = [Top5ListWidget]

/**
 * Renders the index page with statistics and top inspections.
 *
 * @returns {React.ReactElement} The rendered index page.
 */
const IndexPage: React.FC = (): React.ReactElement => {
  return <DashboardPage siteWidgets={WIDGETS} />
}

export default IndexPage
