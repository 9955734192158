import React from "react"
import { Box, type Theme } from "@mui/material"

interface IProps {
  children: string
}

/**
 * Renders the value of the first select option that matches the given
 * key lookup.
 *
 * @param {IProps} props - The props object.
 * @returns {React.ReactElement} The rendered value of the first select option that matches the key lookup.
 */
const ViewColor: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children } = props

  return (
    <Box
      sx={(theme: Theme) => ({
        backgroundColor: children,
        p: 0.5,
        pr: 1,
        pl: 1,
        borderRadius: 1,
        color: children === "" ? children : theme.palette.getContrastText(children),
      })}>
      {children}
    </Box>
  )
}

export default ViewColor
