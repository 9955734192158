import React, { useCallback } from "react"
import AlertDialog from "../../../../../shared/components/AlertDialog"
import RemoveIcon from "@mui/icons-material/Remove"
import useApiAction, { type IUseApiActionProps } from "../../../../../shared/hooks/useApiAction"
import { type RestRepository } from "../../../../../shared/repositories/RestRepository"
import { type IPaging } from "../../../../../shared/models/components/IPaging"
import { type IBuilding } from "../../../../../shared/models/service/IBuilding"

interface IProps {
  building: IBuilding
  onComplete: () => void
  parentId: number
  repository: RestRepository<IBuilding>
}

/**
 * React component for removing a building.
 *
 * @param {IProps} props - The component props.
 * @returns {React.ReactElement} The rendered React component.
 */
const DeleteBuilding: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { building, onComplete, parentId, repository } = props

  const actionProps: IUseApiActionProps<IBuilding> = { repository, itemId: parentId }
  const apiAction = useApiAction<IBuilding>(actionProps)

  const handleYes = useCallback(async () => {
    const paging: IPaging = { filters: [{ field: "id", value: building.id }] }
    await apiAction.callAction("remove_building", paging)
    onComplete()
  }, [building])

  return (
    <AlertDialog
      buttonText="Remove"
      buttonIcon={<RemoveIcon />}
      title="Confirm Remove Building"
      message={
        <>
          Are you sure you want to remove:{" "}
          <p>
            <em>{building.name}</em>
          </p>
        </>
      }
      onYes={handleYes}
    />
  )
}

export default DeleteBuilding
