import { type RefObject, useCallback, useRef, useState } from "react"
import domtoimage from "dom-to-image"
import { saveAs } from "file-saver"

interface IUseSaveChartResponse {
  chartError: any | null
  chartRef: RefObject<HTMLDivElement>
  handleChartExport: (filename?: string) => () => Promise<void>
}

/**
 * useSaveChart is a custom hook that provides functionality for saving a chart as an image file.
 *
 * @returns {IUseSaveChartResponse} The hook response object.
 *   - chartError: Variable holding any error that occurred during chart export.
 *   - chartRef: Reference to the chart's HTML div element.
 *   - handleChartExport: Function that exports the chart as an image file.
 */
const useSaveChart = (): IUseSaveChartResponse => {
  const [chartError, setChartError] = useState<any | null>(null)
  const chartRef = useRef<HTMLDivElement | null>(null)
  const handleChartExport = useCallback(
    (filename: string = "chart.png") =>
      async () => {
        const node = chartRef.current
        if (node !== null) {
          try {
            const blob: Blob | null = await domtoimage.toBlob(node)
            if (blob !== null) {
              saveAs(blob, filename)
            }
          } catch (error: any) {
            setChartError(error)
          }
        }
      },
    [chartRef],
  )

  return { chartError, chartRef, handleChartExport }
}

export default useSaveChart
