import React from "react"
import { TableCell } from "@mui/material"
import { type IUseApiPagedResultsResponse } from "../../hooks/useApiPagedLocal"
import TableOrdering from "./TableOrdering"

interface IProps {
  children: string | React.JSX.Element | number | undefined | null | boolean
  field?: string
  pagingResults?: IUseApiPagedResultsResponse<any>
}

/**
 * Renders a TableCell with a field.
 *
 * @param {IProps} props - The component props.
 * @returns {React.ReactElement} - The rendered TableCell field.
 */
const TableCellData: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children, field, pagingResults } = props

  return (
    <TableCell>
      {pagingResults !== undefined && field !== undefined ? (
        <TableOrdering
          ordering={pagingResults.paging?.ordering}
          field={field}
          title={typeof children === "boolean" ? `${children}` : children}
          onOrdering={pagingResults.handleOrdering}
        />
      ) : (
        children
      )}
    </TableCell>
  )
}

export default TableCellData
