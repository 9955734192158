import { Controller, type FieldValues, type RegisterOptions } from "react-hook-form"
import React from "react"
import { type Control } from "react-hook-form/dist/types/form"
import PhoneInput, { type CountryData } from "react-phone-input-2"
import "react-phone-input-2/lib/material.css"
import { Box, Grid } from "@mui/material"
import { type IFormatNumberAndErrorProps } from "../format/FormatNumberAndError"
import { nameToLabel } from "../../utilities/form_utility"
import ViewPhone from "../display/ViewPhone"
import HtmlToolTip from "../HtmlToolTip"
import InfoIcon from "@mui/icons-material/Info"

interface IProps {
  control: Control
  defaultValue?: string | number
  label?: string
  name: string
  formatter?: React.FC<IFormatNumberAndErrorProps>
  rules?:
    | Omit<RegisterOptions<FieldValues, string>, "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled">
    | undefined
  helperText?: string
}

/**
 * The forms hook MUI phone field.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the forms input for a phone.
 */
const FhMuiPhoneField: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { control, defaultValue = "", name, label, rules = {}, helperText = null } = props

  const theLabel = nameToLabel(name, label)

  return (
    <Grid container>
      <Grid item xs>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          rules={rules}
          render={({ field, fieldState }) => (
            <>
              <PhoneInput
                country="us"
                containerStyle={{ width: "100%" }}
                inputStyle={{ width: "100%" }}
                specialLabel={theLabel}
                onBlur={field.onBlur}
                onChange={(
                  value: string,
                  data: CountryData,
                  event: React.ChangeEvent<HTMLInputElement>,
                  formattedValue: string,
                ) => {
                  field.onChange(formattedValue)
                }}
                value={field.value}
              />
              <Box sx={{ pl: 2 }}>
                <ViewPhone display>{field.value}</ViewPhone>
              </Box>
              <Box>{fieldState.error?.message}</Box>
            </>
          )}
        />
      </Grid>
      {helperText !== null && (
        <Grid item>
          <Box sx={{ position: "relative", marginLeft: -3, marginTop: 0, cursor: "pointer" }}>
            <HtmlToolTip title={<Box dangerouslySetInnerHTML={{ __html: helperText }} />} placement="left">
              <InfoIcon color="secondary" />
            </HtmlToolTip>
          </Box>
        </Grid>
      )}
    </Grid>
  )
}

export default FhMuiPhoneField
