import React from "react"
import { type UseFormReturn } from "react-hook-form/dist/types"
import { Grid } from "@mui/material"
import FhMuiHiddenField from "../../../../../shared/components/forms/FhMuiHiddenField"
import FhMuiTextField from "../../../../../shared/components/forms/FhMuiTextField"
import FhMuiRichTextField from "../../../../../shared/components/forms/FhMuiRichTextField"
import { type IFileTag } from "../../../../../shared/models/service/IFileTag"
import { requiredRule } from "../../../../../shared/utilities/form_utility"
import useLoadFormData from "../../../../../shared/hooks/useLoadFormData"
import FhMuiColorField, { useColorField } from "../../../../../shared/components/forms/FhMuiColorField"
import PaperLocal from "../../../../../shared/components/containers/PaperLocal"

interface IProps {
  form: UseFormReturn
  fileTag?: IFileTag | undefined | null
  isEdit?: boolean
}

/**
 * Use this forms to add or edit an fileTag.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} returns the forms.
 */
const FileTagForm: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { fileTag, form, isEdit = false } = props

  const [color, setColor] = useColorField({ form, field: "color" })

  useLoadFormData<IFileTag>(
    (data: IFileTag) => {
      form.setValue("id", data.id)
      form.setValue("name", data.name)
      form.setValue("notes", data.notes)
      setColor(data.color)
    },
    fileTag,
    isEdit,
    form.setValue,
  )

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <PaperLocal>
          {isEdit && <FhMuiHiddenField control={form.control} />}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FhMuiTextField control={form.control} name="name" rules={requiredRule()} />
            </Grid>
            <Grid item xs={12}>
              <FhMuiColorField form={form} name="color" colorValue={color} />
            </Grid>
            <Grid item xs={12}>
              <FhMuiRichTextField control={form.control} name="notes" />
            </Grid>
          </Grid>
        </PaperLocal>
      </Grid>
    </Grid>
  )
}

export default FileTagForm
