import React from "react"
import {
  type ILocationImpairment,
  LOCATION_IMPAIRMENT_ENDPOINT,
} from "../../../models/service/ILocationImpairment"
import { RestRepository } from "../../../repositories/RestRepository"
import PageHeader from "../../../components/pages/PageHeader"
import { LOCATION_IMPAIRMENT_ADD_URL, LOCATION_IMPAIRMENT_VIEW_URL } from "../../../../apps/admin/config/urls"
import { TableBody, TableHead, TableRow } from "@mui/material"
import TableData from "../../../components/tables/TableData"
import TableCellDate from "../../../components/tables/TableCellDate"
import TableCellLocation from "../../../components/tables/TableCellLocation"
import ListData from "../../../components/lists/ListData"
import ListDataItem from "../../../components/lists/ListDataItem"
import ListProperty from "../../../components/lists/ListProperty"
import TableCellData from "../../../components/tables/TableCellData"
import { type IListItem } from "../../../models/components/IListItem"
import FiltersData from "../../../components/filters/FiltersData"
import FilterLocation from "../../../components/filters/FilterLocation"
import { type IUseApiPagedResultsResponse } from "../../../hooks/useApiPagedLocal"
import TableCellTruncate from "../../../components/tables/TableCellTruncate"
import { ItemPrefixes } from "../../../../config/config"
import TableCellCenter from "../../../components/tables/TableCellCenter"
import ItemViewerDrawer from "../../../components/item_viewer/ItemViewerDrawer"
import ImpairmentInfo from "./ImpairmentInfo"
import type { IFilter } from "../../../models/components/IFilter"
import FilterDate from "../../../components/filters/FilterDate"
import TableCellServiceUser from "../../../components/tables/TableCellServiceUser"
import FilterSearch from "../../../components/filters/FilterSearch"
import FilterLocationImpairmentSystemType from "../../../components/filters/FilterLocationImpairmentSystemType"
import FilterLocationImpairmentTag from "../../../components/filters/FilterLocationImpairmentTag"
import FilterLocationImpairmentStatus from "../../../components/filters/FilterLocationImpairmentStatus"
import FilterLocationImpairmentType from "../../../components/filters/FilterLocationImpairmentType"
import LocationInfo from "../../../../apps/admin/pages/locations/components/LocationInfo"
import { useTranslation } from "react-i18next"
import TableActions from "../../../components/tables/TableActions"
import ExcelDownloadButton from "../../../components/ExcelDownloadButton"

const gotoRepo = new RestRepository<IListItem>(LOCATION_IMPAIRMENT_ENDPOINT)

interface IProps {
  pagingResults: IUseApiPagedResultsResponse<ILocationImpairment>
  showHeader?: boolean
  useInfoView?: boolean
  gotoFilters?: IFilter[]
}

/**
 * Renders the Impairment Index component.
 *
 * @param {IProps} props - The component props.
 * @returns {React.ReactElement} - The rendered component.
 */
const ImpairmentIndex: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { pagingResults, showHeader = true, useInfoView = false, gotoFilters } = props
  const { data } = pagingResults
  const { t } = useTranslation()

  return (
    <>
      {showHeader && <PageHeader title="Impairment" titlePlural="Impairments" toAdd={LOCATION_IMPAIRMENT_ADD_URL} />}
      {pagingResults !== undefined && (
        <>
          <ItemViewerDrawer title={t("Impairment")} prefix={ItemPrefixes.impairment} infoView={ImpairmentInfo} />
          <ItemViewerDrawer title={t("Location")} prefix={ItemPrefixes.location} infoView={LocationInfo} />
          <TableData
            pagingResults={pagingResults}
            to={LOCATION_IMPAIRMENT_VIEW_URL}
            repository={gotoRepo}
            storageSuffix="impairments"
            toInfoPrefix={ItemPrefixes.impairment}
            gotoFilters={gotoFilters}
            toInfo={useInfoView}>
            <TableActions>
              <ExcelDownloadButton repository={gotoRepo} paging={pagingResults.paging} />
            </TableActions>
            <FiltersData pagingResults={pagingResults}>
              <FilterSearch />
              <FilterLocation field="location" />
              <FilterLocationImpairmentSystemType />
              <FilterLocationImpairmentTag />
              <FilterLocationImpairmentStatus />
              <FilterLocationImpairmentType />
              <FilterDate field="start_date" title="Start Date" />
              <FilterDate field="end_date" title="End Date" />
              <FilterDate field="completed" title="Completed Date" />
            </FiltersData>
            <TableHead>
              <TableCellData field="identifier">Identifier</TableCellData>
              <TableCellData field="location" pagingResults={pagingResults}>
                Location
              </TableCellData>
              <TableCellData field="title" pagingResults={pagingResults}>
                Title
              </TableCellData>
              <TableCellData field="description">Description</TableCellData>
              <TableCellCenter field="type" pagingResults={pagingResults}>
                Type
              </TableCellCenter>
              <TableCellCenter field="status" pagingResults={pagingResults}>
                Status
              </TableCellCenter>
              <TableCellCenter field="system_name" pagingResults={pagingResults}>
                System Name
              </TableCellCenter>
              <TableCellCenter field="system_type" pagingResults={pagingResults}>
                System Type
              </TableCellCenter>
              <TableCellCenter field="start_date" pagingResults={pagingResults}>
                Start Date
              </TableCellCenter>
              <TableCellCenter field="end_date" pagingResults={pagingResults}>
                End Date
              </TableCellCenter>
              <TableCellCenter field="completed" pagingResults={pagingResults}>
                Completed
              </TableCellCenter>
              <TableCellCenter field="completed_by" pagingResults={pagingResults}>
                Completed By
              </TableCellCenter>
            </TableHead>
            <TableBody>
              {data?.results.map((item: ILocationImpairment) => (
                <TableRow key={item.id}>
                  <TableCellData field="identifier">{item.identifier}</TableCellData>
                  <TableCellLocation field="location">{item.location}</TableCellLocation>
                  <TableCellTruncate field="title">{item.title}</TableCellTruncate>
                  <TableCellTruncate field="description">{item.description}</TableCellTruncate>
                  <TableCellTruncate field="type">{item.impairment_type?.name}</TableCellTruncate>
                  <TableCellTruncate field="status">{item.status?.name}</TableCellTruncate>
                  <TableCellTruncate field="system_name">{item.system_name}</TableCellTruncate>
                  <TableCellData field="system_type">{item.system_type?.name}</TableCellData>
                  <TableCellDate field="start_date">{item.start_date}</TableCellDate>
                  <TableCellDate field="end_date">{item.end_date}</TableCellDate>
                  <TableCellDate field="completed">{item.completed}</TableCellDate>
                  <TableCellServiceUser field="completed_by">{item.completed_by}</TableCellServiceUser>
                </TableRow>
              ))}
            </TableBody>
          </TableData>

          <ListData pagingResults={pagingResults}>
            {data?.results.map((item: ILocationImpairment) => (
              <ListDataItem key={item.id} to={`${LOCATION_IMPAIRMENT_VIEW_URL}/${item.id}`} title={item.title}>
                <ListProperty label="System">{item.system_name}</ListProperty>
                <ListProperty label="Type">{item.impairment_type?.name}</ListProperty>
              </ListDataItem>
            ))}
          </ListData>
        </>
      )}
    </>
  )
}

export default ImpairmentIndex
