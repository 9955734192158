export enum IRiskInputType {
  RICH_TEXT = "rich_text",
  TEXT = "text",
  IMAGE = "image",
  INTEGER = "integer",
  FLOAT = "float",
  CURRENCY = "currency",
  AREA = "area",
  PERCENT = "percent",
  YEAR = "year",
  CHECKBOX = "checkbox",
  SELECT = "select",
  LABEL = "label",
  SPACE = "space",
}

export enum IRiskCalcType {
  ADD = "add",
  SUBTRACT = "subtract",
  MULTIPLY = "multiply",
  DIVIDE = "divide",
  SUM = "sum",
}

export interface IRiskCalc {
  calc_type: IRiskCalcType
  field1: string | null
  field2: string | null
  fields: string[] | null
}

export interface IRiskWriterField {
  name: string
  helper_text: string
  units: string
  input_type: IRiskInputType
  required: boolean
  title: string | null
  cols: number | null
  options: string | null
  calc: IRiskCalc | null
  show_if_name: string | null
  show_if_value: string | number | boolean | null
}
