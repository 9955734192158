import type { IListItem } from "../components/IListItem"


export const PRIORITY_ENDPOINT = "priorities"

export const PRIORITY_TYPES = ["priority", ]

export interface IPriority extends IListItem {
  id: number
  name: string
  priority_type: string
  notes: string

  created: string
  updated: string
}