import React, { useCallback, useContext, useEffect, useRef } from "react"
import { Button, Container, Grid, Tab, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useApiRead } from "../../hooks/useApiRead"
import { RestRepository } from "../../repositories/RestRepository"
import type IProfile from "../../models/users/IProfile"
import { PROFILES_ENDPOINT } from "../../models/users/IProfile"
import PageHeader from "../../components/pages/PageHeader"
import { PROFILE_EDIT_URL } from "../../../apps/admin/config/urls"
import PaperLocal from "../../components/containers/PaperLocal"
import ViewProperty from "../../components/ViewProperty"
import ViewSelectOption from "../../components/display/ViewSelectOption"
import { SelectOptions } from "../../../config/config"
import useAuth from "../../hooks/useAuth"
import { useAxiosRequest } from "../../hooks/useAxiosRequest"
import ViewLoading from "../../components/ViewLoading"
import ErrorMessage from "../../components/ErrorMessage"
import { PanariskAppContext } from "../../../app/PanariskApp"
import TabPanel, { useTabPanel } from "../../components/tabs/TabPanel"
import FilesIndex from "../../components/files/FilesIndex"
import { useOnDemandPaged } from "../../hooks/useApiPagedLocal"
import { type IFile, SERVICE_USER_FILE_ENDPOINT } from "../../models/service/IFile"
import type { IMainModel } from "../../models/service/IMainModel"
import type { IFilter } from "../../models/components/IFilter"
import CheckMark from "../../components/display/CheckMark"
import useEffectInit from "../../hooks/useEffectInit"
import TabsList from "../../components/tabs/TabsList"
import FilterBookmarkIndex from "../filter_bookmarks/components/FilterBookmarkIndex"
import { FILTER_BOOKMARK_ENDPOINT, type IFilterBookmark } from "../../models/service/IFilterBookmark"

const repository = new RestRepository<IProfile>(PROFILES_ENDPOINT)
const filesRepository = new RestRepository<IFile | IMainModel>(SERVICE_USER_FILE_ENDPOINT)

/**
 * Renders the index page with profile.
 *
 * @returns {React.ReactElement} The rendered index page.
 */
const IndexPage: React.FC = (): React.ReactElement => {
  const { currentUser, resetPassword, refreshUser } = useAuth()
  const itemId = currentUser?.user.profile.id
  const username = currentUser?.user.username
  const { tab, handleTabChange } = useTabPanel()
  const initialLoad = useRef<boolean>(false)

  const { appSettings } = useContext(PanariskAppContext)

  const { data } = useApiRead<IProfile>({ apiFunction: repository.read, itemId })
  const { t } = useTranslation()
  const { callRequest, loading, errorMessage, error } = useAxiosRequest()

  const fbPagingResults = useOnDemandPaged<IFilterBookmark>(FILTER_BOOKMARK_ENDPOINT, [], "FILTER_BOOKMARKS")
  const filesPagingResults = useOnDemandPaged<IFile>(SERVICE_USER_FILE_ENDPOINT, [], "FILES")

  const handlePasswordResetEmail = useCallback(async () => {
    if (username !== undefined) {
      await callRequest(async () => {
        await resetPassword?.(username)
      })
    }
  }, [username])

  useEffect(() => {
    // todo: this needs to be locked for the endpoint. Only the logged in user can see these files.
    if (!initialLoad.current && data?.id !== undefined && tab === 1) {
      initialLoad.current = true
      const theFilter: IFilter[] = [
        {
          field: "service_user",
          value: data.id,
          title: "Files",
          display: "You",
          canBeDelete: false,
        },
      ]
      filesPagingResults.handleFilter(theFilter)
    }
  }, [initialLoad, data?.id, tab])

  useEffectInit(async () => {
    await refreshUser?.()
  }, [])

  return (
    <Container fixed>
      {data !== undefined && (
        <Grid container spacing={2}>
          <PageHeader title={t("Profile")} toEdit={`${PROFILE_EDIT_URL}/${data.id}`} />
          <Grid item xs={12}>
            <ViewLoading loading={loading} />
            {error && <ErrorMessage error={errorMessage} />}
            <TabsList value={tab} onChange={handleTabChange}>
              <Tab label={t("Overview")} value={0} />
              <Tab label={t("Files")} value={1} />
              <Tab label={t("Filter Bookmarks")} value={2} />
            </TabsList>
          </Grid>
          <Grid item xs={12}>
            <TabPanel value={tab} index={0}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <PaperLocal>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <ViewProperty label={t("Language")}>
                          <ViewSelectOption keyLookup={data.language} selectOption={SelectOptions.LANGUAGE_CHOICES} />
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label={t("Auto Save Report Writer")}>
                          <CheckMark value={data.auto_save_risk_writer} />
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label={t("Rich Text Boxes Full Length")}>
                          <CheckMark value={data.rich_text_boxes_full_length} />
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label={t("Map Type")}>
                          <ViewSelectOption keyLookup={data.map_type} selectOption={SelectOptions.MAP_TYPES} />
                        </ViewProperty>
                      </Grid>
                      {appSettings.serverInfo?.enable_username_password_login === true && (
                        <Grid item xs={12}>
                          <ViewProperty label={t("Password")}>
                            <Button onClick={handlePasswordResetEmail}>Reset Password</Button>
                          </ViewProperty>
                        </Grid>
                      )}
                      {data.social_providers.length > 0 && (
                        <Grid item xs={12}>
                          <ViewProperty label={t("Social Logins")}>{data.social_providers.join(", ")}</ViewProperty>
                        </Grid>
                      )}
                    </Grid>
                  </PaperLocal>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <PaperLocal>
                    <>
                      <Typography variant="h5" color="textSecondary">
                        Site Access and User Roles
                      </Typography>
                      {currentUser?.user.groups.map(group => {
                        return (
                          <ViewProperty key={group.id} label={group.name}>
                            <CheckMark value={true} />
                          </ViewProperty>
                        )
                      })}
                    </>
                  </PaperLocal>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <FilesIndex
                parentId={currentUser?.user.profile.service_user.id}
                parentFieldName="service_user"
                repository={filesRepository}
                pagingResults={filesPagingResults}
              />
            </TabPanel>
            <TabPanel value={tab} index={2} onChange={fbPagingResults.call}>
              <FilterBookmarkIndex pagingResults={fbPagingResults} />
            </TabPanel>
          </Grid>
        </Grid>
      )}
    </Container>
  )
}

export default IndexPage
