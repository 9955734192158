import React from "react"
import { type UseFormReturn } from "react-hook-form/dist/types"
import { Alert, Grid } from "@mui/material"
import FhMuiHiddenField from "../../../../../shared/components/forms/FhMuiHiddenField"
import FhMuiTextField from "../../../../../shared/components/forms/FhMuiTextField"
import { type IContact } from "../../../../../shared/models/service/IContact"
import { requiredRule } from "../../../../../shared/utilities/form_utility"
import useLoadFormData from "../../../../../shared/hooks/useLoadFormData"
import FhMuiRichTextField from "../../../../../shared/components/forms/FhMuiRichTextField"
import PaperLocal from "../../../../../shared/components/containers/PaperLocal"
import FhMuiPhoneField from "../../../../../shared/components/forms/FhMuiPhoneField"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import { type IServiceUser, SERVICE_USER_ENDPOINT } from "../../../../../shared/models/service/IServiceUser"
import { type IListItem } from "../../../../../shared/models/components/IListItem"
import SelectFilteredSingle, { useSelectFilteredSingle } from "../../../../../shared/components/forms/SelectFilteredSingle"

const serviceUsersRepository = new RestRepository<IServiceUser | IListItem>(SERVICE_USER_ENDPOINT)

interface IProps {
  form: UseFormReturn
  contact?: IContact | undefined | null
  isEdit?: boolean
}

/**
 * Use this forms to add or edit a contact.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} returns the forms.
 */
const ContactForm: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { contact, form, isEdit = false } = props

  const [serviceUser, setServiceUser] = useSelectFilteredSingle("service_user", form)

  useLoadFormData<IContact>(
    (data: IContact) => {
      form.setValue("id", data.id)
      form.setValue("first_name", data.first_name)
      form.setValue("last_name", data.last_name)
      form.setValue("business_name", data.business_name)
      form.setValue("job_title", data.job_title)
      form.setValue("email", data.email)
      form.setValue("phone", data.phone)
      form.setValue("phone_ext", data.phone_ext)
      form.setValue("email_2", data.email_2)
      form.setValue("phone_2", data.phone_2)
      form.setValue("phone_2_ext", data.phone_2_ext)
      form.setValue("notes", data.notes)

      setServiceUser(data.service_user)
    },
    contact,
    isEdit,
    form.setValue,
  )

  return (
    <PaperLocal>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          {isEdit && <FhMuiHiddenField control={form.control} />}
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <FhMuiTextField control={form.control} name="first_name" rules={requiredRule()} />
            </Grid>
            <Grid item xs={12} lg={6}>
              <FhMuiTextField control={form.control} name="last_name" />
            </Grid>
            <Grid item xs={12}>
              <FhMuiTextField control={form.control} name="job_title" />
            </Grid>
            <Grid item xs={12}>
              <FhMuiTextField control={form.control} name="business_name" />
            </Grid>
            <Grid item xs={12} lg>
              <FhMuiPhoneField control={form.control} name="phone" />
            </Grid>
            <Grid item>
              <FhMuiTextField control={form.control} name="phone_ext" />
            </Grid>
            <Grid item xs={12}>
              <FhMuiTextField control={form.control} name="email" />
            </Grid>
            <Grid item xs={12} lg>
              <FhMuiPhoneField control={form.control} name="phone_2" />
            </Grid>
            <Grid item>
              <FhMuiTextField control={form.control} name="phone_2_ext" />
            </Grid>
            <Grid item xs={12}>
              <FhMuiTextField control={form.control} name="email_2" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SelectFilteredSingle
                name="service_user"
                defaultValue={serviceUser}
                repository={serviceUsersRepository}
                onChange={setServiceUser}
              />
              <Alert severity="info">
                Link this contact to a service user. All changes made the service user will update the contact.
              </Alert>
            </Grid>
            <Grid item xs={12}>
              <FhMuiRichTextField control={form.control} name="notes" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PaperLocal>
  )
}

export default ContactForm
