import React from "react"
import { Grid, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import TruncateText from "../TruncateText"
import ListItem from "@mui/material/ListItem"

interface IProps {
  to?: string
  title: string
  children?: React.JSX.Element | React.JSX.Element[] | undefined
}

/**
 * Displays list data using Material UI components.
 *
 * @param {IProps} props - The properties for displaying list data.
 * @returns {React.ReactElement} - The list data component.
 */
const ListDataItem: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { to, title, children } = props

  return to !== undefined ? (
    <ListItemButton component={Link} to={to}>
      <ListItemText
        primary={
          <Typography variant="h6">
            <TruncateText num={20}>{title}</TruncateText>
          </Typography>
        }
        secondary={
          <Grid container spacing={1}>
            {children}
          </Grid>
        }
      />
    </ListItemButton>
  ) : (
    <ListItem>
      <ListItemText
        primary={
          <Typography variant="h6">
            <TruncateText num={20}>{title}</TruncateText>
          </Typography>
        }
        secondary={
          <Grid container spacing={1}>
            {children}
          </Grid>
        }
      />
    </ListItem>
  )
}

export default ListDataItem
