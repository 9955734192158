import React, { useCallback } from "react"
import { type CredentialResponse, GoogleLogin, type TokenResponse } from "@react-oauth/google"
import { DASHBOARD_URL, LOGIN_URL } from "../../../apps/admin/config/urls"
import type { AxiosError } from "axios"
import { CONNECTION_ERROR, type IConnectionError } from "../../models/components/IConnectionError"
import useAuth from "../../hooks/useAuth"
import { useLocation, useNavigate } from "react-router-dom"

interface ILocationState {
  refer?: string
}

interface IProps {
  setLoading: (loading: boolean) => void
  setError: (loading: boolean) => void
  setErrorMessage: (errorMessage: IConnectionError | null) => void
}

/**
 * GoogleAuthButton component.
 *
 * @param {IProps} props - The props object.
 * @param {Function} props.setLoading - Function to set loading state.
 * @param {Function} props.setError - Function to set error state.
 * @param {Function} props.setErrorMessage - Function to set error message state.
 * @returns {React.ReactElement} - The GoogleAuthButton component.
 */
const GoogleAuthButton: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { setLoading, setError, setErrorMessage } = props

  const { loginWithGoogleToken } = useAuth()
  const location = useLocation()
  const navigate = useNavigate()

  const responseGoogleSuccess = useCallback(
    async (response: CredentialResponse | TokenResponse) => {
      if (loginWithGoogleToken !== null) {
        setLoading(true)
        setError(false)
        try {
          setErrorMessage(null)
          if ((response as CredentialResponse)?.credential !== undefined) {
            await loginWithGoogleToken((response as CredentialResponse).credential)
          }
          // if ((response as TokenResponse)?.access_token !== undefined) {
          //   await loginWithGoogleToken((response as TokenResponse).access_token)
          // }
          setTimeout(() => {
            const refer = (location.state as ILocationState)?.refer
            if (refer?.endsWith(LOGIN_URL) === true) {
              navigate(DASHBOARD_URL)
            } else {
              navigate(refer ?? DASHBOARD_URL)
            }
          }, 2000)
        } catch (reason) {
          setError(true)
          if ((reason as AxiosError)?.response !== undefined) {
            setErrorMessage((reason as AxiosError).response as IConnectionError)
          } else {
            setErrorMessage(CONNECTION_ERROR)
          }
          setLoading(false)
        }
      }
    },
    [location, loginWithGoogleToken],
  )

  const responseGoogleFailure = useCallback(async () => {
    const errorMessage1: IConnectionError = {
      objType: "GoogleError",
      data: { message: ["Google login error."] },
      code: "None",
    }
    setErrorMessage(errorMessage1)
    setError(true)
    setLoading(false)
  }, [])

  return (
    <>
      <GoogleLogin
        onSuccess={responseGoogleSuccess}
        onError={responseGoogleFailure}
        size="large"
        logo_alignment="center"
        width="280px"
        theme="outline"
        text="signin"
        shape="rectangular"
      />
    </>
  )
}

export default GoogleAuthButton
