import { axiosInstance, urlSearchParams } from "../utilities/request_utility"
import { type IPagedResults } from "../models/components/IPagedResults"
import { type IPaging } from "../models/components/IPaging"
import { type IMainModel } from "../models/service/IMainModel"

/**
 * Repository for requesting model rest api endpoints.
 */
export class RestRepository<T extends IMainModel> {
  endpointName: string

  /**
   * Build a rest repository.
   *
   * @param {string} endpointName name of the endpoint.
   */
  constructor(endpointName: string) {
    this.endpointName = endpointName
  }

  findAll = async (paging?: IPaging | null): Promise<IPagedResults<T>> => {
    const queryString = urlSearchParams(paging)
    const { data } = await axiosInstance.get(`/${this.endpointName}/${queryString}`)
    return data as IPagedResults<T>
  }

  list = async (paging?: IPaging): Promise<T[]> => {
    const queryString = urlSearchParams(paging)
    const { data } = await axiosInstance.get(`/${this.endpointName}/list/${queryString}`)
    return data as T[]
  }

  read = async (id: string | number): Promise<T> => {
    const { data } = await axiosInstance.get(`/${this.endpointName}/${id}/`)
    return data as T
  }

  action = async <I>(id: string | number, action: string, paging?: IPaging | null): Promise<I> => {
    const queryString = urlSearchParams(paging)
    const { data } = await axiosInstance.get(`/${this.endpointName}/${id}/${action}/${queryString}`)
    return data as I
  }

  actionNoDetail = async <I>(action: string, paging?: IPaging | null): Promise<I> => {
    const queryString = urlSearchParams(paging)
    const { data } = await axiosInstance.get(`/${this.endpointName}/${action}/${queryString}`)
    return data as I
  }

  actionPost = async <I>(id: string | number, action: string, item: I): Promise<I> => {
    const { data } = await axiosInstance.post(`/${this.endpointName}/${id}/${action}/`, item)
    return data as I
  }

  add = async (item: T): Promise<T> => {
    const { data } = await axiosInstance.post(`/${this.endpointName}/`, item)
    return data as T
  }

  edit = async (item: T, id: string | number): Promise<T> => {
    const { data } = await axiosInstance.put(`/${this.endpointName}/${id}/`, item)
    return data as T
  }

  patch = async (item: T, id: string | number): Promise<T> => {
    const { data } = await axiosInstance.patch(`/${this.endpointName}/${id}/`, item)
    return data as T
  }

  delete = async (id: string | number): Promise<void> => {
    await axiosInstance.delete(`/${this.endpointName}/${id}/`)
  }
}
