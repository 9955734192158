import React, { useCallback } from "react"
import { Box, Grid, List, Typography } from "@mui/material"
import { type IPagedResults } from "../../models/components/IPagedResults"
import ViewLoading from "../ViewLoading"
import ListItemText from "@mui/material/ListItemText"
import TruncateText from "../TruncateText"
import ListItemButton from "@mui/material/ListItemButton"
import { useNavigate } from "react-router-dom"
import { type ITop5ListEntry, type ITop5ListState, Models } from "../widgets/prebuilt/Top5ListWidget"
import {
  INSPECTION_VIEW_URL,
  INSPECTION_RECOMMENDATION_VIEW_URL,
  LOCATION_IMPAIRMENT_VIEW_URL,
  POLICY_VIEW_URL,
} from "../../../apps/admin/config/urls"
import FormatDate from "../format/FormatDate"

/**
 * Retrieves the URL for a given model.
 *
 * @param {Models | undefined} model - The model to retrieve the URL for.
 * @returns {string} - The URL associated with the given model.
 */
const getLinkUrl = (model: Models | null): string | null => {
  if (model === Models.INSPECTION_RECOMMENDATION) {
    return INSPECTION_RECOMMENDATION_VIEW_URL
  }
  if (model === Models.LOCATION_IMPAIRMENT) {
    return LOCATION_IMPAIRMENT_VIEW_URL
  }
  if (model === Models.INSPECTION) {
    return INSPECTION_VIEW_URL
  }
  if (model === Models.POLICY) {
    return POLICY_VIEW_URL
  }
  return null
}

/**
 * Retrieves the primary field from the provided entry based on the specified model.
 *
 * @param {Models | null | undefined} model - The model to determine the primary field.
 * @param {ITop5ListEntry} item - The entry object containing potential fields.
 * @returns {string | null} - The primary field value from the entry, or null if no valid model is provided.
 */
const getPrimary = (model: Models | null | undefined, item: ITop5ListEntry): string | null => {
  if (model === Models.INSPECTION_RECOMMENDATION) {
    return item.title
  }
  if (model === Models.LOCATION_IMPAIRMENT) {
    return item.title
  }
  if (model === Models.INSPECTION) {
    return item.identifier + " " + item.account?.name
  }
  if (model === Models.POLICY) {
    return item.name
  }
  return null
}

/**
 * Returns a React element based on the provided model and item.
 *
 * @param {Models | null | undefined} model - The model type that determines which UI element to render.
 * @param {ITop5ListEntry} item - The item data that will be displayed within the UI element.
 * @returns {React.ReactElement | null} A React element that displays information based on the model, or null if the model does not match any predefined type.
 */
const getSecondary = (model: Models | null | undefined, item: ITop5ListEntry): React.ReactElement | null => {
  if (model === Models.INSPECTION_RECOMMENDATION) {
    return (
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <strong>Location</strong>
        </Grid>
        <Grid item xs>
          <Typography sx={{ display: "inline" }} component="span" variant="body2" color="text.primary">
            {item.location?.name}
          </Typography>
        </Grid>
        <Grid item>
          <strong>
            <TruncateText num={10}>{item.priority?.name}</TruncateText>
          </strong>
        </Grid>
      </Grid>
    )
  }
  if (model === Models.LOCATION_IMPAIRMENT) {
    return (
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <strong>Location</strong>
        </Grid>
        <Grid item xs>
          <Typography sx={{ display: "inline" }} component="span" variant="body2" color="text.primary">
            {item.location?.name}
          </Typography>
        </Grid>
        <Grid item>
          <strong>{item.status?.name}</strong>
        </Grid>
      </Grid>
    )
  }
  if (model === Models.INSPECTION) {
    return (
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <strong>Location</strong>
        </Grid>
        <Grid item xs>
          <Typography sx={{ display: "inline" }} component="span" variant="body2" color="text.primary">
            {item.location?.name}
          </Typography>
        </Grid>
        <Grid item>
          <FormatDate value={item.vendor_due_date} />
        </Grid>
      </Grid>
    )
  }
  if (model === Models.POLICY) {
    return (
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <strong>End Date</strong>
        </Grid>
        <Grid item xs />
        <Grid item>
          <FormatDate value={item.end_date} />
        </Grid>
      </Grid>
    )
  }
  return null
}

interface IProps {
  listState: ITop5ListState | null
  loading?: boolean
  data?: IPagedResults<ITop5ListEntry>
}

/**
 * Render a top 5 list component.
 *
 * @param {IProps} props - The component props.
 * @returns {React.ReactElement} The rendered top 5 list component.
 */
const Top5List: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { listState, loading = false, data } = props
  const navigate = useNavigate()

  const handleClick = useCallback(
    (item: ITop5ListEntry) => () => {
      if (listState !== null) {
        const url = getLinkUrl(listState.model)
        if (url !== null) {
          navigate(`${url}/${item.id}`)
        }
      }
    },
    [listState],
  )

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <ViewLoading loading={loading} />
          <List disablePadding>
            {data?.results.map((item: ITop5ListEntry) => (
              <ListItemButton key={item.id} disableGutters onClick={handleClick(item)}>
                <ListItemText
                  secondaryTypographyProps={{ component: "div" }}
                  primary={<TruncateText num={40}>{getPrimary(listState?.model, item)}</TruncateText>}
                  secondary={getSecondary(listState?.model, item)}
                />
              </ListItemButton>
            ))}
          </List>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Top5List
