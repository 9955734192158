import React, { useCallback, useMemo, useRef, useState } from "react"
import type { IPanariskWidget, IPanariskWidgetProps } from "../models/IPanariskWidgetProps"
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from "@mui/material"
import PaperLocal from "../../containers/PaperLocal"
import { axiosInstance, BASE_URL, urlSearchParams } from "../../../utilities/request_utility"
import { useAxiosRequest } from "../../../hooks/useAxiosRequest"
import ViewLoading from "../../ViewLoading"
import ErrorMessage from "../../ErrorMessage"
import useSaveChart from "../hooks/useSaveChart"
import { type IStats } from "../../../models/service/IStats"
import WidgetPieChart from "../charts/WidgetPieChart"
import WidgetMenu from "../components/WidgetMenu"
import WidgetBarChart from "../charts/WidgetBarChart"
import DialogControls from "../../DialogControls"
import WidgetBarHorizontalChart from "../charts/WidgetBarHorizontalChart"
import { type IPaging } from "../../../models/components/IPaging"
import { nameToLabel } from "../../../utilities/form_utility"
import { type IAvailableModel, type IChartBuilderState } from "../models/IStatBuilderState"
import useProfileStorage from "../../../hooks/useProfileStorage"
import useEffectAsync from "../../../hooks/useEffectAsync"
import { useNavigate } from "react-router-dom"
import { INSPECTION_RECOMMENDATION_INDEX_URL } from "../../../../apps/admin/config/urls"
import { type IFilter } from "../../../models/components/IFilter"
import InfoValueCard from "../../display/InfoValueCard"
import SavedSearchIcon from "@mui/icons-material/SavedSearch"
import { RestRepository } from "../../../repositories/RestRepository"
import { FILTER_BOOKMARK_ENDPOINT, type IFilterBookmark } from "../../../models/service/IFilterBookmark"
import type { IListItem } from "../../../models/components/IListItem"
import SelectFilteredSingle from "../../forms/SelectFilteredSingle"

export const filterBookmarkRepository = new RestRepository<IListItem>(FILTER_BOOKMARK_ENDPOINT)

export const CHART_BUILDER_KEY = "CHART_BUILDER_KEY"

interface IRelatedModelInfo {
  id: number
  name: string
  model_name: string
}

export const CHART_FILTER_TITLE = "Chart Filter"

/**
 * Generates a storage key based on the given ID.
 *
 * @param {string} id - The ID used to generate the storage key.
 * @returns {string} The generated storage key.
 */
const storageKey = (id: string): string => {
  return `${CHART_BUILDER_KEY}-${id}`
}

const DEFAULT_STATE: IChartBuilderState = {
  title: "Chart Builder",
  description: "Build custom stats with charts.",
  modelName: "InspectionRecommendation",
  groupBy: "type",
  aggregator: "Count",
  aggregatorField: null,
  chartType: "bar",
  size: 6,
  limit: 5,
  direction: "desc",
}

/**
 * ChartBuilderWidget is a functional component that displays a widget for displaying recommendation types statistics.
 *
 * @param {IPanariskWidgetProps} props - The props object containing the id of the widget.
 * @returns {React.ReactElement} - The rendered widget component.
 */
const ChartBuilderWidget: IPanariskWidget = (props: IPanariskWidgetProps): React.ReactElement => {
  const { id, onMoveUp, onMoveDown, onDelete } = props
  const initialLoad = useRef<boolean>(false)

  const profileStorage = useProfileStorage<IChartBuilderState>(storageKey(id), DEFAULT_STATE)
  const [chartBuilderState, setChartBuilderState] = profileStorage.data

  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [availableModels, setAvailableModels] = useState<IAvailableModel[]>([])

  const { callRequest, loading, errorMessage, error } = useAxiosRequest()
  const [data, setData] = useState<IStats[]>([])
  const [singleValue, setSingleValue] = useState<number | undefined>()
  const theme = useTheme()
  const navigate = useNavigate()

  const { chartError, chartRef, handleChartExport } = useSaveChart()

  const handleLoadAvailableModels = useCallback(async () => {
    if (chartBuilderState !== null) {
      await callRequest(async () => {
        const url = `${BASE_URL}/stats/models_available/`
        const response = await axiosInstance.get<IAvailableModel[]>(url)
        setAvailableModels(response.data)
      })
    }
  }, [])

  const handleLoadData = useCallback(async () => {
    if (chartBuilderState !== null && !loading) {
      await callRequest(async () => {
        const postData = {
          model_name: chartBuilderState.modelName,
          aggregator: chartBuilderState.aggregator,
          aggregator_field: chartBuilderState.aggregatorField?.name,
        }
        if (chartBuilderState.chartType === "single_value") {
          const paging: IPaging = { ordering: { field: "total" }, ...chartBuilderState.filterBookmark?.paging }
          const queryString = urlSearchParams(paging)
          const url = `${BASE_URL}/stats/model_aggregator/${queryString}`
          const singleResponse = await axiosInstance.post<Record<string, number>>(url, postData)
          setSingleValue(singleResponse.data.total)
        } else {
          const postData = {
            model_name: chartBuilderState.modelName,
            group_by: chartBuilderState.groupBy,
            aggregator: chartBuilderState.aggregator,
            aggregator_field: chartBuilderState.aggregatorField?.name,
          }
          const limit = chartBuilderState.limit
          const direction = chartBuilderState.direction
          const bookmark = chartBuilderState.filterBookmark?.paging
          const paging: IPaging = { ...bookmark, limit, ordering: { field: "total", direction } }
          const queryString = urlSearchParams(paging)
          const url = `${BASE_URL}/stats/model_aggregator/${queryString}`
          const response = await axiosInstance.post<IStats[]>(url, postData)
          const data1 = response.data.map(data1 => ({ value: data1.value, label: data1.label }))
          setData(data1)
        }
      })
    }
  }, [chartBuilderState, data, loading])

  const handleCloseDialog = useCallback(async () => {
    setOpenDialog(false)
    setData([])
    await handleLoadData()
  }, [handleLoadData])

  const handleOpenDialog = useCallback(async () => {
    setOpenDialog(true)
    await handleLoadAvailableModels()
  }, [handleLoadAvailableModels])

  const handleChangeState = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.name === "aggregatorField") {
        const model = availableModels.find(am => am.core_model_name === chartBuilderState?.modelName)
        if (chartBuilderState !== null) {
          setChartBuilderState({
            ...chartBuilderState,
            aggregatorField: model?.aggregator_field.find(af => af.name === event.target.value) ?? null,
          })
        }
      } else {
        if (chartBuilderState !== null) {
          setChartBuilderState({
            ...chartBuilderState,
            [event.target.name]: event.target.value,
          })
        }
      }
    },
    [availableModels, chartBuilderState],
  )

  const handleFilterBookmark = useCallback(
    async (item: IListItem | null) => {
      if (item !== null) {
        const filterBookmark1 = (await filterBookmarkRepository.read(item.id)) as IFilterBookmark
        if (chartBuilderState !== null) {
          setChartBuilderState({ ...chartBuilderState, filterBookmark: filterBookmark1 })
        }
      } else {
        if (chartBuilderState !== null) {
          setChartBuilderState({ ...chartBuilderState, filterBookmark: null })
        }
      }
    },
    [handleChangeState, chartBuilderState],
  )

  useEffectAsync(async () => {
    if (
      data.length === 0 &&
      JSON.stringify(chartBuilderState) !== JSON.stringify(DEFAULT_STATE) &&
      !initialLoad.current
    ) {
      initialLoad.current = true
      await handleLoadData()
      await handleLoadAvailableModels()
    }
  }, [handleLoadData, data, initialLoad])

  const handleSave = useCallback(() => {
    if (chartBuilderState !== null) {
      setChartBuilderState({ ...DEFAULT_STATE })
    }
  }, [chartBuilderState])

  const currentModel = useMemo(() => {
    return availableModels?.find(am => am.core_model_name === chartBuilderState?.modelName)
  }, [availableModels, chartBuilderState?.modelName])

  const handleNavigation = useCallback(
    async (name: string) => {
      if (chartBuilderState !== null) {
        const model = chartBuilderState.modelName
        const field = chartBuilderState.groupBy

        await callRequest(async () => {
          const paging: IPaging = {
            filters: [
              { field: "model", value: model },
              { field: "field", value: field },
              { field: "name", value: name },
            ],
          }
          const queryString = urlSearchParams(paging)
          const url = `${BASE_URL}/stats/lookup_filter_id_by_name/${queryString}`
          const { data } = await axiosInstance.get<IRelatedModelInfo>(url)

          const filter: IFilter = {
            field,
            value: data.id,
            title: `${CHART_FILTER_TITLE} for ${nameToLabel(field)}`,
            display: name,
          }
          if (data.model_name === "InspectionRecommendation") {
            navigate(INSPECTION_RECOMMENDATION_INDEX_URL, { state: filter })
          }
        })
      }
    },
    [chartBuilderState],
  )

  const widgetMenu = useMemo(() => {
    return (
      <WidgetMenu>
        <MenuItem onClick={handleSave}>Clear State</MenuItem>
        <MenuItem onClick={handleLoadData}>Refresh</MenuItem>
        <Divider />
        <MenuItem onClick={handleChartExport()}>Save Chart</MenuItem>
        <Divider />
        <MenuItem onClick={onMoveUp}>Move Up</MenuItem>
        <MenuItem onClick={onMoveDown}>Move Down</MenuItem>
        <Divider />
        <MenuItem onClick={onDelete}>Delete Widget</MenuItem>
        <Divider />
        <MenuItem onClick={handleOpenDialog}>Options...</MenuItem>
      </WidgetMenu>
    )
  }, [handleSave, handleLoadData, handleChartExport, onMoveUp, onMoveDown, onDelete, handleOpenDialog])

  return (
    <Grid item xs={12} lg={chartBuilderState?.size ?? 6}>
      <PaperLocal noPaper={chartBuilderState?.chartType === "single_value"}>
        {chartBuilderState?.chartType !== "single_value" && (
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <h4>{chartBuilderState?.title}</h4>
            </Grid>
            <Grid item xs>
              <ViewLoading inline loading={loading} />
            </Grid>
            <Grid item>{widgetMenu}</Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">{chartBuilderState?.description}</Typography>
            </Grid>
          </Grid>
        )}

        {error && <ErrorMessage error={errorMessage} />}
        {chartError !== undefined && <ErrorMessage error={chartError} />}
        {data.length > 0 && (
          <>
            {chartBuilderState?.chartType === "pie" && (
              <WidgetPieChart
                chartRef={chartRef}
                data={data}
                statBuilderStat={chartBuilderState}
                onClick={handleNavigation}
              />
            )}
            {chartBuilderState?.chartType === "bar" && (
              <WidgetBarChart
                chartRef={chartRef}
                data={data}
                statBuilderStat={chartBuilderState}
                onClick={handleNavigation}
              />
            )}
            {chartBuilderState?.chartType === "bar_horizontal" && (
              <WidgetBarHorizontalChart
                chartRef={chartRef}
                data={data}
                statBuilderStat={chartBuilderState}
                onClick={handleNavigation}
              />
            )}
          </>
        )}
        {chartBuilderState?.chartType === "single_value" && (
          <>
            <Box sx={{ position: "absolute" }}>{widgetMenu}</Box>
            <InfoValueCard
              title={chartBuilderState?.title}
              value={singleValue ?? 0}
              color={theme.palette.info.main}
              icon={SavedSearchIcon}
            />
          </>
        )}
      </PaperLocal>
      <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="md">
        <DialogTitle>Chart Builder Options</DialogTitle>
        {chartBuilderState !== null && (
          <DialogContent>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12} lg={5}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Title"
                      name="title"
                      value={chartBuilderState?.title ?? ""}
                      onChange={handleChangeState}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      select
                      label="Chart Type"
                      name="chartType"
                      value={chartBuilderState?.chartType ?? ""}
                      onChange={handleChangeState}>
                      <MenuItem value="pie">Pie Chart</MenuItem>
                      <MenuItem value="bar">Bar Chart</MenuItem>
                      <MenuItem value="bar_horizontal">Horizontal Bar Chart</MenuItem>
                      <MenuItem value="single_value">Single Value</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      select
                      label="Width"
                      name="size"
                      value={chartBuilderState?.size ?? ""}
                      onChange={handleChangeState}>
                      <MenuItem value={3}>Small</MenuItem>
                      <MenuItem value={6}>Medium</MenuItem>
                      <MenuItem value={12}>Large</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    {chartBuilderState?.chartType !== "single_value" && (
                      <TextField
                        fullWidth
                        select
                        label="Limit"
                        name="limit"
                        value={chartBuilderState?.limit ?? ""}
                        onChange={handleChangeState}>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                      </TextField>
                    )}
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    {chartBuilderState?.chartType !== "single_value" && (
                      <TextField
                        fullWidth
                        select
                        label="Order"
                        name="direction"
                        value={chartBuilderState?.direction ?? ""}
                        onChange={handleChangeState}>
                        <MenuItem value="asc">Ascending</MenuItem>
                        <MenuItem value="desc">Descending</MenuItem>
                      </TextField>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={7}>
                <Grid item container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Description"
                      name="description"
                      value={chartBuilderState?.description ?? ""}
                      onChange={handleChangeState}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      select
                      label="Source"
                      name="modelName"
                      value={chartBuilderState?.modelName ?? ""}
                      onChange={handleChangeState}>
                      {availableModels?.map(am => {
                        return (
                          <MenuItem key={am.core_model_name} value={am.core_model_name}>
                            {am.core_model_title}
                          </MenuItem>
                        )
                      })}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <SelectFilteredSingle
                      name="filter_bookmark"
                      defaultValue={chartBuilderState.filterBookmark}
                      repository={filterBookmarkRepository}
                      onChange={handleFilterBookmark}
                    />
                  </Grid>
                  {currentModel !== undefined &&
                    currentModel.group_by.length > 0 &&
                    chartBuilderState.chartType !== "single_value" && (
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          select
                          label="Group By"
                          name="groupBy"
                          value={chartBuilderState?.groupBy ?? ""}
                          onChange={handleChangeState}>
                          {currentModel.group_by.map(groupBy => (
                            <MenuItem key={groupBy} value={groupBy}>
                              {nameToLabel(groupBy)}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    )}
                  {currentModel !== undefined && currentModel.group_by.length > 0 && (
                    <Grid item xs={12} lg={6}>
                      <TextField
                        fullWidth
                        select
                        label="Aggregator"
                        name="aggregator"
                        value={chartBuilderState?.aggregator ?? ""}
                        onChange={handleChangeState}>
                        <MenuItem value="Count">Count</MenuItem>
                        <MenuItem value="Sum">Sum</MenuItem>
                        <MenuItem value="Avg">Avg</MenuItem>
                        <MenuItem value="Min">Min</MenuItem>
                        <MenuItem value="Max">Max</MenuItem>
                      </TextField>
                    </Grid>
                  )}
                  {currentModel !== undefined && currentModel.group_by.length > 0 && (
                    <Grid item xs={12} lg={6}>
                      <TextField
                        fullWidth
                        select
                        label="Aggregator Field"
                        name="aggregatorField"
                        value={chartBuilderState?.aggregatorField?.name ?? ""}
                        onChange={handleChangeState}>
                        {currentModel?.aggregator_field.map(aggregatorField => (
                          <MenuItem key={aggregatorField.name} value={aggregatorField.name}>
                            {aggregatorField.units_pre} {nameToLabel(aggregatorField.name)} {aggregatorField.units_post}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        )}
        <DialogActions>
          <DialogControls onSave={handleCloseDialog} buttonLabel="Done" />
        </DialogActions>
      </Dialog>
    </Grid>
  )
}

ChartBuilderWidget.nameId = "chart_builder"
ChartBuilderWidget.title = DEFAULT_STATE.title
ChartBuilderWidget.description = DEFAULT_STATE.description

export default ChartBuilderWidget
