import React, { useCallback, useEffect, useMemo, useState } from "react"
import {
  Alert,
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import ErrorMessage from "../ErrorMessage"
import TablePaging from "./TablePaging"
import { type IUseApiPagedResultsResponse } from "../../hooks/useApiPagedLocal"
import { type IListItem } from "../../models/components/IListItem"
import TableRowSelect from "./TableRowSelect"
import { type RestRepository } from "../../repositories/RestRepository"
import Goto from "../Goto"
import TableColumns from "./TableColumns"
import { type IColumnItem } from "../../models/components/IColumnItem"
import ViewLoading from "../ViewLoading"
import Checkbox from "@mui/material/Checkbox"
import BulkData from "../bulk/BulkData"
import { useLocation, useSearchParams } from "react-router-dom"
import _ from "lodash"
import RefreshIcon from "@mui/icons-material/Refresh"
import { type IMainModel } from "../../models/service/IMainModel"
import PaperLocal from "../containers/PaperLocal"
import { type IFilter } from "../../models/components/IFilter"
import useLocalStorage from "beautiful-react-hooks/useLocalStorage"
import { StoragePrefixes } from "../../hooks/useLocalStorageHelpers"
import { getBaseApp } from "../../utilities/request_utility"
import FiltersData from "../filters/FiltersData"
import FilterSearch from "../filters/FilterSearch"
import TableActions from "./TableActions"

interface IProps {
  pagingResults: IUseApiPagedResultsResponse<IMainModel>
  children?: React.JSX.Element[]
  to?: string
  repository?: RestRepository<IListItem>
  itemRepository?: RestRepository<IMainModel>
  storageSuffix?: string
  toInfo?: boolean
  toInfoPrefix?: string
  gotoFilters?: IFilter[]
}

/**
 * Displays table data using Material UI components.
 *
 * @param {IProps} props - The properties for displaying table data.
 * @returns {React.ReactElement} - The table data component.
 */
const TableData: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const {
    pagingResults,
    children,
    to,
    repository,
    itemRepository,
    storageSuffix,
    toInfo = false,
    toInfoPrefix,
    gotoFilters,
  } = props

  const { loading, error, data } = pagingResults

  const isSmall = useMediaQuery(useTheme().breakpoints.down("md"))
  const showNoneFound = !loading && (data === undefined || data.results.length === 0)

  const [headElements, setHeadElements] = useState<React.JSX.Element[] | null>(null)
  const [bodyElements, setBodyElements] = useState<React.JSX.Element[] | null>(null)
  const [filterElement, setFilterElement] = useState<React.JSX.Element | null>(null)
  const [bulkElements, setBulkElements] = useState<React.JSX.Element[] | null>(null)
  const [tableActionsElement, setTableActionsElement] = useState<React.JSX.Element | null>(null)
  const [hasSearchFilter, setHasSearchFilter] = useState<boolean>(false)

  const [searchParams] = useSearchParams()
  const location = useLocation()
  let storageKey = location.pathname.replace(/\/$/, "").replace(/^\//, "")
  if (storageSuffix !== undefined) {
    storageKey = storageSuffix
  }
  storageKey = `${StoragePrefixes.columns}${getBaseApp()}_${storageKey}`

  // Maintains the order.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [foundColumns, setFoundColumns] = useLocalStorage<IColumnItem[] | null>(storageKey, [])

  // use this key if we turn on local storage: `${storageKey}${storageSuffix}/selected/rows`
  const [selectedRows, setSelectedRows] = useState<Array<string | number> | null>([])

  const handleLoadFoundColumns = useCallback(
    (child: React.JSX.Element, forceUpdate: boolean = false) => {
      const columnHeaderElements = child.props.children.flat()
      let columns1: IColumnItem[] = []
      // Handle case where there might only be one column
      if (columnHeaderElements.filter === undefined) {
        const childCol: React.JSX.Element = columnHeaderElements
        if (childCol.props.field !== undefined) {
          columns1.push({ id: childCol.props.field, name: childCol.props.children, show: true } satisfies IColumnItem)
        }
      } else {
        columns1 = columnHeaderElements
          // Ignore any undefined columns
          .filter((c: React.JSX.Element) => c?.props?.field !== undefined)
          // Map jsx columns to a key value list item.
          .map((childCol: React.JSX.Element) => {
            return { id: childCol.props.field, name: childCol.props.children, show: true } satisfies IColumnItem
          })
      }
      const columnsEqual: boolean = _.isEqual(
        _.orderBy(foundColumns?.map(c => c.id)),
        _.orderBy(columns1.map(c => c.id)),
      )
      if (
        foundColumns === undefined ||
        foundColumns === null ||
        foundColumns.length === 0 ||
        !columnsEqual ||
        forceUpdate
      ) {
        setFoundColumns(columns1)
      }
    },
    [foundColumns],
  )

  const handleColumnReset = useCallback(() => {
    setFoundColumns([])
    children?.forEach(child => {
      if (child.type === TableHead) {
        handleLoadFoundColumns(child, true)
      }
    })
  }, [children])

  useEffect(() => {
    children?.forEach((child: React.JSX.Element) => {
      if (child.type === TableHead) {
        const columnHeaderElements = child.props.children
        handleLoadFoundColumns(child)
        setHeadElements(
          (Array.isArray(columnHeaderElements) ? columnHeaderElements : [columnHeaderElements]) as
            | React.JSX.Element[]
            | null,
        )
      }
      if (child.type === TableBody) {
        setBodyElements(child.props.children as React.JSX.Element[] | null)
      }
      if (child.type === FiltersData) {
        if (Array.isArray(child.props.children)) {
          setHasSearchFilter((child.props.children as React.JSX.Element[]).some(el => el.type === FilterSearch))
        } else {
          setHasSearchFilter(child.props.children.type === FilterSearch)
        }
        setFilterElement(child)
      }
      if (child.type === TableActions) {
        setTableActionsElement(child)
      }
      if (child.type === BulkData) {
        setBulkElements(child.props.children as React.JSX.Element[] | null)
      }
    })
  }, [children])

  const filterColumns = useCallback(
    (c1: React.JSX.Element) => {
      if (foundColumns !== null && foundColumns.length > 0 && foundColumns.length > 0) {
        return foundColumns.some(cd => cd.id === c1?.props?.field && cd.show)
      }
      return true
    },
    [foundColumns],
  )

  const orderColumns = useCallback(
    (columns: React.JSX.Element[] | undefined) => {
      if (columns !== undefined && foundColumns !== null && foundColumns.length > 0) {
        const newColumns: React.JSX.Element[] = []
        for (const columnDisplayed of foundColumns) {
          for (const column of columns.flat()) {
            if (column.props.field === columnDisplayed.id) {
              newColumns.push(column)
              break
            }
          }
        }
        return newColumns
      }
      return columns
    },
    [foundColumns],
  )

  const handleColumnToggle = useCallback(
    (column: IColumnItem) => () => {
      setFoundColumns(columns => {
        if (columns !== null) {
          return columns.map(column1 => {
            if (column1.id === column.id) {
              return { ...column1, show: !column1.show }
            }
            return column1
          })
        }
        return null
      })
    },
    [],
  )

  const handleColumnOrder = useCallback((columns: IColumnItem[]) => {
    setFoundColumns(columns)
  }, [])

  const handleRowSelected = useCallback(
    (rowId: number | string | null) => () => {
      if (rowId !== null) {
        setSelectedRows(rows => {
          if (rows !== null) {
            if (rows.includes(rowId)) {
              return rows.filter(id => id !== rowId)
            } else {
              return [...rows, rowId]
            }
          }
          return null
        })
      }
    },
    [],
  )

  const showBulk = useMemo(() => {
    return bulkElements !== null && bodyElements?.length !== undefined && bodyElements.length > 0
  }, [bulkElements, bodyElements])

  const handleSelectAllRows = useCallback(() => {
    if (bodyElements !== null) {
      if (selectedRows !== null && selectedRows.length === bodyElements.length) {
        setSelectedRows([])
      } else {
        const sr1 = bodyElements
          .map(c1 => {
            if (c1.key === null) {
              return 0
            }
            return c1.key
          })
          .filter(key => key !== 0)
        setSelectedRows(sr1)
      }
    }
  }, [selectedRows, bodyElements])

  const handleBulkDone = useCallback(async () => {
    setSelectedRows([])
    await pagingResults.call()
  }, [pagingResults])

  const handleRefresh = useCallback(async () => {
    await pagingResults.call()
  }, [pagingResults])

  const selectInfoPath = useCallback(
    (key: string | null | undefined) => {
      if (toInfo) {
        return (
          to !== undefined &&
          key !== null &&
          `${location.pathname}/${toInfoPrefix}info/${key}?${searchParams.toString()}`
        )
      }
      return to !== undefined && key !== null && `${to}/${key}`
    },
    [to, toInfo, toInfoPrefix, searchParams],
  )

  return !isSmall ? (
    <Grid item xs={12}>
      <PaperLocal sx={{ p: 0 }}>
        <Box sx={{ p: 1 }}>
          <Grid container spacing={1} alignItems="center">
            {showBulk && (
              <Grid item xs={3}>
                <BulkData selectedRows={selectedRows ?? []} onDone={handleBulkDone} repository={itemRepository}>
                  {bulkElements}
                </BulkData>
              </Grid>
            )}
            {repository !== undefined && to !== undefined && (
              <Grid item xs={hasSearchFilter ? 4 : 3}>
                <Goto
                  repository={repository}
                  to={to}
                  filters={gotoFilters}
                  searchable={hasSearchFilter}
                  pagingResults={pagingResults}
                />
              </Grid>
            )}
            <Grid item xs>
              <Box sx={{ position: "relative" }}>
                <ViewLoading loading={loading} inline tight />
              </Box>
            </Grid>
            {filterElement !== null && <Grid item>{filterElement}</Grid>}
            {tableActionsElement !== null && <Grid item>{tableActionsElement}</Grid>}
            <Grid item>
              <Button onClick={handleRefresh} startIcon={<RefreshIcon />}>
                Refresh
              </Button>
            </Grid>
            <Grid item>
              <TableColumns
                foundColumns={foundColumns ?? []}
                onColumnOrder={handleColumnOrder}
                onColumnReset={handleColumnReset}
                onColumnToggle={handleColumnToggle}
              />
            </Grid>
            <Grid item xs={12}>
              {error !== undefined && <ErrorMessage error={error} />}
            </Grid>
          </Grid>
        </Box>
        <TableContainer sx={{ width: "100%" }}>
          <Table>
            <TableHead>
              <TableRow>
                {showBulk && (
                  <TableCell sx={{ textAlign: "center", p: 0 }}>
                    <Checkbox
                      id="files-bulk-all"
                      checked={selectedRows?.length === bodyElements?.length}
                      onClick={handleSelectAllRows}
                    />
                  </TableCell>
                )}
                {orderColumns(headElements?.flat().filter(filterColumns))}
              </TableRow>
            </TableHead>
            <TableBody>
              {bodyElements?.map((c1: React.JSX.Element) => {
                return (
                  <TableRowSelect key={c1.key} to={selectInfoPath(c1.key)}>
                    <>
                      {showBulk && (
                        <TableCell sx={{ textAlign: "center", p: 0 }}>
                          <Checkbox
                            id={`files-bulk-${c1.key}`}
                            checked={selectedRows?.some(id => c1.key === id)}
                            onChange={handleRowSelected(c1.key)}
                          />
                        </TableCell>
                      )}

                      {c1.props.children.filter === undefined
                        ? orderColumns([c1.props.children].flat().filter(filterColumns))
                        : orderColumns((c1.props.children.flat() as React.JSX.Element[]).filter(filterColumns))}
                    </>
                  </TableRowSelect>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {showNoneFound && (
          <Alert color="info" sx={{ m: 1 }}>
            No results found.
          </Alert>
        )}
        <TablePaging pagingResults={pagingResults} size={isSmall ? "small" : "medium"} />
      </PaperLocal>
    </Grid>
  ) : (
    <>
      {repository !== undefined && to !== undefined && (
        <Grid item xs>
          <Goto repository={repository} to={to} filters={gotoFilters} />
        </Grid>
      )}
      {filterElement != null && <Grid item>{filterElement}</Grid>}
    </>
  )
}

export default TableData
