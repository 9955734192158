import React, { useEffect } from "react"
import { Box, Button, Grid } from "@mui/material"
import { useSnackbar } from "notistack"
import useAzurePubSub from "../hooks/useAzurePubSub"
import FileDownloadButton from "./files/FileDownloadButton"
import { Link } from "react-router-dom"
import { PROFILE_URL } from "../../apps/admin/config/urls"
import LinkIcon from "@mui/icons-material/Link"

/**
 * A React functional component responsible for displaying message notifications.
 * Utilizes the Azure PubSub service to listen for messages and the useSnackbar hook
 * to enqueue notifications.
 *
 * When a new message is received from the Azure PubSub service, the message content
 * is logged to the console and a snackbar notification is displayed with the
 * message content.
 *
 * A button is provided to manually trigger a snackbar notification with a predefined message.
 *
 * @returns {React.ReactElement} The rendered component.
 */
const MessageNotifications = (): React.ReactElement => {
  const { enqueueSnackbar } = useSnackbar()
  const azurePubSub = useAzurePubSub()

  useEffect(() => {
    if (azurePubSub.messages.length > 0) {
      const pubSubMessage = azurePubSub.messages[0]
      if (pubSubMessage.item === "ProfileFile") {
        enqueueSnackbar(pubSubMessage.message, {
          variant: pubSubMessage.data !== null ? "success" : "info",
          action: pubSubMessage.data !== null && (
            <Grid container spacing={1}>
              <Grid item>
                <FileDownloadButton file={pubSubMessage.data} withText />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  disableElevation
                  component={Link}
                  to={`${PROFILE_URL}?tab=1`}
                  startIcon={<LinkIcon />}>
                  View
                </Button>
              </Grid>
            </Grid>
          ),
        })
      }
      if (["SummaryFile", "RiskWriterFile"].includes(pubSubMessage.item)) {
        enqueueSnackbar(pubSubMessage.message, {
          variant: pubSubMessage.data !== null ? "success" : "info",
          action: pubSubMessage.data !== null && (
            <Grid container spacing={1}>
              <Grid item>
                <FileDownloadButton file={pubSubMessage.data} withText />
              </Grid>
            </Grid>
          ),
        })
      }
    }
  }, [azurePubSub.messages.length])

  return <Box />
}

export default MessageNotifications
