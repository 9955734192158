import React from "react"
import { TableCell } from "@mui/material"
import ViewPhone from "../display/ViewPhone"

interface IProps {
  children: string | null | undefined
  field?: string
  ext?: string | undefined
}

/**
 * Represents a table cell with phone display.
 *
 * @param {IProps} props - The component props.
 * @returns {React.ReactElement} - The rendered TableCellPhone component.
 */
const TableCellPhone: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children, ext } = props

  return (
    <TableCell>
      <ViewPhone display ext={ext}>
        {children}
      </ViewPhone>
    </TableCell>
  )
}

export default TableCellPhone
