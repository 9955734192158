import React, { useCallback, useContext } from "react"
import FormatNumber, { formatNumber } from "../format/FormatNumber"
import type { IPanariskAppContext } from "../../models/app/IPanariskAppContext"
import { PanariskAppContext } from "../../../app/PanariskApp"
import { useSelectOptions } from "../../hooks/useSelectOptions"
import { SelectOptions } from "../../../config/config"


type TViewCurrencyResponse = (value: number) => string

/**
 * Generates a formatted currency string based on the provided props.
 *
 * @param {boolean} twoDecimalPlaces - The properties used to generate the formatted currency string.
 * @returns {string} The formatted currency string.
 */
export const useViewCurrency = (twoDecimalPlaces: boolean = false): TViewCurrencyResponse => {
  const { appSettings } = useContext<IPanariskAppContext>(PanariskAppContext)
  const currency = appSettings?.serverInfo?.currency
  const theSelectOptions = useSelectOptions(SelectOptions.CURRENCY_SYMBOLS)
  const firstMatch = theSelectOptions.find(option => option.key === currency)

  return useCallback(
    (value: number) => {
      return formatNumber({
        value,
        prefixUnits: firstMatch?.value !== undefined ? firstMatch.value : undefined,
        twoDecimalPlaces,
      })
    },
    [],
  )
}


interface IProps {
  children: number | null | undefined
  twoDecimalPlaces?: boolean
}

/**
 * Renders a formatted number with a currency symbol.
 *
 * @param {IProps} props - The input props object.
 * @returns {React.ReactElement} - The formatted number with a currency symbol.
 */
const ViewCurrency: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children, twoDecimalPlaces = false } = props

  const { appSettings } = useContext<IPanariskAppContext>(PanariskAppContext)
  const currency = appSettings?.serverInfo?.currency
  const theSelectOptions = useSelectOptions(SelectOptions.CURRENCY_SYMBOLS)
  const firstMatch = theSelectOptions.find(option => option.key === currency)

  return (
    <FormatNumber
      value={children}
      prefixUnits={firstMatch?.value !== undefined ? firstMatch.value : undefined}
      twoDecimalPlaces={twoDecimalPlaces}
    />
  )
}

export default ViewCurrency
