import type IUser from "./IUser"
import { type IMainModel } from "../service/IMainModel"
import { type IServiceUser } from "../service/IServiceUser"

export const PROFILES_ENDPOINT = "profiles"

export default interface IProfile extends IMainModel {
  id: number
  user: IUser | null | undefined
  social_photo: string | null
  language: string
  social_providers: string[]
  frontend_storage: Record<string, any>
  service_user: IServiceUser
  auto_save_risk_writer: boolean
  rich_text_boxes_full_length: boolean
  map_type: string
}
