import React from "react"
import { List, type Theme } from "@mui/material"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import { Link, useLocation } from "react-router-dom"
import ListItemText from "@mui/material/ListItemText"
import ListItemIcon from "@mui/material/ListItemIcon"
import InfoIcon from "@mui/icons-material/Info"
import { type IListItem } from "../../models/components/IListItem"
import TruncateText from "../TruncateText"

interface IProps {
  children: IListItem[] | IListItem | null | undefined
  prefix?: string
}

/**
 * Renders a list of items.
 *
 * @param {IProps} props - The properties for the ViewListItems component.
 * @returns {React.ReactElement} - The rendered items list.
 */
const ViewListItems: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children: listItems, prefix } = props
  const location = useLocation()

  if (listItems === undefined || listItems === null || (Array.isArray(listItems) && listItems.length === 0)) {
    return <>Not Set</>
  }

  const theList = !Array.isArray(listItems) ? [listItems] : listItems

  return (
    <List disablePadding>
      {theList.map(item => (
        <ListItem key={item.id} disablePadding>
          {prefix !== undefined ? (
            <ListItemButton
              component={Link}
              to={`${location.pathname}/${prefix}info/${item.id}`}
              sx={(theme: Theme) => ({
                p: 0,
                textDecoration: "underline",
                textUnderlineOffset: 3,
                color: theme.palette.primary.main,
              })}>
              <ListItemText primary={<TruncateText>{item.name}</TruncateText>} sx={{ textAlign: "right" }} />
              <ListItemIcon sx={{ minWidth: 0, ml: 1 }}>
                <InfoIcon />
              </ListItemIcon>
            </ListItemButton>
          ) : (
            <ListItemText primary={<TruncateText>{item.name}</TruncateText>} sx={{ textAlign: "right" }} />
          )}
        </ListItem>
      ))}
    </List>
  )
}

export default ViewListItems
