import React from "react"
import { Container, Grid, Typography } from "@mui/material"

import { useApiRead } from "../../../../shared/hooks/useApiRead"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import PageHeader from "../../../../shared/components/pages/PageHeader"
import { FILE_TAG_ENDPOINT, type IFileTag } from "../../../../shared/models/service/IFileTag"
import ViewProperty from "../../../../shared/components/ViewProperty"
import { FILE_TAG_EDIT_URL } from "../../config/urls"
import ViewColor from "../../../../shared/components/display/ViewColor"
import PaperLocal from "../../../../shared/components/containers/PaperLocal"
import ViewRichText from "../../../../shared/components/display/ViewRichText"

const repository = new RestRepository<IFileTag>(FILE_TAG_ENDPOINT)

/**
 * Renders a page to view file tag details.
 *
 * @returns {React.ReactElement} The rendered component.
 */
const ViewPage: React.FC = (): React.ReactElement => {
  const { data } = useApiRead<IFileTag>({ apiFunction: repository.read })

  return (
    <Container fixed>
      {data !== undefined && (
        <Grid container spacing={2}>
          <PageHeader title="File Tag" toEdit={`${FILE_TAG_EDIT_URL}/${data.id}`} />
          <Grid item xs={12} lg={6}>
            <PaperLocal>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5" color="textSecondary">
                    {data.name}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <ViewProperty label="Color">
                    <ViewColor>{data.color}</ViewColor>
                  </ViewProperty>
                </Grid>
                <Grid item xs={12}>
                  <ViewProperty label="Notes" vertical>
                    <ViewRichText>{data.notes}</ViewRichText>
                  </ViewProperty>
                </Grid>
              </Grid>
            </PaperLocal>
          </Grid>
        </Grid>
      )}
    </Container>
  )
}

export default ViewPage
