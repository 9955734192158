import React from "react"
import { TableCell } from "@mui/material"
import FormatDate from "../format/FormatDate"

interface IProps {
  children: string | null | undefined
  field?: string
}

/**
 * Renders a TableCell with formatted date value.
 *
 * @param {IProps} props - The component props.
 * @returns {React.ReactElement} - The rendered TableCell with formatted date value.
 */
const TableCellDate: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children } = props

  return (
    <TableCell sx={{ textAlign: "center" }}>
      <FormatDate value={children} />
    </TableCell>
  )
}

export default TableCellDate
