import React, { useCallback, useEffect, useState } from "react"
import { Box, Grid, IconButton, Pagination, TextField } from "@mui/material"
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight"
import { type IUseApiPagedResultsResponse } from "../../hooks/useApiPagedLocal"

interface IProps {
  pagingResults: IUseApiPagedResultsResponse<any>
  size?: "small" | "medium" | "large"
}

const numFormatOptions = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
}

/**
 * A general purpose component for paging data.
 *
 * @param {IProps} props see IProps for details.
 * @returns {React.FC<IProps>} the table paging component
 */
const TablePaging: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { pagingResults, size = "medium" } = props
  const { paging } = pagingResults
  const [localPage, setLocalPage] = useState<number | undefined>(paging?.page)
  const [localLimit, setLocalLimit] = useState<number | undefined>(paging?.limit)

  useEffect(() => {
    if (paging?.page === undefined) {
      // setting page to 1 if undefined.
      pagingResults.handlePaging(null, 1)
    } else {
      setLocalPage(paging.page)
    }
  }, [paging?.page])

  useEffect(() => {
    setLocalLimit(paging?.limit)
  }, [paging?.limit])

  const handleLimit = useCallback(() => {
    if (pagingResults.handleLimit !== undefined && localLimit !== undefined) {
      pagingResults.handleLimit(localLimit)
    }
  }, [localLimit])

  const handleLimitChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const newLimit = Number(e.target.value)
    if (newLimit === 0) {
      setLocalLimit(undefined)
    } else {
      if (newLimit > 200) {
        setLocalLimit(200)
      } else {
        setLocalLimit(newLimit)
      }
    }
  }, [])

  const handlePagingChange = useCallback(() => {
    if (localPage !== undefined) {
      pagingResults.handlePaging(null, localPage)
    }
  }, [pagingResults.handlePaging, localPage])

  return (
    <Grid container spacing={size === "small" ? 0 : 2} alignItems="center" alignContent="center">
      <Grid item xs={size === "small" ? 12 : undefined}>
        <Box sx={size === "small" ? {} : { p: 2 }}>
          <Pagination
            count={pagingResults.count}
            page={paging?.page === undefined ? 0 : paging.page}
            siblingCount={size === "small" ? 0 : undefined}
            boundaryCount={size === "small" ? 1 : undefined}
            onChange={pagingResults.handlePaging}
          />
        </Box>
      </Grid>
      {size !== "small" && (
        <>
          <Grid item>
            <TextField
              label="Page"
              value={localPage}
              size="small"
              sx={{ width: 100 }}
              onChange={e => {
                setLocalPage(Number(e.target.value))
              }}
            />
          </Grid>
          <Grid item>
            <IconButton size="small" onClick={handlePagingChange}>
              <ArrowCircleRightIcon />
            </IconButton>
          </Grid>
          {pagingResults.handleLimit !== undefined ? (
            <>
              <Grid item>
                <TextField
                  label="Limit"
                  value={localLimit}
                  size="small"
                  sx={{ width: 100 }}
                  onChange={handleLimitChange}
                />
              </Grid>
              <Grid item xs>
                <IconButton size="small" onClick={handleLimit}>
                  <ArrowCircleRightIcon />
                </IconButton>
              </Grid>
            </>
          ) : (
            <Grid item xs />
          )}
        </>
      )}
      <Grid item xs={size === "small" ? 12 : undefined}>
        <Box sx={size === "small" ? { p: 1 } : { p: 2, mr: 1 }}>
          {pagingResults.data?.count !== undefined && (
            <>Results: {pagingResults.data.count.toLocaleString(undefined, numFormatOptions)}</>
          )}
        </Box>
      </Grid>
    </Grid>
  )
}

export default TablePaging
