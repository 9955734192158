import React, { useCallback, useEffect, useState } from "react"
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from "@mui/material"
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { type ItemPrefixes } from "../../../config/config"
import DialogControls from "../DialogControls"

export interface IItemEditorProps {
  itemId?: number | string | null
  onSave: () => void
  requestSave: boolean
  setRequestSave: React.Dispatch<React.SetStateAction<boolean>>
}

interface IProps {
  title?: string
  prefix: ItemPrefixes
  itemEdit?: React.ComponentType<IItemEditorProps>
  itemAdd?: React.ComponentType<IItemEditorProps>
  onSave: () => Promise<void>
}

/**
 * Displays an info editor dialog.
 *
 * @param {IProps} props - The props passed to the component.
 * @returns {React.ReactElement} - The rendered info editor dialog component.
 */
const ItemEditorDialog: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { title, prefix, itemEdit: ItemEdit, itemAdd: ItemAdd, onSave } = props

  const [open, setOpen] = useState<boolean>(false)
  const [itemId, setItemId] = useState<string | null>(null)
  const isSmall = useMediaQuery(useTheme().breakpoints.down("md"))
  const params = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [requestSave, setRequestSave] = useState(false)

  const handleClose = useCallback(async () => {
    setOpen(false)
    if ("*" in params) {
      const parentPath = location.pathname.replace("/" + params["*"], "")
      navigate(`${parentPath}?${searchParams.toString()}`, { replace: true })
    }
  }, [params, location, searchParams])

  const handleSave = useCallback(async () => {
    await handleClose()
    await onSave()
  }, [handleClose])

  useEffect(() => {
    if ("*" in params) {
      const parts = params["*"]?.split("/")
      if (parts !== undefined && parts[0] === `${prefix}edit` && parts.length > 0) {
        setOpen(true)
        setItemId(parts[1])
      } else if (parts !== undefined && parts[0] === `${prefix}add` && parts.length > 0) {
        setOpen(true)
        setItemId(null)
      } else {
        setOpen(false)
      }
    }
  }, [params])

  const handleRequestSave = useCallback(async () => {
    setRequestSave(true)
  }, [])

  return (
    <Dialog open={open} sx={{ zIndex: 1410 }} fullWidth maxWidth="lg" fullScreen={isSmall}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ pr: 0, pl: 0 }}>
        <Box sx={{ pt: 2 }}>
          {ItemEdit !== undefined && itemId !== null && (
            <ItemEdit itemId={itemId} onSave={handleSave} requestSave={requestSave} setRequestSave={setRequestSave} />
          )}
          {ItemAdd !== undefined && itemId === null && (
            <ItemAdd onSave={handleSave} requestSave={requestSave} setRequestSave={setRequestSave} />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <DialogControls onSave={handleRequestSave} onCancel={handleClose} />
      </DialogActions>
    </Dialog>
  )
}

export default ItemEditorDialog
