import React from "react"
import { Grid, TableBody, TableHead, TableRow } from "@mui/material"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import { useApiPagedLocal } from "../../../../shared/hooks/useApiPagedLocal"
import { type IVendor, VENDOR_ENDPOINT } from "../../../../shared/models/service/IVendor"
import { VENDOR_ADD_URL, VENDOR_VIEW_URL } from "../../config/urls"
import PageHeader from "../../../../shared/components/pages/PageHeader"
import TableData from "../../../../shared/components/tables/TableData"
import ListData from "../../../../shared/components/lists/ListData"
import ListDataItem from "../../../../shared/components/lists/ListDataItem"
import TableCellData from "../../../../shared/components/tables/TableCellData"
import { type IListItem } from "../../../../shared/models/components/IListItem"
import TableCellDate from "../../../../shared/components/tables/TableCellDate"
import TableCellServiceUser from "../../../../shared/components/tables/TableCellServiceUser"

const repository = new RestRepository<IVendor>(VENDOR_ENDPOINT)
const gotoRepo = new RestRepository<IListItem>(VENDOR_ENDPOINT)

/**
 * Renders the index page for the vendors.
 *
 * @returns {React.ReactElement} The rendered index page.
 */
const IndexPage: React.FC = (): React.ReactElement => {
  const pagingResults = useApiPagedLocal<IVendor>({ apiFunction: repository.findAll })
  const { data } = pagingResults

  return (
    <Grid container alignItems="center" spacing={2}>
      <PageHeader title="Vendor" titlePlural="Vendors" toAdd={VENDOR_ADD_URL} />
      <TableData pagingResults={pagingResults} to={VENDOR_VIEW_URL} repository={gotoRepo}>
        <TableHead>
          <TableCellData field="name" pagingResults={pagingResults}>
            Name
          </TableCellData>
          <TableCellData field="lead">Lead</TableCellData>
          <TableCellData field="created">Created</TableCellData>
          <TableCellData field="updated">Updated</TableCellData>
        </TableHead>
        <TableBody>
          {data?.results.map((item: IVendor) => (
            <TableRow key={item.id}>
              <TableCellData field="name">{item.name}</TableCellData>
              <TableCellServiceUser field="lead">{item.lead}</TableCellServiceUser>
              <TableCellDate field="created">{item.created}</TableCellDate>
              <TableCellDate field="updated">{item.updated}</TableCellDate>
            </TableRow>
          ))}
        </TableBody>
      </TableData>

      <ListData pagingResults={pagingResults}>
        {data?.results.map((item: IVendor) => (
          <ListDataItem key={item.id} to={`${VENDOR_VIEW_URL}/${item.id}`} title={item.name} />
        ))}
      </ListData>
    </Grid>
  )
}

export default IndexPage
