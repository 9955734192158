import { useCallback, useState } from "react"
import type { AxiosError } from "axios"
import { CONNECTION_ERROR, type IConnectionError } from "../models/components/IConnectionError"

interface IUseAxiosRequestResponse {
  callRequest: (run: () => Promise<void>) => Promise<void>
  loading: boolean
  error: boolean
  errorMessage: IConnectionError | null
}

/**
 * Represents a custom hook for making requests using Axios.
 *
 * @returns {IUseAxiosRequestResponse} The response object containing the request handling functions and state variables.
 */
export const useAxiosRequest = (): IUseAxiosRequestResponse => {
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState<IConnectionError | null>(null)
  const [loading, setLoading] = useState(false)

  const callRequest = useCallback(async (run: () => Promise<void>) => {
    setLoading(true)
    setError(false)
    setErrorMessage(null)
    try {
      await run()
    } catch (reason) {
      setError(true)
      if ((reason as AxiosError)?.response !== undefined) {
        setErrorMessage((reason as AxiosError).response as IConnectionError)
      } else {
        setErrorMessage(CONNECTION_ERROR)
      }
    }
    setLoading(false)
  }, [])

  return {
    callRequest,
    error,
    errorMessage,
    loading,
  }
}
