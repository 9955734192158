import React, { createContext, useCallback, useEffect, useState } from "react"
import type { IRiskWriterFormat } from "../models/IRiskWriterFormat"
import type { IRiskWriterData } from "../models/IRiskWriterData"
import type { IFile } from "../../../models/service/IFile"
import {
  type IRiskWriterSectionData,
  type ISectionDataImage,
  type ISectionDataRichText,
  type ISectionDataTable,
  SectionType,
} from "../models/IRiskWriterSectionData"
import { findSectionByName, type IRiskWriterSection } from "../models/IRiskWriterSection"
import type { IRiskWriterFieldGroup } from "../models/IRiskWriterFieldGroup"
import ViewLoading from "../../ViewLoading"
import { Box } from "@mui/material"
import type { IRiskWriterTable } from "../models/IRiskWriterTable"
import { diff, type Diff } from "deep-diff"
import _ from "lodash"
import useEffectAsync from "../../../hooks/useEffectAsync"
import useDebounce from "react-debounced"

export interface IRiskReportWriterContext {
  reportWriterFormat?: IRiskWriterFormat | null
  units?: string | undefined
  autoSave: boolean
  onSave?: () => Promise<void>
  onToggleAutoSave?: () => Promise<void>
  reloadReport?: () => Promise<void>

  reportWriterData?: IRiskWriterData | null
  reportWriterDataDiff?: Array<Diff<IRiskWriterData, IRiskWriterData>> | undefined
  setReportWriterData?: React.Dispatch<React.SetStateAction<IRiskWriterData | null>>
  currentContent?: IRiskWriterSectionData | null
  setCurrentContent?: React.Dispatch<React.SetStateAction<IRiskWriterSectionData | null>>
  currentSection?: IRiskWriterSection | null
  setCurrentSection?: React.Dispatch<React.SetStateAction<IRiskWriterSection | null>>
  currentFieldGroup?: IRiskWriterFieldGroup | null
  setCurrentFieldGroup?: React.Dispatch<React.SetStateAction<IRiskWriterFieldGroup | null>>

  onContentMove?: (sectionData: IRiskWriterSectionData, direction: "up" | "down") => void
  onChangeFieldGroup?: (fieldGroup: IRiskWriterFieldGroup) => void
  onChangeSection?: (section: IRiskWriterSection) => void
  onAddContent?: (
    sectionName: string,
    contentType: SectionType,
    data?: ISectionDataRichText | ISectionDataImage | ISectionDataTable | null,
  ) => void
  onChangeContent?: (
    contentName: string,
    data: ISectionDataRichText | ISectionDataImage | ISectionDataTable | null,
    clearCurrentContent?: boolean,
  ) => void
  onDeleteContent?: (content: IRiskWriterSectionData) => void
  onClearContent?: () => void
  onEditContent?: (section: IRiskWriterSection, content: IRiskWriterSectionData) => void
  onChangeTable?: (table: IRiskWriterTable, data: any[]) => void
  onGenerateContent?: (section: IRiskWriterSection) => Promise<string | null>
  onGenerateAll?: () => Promise<void>

  getFile?: (fileId: number) => Promise<IFile | null>
  fileSelected?: IFile | null
  onFileRequest?: () => void
  clearFileSelected?: () => void
  onFileCaptionChange?: (file: IFile) => Promise<void>
}

export const RiskWriterContext = createContext<IRiskReportWriterContext>({ autoSave: false })

interface IProps {
  format?: IRiskWriterFormat | null
  units?: string | undefined
  autoSave?: boolean | undefined
  data?: IRiskWriterData | null
  onSave?: (data: IRiskWriterData) => Promise<void>
  onToggleAutoSave?: () => Promise<void>

  onFileRequest?: () => void
  fileSelected?: IFile | null
  getFile?: (fileId: number) => Promise<IFile | null>
  onFileCaptionChange?: (file: IFile) => Promise<void>
  onReloadReport?: () => Promise<void>

  onGenerateContent?: (section: IRiskWriterSection) => Promise<string | null>
  children: React.JSX.Element
}

/**
 * The RiskWriterContext component is a React functional component that wraps its children with a ReportWriterContext.Provider.
 * It takes a single prop of type IProps and returns a React element.
 *
 * @param {IProps} props - The props object containing the children to be wrapped by ReportWriterContext.Provider.
 * @returns {React.ReactElement} - A React element that wraps the children with ReportWriterContext.Provider.
 */
const RiskWriterProvider: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const {
    children,
    format,
    units,
    autoSave = false,
    data,
    onReloadReport,
    onSave,
    onToggleAutoSave,
    onGenerateContent,
    onFileRequest,
    fileSelected,
    getFile,
    onFileCaptionChange,
  } = props

  const [reportWriterData, setReportWriterData] = useState<IRiskWriterData | null>(null)
  const [reportWriterDataDiff, setReportWriterDataDiff] = useState<
    Array<Diff<IRiskWriterData, IRiskWriterData>> | undefined
  >()

  const [loadingGenerateAll, setLoadingGenerateAll] = useState<boolean>(false)
  const [generateAllProgress, setGenerateAllProgress] = useState<number>(0)
  const [generateAllName, setGenerateAllName] = useState<string>("")

  const [currentFileSelected, setCurrentFileSelected] = useState<IFile | null>(null)
  const [currentContent, setCurrentContent] = useState<IRiskWriterSectionData | null>(null)
  const [currentSection, setCurrentSection] = useState<IRiskWriterSection | null>(null)
  const [currentFieldGroup, setCurrentFieldGroup] = useState<IRiskWriterFieldGroup | null>(null)

  const debounce = useDebounce()

  const clearFileSelected = useCallback(() => {
    setCurrentFileSelected(null)
  }, [])

  const compareData = useCallback(() => {
    if (reportWriterData !== null && data !== undefined && data !== null) {
      const differences = diff(data, reportWriterData)
      if (differences !== undefined) {
        setReportWriterDataDiff(differences)
      }
    }
  }, [data, reportWriterData])

  /**
   * Handles adding content to the report writer.
   *
   * @param {string} sectionName - The name of the section to add the content to.
   * @param {string} contentType - The type of the content to add.
   * @param {string} [data=""] - The data for the content.
   * @returns {void}
   */
  const handleAddContent = useCallback(
    (
      sectionName: string,
      sectionType: SectionType,
      data: ISectionDataRichText | ISectionDataImage | ISectionDataTable | null = null,
    ) => {
      setReportWriterData(prevReport => {
        if (prevReport !== null && format?.sections !== undefined) {
          const newReport = _.cloneDeep(prevReport)
          const targetSection = findSectionByName(format.sections, sectionName)

          if (targetSection !== null) {
            const newContent: IRiskWriterSectionData = {
              name: crypto.randomUUID(),
              section_name: sectionName,
              data,
              section_type: sectionType,
            }
            setCurrentSection(targetSection)
            setCurrentContent(newContent)
            newReport.sections !== undefined ? newReport.sections.push(newContent) : (newReport.sections = [newContent])
          }
          return { ...newReport }
        }
        return null
      })
    },
    [format],
  )

  /**
   * Updates the current section and current content.
   *
   * @param {IRiskWriterSection} section - The section to update the current section to.
   * @param {IRiskWriterSectionData} content - The content to update the current content to.
   * @returns {void}
   */
  const handleEditContent = useCallback((section: IRiskWriterSection, content: IRiskWriterSectionData) => {
    setCurrentSection(section)
    setCurrentContent(content)
  }, [])

  /**
   * Moves the content of a section up or down within the report writer data.
   * If the data is null, the current content will be cleared.
   *
   * @param {IRiskWriterSectionData} sectionData - The section data to be moved.
   * @param {string} direction - The direction of move ("up" or "down").
   */
  const handleContentMove = useCallback((sectionData: IRiskWriterSectionData, direction: "up" | "down") => {
    // when data is null just clear the current content.
    if (data !== null) {
      setReportWriterData(prevReport => {
        if (prevReport !== null) {
          const newReport = _.cloneDeep(prevReport)
          const ourSectionIndex = newReport.sections.findIndex(section => section.name === sectionData.name)
          let swapWithIndex = -1
          const start = direction === "up" ? ourSectionIndex - 1 : ourSectionIndex + 1
          const end = direction === "up" ? -1 : newReport.sections.length
          const step = direction === "up" ? -1 : 1

          for (let i = start; i !== end; i += step) {
            if (
              newReport.sections[i].section_name === sectionData.section_name &&
              ((i < ourSectionIndex && direction === "up") || (i > ourSectionIndex && direction === "down"))
            ) {
              swapWithIndex = i
              break
            }
          }

          if (swapWithIndex !== -1) {
            ;[newReport.sections[swapWithIndex], newReport.sections[ourSectionIndex]] = [
              newReport.sections[ourSectionIndex],
              newReport.sections[swapWithIndex],
            ]
          }
          return newReport
        }
        return null
      })
    }
  }, [])

  /**
   * Handles the change of content in the report writer.
   *
   * @function handleChangeContent
   * @param {string} contentName - The name of the content to be changed.
   * @param {ISectionDataRichText | ISectionDataImage | ISectionDataTable | null} data - The new data to be assigned to the content.
   * @param {boolean} clearCurrentContent - Set too false to keep the current content editable.
   * @returns {void}
   */
  const handleChangeContent = useCallback(
    (
      contentName: string,
      data: ISectionDataRichText | ISectionDataImage | ISectionDataTable | null,
      clearCurrentContent: boolean = true,
    ) => {
      // when data is null just clear the current content.
      if (data !== null) {
        setReportWriterData(prevReport => {
          if (prevReport !== null) {
            const newReport = _.cloneDeep(prevReport)
            const content = newReport.sections.find(c => c.name === contentName)
            if (content !== undefined) {
              content.data = data
            }
            return newReport
          }
          return null
        })
      }
      if (clearCurrentContent) {
        setCurrentContent(null)
      }
    },
    [],
  )

  /**
   * Handles deletion of content from report writer data.
   *
   * @param {IRiskWriterSectionData} content - The content to be deleted from the report writer data.
   * @returns {void}
   */
  const handleDeleteContent = useCallback((content: IRiskWriterSectionData) => {
    setReportWriterData(prevReport => {
      if (prevReport !== null) {
        const newReport = _.cloneDeep(prevReport)
        const index = newReport.sections.findIndex(c => c.name === content.name)
        if (index !== -1) {
          newReport.sections.splice(index, 1)
          return { ...newReport }
        }
      }
      return null
    })
  }, [])

  const handleClearContent = useCallback(() => {
    setReportWriterData(prevReport => {
      if (prevReport !== null) {
        const newReport = _.cloneDeep(prevReport)
        return { ...newReport, sections: [] }
      }
      return null
    })
  }, [])

  /**
   * Function to handle the change in table data.
   *
   * @param {IRiskWriterTable} table - The table object being modified.
   * @param {any[]} data - The new data for the table.
   */
  const handleChangeTable = useCallback((table: IRiskWriterTable, data: any[]) => {
    setReportWriterData?.(prevReport => {
      if (prevReport !== null) {
        if (prevReport.tables === undefined || prevReport.tables.length === 0) {
          const newReport = _.cloneDeep(prevReport)
          newReport.tables.push({ ...table, rows: data })
          return newReport
        }

        const newReport: IRiskWriterData = {
          ..._.cloneDeep(prevReport),
          tables: prevReport.tables.map(t1 => {
            if (t1.name === table.name) {
              return { ...t1, rows: data }
            }
            return t1
          }),
        }
        return newReport
      }
      return null
    })
  }, [])

  /**
   * Handles the change of section.
   *
   * @param {IRiskWriterSection} section - The new section to set as current section.
   * @returns {void}
   */
  const handleChangeSection = useCallback((section: IRiskWriterSection) => {
    setCurrentSection(section)
    setCurrentFieldGroup(null)
  }, [])

  /**
   * Callback function to handle the change of a field group.
   * This function sets the current field group and clears the current section.
   *
   * @param {IRiskWriterFieldGroup} fieldGroup - The field group to be set as the current field group.
   * @returns {void}
   */
  const handleChangeFieldGroup = useCallback((fieldGroup: IRiskWriterFieldGroup) => {
    setCurrentFieldGroup(fieldGroup)
    setCurrentSection(null)
  }, [])

  /**
   * A callback function used to generate content for all sections and subsections.
   * The function iterates through the sections and makes requests to retrieve content.
   * The retrieved content is then added using the handleAddContent function.
   * The progress of the generation process is tracked and updated using the setGenerateAllProgress function.
   *
   * @async
   * @function handleGenerateAll
   * @returns {void}
   * @throws {Error} If there is an error while making requests or adding content
   */
  const handleGenerateAll = useCallback(async () => {
    if (format?.sections !== undefined && format?.sections !== null) {
      setLoadingGenerateAll(true)
      let total = 0
      for (const section of format.sections) {
        if (section.allowed_content_types?.includes(SectionType.RICH_TEXT_EDITOR) === true) {
          total++
        }
        if (section.sections !== undefined && section.sections !== null) {
          for (const subSection of section.sections) {
            if (subSection.allowed_content_types?.includes(SectionType.RICH_TEXT_EDITOR) === true) {
              total++
            }
          }
        }
      }
      let progress = 0
      for (const section of format.sections) {
        if (section.allowed_content_types?.includes(SectionType.RICH_TEXT_EDITOR) === true) {
          setGenerateAllName(section.title)
          const data = await onGenerateContent?.(section)
          const sectionDataRichText: ISectionDataRichText = { html: `<p>${data}</p>` }
          handleAddContent(section.name, SectionType.RICH_TEXT_EDITOR, sectionDataRichText)
          progress++
          setGenerateAllProgress((progress / total) * 100)
        }
        if (section.sections !== undefined && section.sections !== null) {
          for (const subSection of section.sections) {
            if (subSection.allowed_content_types?.includes(SectionType.RICH_TEXT_EDITOR) === true) {
              setGenerateAllName(subSection.title)
              const data = await onGenerateContent?.(subSection)
              const sectionDataRichText: ISectionDataRichText = { html: `<p>${data}</p>` }
              handleAddContent(subSection.name, SectionType.RICH_TEXT_EDITOR, sectionDataRichText)
              progress++
              setGenerateAllProgress((progress / total) * 100)
            }
          }
        }
      }
      setLoadingGenerateAll(false)
    }
  }, [format, currentContent])

  /**
   * A callback function for handling the save operation.
   *
   * @async
   * @function handleSave
   * @returns {Promise<void>} Resolves when the save operation is completed.
   *
   * @param {Function} onSave - The onSave function to be called for saving the reportWriterData.
   * @param {IRiskWriterData} reportWriterData - The data to be saved.
   */
  const handleSave = useCallback(async () => {
    if (reportWriterData !== null) {
      debounce(async () => {
        setReportWriterDataDiff(undefined)
        await onSave?.(reportWriterData)
      })
    }
  }, [onSave, reportWriterData])

  const handleReloadReport = useCallback(async () => {
    await onReloadReport?.()
  }, [onReloadReport])

  const reportWriterContext: IRiskReportWriterContext = {
    reportWriterFormat: format,
    onSave: handleSave,
    onToggleAutoSave,
    reportWriterData,
    setReportWriterData,
    reportWriterDataDiff,
    currentContent,
    setCurrentContent,
    currentSection,
    setCurrentSection,
    currentFieldGroup,
    setCurrentFieldGroup,
    onContentMove: handleContentMove,
    onChangeFieldGroup: handleChangeFieldGroup,
    onChangeSection: handleChangeSection,
    onAddContent: handleAddContent,
    onChangeContent: handleChangeContent,
    onDeleteContent: handleDeleteContent,
    onClearContent: handleClearContent,
    onEditContent: handleEditContent,
    onChangeTable: handleChangeTable,
    onGenerateContent,
    onGenerateAll: handleGenerateAll,
    reloadReport: handleReloadReport,
    onFileRequest,
    fileSelected: currentFileSelected,
    getFile,
    units,
    autoSave,
    clearFileSelected,
    onFileCaptionChange,
  }

  useEffect(() => {
    if (data !== undefined) {
      setReportWriterDataDiff(undefined)
      setReportWriterData(_.cloneDeep(data))
    }
  }, [data])

  useEffect(() => {
    if (fileSelected !== undefined) {
      setCurrentFileSelected(fileSelected)
    }
  }, [fileSelected])

  useEffectAsync(async () => {
    if (reportWriterDataDiff !== undefined && autoSave) {
      await handleSave()
    }
  }, [reportWriterDataDiff, handleSave, autoSave])

  useEffect(() => {
    compareData()
  }, [compareData])

  return (
    <>
      <RiskWriterContext.Provider value={reportWriterContext}>
        <ViewLoading
          loading={loadingGenerateAll}
          progress={generateAllProgress}
          message={
            <>
              <strong>Writing section:</strong> <Box>{generateAllName}</Box>
            </>
          }
        />
        {children}
      </RiskWriterContext.Provider>
    </>
  )
}

export default RiskWriterProvider
