import React from "react"
import { TableBody, TableHead, TableRow } from "@mui/material"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import { type IUseApiPagedResultsResponse } from "../../../../../shared/hooks/useApiPagedLocal"
import { type IServiceUser, SERVICE_USER_ENDPOINT } from "../../../../../shared/models/service/IServiceUser"
import { SERVICE_USER_ADD_URL, SERVICE_USER_VIEW_URL } from "../../../config/urls"
import PageHeader from "../../../../../shared/components/pages/PageHeader"
import TableData from "../../../../../shared/components/tables/TableData"
import ListData from "../../../../../shared/components/lists/ListData"
import ListDataItem from "../../../../../shared/components/lists/ListDataItem"
import TableCellData from "../../../../../shared/components/tables/TableCellData"
import { type IListItem } from "../../../../../shared/models/components/IListItem"
import ListProperty from "../../../../../shared/components/lists/ListProperty"
import ItemViewerDrawer from "../../../../../shared/components/item_viewer/ItemViewerDrawer"
import ServiceUserInfo from "./ServiceUserInfo"
import TableCellPhone from "../../../../../shared/components/tables/TableCellPhone"
import TableCellDate from "../../../../../shared/components/tables/TableCellDate"
import TableCellCheckMark from "../../../../../shared/components/tables/TableCellCheckMark"
import TableActions from "../../../../../shared/components/tables/TableActions"
import TableCellAction from "../../../../../shared/components/tables/TableCellAction"
import { ItemPrefixes } from "../../../../../config/config"
import PrivateComponent from "../../../../../shared/components/PrivateComponent"
import { ROLE_SYSTEM_MANAGER_GROUP } from "../../../../../config/permissions"

const repository = new RestRepository<IListItem>(SERVICE_USER_ENDPOINT)

interface IProps {
  pagingResults: IUseApiPagedResultsResponse<IServiceUser> | undefined
  showHeader?: boolean
  useInfoView?: boolean
  tableActions?: React.JSX.Element[] | React.JSX.Element | undefined
  cellActions?: (serviceUser: IServiceUser) => React.JSX.Element[] | React.JSX.Element | undefined
}

/**
 * Renders the index page for the service users.
 *
 * @param {IProps} props See props for details
 * @returns {React.ReactElement} The rendered index page.
 */
const IndexPage: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { pagingResults, showHeader = true, useInfoView = false, tableActions, cellActions } = props

  return (
    <>
      <PrivateComponent
        component={
          <>
            {showHeader && <PageHeader title="Service User" titlePlural="Service Users" toAdd={SERVICE_USER_ADD_URL} />}
          </>
        }
        groupNames={[ROLE_SYSTEM_MANAGER_GROUP]}
        componentElse={<>{showHeader && <PageHeader title="Service User" titlePlural="Service Users" />}</>}
      />
      {pagingResults !== undefined && (
        <>
          <ItemViewerDrawer title={"Service User"} prefix={ItemPrefixes.serviceUser} infoView={ServiceUserInfo} />
          <TableData
            pagingResults={pagingResults}
            toInfo={useInfoView}
            toInfoPrefix={ItemPrefixes.serviceUser}
            to={SERVICE_USER_VIEW_URL}
            repository={repository}>
            {tableActions !== undefined ? <TableActions>{tableActions}</TableActions> : <></>}
            <TableHead>
              <TableCellData field="first_name" pagingResults={pagingResults}>
                First Name
              </TableCellData>
              <TableCellData field="last_name" pagingResults={pagingResults}>
                Last Name
              </TableCellData>
              <TableCellData field="phone" pagingResults={pagingResults}>
                Phone
              </TableCellData>
              <TableCellData field="email" pagingResults={pagingResults}>
                Email
              </TableCellData>
              <TableCellData field="last_login">Last Login</TableCellData>
              <TableCellData field="has_enabled_user_account">Enabled User</TableCellData>
              {cellActions !== undefined && <TableCellAction field="action">Action</TableCellAction>}
            </TableHead>
            <TableBody>
              {pagingResults.data?.results.map((item: IServiceUser) => (
                <TableRow key={item.id}>
                  <TableCellData field="first_name">{item.first_name}</TableCellData>
                  <TableCellData field="last_name">{item.last_name}</TableCellData>
                  <TableCellPhone field="phone" ext={item.phone_ext}>
                    {item.phone}
                  </TableCellPhone>
                  <TableCellData field="email">{item.email}</TableCellData>
                  <TableCellDate field="last_login">{item.last_login}</TableCellDate>
                  <TableCellCheckMark field="has_enabled_user_account">
                    {item.has_enabled_user_account}
                  </TableCellCheckMark>
                  {cellActions !== undefined && <TableCellAction field="action">{cellActions(item)}</TableCellAction>}
                </TableRow>
              ))}
            </TableBody>
          </TableData>

          <ListData pagingResults={pagingResults}>
            {pagingResults.data?.results.map((item: IServiceUser) => (
              <ListDataItem
                key={item.id}
                to={`${SERVICE_USER_VIEW_URL}/${item.id}`}
                title={`${item.first_name} ${item.last_name}`}>
                <ListProperty label="Phone">{item.phone}</ListProperty>
                <ListProperty label="Email">{item.email}</ListProperty>
              </ListDataItem>
            ))}
          </ListData>
        </>
      )}
    </>
  )
}

export default IndexPage
