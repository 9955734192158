import React, { useContext } from "react"
import { type IUseApiPagedResultsResponse } from "../../../hooks/useApiPagedLocal"
import { INSPECTION_BUILDING_VIEW_URL } from "../../../../apps/admin/config/urls"
import TableData from "../../../components/tables/TableData"
import { Grid, TableBody, TableHead, TableRow } from "@mui/material"
import TableCellData from "../../../components/tables/TableCellData"
import { ItemPrefixes } from "../../../../config/config"
import { type IInspectionBuilding, INSPECTION_BUILDING_ENDPOINT } from "../../../models/service/IInspectionBuilding"
import { RestRepository } from "../../../repositories/RestRepository"
import { type IListItem } from "../../../models/components/IListItem"
import TableActions from "../../../components/tables/TableActions"
import TableCellAction from "../../../components/tables/TableCellAction"
import { nameToLabel } from "../../../utilities/form_utility"
import { type IRiskWriterFormat } from "../../risk_writer/models/IRiskWriterFormat"
import TableCellArea from "../../../components/tables/TableCellArea"
import TableCellCenter from "../../../components/tables/TableCellCenter"
import { IRiskInputType } from "../../risk_writer/models/IRiskWriterField"
import TableCellNumber from "../../../components/tables/TableCellNumber"
import TableCellCurrency from "../../../components/tables/TableCellCurrency"
import TableCellPercent from "../../../components/tables/TableCellPercent"
import { useRiskWriterOptions } from "../../risk_writer/hooks/useRiskWriterOptions"
import TableCellTruncate from "../../../components/tables/TableCellTruncate"
import type { IFilter } from "../../../models/components/IFilter"
import ItemDeleteDrawer from "../../../components/item_delete/ItemDeleteDrawer"
import InspectionBuildingInfo from "./InspectionBuildingInfo"
import type { IMainModel } from "../../../models/service/IMainModel"
import type { IPanariskAppContext } from "../../../models/app/IPanariskAppContext"
import { PanariskAppContext } from "../../../../app/PanariskApp"

const repository = new RestRepository<IListItem>(INSPECTION_BUILDING_ENDPOINT)
const mainRepository = new RestRepository<IMainModel>(INSPECTION_BUILDING_ENDPOINT)

interface IProps {
  pagingResults: IUseApiPagedResultsResponse<IInspectionBuilding>
  useInfoView?: boolean
  tableActions?: React.JSX.Element[] | React.JSX.Element | undefined
  itemDelete?: boolean
  format?: IRiskWriterFormat | null | undefined
  gotoFilters?: IFilter[]
}

/**
 * Renders the Inspection Recommendation Index component.
 *
 * @param {IProps} props - The props object.
 * @returns {React.ReactElement} The rendered component.
 */
const InspectionBuildingIndex: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const {
    pagingResults,
    format,
    useInfoView = false,
    itemDelete = false,
    tableActions,
    gotoFilters,
  } = props

  const { appSettings } = useContext<IPanariskAppContext>(PanariskAppContext)
  const units = appSettings?.serverInfo?.units
  const { getOptionValue } = useRiskWriterOptions({ format })

  return (
    <Grid container alignItems="center" spacing={2}>
      {pagingResults !== undefined && (
        <>
          <TableData
            pagingResults={pagingResults}
            to={INSPECTION_BUILDING_VIEW_URL}
            toInfo={useInfoView}
            toInfoPrefix={ItemPrefixes.inspectionBuilding}
            repository={repository}
            gotoFilters={gotoFilters}
            storageSuffix="inspection/building">
            {tableActions !== undefined ? <TableActions>{tableActions}</TableActions> : <></>}
            <TableHead>
              <TableCellData field="id">ID</TableCellData>
              <TableCellData field="building">Building</TableCellData>
              <TableCellData field="occupancy">Occupancy</TableCellData>
              <TableCellCenter field="area">
                {`Area ${units === "US" ? "ft<sup>2</sup>" : "m<sup>2</sup>"}`}
              </TableCellCenter>
              {format?.building_form?.columns.map(field => {
                return (
                  <TableCellData key={field.name} field={field.name}>
                    {nameToLabel(field.name, field.title)}
                  </TableCellData>
                )
              })}
              {itemDelete && <TableCellAction field="action">Action</TableCellAction>}
            </TableHead>
            <TableBody>
              {pagingResults.data?.results.map((item: IInspectionBuilding) => (
                <TableRow key={item.id}>
                  <TableCellData field="id">{item.id}</TableCellData>
                  <TableCellTruncate field="building">{item.building.name}</TableCellTruncate>
                  <TableCellTruncate field="occupancy">{item.building.occupancy?.name}</TableCellTruncate>
                  <TableCellArea field="area" units={units}>
                    {item.building.area}
                  </TableCellArea>
                  {format?.building_form?.columns.map(field => {
                    if (field.input_type === IRiskInputType.INTEGER || field.input_type === IRiskInputType.FLOAT) {
                      return (
                        <TableCellNumber key={field.name} field={field.name}>
                          {(item.data?.[field.name] as number) ?? 0}
                        </TableCellNumber>
                      )
                    }
                    if (field.input_type === IRiskInputType.CURRENCY) {
                      return (
                        <TableCellCurrency key={field.name} field={field.name}>
                          {(item.data?.[field.name] as number) ?? 0}
                        </TableCellCurrency>
                      )
                    }
                    if (field.input_type === IRiskInputType.AREA) {
                      return (
                        <TableCellArea key={field.name} field={field.name} units={units}>
                          {(item.data?.[field.name] as number) ?? 0}
                        </TableCellArea>
                      )
                    }
                    if (field.input_type === IRiskInputType.PERCENT) {
                      return (
                        <TableCellPercent key={field.name} field={field.name}>
                          {(item.data?.[field.name] as number) ?? 0}
                        </TableCellPercent>
                      )
                    }
                    if (field.input_type === IRiskInputType.SELECT && field.options !== null) {
                      return (
                        <TableCellTruncate key={field.name} field={field.name}>
                          {`${getOptionValue(field.options, item.data?.[field.name] ?? "Not Set")}`}
                        </TableCellTruncate>
                      )
                    }
                    return (
                      <TableCellData key={field.name} field={field.name}>
                        {item.data?.[field.name] ?? "Not Set"}
                      </TableCellData>
                    )
                  })}
                  {itemDelete && (
                    <TableCellAction field="action">
                      <ItemDeleteDrawer
                        itemId={item.id}
                        onDelete={pagingResults.call}
                        infoView={InspectionBuildingInfo}
                        repository={mainRepository}
                        name="Building"
                        iconButton
                      />
                    </TableCellAction>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </TableData>
        </>
      )}
    </Grid>
  )
}

export default InspectionBuildingIndex
