import React from "react"
import { Button, IconButton } from "@mui/material"
import { type IFile } from "../../models/service/IFile"
import HtmlToolTip from "../HtmlToolTip"
import DownloadIcon from "@mui/icons-material/Download"

interface IProps {
  file: IFile
  withText?: boolean
}

/**
 * This component will download a file that is in azure storage.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the download button.
 */
const FileDownloadButton: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { file, withText = false } = props

  return (
    <>
      {file.file?.filename !== undefined && (
        <HtmlToolTip title={`Download file: ${file.file.filename}`}>
          {withText ? (
            <Button variant="contained" disableElevation href={file.file.read_url} startIcon={<DownloadIcon />}>
              Download
            </Button>
          ) : (
            <IconButton color="primary" href={file.file.read_url} size="small">
              <DownloadIcon />
            </IconButton>
          )}
        </HtmlToolTip>
      )}
    </>
  )
}

export default FileDownloadButton
