import React from "react"
import { Grid, TableBody, TableHead, TableRow } from "@mui/material"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import { useApiPagedLocal } from "../../../../shared/hooks/useApiPagedLocal"
import { type IRecommendation, RECOMMENDATION_ENDPOINT } from "../../../../shared/models/service/IRecommendation"
import { RECOMMENDATION_ADD_URL, RECOMMENDATION_VIEW_URL } from "../../config/urls"
import PageHeader from "../../../../shared/components/pages/PageHeader"
import TableData from "../../../../shared/components/tables/TableData"
import ListData from "../../../../shared/components/lists/ListData"
import ListDataItem from "../../../../shared/components/lists/ListDataItem"
import TableCellData from "../../../../shared/components/tables/TableCellData"
import { type IListItem } from "../../../../shared/models/components/IListItem"
import TableCellDate from "../../../../shared/components/tables/TableCellDate"
import ListProperty from "../../../../shared/components/lists/ListProperty"
import TableCellServiceUser from "../../../../shared/components/tables/TableCellServiceUser"
import TableCellReportFormat from "../../../../shared/components/tables/TableCellReportFormat"

const repository = new RestRepository<IRecommendation>(RECOMMENDATION_ENDPOINT)
const gotoRepo = new RestRepository<IListItem>(RECOMMENDATION_ENDPOINT)

/**
 * Renders the index page for the recommendation library.
 *
 * @returns {React.ReactElement} The rendered index page.
 */
const IndexPage: React.FC = (): React.ReactElement => {
  const pagingResults = useApiPagedLocal<IRecommendation>({ apiFunction: repository.findAll })
  const { data } = pagingResults

  // todo: Fix recommendation types filter.
  return (
    <Grid container alignItems="center" spacing={2}>
      <PageHeader title="Recommendation" titlePlural="Recommendations" toAdd={RECOMMENDATION_ADD_URL} />
      <TableData pagingResults={pagingResults} to={RECOMMENDATION_VIEW_URL} repository={gotoRepo}>
        {/* <FiltersData pagingResults={pagingResults}> */}
        {/*  <FilterSelectOption field="type" selectOption={SelectOptions.RECOMMENDATION_TYPES} /> */}
        {/* </FiltersData> */}
        <TableHead>
          <TableCellData field="title" pagingResults={pagingResults}>
            Title
          </TableCellData>
          <TableCellData field="type" pagingResults={pagingResults}>
            Type
          </TableCellData>
          <TableCellData field="owner" pagingResults={pagingResults}>
            Owner
          </TableCellData>
          <TableCellData field="report_format" pagingResults={pagingResults}>
            Report Format
          </TableCellData>
          <TableCellData field="created" pagingResults={pagingResults}>
            Created
          </TableCellData>
          <TableCellData field="updated" pagingResults={pagingResults}>
            Updated
          </TableCellData>
        </TableHead>
        <TableBody>
          {data?.results.map((item: IRecommendation) => (
            <TableRow key={item.id}>
              <TableCellData field="title">{item.title}</TableCellData>
              <TableCellData field="type">{item.type?.name}</TableCellData>
              <TableCellServiceUser field="owner">{item.owner}</TableCellServiceUser>
              <TableCellReportFormat field="report_format">{item.report_format}</TableCellReportFormat>
              <TableCellDate field="created">{item.created}</TableCellDate>
              <TableCellDate field="updated">{item.updated}</TableCellDate>
            </TableRow>
          ))}
        </TableBody>
      </TableData>

      <ListData pagingResults={pagingResults}>
        {data?.results.map((item: IRecommendation) => (
          <ListDataItem key={item.id} to={`${RECOMMENDATION_VIEW_URL}/${item.id}`} title={item.title}>
            <ListProperty label="Type">{item.type?.name}</ListProperty>
          </ListDataItem>
        ))}
      </ListData>
    </Grid>
  )
}

export default IndexPage
