import React from "react"
import { TableCell } from "@mui/material"
import { type IContact } from "../../models/service/IContact"

interface IProps {
  children: IContact | null | undefined
  field?: string
}

/**
 * TableCellContact component is a functional component that renders a table cell
 * with a contact name.
 *
 * @param {IProps} props - The component props.
 * @returns {React.ReactElement} - A React element representing a table cell with a contact name.
 */
const TableCellContact: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children } = props

  return <TableCell>{children?.name}</TableCell>
}

export default TableCellContact
