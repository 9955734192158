/**
 * Strips all HTML tags from a given string.
 *
 * @param {string} input - The string from which HTML tags should be removed.
 * @returns {string} - The string without HTML tags.
 */
export const stripHtmlTags = (input: string): string => {
  return input.replace(/<\/?[^>]+(>|$)/g, "")
}

/**
 * Truncate a string to a specific length and add dots to the end. Also removes special characters.
 *
 * @param {string | undefined | null} str string to truncate.
 * @param {number} num length to truncate to.
 * @param {boolean} middle if middle is true then truncation will be in the middle.
 * @returns {string | undefined | null} the truncated string
 */
export const truncateString = (
  str: string | undefined | null,
  num: number = 30,
  middle: boolean = false,
): string | undefined | null => {
  if (str === null || str === undefined || str.length <= num) {
    return str
  }
  if (middle) {
    const sliceOffset = str.length / 2 - (str.length - num) / 2 - 1
    const replace = str.slice(sliceOffset, sliceOffset + (str.length - num)).replace(/<\/?[^>]+(>|$)/g, "")
    return str.replace(replace, "...")
  }
  return str.slice(0, num).replace(/<\/?[^>]+(>|$)/g, "") + "..."
}

/**
 * Check to see if a string is going to be truncated.
 *
 * @param {string} str to check.
 * @param {number} num length to truncate to.
 * @returns {boolean} true if the value is truncated.
 */
export const isTruncated = (str: string | undefined | null, num: number = 30): boolean => {
  return str !== null && str !== undefined ? str.length > num : false
}
