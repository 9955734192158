import React from "react"
import { type IBuilding } from "../../models/service/IBuilding"
import { List } from "@mui/material"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import { Link, useLocation } from "react-router-dom"
import ListItemText from "@mui/material/ListItemText"
import ListItemIcon from "@mui/material/ListItemIcon"
import InfoIcon from "@mui/icons-material/Info"
import { truncateString } from "../../utilities/format_utility"

interface IProps {
  children: IBuilding | null | undefined
  prefix?: string
}

/**
 * Renders the description and address of a building.
 * todo: replace with ViewListItems
 *
 * @param {IProps} props - The properties passed to the component.
 * @returns {React.ReactElement} - The JSX code to render the building information.
 */
const ViewBuilding: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children: building, prefix } = props
  const location = useLocation()

  return building !== undefined && building !== null ? (
    <List disablePadding>
      <ListItem disablePadding>
        {prefix !== undefined ? (
          <ListItemButton component={Link} to={`${location.pathname}/${prefix}info/${building.id}`} sx={{ p: 0 }}>
            <ListItemText primary={truncateString(building.name)} sx={{ textAlign: "right" }} />
            <ListItemIcon sx={{ minWidth: 0, ml: 1 }}>
              <InfoIcon />
            </ListItemIcon>
          </ListItemButton>
        ) : (
          <ListItemText primary={truncateString(building.name)} sx={{ textAlign: "right" }} />
        )}
      </ListItem>
    </List>
  ) : (
    <>Not Set</>
  )
}

export default ViewBuilding
