import React, { useCallback, useState } from "react"
import { IconButton, Menu } from "@mui/material"
import MoreVertIcon from "@mui/icons-material/MoreVert"

interface IProps {
  children: React.JSX.Element[] | React.JSX.Element
}

/**
 * A component that displays a menu when clicked.
 *
 * @param {IProps} props - The props for the component.
 * @param {React.JSX.Element[] | React.JSX.Element} props.children - The content to be displayed within the menu.
 *
 * @returns {React.ReactElement} The rendered WidgetMenu component.
 */
const WidgetMenu: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children } = props

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} onClick={handleClose}>
        {children}
      </Menu>
    </>
  )
}

export default WidgetMenu
