import React, { useCallback } from "react"
import { Alert, Button, Grid, IconButton, Snackbar } from "@mui/material"
import EmailIcon from "@mui/icons-material/Email"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"

import { CopyToClipboard } from "react-copy-to-clipboard"

interface IProps {
  children: string | null | undefined
  display?: boolean
}

/**
 * Renders an email view component.
 *
 * @param {IProps} props - The input props.
 * @returns {React.ReactElement} - The rendered email view component.
 */
const ViewEmail: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children: email, display = false } = props
  const [open, setOpen] = React.useState(false)

  const handleCopy = useCallback(() => {
    setOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  if (email === undefined || email === null || email === "") {
    return <>Not Set</>
  }

  if (display) {
    return <>{email}</>
  }

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item xs>
        <Button startIcon={<EmailIcon />} href={`mailto:${email}`} size="small" sx={{ textTransform: "lowercase" }}>
          {email}
        </Button>
      </Grid>
      <Grid item>
        <Snackbar
          open={open}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={1000}
          onClose={handleClose}>
          <Alert severity="info">Email copied.</Alert>
        </Snackbar>
        <CopyToClipboard text={email} onCopy={handleCopy}>
          <IconButton size="small">
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        </CopyToClipboard>
      </Grid>
    </Grid>
  )
}

export default ViewEmail
