import React from "react"
import { Grid, Typography } from "@mui/material"
import { RestRepository } from "../../../repositories/RestRepository"
import { useApiRead } from "../../../hooks/useApiRead"
import { type IInfoViewProps } from "../../../components/item_viewer/ItemViewerDrawer"
import ItemViewerTitle from "../../../components/item_viewer/ItemViewerTitle"
import { INSPECTION_BUILDING_VIEW_URL } from "../../../../apps/admin/config/urls"
import ViewProperty from "../../../components/ViewProperty"
import { type IReportFormat, REPORT_FORMAT_ENDPOINT } from "../../../models/service/IReportFormat"
import CheckMark from "../../../components/display/CheckMark"

const repository = new RestRepository<IReportFormat>(REPORT_FORMAT_ENDPOINT)

/**
 * Renders a view page for an inspection report building.
 *
 * @param {IInfoViewProps} props See props for details
 * @returns {React.ReactElement} The rendered view page.
 */
const ReportWriterFormatInfo: React.FC<IInfoViewProps> = (props: IInfoViewProps): React.ReactElement => {
  const { infoId } = props
  const apiRead = useApiRead<IReportFormat>({ apiFunction: repository.read, itemId: infoId })
  const { data } = apiRead

  return (
    <>
      {data !== undefined && (
        <>
          <Grid container spacing={2}>
            <Grid item xs>
              <ItemViewerTitle to={`${INSPECTION_BUILDING_VIEW_URL}/${infoId}`}>{data.name}</ItemViewerTitle>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label="Description">{data.description}</ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label="Uses Report Writer">
                <CheckMark value={data.use_report_writer} />
              </ViewProperty>
            </Grid>
            {data.use_report_writer && data.report_writer_format !== null && (
              <>
                <Grid item xs={12}>
                  <Typography variant="h6">Report Writer</Typography>
                </Grid>
                <Grid item xs={12}>
                  <ViewProperty label="Title">{data.report_writer_format.title}</ViewProperty>
                </Grid>
                <Grid item xs={12}>
                  <ViewProperty label="Name">{data.report_writer_format.name}</ViewProperty>
                </Grid>
                <Grid item xs={12}>
                  <ViewProperty label="Version">{data.report_writer_format.version}</ViewProperty>
                </Grid>
              </>
            )}
          </Grid>
        </>
      )}
    </>
  )
}

export default ReportWriterFormatInfo
