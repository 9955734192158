import { Controller, type FieldValues, type RegisterOptions } from "react-hook-form"
import React from "react"
import { type Control } from "react-hook-form/dist/types/form"
import { Box, Grid, type SxProps, TextField } from "@mui/material"
import { type InputProps as StandardInputProps } from "@mui/material/Input/Input"
import { type IFormatNumberAndErrorProps } from "../format/FormatNumberAndError"
import { nameToLabel } from "../../utilities/form_utility"
import InfoIcon from "@mui/icons-material/Info"
import HtmlToolTip from "../HtmlToolTip"

interface IProps {
  control: Control
  defaultValue?: string | number
  label?: string | null
  showLabelRequired?: boolean
  showLabel?: boolean
  disabled?: boolean
  name: string
  type?: React.HTMLInputTypeAttribute
  formatter?: React.FC<IFormatNumberAndErrorProps>
  rules?:
    | Omit<RegisterOptions<FieldValues, string>, "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled">
    | undefined
  onChange?: StandardInputProps["onChange"]
  onBlur?: () => void
  multiline?: boolean
  suffixUnits?: string
  prefixUnits?: string
  sx?: SxProps
  size?: "small" | "medium"
  helperText?: string
}

/**
 * A reusable text input forms field that uses mui and react hook forms.
 *
 * @param {IProps} props see IProps for details.
 * @returns {React.FC<IProps>} the input field.
 */
const FhMuiTextField: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const {
    control,
    type = "string",
    defaultValue = "",
    onChange,
    onBlur,
    name,
    label,
    showLabelRequired = false,
    showLabel = true,
    disabled = false,
    formatter: Formatter,
    suffixUnits = undefined,
    prefixUnits = undefined,
    rules = {},
    multiline = false,
    sx,
    size,
    helperText = null,
  } = props

  const theLabel = showLabel ? nameToLabel(name, label) : undefined

  return (
    <Grid container>
      <Grid item xs>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          rules={rules}
          render={({ field, fieldState }) => {
            return (
              <TextField
                size={size}
                fullWidth
                type={type}
                disabled={disabled}
                multiline={multiline}
                error={fieldState.invalid}
                InputLabelProps={{ shrink: true }}
                helperText={
                  Formatter !== undefined ? (
                    <Formatter
                      field={field}
                      fieldState={fieldState}
                      suffixUnits={suffixUnits}
                      prefixUnits={prefixUnits}
                    />
                  ) : (
                    fieldState.error?.message
                  )
                }
                label={
                  showLabel ? <>
                    {theLabel}
                    {suffixUnits !== undefined && suffixUnits !== "" ? ` (${suffixUnits.trim()})` : null}
                    {showLabelRequired && <sup>(* required)</sup>}
                  </> : undefined
                }
                onBlur={() => {
                  onBlur?.()
                  field.onBlur()
                }}
                onChange={e => {
                  field.onChange(e)
                  onChange?.(e)
                }}
                value={field.value}
                inputRef={field.ref}
                sx={sx}
              />
            )
          }}
        />
      </Grid>
      {helperText !== null && <Grid item>
        <Box sx={{ position: "relative", marginLeft: -3, marginTop: 0, cursor: "pointer" }}>
          <HtmlToolTip title={<Box dangerouslySetInnerHTML={{ __html: helperText }} />} placement="left">
            <InfoIcon color="secondary" />
          </HtmlToolTip>
        </Box>
      </Grid>}
    </Grid>
  )
}

export default FhMuiTextField
