import React from "react"
import FormatNumber from "../format/FormatNumber"

interface IProps {
  children: number | null | undefined
  units: string | undefined
  twoDecimalPlaces?: boolean
  field?: string
}

/**
 * Represents a React functional component for displaying an area
 * value in square feet.
 *
 * @param {IProps} props - The component props.
 * @returns {React.ReactElement} The rendered area component.
 */
const ViewArea: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children, units, twoDecimalPlaces = false } = props

  return (
    <FormatNumber
      value={children}
      prefixUnits={false}
      twoDecimalPlaces={twoDecimalPlaces}
      suffixUnits={
        <>
          {" "}
          {units === "US" ? <>ft<sup>2</sup></> : <>m<sup>2</sup></>}
        </>
      }
    />
  )
}

export default ViewArea
