import React, { useCallback } from "react"
import { Box } from "@mui/material"
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import { CHART_COLORS } from "../../../../config/config"
import { type IStats } from "../../../models/service/IStats"
import type { IChartBuilderState } from "../models/IStatBuilderState"
import ChartTooltip from "./ChartTooltip"
import { truncateString } from "../../../utilities/format_utility"

interface ITickProps {
  payload?: {
    value: string
  }
  x?: number
  y?: number
  onClick?: (label: string) => void
}

/**
 * CustomTick component.
 *
 * @param {ITickProps} props - The props for the CustomTick component.
 * @returns {React.ReactElement} The rendered CustomTick component.
 */
const CustomTick: React.FC<ITickProps> = (props: ITickProps): React.ReactElement => {
  const { x, y, payload, onClick } = props

  const handleClick = useCallback(() => {
    if (payload?.value != null) {
      onClick?.(payload.value)
    }
  }, [onClick, payload])

  return (
    <g transform={`translate(${x},${y})`} onClick={handleClick} style={{ cursor: "pointer" }}>
      <text x={0} y={0} dy={6} textAnchor="end" fill="#666">
        {truncateString(payload?.value)}
      </text>
    </g>
  )
}

const numFormatOptions = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
}

interface IProps {
  chartRef: React.RefObject<HTMLDivElement>
  data: IStats[]
  statBuilderStat: IChartBuilderState
  onClick?: (label: string) => void
}

/**
 * WidgetBarChart is a React functional component that renders a bar chart widget.
 *
 * @param {IProps} props - The props for the WidgetBarChart component.
 * @param {React.Ref} props.chartRef - The ref of the chart container.
 * @param {Array} props.data - The data to be displayed on the bar chart.
 * @returns {React.ReactElement} - The rendered bar chart widget.
 */
const WidgetBarHorizontalChart: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { chartRef, data, statBuilderStat, onClick } = props

  const formatTicker = useCallback(
    (value: any): string => {
      if (statBuilderStat.aggregatorField !== null) {
        const { units_pre: unitsPre, units_post: unitsPost } = statBuilderStat.aggregatorField
        return `${unitsPre ?? ""} ${Number(value).toLocaleString(undefined, numFormatOptions)} ${unitsPost ?? ""}`
      }
      return Number(value).toLocaleString(undefined, numFormatOptions)
    },
    [statBuilderStat],
  )

  return (
    <Box ref={chartRef}>
      <ResponsiveContainer width="100%" aspect={statBuilderStat.limit === 5 ? 3 : 2}>
        <BarChart data={data} margin={{ left: 200 }} layout="vertical">
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" dataKey="value" tickFormatter={value => formatTicker(value)} />
          <YAxis type="category" dataKey="label" textAnchor="end" tick={<CustomTick onClick={onClick} />} />
          <Tooltip content={props => <ChartTooltip {...props} statBuilderStat={statBuilderStat} />} />
          <Bar dataKey="value" barSize={30}>
            {data.map((_entry, index) => (
              <Cell key={`cell-${index}`} fill={CHART_COLORS[index % CHART_COLORS.length]} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Box>
  )
}

export default WidgetBarHorizontalChart
