import DashboardIcon from "@mui/icons-material/Dashboard"
import {
  ACCOUNT_INDEX_URL,
  ADDONS_INDEX_URL,
  CONTACT_INDEX_URL,
  DASHBOARD_URL,
  FILE_TAG_INDEX_URL,
  INSPECTION_INDEX_URL,
  INSPECTION_RECOMMENDATION_INDEX_URL,
  LOCATION_IMPAIRMENT_INDEX_URL,
  LOCATION_INDEX_URL,
  OCCUPANCY_INDEX_URL,
  POLICY_INDEX_URL,
  RECOMMENDATION_INDEX_URL, REST_API_KEY_INDEX_URL,
  SERVICE_USER_INDEX_URL,
  SETTING_VIEW_URL,
  SUMMARY_INDEX_URL,
  VENDOR_INDEX_URL
} from "./urls"
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload"
import LocationCityIcon from "@mui/icons-material/LocationCity"
import SavedSearchIcon from "@mui/icons-material/SavedSearch"
import ReportProblemIcon from "@mui/icons-material/ReportProblem"
import RecommendIcon from "@mui/icons-material/Recommend"
import ContactsIcon from "@mui/icons-material/Contacts"
import SummarizeIcon from "@mui/icons-material/Summarize"
import SettingsIcon from "@mui/icons-material/Settings"
import LibraryAddIcon from "@mui/icons-material/LibraryAdd"
import PolicyIcon from "@mui/icons-material/Policy"
import { type ISideNav } from "../../../shared/components/SideNavMenu"
import { ROLE_SYSTEM_MANAGER_GROUP } from "../../../config/permissions"

/**
 * Represents the side navigation menu.
 *
 * @param {ISideNav[] | null} addonMenuItems - are additional menu items to be displayed.
 * @returns {Array<ISideNav | null>} - The children menu items.
 */
export const navMenu = (addonMenuItems: ISideNav[] | null = null): Array<ISideNav | null | boolean> => {
  return [
    {
      icon: DashboardIcon,
      url: DASHBOARD_URL,
      name: "Dashboard",
    },
    {
      icon: AssuredWorkloadIcon,
      url: ACCOUNT_INDEX_URL,
      name: "Accounts",
    },
    {
      icon: LocationCityIcon,
      url: LOCATION_INDEX_URL,
      name: "Locations",
    },
    {
      icon: SavedSearchIcon,
      url: INSPECTION_INDEX_URL,
      name: "Inspections",
    },
    {
      icon: RecommendIcon,
      url: INSPECTION_RECOMMENDATION_INDEX_URL,
      name: "Inspection Recs",
    },
    {
      icon: ReportProblemIcon,
      url: LOCATION_IMPAIRMENT_INDEX_URL,
      name: "Impairments",
    },
    {
      icon: SummarizeIcon,
      url: SUMMARY_INDEX_URL,
      name: "Summaries",
    },
    {
      icon: PolicyIcon,
      url: POLICY_INDEX_URL,
      name: "Policies",
    },
    {
      icon: ContactsIcon,
      url: CONTACT_INDEX_URL,
      name: "Contacts",
    },
    addonMenuItems !== null ? null : false,
    addonMenuItems !== null
      ? {
          icon: LibraryAddIcon,
          url: ADDONS_INDEX_URL,
          name: "Addons",
          children: addonMenuItems,
        }
      : null,
    addonMenuItems !== null ? null : false,
    {
      icon: SettingsIcon,
      url: SETTING_VIEW_URL,
      name: "Settings",
      accessGroups: [ROLE_SYSTEM_MANAGER_GROUP],
    },
    { url: FILE_TAG_INDEX_URL, name: "File Tags" },
    { url: OCCUPANCY_INDEX_URL, name: "Occupancy" },
    { url: RECOMMENDATION_INDEX_URL, name: "Recommendations" },
    {
      url: SERVICE_USER_INDEX_URL,
      name: "Service Users",
      accessGroups: [ROLE_SYSTEM_MANAGER_GROUP],
    },
    { url: VENDOR_INDEX_URL, name: "Vendors" },
    { url: REST_API_KEY_INDEX_URL, name: "API Keys" },
  ]
}
