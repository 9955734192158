import React from "react"
import { Grid, TableCell } from "@mui/material"
import CheckMark from "../display/CheckMark"

interface IProps {
  children: boolean | undefined
  field?: string
}

/**
 * Renders a TableCell with formatted date value.
 *
 * @param {IProps} props - The component props.
 * @returns {React.ReactElement} - The rendered TableCell with formatted date value.
 */
const TableCellCheckMark: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children } = props

  return (
    <TableCell>
      <Grid container>
        <Grid item xs />
        <Grid item>
          <CheckMark value={children} />
        </Grid>
        <Grid item xs />
      </Grid>
    </TableCell>
  )
}

export default TableCellCheckMark
