import { type IListItem } from "../components/IListItem"
import { type IAccount } from "./IAccount"

export const POLICY_ENDPOINT = "policies"

export interface IPolicy extends IListItem {
  id: number
  name: string
  description: string | null
  start_date: string | null
  end_date: string | null
  accounts?: IAccount[] | null
  created: string
  updated: string
}
