import React, { useContext } from "react"
import { Grid } from "@mui/material"
import { type ILocation, LOCATION_ENDPOINT } from "../../../../../shared/models/service/ILocation"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import { useApiRead } from "../../../../../shared/hooks/useApiRead"
import { type IInfoViewProps } from "../../../../../shared/components/item_viewer/ItemViewerDrawer"
import ViewProperty from "../../../../../shared/components/ViewProperty"
import ViewMap from "../../../../../shared/components/display/ViewMap"
import ItemViewerTitle from "../../../../../shared/components/item_viewer/ItemViewerTitle"
import ViewArea from "../../../../../shared/components/display/ViewArea"
import ViewListItems from "../../../../../shared/components/display/ViewListItems"
import type { IPanariskAppContext } from "../../../../../shared/models/app/IPanariskAppContext"
import { PanariskAppContext } from "../../../../../app/PanariskApp"

const repository = new RestRepository<ILocation>(LOCATION_ENDPOINT)

/**
 * Render the view page for a locations.
 *
 * @param {IInfoViewProps} props See props for details
 * @returns {React.ReactElement} element containing the view page.
 */
const LocationInfo: React.FC<IInfoViewProps> = (props: IInfoViewProps): React.ReactElement => {
  const { infoId } = props
  const { data } = useApiRead<ILocation>({ apiFunction: repository.read, itemId: infoId })
  const { appSettings } = useContext<IPanariskAppContext>(PanariskAppContext)
  const units = appSettings?.serverInfo?.units

  return (
    <>
      {data !== undefined && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ItemViewerTitle>{data.name}</ItemViewerTitle>
          </Grid>
          <Grid item xs={12}>
            <ViewMap place={data} showInfo={false} />
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label="Address">{data.address}</ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label="City">{data.city}</ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label="State/Region">{data.state_region}</ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label="Postal Code">{data.postal_code}</ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label="Country">{data.country}</ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label="Buildings">{data.buildings.length}</ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label="Area">
              <ViewArea units={units}>{data.area}</ViewArea>
            </ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label="Primary Staff">
              <ViewListItems>{data.primary_staff}</ViewListItems>
            </ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label="Support Staff">
              <ViewListItems>{data.support_staff}</ViewListItems>
            </ViewProperty>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default LocationInfo
