import DashboardIcon from "@mui/icons-material/Dashboard"
import { ADDONS_INDEX_URL, DASHBOARD_URL, INSPECTION_RECOMMENDATION_INDEX_URL } from "./urls"
import LibraryAddIcon from "@mui/icons-material/LibraryAdd"
import { type ISideNav } from "../../../shared/components/SideNavMenu"
import RecommendIcon from "@mui/icons-material/Recommend"
import ReportProblemIcon from "@mui/icons-material/ReportProblem"
import { LOCATION_IMPAIRMENT_INDEX_URL } from "../../admin/config/urls"

/**
 * Represents the side navigation menu.
 *
 * @param {ISideNav[] | null} addonMenuItems - are additional menu items to be displayed.
 * @returns {Array<ISideNav | null>} - The children menu items.
 */
export const navMenu = (addonMenuItems: ISideNav[] | null = null): Array<ISideNav | null | boolean> => {
  return [
    {
      icon: DashboardIcon,
      url: DASHBOARD_URL,
      name: "Dashboard",
    },
    {
      icon: RecommendIcon,
      url: INSPECTION_RECOMMENDATION_INDEX_URL,
      name: "Inspection Recs",
    },
    {
      icon: ReportProblemIcon,
      url: LOCATION_IMPAIRMENT_INDEX_URL,
      name: "Impairments",
    },
    addonMenuItems !== null ? null : false,
    addonMenuItems !== null
      ? {
          icon: LibraryAddIcon,
          url: ADDONS_INDEX_URL,
          name: "Addons",
          children: addonMenuItems,
        }
      : null,
    addonMenuItems !== null ? null : false
  ]
}
