import { useCallback, useEffect, useRef, useState } from "react"
import { WebPubSubClient } from "@azure/web-pubsub-client"
import { RestRepository } from "../repositories/RestRepository"
import type IProfile from "../models/users/IProfile"
import { PROFILES_ENDPOINT } from "../models/users/IProfile"
import useApiAction from "./useApiAction"
import useAuth from "./useAuth"

const repository = new RestRepository<IProfile>(PROFILES_ENDPOINT)

interface IPubSub {
  url: string
}

interface IPubSubMessage {
  message_id: string
  item: string
  item_id: number
  message: string
  data: any | null
}

interface IUseAzurePubSubResponse {
  messages: IPubSubMessage[]
  error: any
  removeMessage: (message: IPubSubMessage) => void
}

/**
 * Initializes and manages a connection to Azure Web PubSub. This hook sets up the connection using the provided
 * API action and listens for messages and connection events.
 *
 * @returns {IUseAzurePubSubResponse} An object containing the messages received from the Web PubSub.
 */
const useAzurePubSub = (): IUseAzurePubSubResponse => {
  const [messages, setMessages] = useState<IPubSubMessage[]>([])
  const [error, setError] = useState<any>(null)
  const initialLoad = useRef<boolean>(false)

  const { currentUser } = useAuth()
  const itemId = currentUser?.user.profile.id
  const apiAction = useApiAction({ repository, itemId })

  /**
   * Initializes a WebPubSub client and establishes the connection.
   *
   * This variable is a callback function that:
   * - Creates a new WebPubSubClient and retrieves the client access URL asynchronously.
   * - Starts the WebSocket connection and sets an error if one occurs during the connection process.
   * - Sets up a message handler to process incoming server messages and stores them in state.
   *
   * Dependencies: The function depends on `apiAction` for retrieving the PubSub URL.
   *
   * Returns:
   * - An instance of the WebPubSubClient.
   */
  const initializeClient = useCallback(() => {
    const client = new WebPubSubClient({
      getClientAccessUrl: async () => {
        const pubSub = await apiAction.callAction<IPubSub>("pub_sub_url")
        if (pubSub?.url !== undefined) {
          return pubSub.url
        }
        return ""
      },
    })

    // Start client connection and set error if occurs
    client.start().catch(setError)

    // Message handler
    client.on("server-message", message => {
      setMessages(messages1 => [message.message.data as IPubSubMessage, ...messages1])
    })

    return client
  }, [apiAction])

  /**
   * Callback function to remove a specific message from the list of messages.
   *
   * This function uses the `useCallback` hook to memoize the function, preventing unnecessary re-renders or re-creations of the function.
   *
   * The `removeMessage` function takes a single parameter:
   * @param {IPubSubMessage} message - The message object to be removed, identified by its unique `message_id`.
   *
   * The function updates the state of `messages` by filtering out the message with the matching `message_id`.
   *
   * Dependencies:
   * - The function depends on the `messages` array state.
   */
  const removeMessage = useCallback((message: IPubSubMessage) => {
    setMessages(messages1 => messages1.filter(m => m.message_id !== message.message_id))
  }, [messages])

  useEffect(() => {
    if (!initialLoad.current) {
      initialLoad.current = true
      let client = initializeClient()
      const intervalId = setInterval(
        async () => {
          // Dispose of the old client
          client.stop()
          // Initialize new client
          client = initializeClient()
        },
        55 * 60 * 1000,
      ) // 55 minutes in milliseconds

      return () => {
        clearInterval(intervalId) // Clear interval on component unmount
        client.stop() // Stop the client on component unmount
      }
    }
  }, [])

  return { messages, error, removeMessage }
}

export default useAzurePubSub
