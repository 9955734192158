import React from "react"
import { Container, Grid } from "@mui/material"
import { useForm } from "react-hook-form"
import { RestRepository } from "../../repositories/RestRepository"
import ImpairmentForm from "./components/ImpairmentForm"
import { type ILocationImpairment, LOCATION_IMPAIRMENT_ENDPOINT } from "../../models/service/ILocationImpairment"
import { LOCATION_IMPAIRMENT_VIEW_URL } from "../../../apps/admin/config/urls"
import useApiAdd, { type IUseApiAddProps } from "../../hooks/useApiAdd"
import FormHeader from "../../components/forms/FormHeader"
import FormBox from "../../components/forms/FormBox"
import FormErrors from "../../components/forms/FormErrors"

const repository = new RestRepository<ILocationImpairment>(LOCATION_IMPAIRMENT_ENDPOINT)
const redirectView = LOCATION_IMPAIRMENT_VIEW_URL

/**
 * This page will allow adding of a location impairment.
 *
 * @returns {React.FC} the locationImpairment add page.
 */
const AddPage: React.FC = (): React.ReactElement => {
  const form = useForm()

  const props: IUseApiAddProps<ILocationImpairment> = {
    apiFunction: repository.add,
    setError: form.setError,
    redirectView,
  }
  const apiAdd = useApiAdd<ILocationImpairment>(props)

  return (
    <Container fixed>
      <Grid container spacing={2}>
        <FormHeader title="Add Impairment" loading={apiAdd.saving} errors={form.formState.errors} />
        <FormErrors connectionError={apiAdd.connectionError} />
        <FormBox form={form} onSubmit={apiAdd.handleAdd}>
          <ImpairmentForm form={form} />
        </FormBox>
      </Grid>
    </Container>
  )
}

export default AddPage
