import React, { useCallback } from "react"
import { Box } from "@mui/material"
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts"
import { CHART_COLORS } from "../../../../config/config"
import { type IStats } from "../../../models/service/IStats"
import ChartTooltip from "./ChartTooltip"
import { type IChartBuilderState } from "../models/IStatBuilderState"
import { truncateString } from "../../../utilities/format_utility"


interface ITickProps {
  label?: string
  textAnchor?: string
  x?: number
  y?: number
  onClick?: (label: string) => void
}

/**
 * CustomTick component.
 *
 * @param {ITickProps} props - The props for the CustomTick component.
 * @returns {React.ReactElement} The rendered CustomTick component.
 */
const CustomTick: React.FC<ITickProps> = (props: ITickProps): React.ReactElement => {
  const { x, y, textAnchor, label, onClick } = props

  const handleClick = useCallback(() => {
    if (label != null) {
      onClick?.(label)
    }
  }, [onClick, label])

  return (
    <g transform={`translate(${x},${y})`} onClick={handleClick} style={{ cursor: "pointer" }}>
      <text x={0} y={0} dy={6} textAnchor={textAnchor} fill="#666">
        {truncateString(label, 40)}
      </text>
    </g>
  )
}

interface IProps {
  chartRef: React.RefObject<HTMLDivElement>
  data: IStats[]
  statBuilderStat: IChartBuilderState
  onClick?: (label: string) => void
}

/**
 * WidgetPieChart is a React functional component that renders a pie chart widget.
 *
 * @param {IProps} props - The props for the WidgetPieChart component.
 * @param {React.Ref} props.chartRef - The ref of the chart container.
 * @param {Array} props.data - The data to be displayed on the pie chart.
 * @returns {React.ReactElement} - The rendered pie chart widget.
 */
const WidgetPieChart: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { chartRef, data, statBuilderStat, onClick } = props

  return (
    <Box ref={chartRef}>
      <ResponsiveContainer width="100%" aspect={1}>
        <PieChart margin={{ left: 100, right: 100 }}>
          <Tooltip content={props => <ChartTooltip {...props} statBuilderStat={statBuilderStat} />} />
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            outerRadius="70%"
            dataKey="value"
            label={<CustomTick onClick={onClick} />}>
            {data.map((_entry, index) => (
              <Cell key={`cell-${index}`} fill={CHART_COLORS[index % CHART_COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </Box>
  )
}
export default WidgetPieChart
