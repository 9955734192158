import { type SelectOptions } from "../../config/config"
import { type ISelectItem } from "../models/components/ISelectItem"
import { useContext } from "react"
import { type IPanariskAppContext } from "../models/app/IPanariskAppContext"
import { PanariskAppContext } from "../../app/PanariskApp"

/**
 * Retrieves the select options based on the provided select option key.
 *
 * @param {SelectOptions} selectOption - The key of the select option to retrieve.
 * @returns {ISelectItem[] | null} - The selected option items if found, otherwise null.
 */
export const useSelectOptions = (selectOption: SelectOptions): ISelectItem[] => {
  const { appSettings } = useContext<IPanariskAppContext>(PanariskAppContext)
  if (selectOption in appSettings.selectOptions) {
    return appSettings.selectOptions[selectOption]
  }
  return []
}
