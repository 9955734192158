import React, { useCallback } from "react"
import { Container, Grid, Tab } from "@mui/material"
import {
  type ILocationImpairment,
  LOCATION_IMPAIRMENT_ENDPOINT,
} from "../../models/service/ILocationImpairment"
import { RestRepository } from "../../repositories/RestRepository"
import { useApiRead } from "../../hooks/useApiRead"
import ViewProperty from "../../components/ViewProperty"
import FormatDate from "../../components/format/FormatDate"
import PageHeader from "../../components/pages/PageHeader"
import { LOCATION_IMPAIRMENT_EDIT_URL } from "../../../apps/admin/config/urls"
import TabsList from "../../components/tabs/TabsList"
import TabPanel, { useTabPanel } from "../../components/tabs/TabPanel"
import { useParams } from "react-router-dom"
import { type IFilter } from "../../models/components/IFilter"
import { useOnDemandPaged } from "../../hooks/useApiPagedLocal"
import { type IFile, LOCATION_IMPAIRMENT_FILE_ENDPOINT } from "../../models/service/IFile"
import { type IMainModel } from "../../models/service/IMainModel"
import FilesIndex from "../../components/files/FilesIndex"
import PaperLocal from "../../components/containers/PaperLocal"
import ItemViewerDrawer from "../../components/item_viewer/ItemViewerDrawer"
import LocationInfo from "../../../apps/admin/pages/locations/components/LocationInfo"
import { ItemPrefixes } from "../../../config/config"
import { LoadingButton } from "@mui/lab"
import ViewListItems from "../../components/display/ViewListItems"
import useApiAction, { type IUseApiActionProps } from "../../hooks/useApiAction"
import ServiceUserInfo from "../../../apps/admin/pages/service_users/components/ServiceUserInfo"

const repository = new RestRepository<ILocationImpairment>(LOCATION_IMPAIRMENT_ENDPOINT)
const filesRepository = new RestRepository<IFile | IMainModel>(LOCATION_IMPAIRMENT_FILE_ENDPOINT)

/**
 * Renders a page to view locations impairment details.
 *
 * @returns {React.ReactElement} The rendered component.
 */
const ViewPage: React.FC = (): React.ReactElement => {
  const apiRead = useApiRead<ILocationImpairment>({ apiFunction: repository.read })
  const { data } = apiRead

  const actionProps: IUseApiActionProps<ILocationImpairment> = { repository, itemId: data?.id }
  const apiAction = useApiAction<ILocationImpairment>(actionProps)

  const handleToggleCompleted = useCallback(async () => {
    await apiAction.callAction("toggle_completed")
    await apiRead.call()
  }, [apiAction.callAction])

  const { tab, handleTabChange } = useTabPanel()
  const { id } = useParams()

  const theFilter: IFilter[] = [
    {
      canBeDelete: false,
      title: "Impairment",
      display: "This Impairment",
      field: "location_impairment",
      value: id,
    },
  ]

  const filesPagingResults = useOnDemandPaged<IFile>(LOCATION_IMPAIRMENT_FILE_ENDPOINT, theFilter, "FILES")

  return (
    <Container fixed>
      {data !== undefined && (
        <Grid container spacing={2}>
          <PageHeader
            title="Impairment"
            titlePlural={`${data.identifier} - ${data.title}`}
            toEdit={`${LOCATION_IMPAIRMENT_EDIT_URL}/${data.id}`}
          />
          <ItemViewerDrawer prefix={ItemPrefixes.location} infoView={LocationInfo} />
          <ItemViewerDrawer prefix={ItemPrefixes.serviceUser} infoView={ServiceUserInfo} />
          <Grid item xs={12}>
            <TabsList value={tab} onChange={handleTabChange}>
              <Tab label="Overview" value={0} />
              <Tab label="Files" value={1} />
            </TabsList>
            <TabPanel value={tab} index={0}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <PaperLocal>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <ViewProperty label="Location">
                          <ViewListItems prefix={ItemPrefixes.location}>{data.location}</ViewListItems>
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label="Start Date">
                          <FormatDate value={data.start_date} />
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label="End Date">
                          <FormatDate value={data.end_date} />
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label="Type">{data.impairment_type?.name}</ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label="Status">{data.status?.name}</ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label="System Type">{data.system_type?.name}</ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label="System Name">{data.system_name}</ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label="Tags">{data.tags.map(tag => tag.name).join(", ")}</ViewProperty>
                      </Grid>
                    </Grid>
                  </PaperLocal>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <PaperLocal>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <ViewProperty label="Completed">
                              <LoadingButton
                                onClick={handleToggleCompleted}
                                color={data.completed === null ? "primary" : "error"}
                                loading={apiAction.saving}>
                                {data.completed === null ? "Complete" : "Reopen"}
                              </LoadingButton>
                            </ViewProperty>
                          </Grid>
                          <Grid item xs={12}>
                            <ViewProperty label="Completed By" show={data.completed !== null}>
                              <Grid container alignItems="center" spacing={1}>
                                <Grid item xs>
                                  <FormatDate value={data.completed} />
                                </Grid>
                                <Grid item>
                                  <ViewListItems prefix={ItemPrefixes.serviceUser}>{data.completed_by}</ViewListItems>
                                </Grid>
                              </Grid>
                            </ViewProperty>
                          </Grid>
                        </Grid>
                      </PaperLocal>
                    </Grid>
                    <Grid item xs={12}>
                      <PaperLocal>
                        <ViewProperty label="Description" vertical>
                          {data.description}
                        </ViewProperty>
                      </PaperLocal>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tab} index={1} onChange={filesPagingResults.call}>
              <FilesIndex
                parentId={id}
                parentFieldName="location_impairment"
                repository={filesRepository}
                pagingResults={filesPagingResults}
              />
            </TabPanel>
          </Grid>
        </Grid>
      )}
    </Container>
  )
}

export default ViewPage
