import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { type IRiskWriterSection } from "../../models/IRiskWriterSection"
import {
  type IRiskWriterSectionData,
  type ISectionDataRichText,
  SectionType,
} from "../../models/IRiskWriterSectionData"
import { Box, Button, Grid, IconButton } from "@mui/material"
import ViewLoading from "../../../ViewLoading"
import { cleanHtml, nameToLabel, sanitizeOptions } from "../../../../utilities/form_utility"
import {
  BtnBold,
  BtnBulletList,
  BtnClearFormatting,
  BtnItalic,
  BtnNumberedList,
  BtnRedo,
  BtnUnderline,
  BtnUndo,
  type ContentEditableEvent,
  createButton,
  Editor,
  EditorProvider,
  type EditorState,
  Separator,
  Toolbar,
} from "react-simple-wysiwyg"
import AlertDialog from "../../../AlertDialog"
import DeleteIcon from "@mui/icons-material/Delete"
import { RiskWriterContext } from "../../context/RiskWriterProvider"
import AutoModeIcon from "@mui/icons-material/AutoMode"
import ClearAllIcon from "@mui/icons-material/ClearAll"
import HtmlToolTip from "../../../HtmlToolTip"
import { ArrowDownward, ArrowUpward } from "@mui/icons-material"
import useAuth from "../../../../hooks/useAuth"

interface IProps {
  section: IRiskWriterSection
  content: IRiskWriterSectionData
  viewerOnly: boolean
}

/**
 * ContentTextEditor is a React functional component that renders a text editor for content.
 *
 * @param {object} props - The props for the component.
 * @param {object} props.section - The section object.
 * @param {object} props.content - The content object.
 * @returns {React.ReactElement} The rendered component.
 */
const ContentTextEditor: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { section, content, viewerOnly } = props

  const [html, setHtml] = useState<string>("<p><br /></p>")
  const [loading, setLoading] = useState<boolean>(false)

  const { currentUser } = useAuth()

  const {
    currentContent,
    currentSection,
    onGenerateContent,
    onChangeContent,
    onDeleteContent,
    onEditContent,
    onContentMove,
  } = useContext(RiskWriterContext)

  const handleChange = useCallback(
    (event: ContentEditableEvent) => {
      const cleaned = cleanHtml(event.target.value)
      if (html !== cleaned) {
        setHtml(cleaned)
      }
    },
    [html, sanitizeOptions],
  )

  const handleContentMoveUp = useCallback(() => {
    onContentMove?.(content, "up")
  }, [html, content])

  const handleContentMoveDown = useCallback(() => {
    onContentMove?.(content, "down")
  }, [html, content])

  const handleSaveText = useCallback(() => {
    const cleaned = cleanHtml(html)
    const sectionContent: ISectionDataRichText = { html: cleaned }
    onChangeContent?.(content.name, sectionContent)
  }, [html, content])

  const handleDeleteContent = useCallback(async () => {
    onDeleteContent?.(content)
  }, [html, content])

  const handleEditContent = useCallback(() => {
    if (!viewerOnly) {
      onEditContent?.(section, content)
    }
  }, [section, content, viewerOnly])

  const handleGenerateContent = useCallback(
    async (_state: EditorState) => {
      if (currentSection !== null && currentSection !== undefined) {
        setLoading(true)
        try {
          const data = await onGenerateContent?.(currentSection)
          setHtml(html => `${html}<p>${data}</p>`)
        } catch (e: any) {
          console.log(e)
        }
        setLoading(false)
      }
    },
    [currentSection],
  )

  const BtnGetAi = createButton(
    `Write content for ${nameToLabel(section.name, section.title)}.`,
    <AutoModeIcon fontSize="small" />,
    handleGenerateContent,
  )

  const handleClearEditor = useCallback((_state: EditorState) => {
    setHtml("<p><br /></p>")
  }, [])

  const BtnClear = createButton(`Clear and reset the editor.`, <ClearAllIcon fontSize="small" />, handleClearEditor)

  useEffect(() => {
    if (content.name === currentContent?.name) {
      if (content.section_type === SectionType.RICH_TEXT_EDITOR) {
        const sectionRichText = content.data as ISectionDataRichText
        if (content.data !== null && sectionRichText.html.trim() !== "") {
          setHtml(sectionRichText.html)
        }
      }
    }
  }, [content, currentContent])

  const maxHeight = useMemo(() => {
    if (currentUser?.user.profile.rich_text_boxes_full_length === true) {
      return "auto"
    }
    return "40em"
  }, [currentUser])

  return (
    <>
      {content.section_type === SectionType.RICH_TEXT_EDITOR && (
        <Box sx={{ m: 1, mr: 0, ml: 0, border: "1px dashed #ccc" }}>
          <ViewLoading
            loading={loading}
            message={`Writing content for ${nameToLabel(section.name, section.title)}...`}
          />
          {content.name === currentContent?.name && !viewerOnly ? (
            <Box sx={{ p: 1, "& .rsw-ce": { mt: 0, mb: 0, border: "none" } }}>
              <EditorProvider>
                <Editor
                  value={html}
                  onChange={handleChange}
                  onBlur={handleSaveText}
                  style={{ maxHeight, overflow: "auto" }}
                  containerProps={{ style: { resize: "vertical" } }}>
                  <Toolbar>
                    <BtnUndo />
                    <BtnRedo />
                    <Separator />
                    <BtnBold />
                    <BtnItalic />
                    <BtnUnderline />
                    <Separator />
                    <BtnNumberedList />
                    <BtnBulletList />
                    <Separator />
                    <BtnClearFormatting />
                    <Separator />
                    <BtnClear />
                    <Separator />
                    <BtnGetAi />
                  </Toolbar>
                </Editor>
              </EditorProvider>
              <Grid container spacing={1}>
                <Grid item>
                  <HtmlToolTip title={"Move this text up in the section."}>
                    <IconButton color="primary" onClick={handleContentMoveUp} size="small">
                      <ArrowUpward />
                    </IconButton>
                  </HtmlToolTip>
                  <HtmlToolTip title={"Move this text down in the section."}>
                    <IconButton color="primary" onClick={handleContentMoveDown} size="small">
                      <ArrowDownward />
                    </IconButton>
                  </HtmlToolTip>
                </Grid>
                <Grid item xs>
                  <AlertDialog
                    buttonText="Delete"
                    buttonIcon={<DeleteIcon />}
                    onYes={handleDeleteContent}
                    message={`Are you sure you want to delete this content?`}
                  />
                </Grid>
                <Grid item>
                  <Button onClick={handleSaveText}>Done</Button>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <>
              {content.data !== null ? (
                <Box
                  onClick={handleEditContent}
                  sx={{ p: 1, "&:hover": viewerOnly ? {} : { background: "#ddd", cursor: "pointer" } }}
                  dangerouslySetInnerHTML={{ __html: (content.data as ISectionDataRichText).html }}
                />
              ) : (
                <Box
                  onClick={handleEditContent}
                  sx={{ p: 1, "&:hover": viewerOnly ? {} : { background: "#ddd", cursor: "pointer" } }}>
                  Click here to edit text.
                </Box>
              )}
            </>
          )}
        </Box>
      )}
    </>
  )
}

export default ContentTextEditor
