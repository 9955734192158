import { type IListItem } from "../components/IListItem"
import { type IReportFormat } from "./IReportFormat"
import { type IFilter } from "../components/IFilter"
import { type IRiskWriterData } from "../../components/risk_writer/models/IRiskWriterData"
import { type IRiskWriteModel } from "../components/IRiskWriteModel"
import type { TRiskWriterFormData } from "../../components/risk_writer/models/TRiskWriterFormData"

export const SUMMARY_ENDPOINT = "summaries"

export interface ISummary extends IRiskWriteModel, IListItem {
  id: number
  name: string
  report_format: IReportFormat | null
  report_format_summary_name: string
  filters: IFilter[]
  risk_writer_data: IRiskWriterData | null
  risk_writer_version: string
  risk_writer_brief_data: TRiskWriterFormData | null
  notes: string
  created: string
  updated: string
}
