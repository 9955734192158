import React from "react"
import { Grid, TableBody, TableHead, TableRow } from "@mui/material"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import { useApiPagedLocal } from "../../../../shared/hooks/useApiPagedLocal"
import { type IRestApiKey, REST_API_KEY_ENDPOINT } from "../../../../shared/models/service/IRestApiKey"
import { REST_API_KEY_ADD_URL, REST_API_KEY_VIEW_URL } from "../../config/urls"
import PageHeader from "../../../../shared/components/pages/PageHeader"
import TableData from "../../../../shared/components/tables/TableData"
import TableCellCenter from "../../../../shared/components/tables/TableCellCenter"
import ListData from "../../../../shared/components/lists/ListData"
import ListDataItem from "../../../../shared/components/lists/ListDataItem"
import TableCellData from "../../../../shared/components/tables/TableCellData"
import { type IListItem } from "../../../../shared/models/components/IListItem"
import ListProperty from "../../../../shared/components/lists/ListProperty"
import FiltersData from "../../../../shared/components/filters/FiltersData"
import FilterSearch from "../../../../shared/components/filters/FilterSearch"
import TableCellDate from "../../../../shared/components/tables/TableCellDate"
import TableCellCheckMark from "../../../../shared/components/tables/TableCellCheckMark"

const repository = new RestRepository<IRestApiKey>(REST_API_KEY_ENDPOINT)
const gotoRepo = new RestRepository<IListItem>(REST_API_KEY_ENDPOINT)

/**
 * Renders the rest api key page for the rest api keys.
 *
 * @returns {React.ReactElement} The rendered rest api key page.
 */
const RestApiKeyPage: React.FC = (): React.ReactElement => {
  const pagingResults = useApiPagedLocal<IRestApiKey>({ apiFunction: repository.findAll })
  const { data } = pagingResults

  return (
    <Grid container alignItems="center" spacing={2}>
      <PageHeader title="API Key" titlePlural="API Keys" toAdd={REST_API_KEY_ADD_URL} />
      <TableData pagingResults={pagingResults} to={REST_API_KEY_VIEW_URL} repository={gotoRepo}>
        <FiltersData pagingResults={pagingResults}>
          <FilterSearch />
        </FiltersData>
        <TableHead>
          <TableCellData field="name" pagingResults={pagingResults}>
            Name
          </TableCellData>
          <TableCellData field="prefix" pagingResults={pagingResults}>
            Prefix
          </TableCellData>
          <TableCellCenter field="expiry_date" pagingResults={pagingResults}>
            Expires
          </TableCellCenter>
          <TableCellCenter field="revoked" pagingResults={pagingResults}>
            Revoked
          </TableCellCenter>
          <TableCellCenter field="created" pagingResults={pagingResults}>
            Created
          </TableCellCenter>
        </TableHead>
        <TableBody>
          {data?.results.map((item: IRestApiKey) => (
            <TableRow key={item.id}>
              <TableCellData field="name">{item.name}</TableCellData>
              <TableCellData field="prefix">{item.prefix}</TableCellData>
              <TableCellDate field="expiry_date">{item.expiry_date}</TableCellDate>
              <TableCellCheckMark field="revoked">{item.revoked}</TableCellCheckMark>
              <TableCellDate field="created">{item.created}</TableCellDate>
            </TableRow>
          ))}
        </TableBody>
      </TableData>

      <ListData pagingResults={pagingResults}>
        {data?.results.map((item: IRestApiKey) => (
          <ListDataItem key={item.id} to={`${REST_API_KEY_VIEW_URL}/${item.id}`} title={item.name}>
            <ListProperty>{item.created}</ListProperty>
          </ListDataItem>
        ))}
      </ListData>
    </Grid>
  )
}

export default RestApiKeyPage
