import React from "react"
import { Grid } from "@mui/material"

import { useApiRead } from "../../../../shared/hooks/useApiRead"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import PageHeader from "../../../../shared/components/pages/PageHeader"
import { type IVendor, VENDOR_ENDPOINT } from "../../../../shared/models/service/IVendor"
import { VENDOR_EDIT_URL } from "../../config/urls"
import PageViewBody from "../../../../shared/components/pages/PageViewBody"
import ViewProperty from "../../../../shared/components/ViewProperty"
import FormatDate from "../../../../shared/components/format/FormatDate"
import { type IUseApiPagedResultsProps, useApiPagedLocal } from "../../../../shared/hooks/useApiPagedLocal"
import { type IServiceUser, SERVICE_USER_ENDPOINT } from "../../../../shared/models/service/IServiceUser"
import { useParams } from "react-router-dom"
import ServiceUserIndex from "../service_users/components/ServiceUserIndex"
import PaperLocal from "../../../../shared/components/containers/PaperLocal"
import ItemViewerDrawer from "../../../../shared/components/item_viewer/ItemViewerDrawer"
import ContactInfo from "../contacts/components/ContactInfo"
import ViewRichText from "../../../../shared/components/display/ViewRichText"
import RemoveServiceUser from "../../components/service_users/RemoveServiceUser"
import AddServiceUser from "../../components/service_users/AddServiceUser"
import { type IMainModel } from "../../../../shared/models/service/IMainModel"
import { ItemPrefixes } from "../../../../config/config"
import ViewListItems from "../../../../shared/components/display/ViewListItems"
import ServiceUserInfo from "../service_users/components/ServiceUserInfo"

const repository = new RestRepository<IVendor | IMainModel>(VENDOR_ENDPOINT)
const serviceUserRepository = new RestRepository<IServiceUser>(SERVICE_USER_ENDPOINT)

/**
 * Renders a page to view vendor details.
 *
 * @returns {React.ReactElement} The rendered component.
 */
const ViewPage: React.FC = (): React.ReactElement => {
  const apiRead = useApiRead<IVendor>({ apiFunction: (repository as RestRepository<IVendor>).read })
  const { data } = apiRead
  const { id } = useParams()

  const serviceUserProps: IUseApiPagedResultsProps<IServiceUser> = {
    apiFunction: serviceUserRepository.findAll,
    initialFilters: [{ field: "vendor_members", value: id }],
  }
  const serviceUserPagingResults = useApiPagedLocal<IServiceUser>(serviceUserProps)

  return (
    <PageViewBody loading={apiRead.loading}>
      {data !== undefined && (
        <>
          <PageHeader title="Vendor" titlePlural={data.name} toEdit={`${VENDOR_EDIT_URL}/${data.id}`} />
          <Grid item xs={12}>
            <ItemViewerDrawer title={"Service User"} prefix={ItemPrefixes.serviceUser} infoView={ServiceUserInfo} />
            <ItemViewerDrawer title={"Contact"} prefix={ItemPrefixes.contact} infoView={ContactInfo} />
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <PaperLocal>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <ViewProperty label="Created">
                        <FormatDate value={data.created} />
                      </ViewProperty>
                    </Grid>
                    <Grid item xs={12}>
                      <ViewProperty label="Lead">
                        <ViewListItems prefix={ItemPrefixes.serviceUser}>{data.lead}</ViewListItems>
                      </ViewProperty>
                    </Grid>
                    <Grid item xs={12}>
                      <ViewProperty label="Additional Contacts">
                        <ViewListItems prefix={ItemPrefixes.contact}>{data.contacts}</ViewListItems>
                      </ViewProperty>
                    </Grid>
                  </Grid>
                </PaperLocal>
              </Grid>
              <Grid item xs={12} lg={6}>
                <PaperLocal>
                  <ViewProperty label="Notes" vertical>
                    <ViewRichText>{data.notes}</ViewRichText>
                  </ViewProperty>
                </PaperLocal>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ServiceUserIndex
              useInfoView
              pagingResults={serviceUserPagingResults}
              showHeader={false}
              cellActions={(serviceUser: IServiceUser) => (
                <RemoveServiceUser
                  serviceUser={serviceUser}
                  repository={repository}
                  parentId={data?.id}
                  onComplete={serviceUserPagingResults.call}
                />
              )}
              tableActions={
                <AddServiceUser
                  repository={repository}
                  parentId={data?.id}
                  onComplete={serviceUserPagingResults.call}
                />
              }
            />
          </Grid>
        </>
      )}
    </PageViewBody>
  )
}

export default ViewPage
