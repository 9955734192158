import React, { useCallback, useState } from "react"
import { Alert, Button, Grid, IconButton, Snackbar } from "@mui/material"
import { type Extension, parsePhoneNumber } from "libphonenumber-js"
import PhoneIcon from "@mui/icons-material/Phone"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"

import { CopyToClipboard } from "react-copy-to-clipboard"

interface IProps {
  children: string | null | undefined
  ext?: string | null | undefined
  display?: boolean
}

/**
 * ViewPhone is a React functional component that displays a phone number and provides copy functionality.
 *
 * @param {IProps} props - The props object.
 * @returns {React.ReactElement} - The rendered component.
 */
const ViewPhone: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children: phone, ext, display = false } = props
  const [open, setOpen] = useState(false)

  const handleCopy = useCallback(() => {
    setOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  if (phone === undefined || phone === null || phone === "") {
    return <>Not Set</>
  }

  try {
    const phoneNumber = parsePhoneNumber(phone)
    if (ext !== undefined && ext !== "" && ext !== null) {
      phoneNumber.setExt(ext as Extension)
    }

    if (display) {
      return (
        <>
          {phoneNumber.country} {phoneNumber.formatInternational()}
        </>
      )
    }

    return (
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs>
          <Button startIcon={<PhoneIcon />} href={phoneNumber.getURI()} size="small">
            {phoneNumber.country} {phoneNumber.formatInternational()}
          </Button>
        </Grid>
        <Grid item>
          <Snackbar
            open={open}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            autoHideDuration={1000}
            onClose={handleClose}>
            <Alert severity="info">Phone number copied.</Alert>
          </Snackbar>
          <CopyToClipboard text={phoneNumber.formatInternational()} onCopy={handleCopy}>
            <IconButton size="small">
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </CopyToClipboard>
        </Grid>
      </Grid>
    )
  } catch (error) {
    return <>{phone}</>
  }
}

export default ViewPhone
