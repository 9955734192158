import React from "react"
import { SvgIcon } from "@mui/material"
import { ReactComponent as CompanyLogoSvg } from "../images/panarisk_logo.svg"
import { ReactComponent as CompanyLogoSvgBlue } from "../images/panarisk_logo_blue.svg"

interface IProps {
  width?: number
  height?: number
  blue?: boolean
}

/**
 * Renders the CompanyLogo component.
 *
 * @param {IProps} props - The props object.
 * @param {number} props.width - The width of the logo (default value is 150).
 * @param {number} props.height - The height of the logo (default value is 100).
 * @returns {React.ReactElement} - The rendered CompanyLogo component.
 */
const CompanyLogo: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { width = 150, height = 100, blue = false } = props
  return (
    <SvgIcon sx={{ width, height }}>
      {blue ? <CompanyLogoSvgBlue /> : <CompanyLogoSvg />}
    </SvgIcon>
  )
}

export default CompanyLogo
