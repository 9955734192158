import { type TRiskWriterTableRows } from "../models/IRiskWriterData"
import { type IColumn } from "../models/IRiskWriterTable"

export enum Operation {
  IsEmpty = "Is Empty",
  IsNotEmpty = "Is Not Empty",
  TextContains = "Text Contains",
  TextDoesNotContain = "Text Does Not Contain",
  TextStartsWith = "Text Starts With",
  TextEndsWith = "Text Ends With",
  TextIsExactly = "Text Is Exactly",
  DateIs = "Date Is",
  DateIsBefore = "Date Is Before",
  DateIsAfter = "Date Is After",
  GreaterThan = "Greater Than",
  GreaterThanOrEqualTo = "Greater Than Or Equal To",
  LessThan = "Less Than",
  LessThanOrEqualTo = "Less Than Or Equal To",
  IsEqualTo = "Is Equal To",
  IsNotEqualTo = "Is Not Equal To",
}

/**
 * Finds an operation by its corresponding string value.
 *
 * @param {string | undefined} value - The string representation of an operation.
 * @returns {(Operation | undefined)} The matched operation if found, otherwise undefined.
 */
export const findOperationByString = (value: string | undefined): Operation | undefined => {
  if (value === undefined) {
    return undefined
  }
  if (Object.keys(Operation).includes(value)) {
    return Operation[value as keyof typeof Operation]
  } else {
    return undefined
  }
}

export interface ITableFilter {
  column_name: string
  value: string | number | boolean | null
  operation: Operation
}

/**
 * Filters rows based on an array of specified column name, value, and operation.
 *
 * const filteredRows = filterRows([{ column_name: "building_area", value: 0.1, operation: Operation.TextIsExactly }], columns, rows)
 *
 * @param {ITableFilter[]} tableFilters - The array of filters to use
 * @param {IColumn[]} columns - The columns of the table.
 * @param {TRiskWriterTableRows} rows - The rows of the table.
 * @returns {TRiskWriterTableRows} - The filtered rows.
 *
 * @throws {Error} If any specified column name is not found.
 */
export const filterRows = (
  tableFilters: ITableFilter[] | undefined | null,
  columns: IColumn[],
  rows: TRiskWriterTableRows,
): TRiskWriterTableRows => {
  if (tableFilters === undefined || tableFilters === null) {
    return rows
  }
  return tableFilters.reduce<TRiskWriterTableRows>((filteredRows, tableFilter) => {
    // Find the index of the column
    const columnIndex = columns.findIndex(col => col.name === tableFilter.column_name)
    if (columnIndex === -1) {
      throw new Error(`Column "${tableFilter.column_name}" not found.`)
    }
    const { value } = tableFilter
    return filteredRows.filter(row => {
      const cell = row[columnIndex]
      switch (tableFilter.operation) {
        case Operation.IsEmpty:
          return cell === null || cell === "" || cell === undefined
        case Operation.IsNotEmpty:
          return cell !== null && cell !== "" && cell !== undefined
        case Operation.TextContains:
          return typeof cell === "string" && cell.includes(`${value}`)
        case Operation.TextDoesNotContain:
          return typeof cell === "string" && !cell.includes(`${value}`)
        case Operation.TextStartsWith:
          return typeof cell === "string" && cell.startsWith(`${value}`)
        case Operation.TextEndsWith:
          return typeof cell === "string" && cell.endsWith(`${value}`)
        case Operation.TextIsExactly:
          return cell === value
        case Operation.DateIs:
          return new Date(cell as string).getTime() === new Date(`${value}`).getTime()
        case Operation.DateIsBefore:
          return new Date(cell as string).getTime() < new Date(`${value}`).getTime()
        case Operation.DateIsAfter:
          return new Date(cell as string).getTime() > new Date(`${value}`).getTime()
        case Operation.GreaterThan:
          return (cell as number) > Number(value)
        case Operation.GreaterThanOrEqualTo:
          return (cell as number) >= Number(value)
        case Operation.LessThan:
          return (cell as number) < Number(value)
        case Operation.LessThanOrEqualTo:
          return (cell as number) <= Number(value)
        case Operation.IsEqualTo:
          return cell === value
        case Operation.IsNotEqualTo:
          return cell !== value
        default:
          return false
      }
    })
  }, rows)
}
