import { type ErrorOption } from "react-hook-form"

export type TSetFormError = (name: string, error: ErrorOption, options?: { shouldFocus: boolean }) => void

/**
 * Map the connection errors to forms errors.
 *
 * @param {IConnectionError} errors to map to the errors.
 * @param {TSetFormError} setError the forms to set the errors to.
 */
export const setFormConnectionErrors = (errors: IConnectionError, setError: TSetFormError): void => {
  Object.keys(errors.data).forEach(field => {
    const messages = errors.data[field]
    let message = ""
    if (Array.isArray(messages)) {
      message += messages.join(", ")
      setError(field, { type: "manual", message })
    } else {
      let message2 = ""
      Object.keys(messages).forEach(field1 => {
        const messages2 = messages[field1]
        message2 += messages2?.join(", ")
        setError(`${field}.${field1}`, { type: "manual", message: message2 })
      })
    }
  })
}

export const CONNECTION_ERROR = {
  data: { error: ["Connection Error"] },
  objType: "Error",
  code: "0",
}

export interface IConnectionError {
  objType?: string
  data: Record<string, string[] | Record<string, string[]>>
  code?: string
}
