import React, { useCallback, useContext } from "react"
import type { IRiskWriterSection } from "../models/IRiskWriterSection"
import { Box, Grid, Typography } from "@mui/material"
import { nameToLabel } from "../../../utilities/form_utility"
import type { IRiskWriterSectionData } from "../models/IRiskWriterSectionData"
import RiskWriterContentEditor from "./RiskWriterContentEditor"
import { RiskWriterContext } from "../context/RiskWriterProvider"

/**
 * Renders the Risk Writer Viewer component.
 *
 * @returns {React.ReactElement} - The rendered Risk Writer Viewer component.
 */
const RiskWriterViewer: React.FC = (): React.ReactElement => {
  const { reportWriterFormat, reportWriterData } = useContext(RiskWriterContext)

  const contentsForSection = useCallback(
    (sectionId: string) => {
      return reportWriterData?.sections?.filter(content => content.section_name === sectionId)
    },
    [reportWriterData],
  )

  return (
    <>
      {reportWriterFormat?.sections?.map((section: IRiskWriterSection) => {
        return (
          <Grid container spacing={1} key={section.name}>
            <Grid item xs={12}>
              <Box>
                <Typography variant="h5" id={section.name}>
                  {nameToLabel(section.name, section.title)}
                </Typography>
                <Grid container>
                  {contentsForSection(section.name)?.map((content: IRiskWriterSectionData) => {
                    return (
                      <Grid key={content.name} item xs={12} md={section.cols ?? 12}>
                        <RiskWriterContentEditor section={section} content={content} viewerOnly />
                      </Grid>
                    )
                  })}
                </Grid>

                {section.sections?.map((subSection: IRiskWriterSection) => {
                  return (
                    <Box key={subSection.name}>
                      <Typography variant="h6" id={subSection.name}>
                        {nameToLabel(subSection.name, subSection.title)}
                      </Typography>
                      <Grid container>
                        {contentsForSection(subSection.name)?.map((content: IRiskWriterSectionData) => {
                          return (
                            <Grid key={content.name} item xs={12} md={section.cols ?? 12}>
                              <RiskWriterContentEditor section={subSection} content={content} viewerOnly />
                            </Grid>
                          )
                        })}
                      </Grid>
                    </Box>
                  )
                })}
              </Box>
            </Grid>
          </Grid>
        )
      })}
    </>
  )
}

export default RiskWriterViewer
