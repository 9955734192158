import { type IRiskWriterFormat } from "../../components/risk_writer/models/IRiskWriterFormat"
import { type IInspection } from "./IInspection"
import { nameToLabel } from "../../utilities/form_utility"

export const REPORT_FORMAT_ENDPOINT = "report_formats"

interface IReportWriterFormat {
  version: string
  name: string
  title: string

  data: IRiskWriterFormat | null
}

export interface IReportFormat {
  id: number
  name: string

  description: string

  use_report_writer: boolean
  report_writer_format_name: string
  report_writer_format: IReportWriterFormat | null
}

/**
 * Checks if an inspection has a report writer.
 *
 * @param {IInspection | null} inspection - The inspection object to check.
 * @returns {boolean} - true if the inspection has a report writer, false otherwise.
 */
export const checkHasRiskWriter = (inspection: IInspection | undefined | null): boolean => {
  return (
    inspection?.report_format !== undefined &&
    inspection.report_format !== null &&
    inspection.report_format.use_report_writer &&
    inspection.report_format.report_writer_format !== null
  )
}

/**
 * Function to check if the report writer format within an inspection object has a brief form.
 *
 * @param {IInspection | undefined | null} inspection - The inspection object to be checked.
 * May be undefined or null.
 * @returns {boolean} - Returns true if the inspection object has a report writer format
 * with a brief form; otherwise, returns false.
 */
export const checkRiskWriterHasBrief = (inspection: IInspection | undefined | null): boolean => {
  const reportWriterFormat = inspection?.report_format?.report_writer_format?.data
  if (reportWriterFormat !== undefined && reportWriterFormat !== null) {
    return reportWriterFormat.brief_form !== undefined && reportWriterFormat.brief_form !== null
  }
  return false
}

/**
 * Generates a brief title for a risk report based on inspection data.
 *
 * This function extracts the brief form name and title from the inspection's
 * report format. If they are available, it converts the name and title to a
 * label. If the inspection data or the report format is missing, it returns
 * the default title "Brief".
 *
 * @param {IInspection | undefined | null} inspection - The inspection object which may contain the report format data.
 * @returns {string} - The generated brief title or the default "Brief" if not available.
 */
export const riskWriterBriefTitle = (inspection: IInspection | undefined | null): string => {
  const reportWriterFormat = inspection?.report_format?.report_writer_format?.data
  if (reportWriterFormat !== undefined && reportWriterFormat !== null) {
     if(reportWriterFormat.brief_form !== undefined && reportWriterFormat.brief_form !== null) {
       return nameToLabel(reportWriterFormat.brief_form.name, reportWriterFormat.brief_form.title)
     }
  }
  return "Brief"
}

/**
 * Generates the title for the risk writer building form based on the provided inspection data.
 *
 * @param {IInspection | undefined | null} inspection - The inspection object which may contain report format details.
 * @returns {string} - The formatted title for the building form. Defaults to "Inspection Building" if necessary data is absent.
 */
export const riskWriterBuildingFormTitle = (inspection: IInspection | undefined | null): string => {
  const reportWriterFormat = inspection?.report_format?.report_writer_format?.data
  if (reportWriterFormat !== undefined && reportWriterFormat !== null) {
     if(reportWriterFormat.building_form !== undefined && reportWriterFormat.building_form !== null) {
       return nameToLabel(reportWriterFormat.building_form.name, reportWriterFormat.building_form.title)
     }
  }
  return "Inspection Building"
}
