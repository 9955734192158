import { type IInspection } from "./IInspection"
import { type IListRecItem } from "../components/IListRecItem"
import { type ILocation } from "./ILocation"
import { type IServiceUser } from "./IServiceUser"
import { type IPriority } from "./IPriority"
import { type IRecommendationStatus } from "./IRecommendationStatus"
import { type IRecommendationType } from "./IRecommendationType"
import { type IRiskScoreCategory } from "./IRiskScoreCategory"
import { type IFile } from "./IFile"

export const INSPECTION_RECOMMENDATION_ENDPOINT = "inspection_recommendations"

export interface IInspectionRecommendation extends IListRecItem {
  id: number
  identifier: string
  title: string
  name: string
  body: string
  loss_estimate: string
  comment: string
  reply: string
  order: number

  inspection: IInspection
  location: ILocation
  inspection_files: IFile[]

  priority: IPriority | null
  status: IRecommendationStatus | null
  type: IRecommendationType | null

  loss_estimate_before: number
  loss_estimate_after: number
  cost_to_complete: number

  risk_score_deduction: number
  risk_score_category: IRiskScoreCategory | null

  completed: string | null
  completed_by: IServiceUser | null

  created: string
  updated: string
}
