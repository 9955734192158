import React, { useCallback, useState } from "react"
import DrawerRight from "../../containers/DrawerRight"
import AddIcon from "@mui/icons-material/Add"
import { Button, Grid } from "@mui/material"
import SelectFilteredSingle from "../../forms/SelectFilteredSingle"
import { RestRepository } from "../../../repositories/RestRepository"
import { type IListItem } from "../../../models/components/IListItem"
import { type IPaging } from "../../../models/components/IPaging"
import { buildApiAction } from "../../../hooks/useApiAction"
import { BUILDING_ENDPOINT } from "../../../models/service/IBuilding"
import BuildingInfo from "../../../../apps/admin/pages/buildings/components/BuildingInfo"
import { type IInspection, INSPECTION_ENDPOINT } from "../../../models/service/IInspection"
import { CONNECTION_ERROR, type IConnectionError } from "../../../models/components/IConnectionError"
import ErrorMessage from "../../ErrorMessage"

const buildingRepository = new RestRepository<IListItem>(BUILDING_ENDPOINT)
const repository = new RestRepository<IInspection>(INSPECTION_ENDPOINT)

interface IProps {
  onComplete: () => void
  inspection: IInspection
}

/**
 * Represents a component for adding a service user.
 *
 * @param {IProps} props - The component props.
 * @returns {React.ReactElement} The rendered component.
 */
const AddBuilding: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { onComplete, inspection } = props
  const [building, setBuilding] = useState<IListItem | null>(null)
  const [error, setError] = useState<IConnectionError | null>(null)
  const [closeDrawer, setCloseDrawer] = useState<boolean>(true)

  const apiAction = buildApiAction<IInspection>(repository, inspection.id)

  const handleAddBuilding = useCallback(async () => {
    if (building !== null) {
      try {
        const paging: IPaging = { filters: [{ field: "id", value: building.id }] }
        await apiAction.callAction("add_building", paging)
        setCloseDrawer(closeDrawer => !closeDrawer)
        onComplete()
      } catch (reason: any) {
        if (reason?.response !== undefined) {
          setError(reason.response as IConnectionError)
        } else {
          setError(CONNECTION_ERROR)
        }
      }
    }
  }, [building])

  return (
    <DrawerRight title={"Add Building"} icon={<AddIcon />} closeDrawer={closeDrawer}>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <SelectFilteredSingle
            name="building"
            defaultValue={building}
            filters={[{ field: "location", value: inspection.location.id }]}
            repository={buildingRepository}
            onChange={setBuilding}
          />
        </Grid>
        <Grid item xs />
        <Grid item>
          <Button onClick={handleAddBuilding} disabled={building === null}>
            Add Building
          </Button>
        </Grid>
        <Grid item xs={12}>
          <ErrorMessage error={error} />
        </Grid>
        <Grid item xs={12}>
          {building !== null && <BuildingInfo infoId={building.id} />}
        </Grid>
      </Grid>
    </DrawerRight>
  )
}

export default AddBuilding
