import React, { useContext } from "react"
import { Container, Grid, Typography } from "@mui/material"
import { useApiRead } from "../../../../shared/hooks/useApiRead"
import PageHeader from "../../../../shared/components/pages/PageHeader"
import ViewProperty from "../../../../shared/components/ViewProperty"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import { type ISetting, SETTING_ENDPOINT } from "../../../../shared/models/service/ISetting"
import { SETTING_EDIT_URL } from "../../config/urls"
import CheckMark from "../../../../shared/components/display/CheckMark"
import ViewSelectOption from "../../../../shared/components/display/ViewSelectOption"
import { SelectOptions } from "../../../../config/config"
import ViewColor from "../../../../shared/components/display/ViewColor"
import PaperLocal from "../../../../shared/components/containers/PaperLocal"
import FormatDate from "../../../../shared/components/format/FormatDate"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { type IPanariskAppContext } from "../../../../shared/models/app/IPanariskAppContext"
import { PanariskAppContext } from "../../../../app/PanariskApp"
import ErrorMessage from "../../../../shared/components/ErrorMessage"
import ViewLoading from "../../../../shared/components/ViewLoading"

const repository = new RestRepository<ISetting>(SETTING_ENDPOINT)

/**
 * Renders the index page with settings.
 *
 * @returns {React.ReactElement} The rendered index page.
 */
const IndexPage: React.FC = (): React.ReactElement => {
  const { appSettings } = useContext<IPanariskAppContext>(PanariskAppContext)
  const itemId = appSettings.serverInfo?.setting_id
  const { data, error, loading } = useApiRead<ISetting>({ apiFunction: repository.read, itemId })
  const { t } = useTranslation()

  return (
    <Container fixed>
      <ErrorMessage error={error} />
      <ViewLoading loading={loading} />
      {data !== undefined && (
        <Grid container spacing={2}>
          <PageHeader title={t("Settings")} toEdit={`${SETTING_EDIT_URL}/${data.id}`} />
          <Grid item xs={12} lg={6}>
            <PaperLocal>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5" color="textSecondary">
                    {data.company_name}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <ViewProperty label={t("Enable All Emails")}>
                    <CheckMark value={data.enable_all_emails} />
                  </ViewProperty>
                </Grid>
                <Grid item xs={12}>
                  <ViewProperty label={t("Enable Username and Password Login")}>
                    <CheckMark value={data.enable_username_password_login} />
                  </ViewProperty>
                </Grid>
                <Grid item xs={12}>
                  <ViewProperty label={t("Units")}>
                    <ViewSelectOption keyLookup={data.units} selectOption={SelectOptions.UNITS_SYSTEMS} />
                  </ViewProperty>
                </Grid>
                <Grid item xs={12}>
                  <ViewProperty label={t("Currency")}>
                    <ViewSelectOption keyLookup={data.currency} selectOption={SelectOptions.CURRENCY_CHOICES} />
                  </ViewProperty>
                </Grid>
                <Grid item xs={12}>
                  <ViewProperty label={t("Date Format")} vertical>
                    <Grid container>
                      <Grid item xs>
                        <small>
                          Sample: <FormatDate value={moment()} />
                        </small>
                      </Grid>
                      <Grid item>{data.date_format}</Grid>
                    </Grid>
                  </ViewProperty>
                </Grid>
                <Grid item xs={12}>
                  <ViewProperty label={t("Primary Color")}>
                    <ViewColor>{data.primary_color}</ViewColor>
                  </ViewProperty>
                </Grid>
                <Grid item xs={12}>
                  <ViewProperty label={t("Secondary Color")}>
                    <ViewColor>{data.secondary_color}</ViewColor>
                  </ViewProperty>
                </Grid>
              </Grid>
            </PaperLocal>
          </Grid>
        </Grid>
      )}
    </Container>
  )
}

export default IndexPage
