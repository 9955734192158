import { Controller } from "react-hook-form"
import { Box, Grid, MenuItem, type SxProps, TextField } from "@mui/material"
import React from "react"
import { type Control } from "react-hook-form/dist/types/form"
import { type ISelectItem } from "../../models/components/ISelectItem"
import { nameToLabel } from "../../utilities/form_utility"
import HtmlToolTip from "../HtmlToolTip"
import InfoIcon from "@mui/icons-material/Info"

interface IProps {
  control: Control
  items: ISelectItem[]
  defaultValue?: string | number
  label?: string | null
  name: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  allowBlank?: boolean
  sx?: SxProps
  helperText?: string
}

/**
 * Form wrapper for a MUI selection field.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the wrapped select field.
 */
const FhMuiSelectField: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const {
    control,
    defaultValue = null,
    items,
    name,
    label,
    onChange,
    allowBlank = false,
    sx,
    helperText = null,
  } = props

  const theLabel = nameToLabel(name, label)
  const theDefaultValue = defaultValue ?? items[0].key

  return (
    <Grid container>
      <Grid item xs>
        <Controller
          name={name}
          control={control}
          defaultValue={theDefaultValue}
          render={({ field, fieldState }) => (
            <TextField
              fullWidth
              label={theLabel}
              select
              sx={sx}
              helperText={fieldState.error?.message}
              onBlur={field.onBlur}
              onChange={event => {
                field.onChange(event)
                onChange?.(event)
              }}
              value={field.value}
              inputRef={field.ref}>
              {allowBlank && <MenuItem value={""}>{"---"}</MenuItem>}
              {items.map(item => (
                <MenuItem key={item.key} value={item.key}>
                  {item.value}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Grid>
      {helperText !== null && (
        <Grid item>
          <Box sx={{ position: "relative", marginLeft: -3, marginTop: 0, cursor: "pointer" }}>
            <HtmlToolTip title={<Box dangerouslySetInnerHTML={{ __html: helperText }} />} placement="left">
              <InfoIcon color="secondary" />
            </HtmlToolTip>
          </Box>
        </Grid>
      )}
    </Grid>
  )
}

export default FhMuiSelectField
