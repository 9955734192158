import React, { useCallback } from "react"
import AlertDialog from "../../../../shared/components/AlertDialog"
import RemoveIcon from "@mui/icons-material/Remove"
import { type IServiceUser } from "../../../../shared/models/service/IServiceUser"
import useApiAction, { type IUseApiActionProps } from "../../../../shared/hooks/useApiAction"
import { type RestRepository } from "../../../../shared/repositories/RestRepository"
import { type IMainModel } from "../../../../shared/models/service/IMainModel"
import { type IPaging } from "../../../../shared/models/components/IPaging"

interface IProps {
  serviceUser: IServiceUser
  onComplete: () => void
  parentId: number
  repository: RestRepository<IMainModel>
}

/**
 * React component for removing a service user.
 *
 * @param {IProps} props - The component props.
 * @returns {React.ReactElement} The rendered React component.
 */
const RemoveServiceUser: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { serviceUser, onComplete, parentId, repository } = props

  const actionProps: IUseApiActionProps<IMainModel> = { repository, itemId: parentId }
  const apiAction = useApiAction<IMainModel>(actionProps)

  const handleYes = useCallback(async () => {
    const paging: IPaging = { filters: [{ field: "id", value: serviceUser.id }] }
    await apiAction.callAction("remove_service_user", paging)
    onComplete()
  }, [serviceUser])

  return (
    <AlertDialog
      buttonText="Remove"
      buttonIcon={<RemoveIcon />}
      title="Confirm Remove Service User"
      message={
        <>
          Are you sure you want to remove:{" "}
          <p>
            <em>{serviceUser.name}</em>
          </p>
        </>
      }
      onYes={handleYes}
    />
  )
}

export default RemoveServiceUser
