import React, { useCallback, useState } from "react"
import DrawerRight from "../../../../../shared/components/containers/DrawerRight"
import AddIcon from "@mui/icons-material/Add"
import AddPage from "../../buildings/AddPage"
import { type ILocation } from "../../../../../shared/models/service/ILocation"

interface IProps {
  onComplete: () => void
  location: ILocation
}

/**
 * Represents a component for adding a building.
 *
 * @param {IProps} props - The component props.
 * @returns {React.ReactElement} The rendered component.
 */
const AddBuilding: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { onComplete, location } = props

  const [closeDrawer, setCloseDrawer] = useState<boolean>(true)

  const handleComplete = useCallback(async () => {
    setCloseDrawer(close => !close)
    onComplete()
  }, [])

  return (
    <DrawerRight title="Add Building" icon={<AddIcon />} closeDrawer={closeDrawer}>
      <AddPage thisLocation={location} onComplete={handleComplete} />
    </DrawerRight>
  )
}

export default AddBuilding
