import { useEffect, useState } from "react"
import { type UseFormSetValue } from "react-hook-form/dist/types/form"

/**
 * Loads forms data and triggers an effect function if data is present and conditions are met.
 *
 * @template T - The type of data to be loaded.
 * @param {Function} effect - The effect function to be triggered with the loaded data.
 * @param {T|null|undefined} data - The data to be loaded.
 * @param {boolean} isEdit - The flag indicating if edit mode is enabled.
 * @param {UseFormSetValue} setValue - The function to set a value.
 * @returns {void}
 */
const useLoadFormData = <T>(
  effect: (data: T) => void,
  data: T | null | undefined,
  isEdit: boolean,
  setValue: UseFormSetValue<any>,
): void => {
  const [hasData, setHasData] = useState(false)

  useEffect(() => {
    if (data !== undefined && data !== null) {
      if (isEdit && !hasData) {
        setHasData(true)
        effect(data)
      }
      if (!hasData) {
        setHasData(true)
      }
    }
  }, [data, hasData, setValue, isEdit])
}

export default useLoadFormData
