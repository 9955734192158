import { type IMainModel } from "./IMainModel"

export const FILE_TAG_ENDPOINT = "file_tags"

export interface IFileTag extends IMainModel {
  id: number
  name: string
  color: string
  notes: string
}
