import React, { useCallback, useMemo, useState } from "react"
import { Alert, Box, Button, Divider, Grid, List, Typography } from "@mui/material"
import DrawerRight from "../containers/DrawerRight"
import { Delete } from "@mui/icons-material"
import useApiAction from "../../hooks/useApiAction"
import { type RestRepository } from "../../repositories/RestRepository"
import ItemViewerTitle from "../item_viewer/ItemViewerTitle"
import ListItem from "@mui/material/ListItem"
import { type IListItem } from "../../models/components/IListItem"
import TruncateText from "../TruncateText"
import DeleteIcon from "@mui/icons-material/Delete"
import { type IMainModel } from "../../models/service/IMainModel"
import { useAxiosRequest } from "../../hooks/useAxiosRequest"
import type { IInfoViewProps } from "../item_viewer/ItemViewerDrawer"
import ViewLoading from "../ViewLoading"
import ErrorMessage from "../ErrorMessage"

interface IRelatedModels {
  deletes: Record<string, IListItem[]>
  nulls: Record<string, IListItem[]>
}

interface IRelatedItemsProps {
  items: Record<string, IListItem[]>
}

/**
 * Renders a list of related items.
 *
 * @param {IRelatedItemsProps} props - The props for the component.
 * @returns {React.ReactElement} - The rendered list of related items.
 */
const RelatedItems: React.FC<IRelatedItemsProps> = (props: IRelatedItemsProps): React.ReactElement => {
  const { items } = props
  const [showAll, setShowAll] = useState<Record<string, boolean>>({})

  const handleShowAll = useCallback(
    (key: string) => () => {
      setShowAll(showAll1 => {
        return { ...showAll1, [key]: !showAll1[key] }
      })
    },
    [],
  )

  return (
    <>
      {Object.keys(items).map(relatedModelKey => {
        return (
          <Box key={relatedModelKey}>
            <Grid container>
              <Grid item xs>
                <Typography variant="h6">{relatedModelKey}</Typography>
              </Grid>
              <Grid item>
                {items[relatedModelKey].length > 3 && (
                  <Button onClick={handleShowAll(relatedModelKey)}>
                    Show {showAll[relatedModelKey] ? "Less" : "All"}
                  </Button>
                )}
              </Grid>
            </Grid>
            <List>
              {items[relatedModelKey]
                .slice(0, showAll[relatedModelKey] ? items[relatedModelKey].length : 3)
                .map(item => (
                  <ListItem key={item.id}>
                    <TruncateText>{item.name}</TruncateText>
                  </ListItem>
                ))}
              {!showAll[relatedModelKey] && <ListItem>...</ListItem>}
            </List>
          </Box>
        )
      })}
    </>
  )
}

interface IProps {
  itemId: number | string | undefined
  name: string
  iconButton?: boolean
  onDelete: () => void
  infoView: React.FC<IInfoViewProps>
  repository: RestRepository<IMainModel>
}

/**
 * Renders a component for deleting an item.
 *
 * @param {IProps} props - The props for the component.
 * @returns {React.ReactElement} - The rendered component.
 */
const ItemDeleteDrawer: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { itemId, name, onDelete, infoView: InfoView, repository, iconButton } = props
  const [closeDrawer, setCloseDrawer] = useState<boolean>(false)
  const [relatedModels, setRelatedModels] = useState<IRelatedModels | null>(null)
  const { callAction, saving, connectionError } = useApiAction<IMainModel>({ repository, itemId })
  const { callRequest, loading, errorMessage } = useAxiosRequest()

  const countNulls = useMemo(() => {
    if (relatedModels !== null) {
      let count = 0
      Object.values(relatedModels.nulls).forEach(items => {
        count += items.length
      })
      return count
    }
    return 0
  }, [relatedModels])

  const countDeletes = useMemo(() => {
    if (relatedModels !== null) {
      let count = 0
      Object.values(relatedModels.deletes).forEach(items => {
        count += items.length
      })
      return count
    }
    return 0
  }, [relatedModels])

  const handleDrawerOpen = useCallback(async () => {
    const related = await callAction<IRelatedModels>("related_model_deletion")
    if (related !== undefined) {
      setRelatedModels(related)
    } else {
      setRelatedModels(null)
    }
  }, [callAction])

  const handleDelete = useCallback(async () => {
    if (itemId !== undefined) {
      await callRequest(async () => {
        await repository.delete(itemId)
        setCloseDrawer(true)
        onDelete()
      })
    }
  }, [itemId])

  return (
    <>
      <DrawerRight
        title={`Delete ${name}`}
        buttonColor="error"
        icon={<Delete />}
        showButton
        showIconButton={iconButton}
        closeDrawer={closeDrawer}
        onDrawerOpen={handleDrawerOpen}>
        <Box>
          <ViewLoading loading={saving || loading} />
          <ErrorMessage error={connectionError} />
          <ErrorMessage error={errorMessage} />
          <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
            <Grid item xs={12}>
              <Alert severity="warning">Are you sure you want to delete this {name}?</Alert>
            </Grid>
            {itemId !== undefined && InfoView !== undefined && (
              <Grid item xs={12}>
                <InfoView infoId={itemId} />
              </Grid>
            )}
            {relatedModels !== null && (
              <>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <ItemViewerTitle>
                    <Grid container>
                      <Grid item xs>
                        Related Deletions
                      </Grid>
                      <Grid item>
                        <small>({countDeletes})</small>
                      </Grid>
                    </Grid>
                  </ItemViewerTitle>
                </Grid>
                <Grid item xs={12}>
                  <Box>The following items will also be deleted.</Box>
                </Grid>
                <Grid item xs={12}>
                  <RelatedItems items={relatedModels.deletes} />
                </Grid>
                <Grid item xs={12}>
                  <ItemViewerTitle>
                    <Grid container>
                      <Grid item xs>
                        Related Removed
                      </Grid>
                      <Grid item>
                        <small>({countNulls})</small>
                      </Grid>
                    </Grid>
                  </ItemViewerTitle>
                </Grid>
                <Grid item xs={12}>
                  <Box>The following will have this {name} removed.</Box>
                </Grid>
                <Grid item xs={12}>
                  <RelatedItems items={relatedModels.nulls} />
                </Grid>
              </>
            )}
          </Grid>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Alert
              severity="error"
              action={
                <Button color="error" startIcon={<DeleteIcon />} onClick={handleDelete}>
                  Delete!
                </Button>
              }>
              Yes, delete this {name}.
            </Alert>
          </Box>
        </Box>
      </DrawerRight>
    </>
  )
}

export default ItemDeleteDrawer
