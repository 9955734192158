import React, { useContext } from "react"
import Moment from "react-moment"
import type moment from "moment"
import { DATE_FORMAT } from "../../../config/config"
import { type IPanariskAppContext } from "../../models/app/IPanariskAppContext"
import { PanariskAppContext } from "../../../app/PanariskApp"

interface IProps {
  value: string | moment.Moment | null | undefined
  notSetMessage?: string
  format?: string | null
}

/**
 * Format a date given a string.
 *
 * @param {IProps} props see IProps for details.
 * @returns {React.FC<IProps>} a formatted date.
 */
const FormatDate: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { value, notSetMessage = "Not Set", format } = props
  const { appSettings } = useContext<IPanariskAppContext>(PanariskAppContext)

  let theFormat = appSettings?.serverInfo?.date_format ?? DATE_FORMAT
  if (format !== undefined && format !== null) {
    theFormat = format
  }

  return value !== null && value !== undefined ? <Moment format={theFormat}>{value}</Moment> : <>{notSetMessage}</>
}

export default FormatDate
