import React, { useCallback } from "react"
import {
  type IInspectionRecommendation,
  INSPECTION_RECOMMENDATION_ENDPOINT,
} from "../../../../shared/models/service/IInspectionRecommendation"
import { Box, Container, Grid } from "@mui/material"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import { type IUseApiReadProps, useApiRead } from "../../../../shared/hooks/useApiRead"
import ViewProperty from "../../../../shared/components/ViewProperty"
import PageHeader from "../../../../shared/components/pages/PageHeader"
import PaperLocal from "../../../../shared/components/containers/PaperLocal"
import ViewRichText from "../../../../shared/components/display/ViewRichText"
import { ItemPrefixes } from "../../../../config/config"
import { LoadingButton } from "@mui/lab"
import useApiAction, { type IUseApiActionProps } from "../../../../shared/hooks/useApiAction"
import ViewListItems from "../../../../shared/components/display/ViewListItems"
import FormatDate from "../../../../shared/components/format/FormatDate"
import ItemViewerDrawer from "../../../../shared/components/item_viewer/ItemViewerDrawer"
import ServiceUserInfo from "../../../admin/pages/service_users/components/ServiceUserInfo"
import LocationInfo from "../../../admin/pages/locations/components/LocationInfo"
import InspectionInfo from "../../../admin/pages/inspections/components/InspectionInfo"
import ViewCurrency from "../../../../shared/components/display/ViewCurrency"
import { ImageChooserProvider } from "../../../../shared/components/files/ImageChooser"
import InspectionRecommendationImages from "../../../admin/pages/inspection_recommendations/components/InspectionRecommendationImages"
import { INSPECTION_FILE_ENDPOINT } from "../../../../shared/models/service/IFile"

const repository = new RestRepository<IInspectionRecommendation>(INSPECTION_RECOMMENDATION_ENDPOINT)

/**
 * Renders a view page for an inspection report recommendation.
 *
 * @returns {React.ReactElement} The rendered view page.
 */
const ViewPage: React.FC = (): React.ReactElement => {
  const props: IUseApiReadProps<IInspectionRecommendation> = { apiFunction: repository.read }
  const apiRead = useApiRead<IInspectionRecommendation>(props)
  const { data } = apiRead

  const actionProps: IUseApiActionProps<IInspectionRecommendation> = { repository, itemId: data?.id }
  const apiAction = useApiAction<IInspectionRecommendation>(actionProps)

  const handleToggleCompleted = useCallback(async () => {
    await apiAction.callAction("toggle_completed")
    await apiRead.call()
  }, [apiAction.callAction])

  return (
    <Container fixed>
      {data !== undefined && (
        <Grid container spacing={2}>
          <PageHeader title="Inspection Rec" titlePlural={`${data.identifier} - ${data.title}`} />
          <ItemViewerDrawer prefix={ItemPrefixes.location} infoView={LocationInfo} />
          <ItemViewerDrawer prefix={ItemPrefixes.serviceUser} infoView={ServiceUserInfo} />
          <ItemViewerDrawer prefix={ItemPrefixes.inspection} infoView={InspectionInfo} />
          <Grid item xs={12} lg={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <PaperLocal>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <ViewProperty label="Inspection">
                        <ViewListItems prefix={ItemPrefixes.inspection}>{data.inspection}</ViewListItems>
                      </ViewProperty>
                    </Grid>
                    <Grid item xs={12}>
                      <ViewProperty label="Location">
                        <ViewListItems prefix={ItemPrefixes.location}>{data.location}</ViewListItems>
                      </ViewProperty>
                    </Grid>
                    <Grid item xs={12}>
                      <ViewProperty label="Cost To Complete">
                        <ViewCurrency>{data.cost_to_complete}</ViewCurrency>
                      </ViewProperty>
                    </Grid>
                    <Grid item xs={12}>
                      <ViewProperty label="Loss Estimate After">
                        <ViewCurrency>{data.loss_estimate_after}</ViewCurrency>
                      </ViewProperty>
                    </Grid>
                    <Grid item xs={12}>
                      <ViewProperty label="Loss Estimate Before">
                        <ViewCurrency>{data.loss_estimate_before}</ViewCurrency>
                      </ViewProperty>
                    </Grid>
                    <Grid item xs={12}>
                      <ViewProperty label="Type">{data.type?.name}</ViewProperty>
                    </Grid>
                    <Grid item xs={12}>
                      <ViewProperty label="Status">{data.status?.name}</ViewProperty>
                    </Grid>
                    <Grid item xs={12}>
                      <ViewProperty label="Priority">{data.priority?.name}</ViewProperty>
                    </Grid>
                    <Grid item xs={12}>
                      <ViewProperty label="Risk Score Deduction">{data.risk_score_deduction}</ViewProperty>
                    </Grid>
                    <Grid item xs={12}>
                      <ViewProperty label="Risk Score Category">{data.risk_score_category?.name}</ViewProperty>
                    </Grid>
                  </Grid>
                </PaperLocal>
              </Grid>
              <Grid item xs={12}>
                <PaperLocal>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <ViewProperty label="Completed">
                        <LoadingButton
                          onClick={handleToggleCompleted}
                          color={data.completed === null ? "primary" : "error"}
                          loading={apiAction.saving}>
                          {data.completed === null ? "Complete" : "Reopen"}
                        </LoadingButton>
                      </ViewProperty>
                    </Grid>
                    <Grid item xs={12}>
                      <ViewProperty label="Completed By" show={data.completed !== null}>
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item xs>
                            <FormatDate value={data.completed} />
                          </Grid>
                          <Grid item>
                            <ViewListItems prefix={ItemPrefixes.serviceUser}>{data.completed_by}</ViewListItems>
                          </Grid>
                        </Grid>
                      </ViewProperty>
                    </Grid>
                  </Grid>
                </PaperLocal>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <PaperLocal>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <ViewProperty label="Description">
                        <ViewRichText>{data.body}</ViewRichText>
                      </ViewProperty>
                    </Grid>
                    <Grid item xs={12}>
                      <ViewProperty label="Loss Estimate">
                        <ViewRichText>{data.loss_estimate}</ViewRichText>
                      </ViewProperty>
                    </Grid>
                    <Grid item xs={12}>
                      <ViewProperty label="Comment">
                        <ViewRichText>{data.comment}</ViewRichText>
                      </ViewProperty>
                    </Grid>
                    <Grid item xs={12}>
                      <ViewProperty label="Reply">
                        <ViewRichText>{data.reply}</ViewRichText>
                      </ViewProperty>
                    </Grid>
                  </Grid>
                </PaperLocal>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <ImageChooserProvider
                itemId={data.inspection.id}
                closeOnFileSelect={false}
                field="inspection"
                fileEndpoint={INSPECTION_FILE_ENDPOINT}>
                <InspectionRecommendationImages inspectionRecommendation={data} onChange={apiRead.call} />
              </ImageChooserProvider>
            </Box>
          </Grid>
        </Grid>
      )}
    </Container>
  )
}

export default ViewPage
