import React from "react"
import FormatNumber from "../format/FormatNumber"

interface IProps {
  children: number | null | undefined
  field?: string
}

/**
 * Renders a formatted number with a percentage symbol.
 *
 * @param {IProps} props - The input props object.
 * @returns {React.ReactElement} - The formatted number with a percentage symbol.
 */
const ViewPercent: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children } = props

  return (
    <FormatNumber
      value={children ?? null}
      prefixUnits={false}
      suffixUnits={<>%</>}
    />
  )
}

export default ViewPercent
