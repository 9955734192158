import React from "react"
import { Chip, TableCell } from "@mui/material"
import { type IFileTag } from "../../models/service/IFileTag"

interface IProps {
  children: IFileTag[] | number[] | undefined
  field?: string
}

/**
 * Checks if the given value is an array of IFileTag objects.
 *
 * @param {IFileTag[] | number[] | undefined} children - The value to be checked.
 * @returns {boolean} Returns true if the value is an array of IFileTag objects, false otherwise.
 */
function isIFileTagArray(children: IFileTag[] | number[] | undefined): children is IFileTag[] {
  if (!Array.isArray(children)) {
    return false
  }

  return children.every((child: IFileTag | number) => {
    return typeof child === "object" && "name" in child
  })
}

/**
 * Renders a table cell with a list of file tags as chips.
 *
 * @param {IProps} props - The component properties.
 * @param {React.ReactElement} props.children - The child elements representing the file tags.
 * @returns {React.ReactElement} - The rendered table cell with file tags chips.
 */
const TableCellFileTags: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children } = props
  if (!isIFileTagArray(children)) {
    return <TableCell />
  }

  return (
    <TableCell>
      {children.map((fileTag: IFileTag) => (
        <Chip key={fileTag.id} size="small" sx={{ backgroundColor: fileTag.color, ml: 1 }} label={fileTag.name} />
      ))}
    </TableCell>
  )
}

export default TableCellFileTags
