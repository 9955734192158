import type { IRiskWriterForm } from "./IRiskWriterForm"
import type { IRiskWriterSection } from "./IRiskWriterSection"
import type { IRiskWriterTable } from "./IRiskWriterTable"

export enum InputType {
  RICH_TEXT = "rich_text",
  TEXT = "text",
  INTEGER = "integer",
  FLOAT = "float",
  CURRENCY = "currency",
  AREA = "area",
  PERCENT = "percent",
  YEAR = "year",
  CHECKBOX = "checkbox",
  SELECT = "select",
  DATE = "date",
  LABEL = "label",
  SPACE = "space"
}

export interface IRiskWriterFormat {
  version: string
  name: string
  title: string
  options: Record<string, Array<[string, string | number | boolean | null]>> | null
  summary_formats: IRiskWriterFormat[] | null
  brief_form: IRiskWriterForm | null
  building_form: IRiskWriterForm | null
  tables: IRiskWriterTable[] | null
  sections: IRiskWriterSection[] | null
}
