import * as React from "react"
import { useCallback, useContext, useEffect, useState } from "react"
import { alpha, Box, Collapse, Link, List, ListItemButton, ListItemText, type Theme } from "@mui/material"
import { ExpandLess, ExpandMore } from "@mui/icons-material"
import { type IRiskWriterSection } from "../models/IRiskWriterSection"
import TruncateText from "../../TruncateText"
import { nameToLabel } from "../../../utilities/form_utility"
import { RiskWriterContext } from "../context/RiskWriterProvider"

const styles = {
  root: (theme: Theme) => ({
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    "& .MuiButtonBase-root.Mui-selected": (theme: Theme) => ({
      backgroundColor: alpha(theme.palette.primary.main, 0.13),
      color: theme.palette.primary.main,
    }),
    "& .MuiButtonBase-root": (theme: Theme) => ({ color: alpha(theme.palette.text.secondary, 0.6) }),
  }),
  navLink: (theme: Theme) => ({
    minHeight: 36,
    px: 2.5,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    color: theme.palette.text.primary,
    ml: 1,
    mt: 0.5,
    mb: 0.5,
  }),
  nested: {
    pl: 5,
  },
}

interface IProps {
  onNavigation?: () => void
  showBuildings?: boolean
  showRecommendations?: boolean
}

/**
 * RiskWriterToc is a functional component that displays a table of contents menu for a report.
 *
 * @param {IProps} props - The component props.
 * @returns {React.ReactElement} The table of contents menu component.
 */
const RiskWriterToc: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { onNavigation, showBuildings = true, showRecommendations = true } = props
  const [sectionsOpen, setSectionsOpen] = useState<string[]>([])

  const { reportWriterFormat, currentSection, onChangeSection } = useContext(RiskWriterContext)

  const handleShow = useCallback(
    (section: string) => () => {
      onNavigation?.()
      document.getElementById(section)?.scrollIntoView()
    },
    [],
  )

  const handleShowSection = useCallback(
    (section: IRiskWriterSection) => () => {
      onChangeSection?.(section)
      onNavigation?.()
      document.getElementById(`section-${section.name}`)?.scrollIntoView()
      setSectionsOpen(sectionsOpen => {
        if (sectionsOpen.includes(section.name)) {
          return sectionsOpen.filter(sIndex => sIndex !== section.name)
        }
        sectionsOpen.push(section.name)
        return [...sectionsOpen]
      })
    },
    [],
  )

  const handleSubSection = useCallback(
    (section: IRiskWriterSection) => () => {
      onChangeSection?.(section)
      onNavigation?.()
      document.getElementById(`section-${section.name}`)?.scrollIntoView()
    },
    [],
  )

  const isSectionOpen = useCallback(
    (sectionId: string) => {
      return sectionsOpen.includes(sectionId)
    },
    [sectionsOpen],
  )

  useEffect(() => {
    if (currentSection?.name !== undefined && reportWriterFormat?.sections !== undefined) {
      setSectionsOpen(sectionsOpen => {
        if (!sectionsOpen.includes(currentSection.name) && reportWriterFormat.sections !== null) {
          for (const section of reportWriterFormat.sections) {
            if (section.sections !== undefined && section.sections !== null) {
              for (const subSection of section.sections) {
                if (subSection.name === currentSection.name) {
                  return [...sectionsOpen, section.name]
                }
              }
            }
          }
        }
        return sectionsOpen
      })
    }
  }, [currentSection?.name])

  return (
    <>
      <List dense sx={{ ...styles.root }}>
        {showBuildings && (
          <ListItemButton component={Link} onClick={handleShow("buildings")} sx={styles.navLink}>
            <ListItemText primary="Buildings" />
          </ListItemButton>
        )}
        {reportWriterFormat?.tables !== undefined && reportWriterFormat.tables !== null && (
          <ListItemButton onClick={handleShow("tables")} sx={styles.navLink}>
            <ListItemText primary="Tables" />
          </ListItemButton>
        )}
        {showRecommendations && (
          <ListItemButton onClick={handleShow("recommendations")} sx={styles.navLink}>
            <ListItemText primary="Recommendations" />
          </ListItemButton>
        )}
        {reportWriterFormat?.brief_form !== undefined && reportWriterFormat.brief_form !== null && (
          <ListItemButton onClick={handleShow("brief")} sx={styles.navLink}>
            <ListItemText
              primary={nameToLabel(reportWriterFormat.brief_form.name, reportWriterFormat.brief_form.title)}
            />
          </ListItemButton>
        )}
        {reportWriterFormat?.sections !== undefined && reportWriterFormat.sections !== null && (
          <>
            <ListItemButton onClick={handleShow("narrative")} sx={styles.navLink}>
              <ListItemText primary="Narrative" />
            </ListItemButton>
            {reportWriterFormat.sections?.map((section: IRiskWriterSection) => {
              return (
                <Box key={section.name} sx={{ pl: 2 }}>
                  <ListItemButton
                    onClick={handleShowSection(section)}
                    id={`nav-${section.name}`}
                    sx={styles.navLink}
                    selected={currentSection?.name === section.name}>
                    <ListItemText primary={<TruncateText>{nameToLabel(section.name, section.title)}</TruncateText>} />
                    {section.sections !== undefined && section.sections !== null && section.sections.length > 0 && (
                      <>{isSectionOpen(section.name) ? <ExpandLess /> : <ExpandMore />}</>
                    )}
                  </ListItemButton>
                  <Collapse in={isSectionOpen(section.name)} timeout="auto" unmountOnExit>
                    {section.sections !== undefined && section.sections !== null && section.sections?.length > 0 && (
                      <List dense component="div" disablePadding>
                        {section.sections?.map((subSection: IRiskWriterSection) => {
                          return (
                            <ListItemButton
                              key={subSection.name}
                              sx={styles.nested}
                              id={`nav-${subSection.name}`}
                              onClick={handleSubSection(subSection)}
                              selected={currentSection?.name === subSection.name}>
                              <ListItemText
                                primary={<TruncateText>{nameToLabel(subSection.name, subSection.title)}</TruncateText>}
                              />
                            </ListItemButton>
                          )
                        })}
                      </List>
                    )}
                  </Collapse>
                </Box>
              )
            })}
          </>
        )}
      </List>
    </>
  )
}

export default RiskWriterToc
