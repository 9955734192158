import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { type IBulk } from "../../models/components/IBulk"
import { type IComboItem } from "../../models/components/IComboItem"
import { Autocomplete, Grid, Popper, type PopperProps, TextField } from "@mui/material"
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges"
import DrawerRight from "../containers/DrawerRight"
import { type RestRepository } from "../../repositories/RestRepository"

interface IBulkDataContext {
  availableBulk: IBulk[]
  setAvailableBulk: React.Dispatch<React.SetStateAction<IBulk[]>>
  selectedBulk: IComboItem | null
  selectedRows: Array<string | number> | null
  processing: boolean
  setProcessing: React.Dispatch<React.SetStateAction<boolean>>
  onDone?: () => void
  repository?: RestRepository<any>
}

export const BulkDataContext = createContext<IBulkDataContext | null>(null)

/**
 * Sets the availability of bulk data for the specified field.
 *
 * @param {string} field - The field for which the bulk data availability is being set.
 * @param {string} title - The title of the bulk data.
 * @returns {IBulkDataContext | null} - The bulk data context or null if not available.
 */
export const useBulkDataAvailable = (field: string, title: string): IBulkDataContext | null => {
  const bulkDataContext = useContext(BulkDataContext)

  useEffect(() => {
    bulkDataContext?.setAvailableBulk(availableBulk => {
      if (!availableBulk.some(bulk => bulk.field === field)) {
        return [...availableBulk, { field, title }]
      }
      return availableBulk
    })
  }, [field, title, bulkDataContext])

  return bulkDataContext
}

interface IProps {
  children?: React.JSX.Element | React.JSX.Element[] | null | undefined
  selectedRows?: Array<string | number>
  onDone?: () => void
  repository?: RestRepository<any>
}

/**
 * Render a bulk data element with the given props.
 *
 * @param {IProps} props - The props object containing the children to be rendered.
 * @returns {React.ReactElement} - The rendered bulk data element.
 */
const BulkData: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children, selectedRows = [], onDone, repository } = props
  const [availableBulk, setAvailableBulk] = useState<IBulk[]>([])
  const [selectedBulk, setSelectedBulk] = useState<IComboItem | null>(null)
  const [processing, setProcessing] = useState<boolean>(false)
  const [closeDrawer, setCloseDrawer] = useState<boolean>(false)

  const bulkToShowComboItems: IComboItem[] = useMemo(() => {
    return availableBulk.map(bulk => ({ label: bulk.title, value: bulk.field }))
  }, [availableBulk?.length])

  const handleBulkCombo = useCallback(
    (_event: React.SyntheticEvent, comboItem: IComboItem | null) => {
      setSelectedBulk(comboItem)
    },
    [availableBulk],
  )

  const handleDone = useCallback(() => {
    setSelectedBulk(null)
    setCloseDrawer(closeDrawer => !closeDrawer)
    onDone?.()
  }, [onDone])

  /**
   * This is needed so the autocomplete can be used in right drawer on the report writer.
   * See public/index.html for styling.
   */
  const PopperSelectFilter = useCallback((props: PopperProps) => {
    return <Popper {...props} id="AutocompletePopper" />
  }, [])

  return (
    <BulkDataContext.Provider
      value={{
        availableBulk,
        setAvailableBulk,
        selectedBulk,
        selectedRows,
        processing,
        setProcessing,
        onDone: handleDone,
        repository,
      }}>
      <DrawerRight
        title="Bulk"
        icon={<PublishedWithChangesIcon />}
        showButton={selectedRows.length > 0}
        closeDrawer={closeDrawer}>
        <Grid container spacing={2} sx={{ mt: 1 }} alignItems="center">
          <Grid item xs>
            <Autocomplete
              fullWidth
              autoHighlight
              onChange={handleBulkCombo}
              PopperComponent={PopperSelectFilter}
              value={selectedBulk}
              renderInput={params => <TextField {...params} autoFocus label="Bulk" />}
              options={bulkToShowComboItems}
            />
          </Grid>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </DrawerRight>
    </BulkDataContext.Provider>
  )
}

export default BulkData
