import React, { useCallback } from "react"
import { Button } from "@mui/material"
import { CloudDownload } from "@mui/icons-material"
import useApiAction from "../hooks/useApiAction"
import { type RestRepository } from "../repositories/RestRepository"
import { type IPaging } from "../models/components/IPaging"

interface IProps {
  repository: RestRepository<any>
  paging: IPaging | null | undefined
}

/**
 * ExcelDownloadButton is a React functional component that provides a button
 * for triggering the download of an Excel file. It utilizes a repository
 * passed in through props to perform the download action.
 *
 * @param {IProps} props - The properties object that includes the repository.
 * @returns {React.ReactElement} - The rendered button element with an Excel download functionality.
 */
const ExcelDownloadButton: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { repository, paging } = props

  const apiAction = useApiAction<any>({ repository })

  const handleDownload = useCallback(async () => {
    await apiAction.callAction("export_xlsx", paging)
  }, [paging])

  return (
    <>
      <Button startIcon={<CloudDownload />} onClick={handleDownload}>
        Excel
      </Button>
    </>
  )
}
export default ExcelDownloadButton
