import React, { useCallback, useState } from "react"
import { Box, Card, CardContent, CardMedia, Grid, Typography, useMediaQuery, useTheme } from "@mui/material"
import ViewProperty from "../../ViewProperty"
import { type IRiskWriterFormat } from "../models/IRiskWriterFormat"
import { nameToLabel } from "../../../utilities/form_utility"
import { IRiskInputType } from "../models/IRiskWriterField"
import ViewCurrency from "../../display/ViewCurrency"
import ViewPercent from "../../display/ViewPercent"
import ViewRichText from "../../display/ViewRichText"
import { useRiskWriterOptions } from "../hooks/useRiskWriterOptions"
import type { TRiskWriterFormData } from "../models/TRiskWriterFormData"
import ViewArea from "../../display/ViewArea"
import TruncateText from "../../TruncateText"
import { type IFile, INSPECTION_FILE_ENDPOINT } from "../../../models/service/IFile"
import { useAxiosRequest } from "../../../hooks/useAxiosRequest"
import { RestRepository } from "../../../repositories/RestRepository"
import useEffectAsync from "../../../hooks/useEffectAsync"
import type { IMainModel } from "../../../models/service/IMainModel"
import CheckMark from "../../display/CheckMark"

const filesRepository = new RestRepository<IFile | IMainModel>(INSPECTION_FILE_ENDPOINT)


interface IProps {
  format: IRiskWriterFormat | null | undefined
  units: string | undefined
  formType: "brief" | "building"
  children: TRiskWriterFormData | null
}

/**
 * Renders the inspection data.data.
 *
 * @param {IProps} props - The properties for the component.
 * @param {React.ReactElement} props.children - The brief data to be displayed.
 * @returns {React.ReactElement} - The rendered inspection data.data.
 */
const RiskWriterFormInfo: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children: data, units, formType, format } = props
  const { getOptionValue } = useRiskWriterOptions({ format })
  const [images, setImages] = useState<Record<string, IFile>>({})
  const axiosRequest = useAxiosRequest()

  if (format === undefined || format === null) {
    return <></>
  }

  const form = formType === "brief" ? format.brief_form : format.building_form
  const isSmall = useMediaQuery(useTheme().breakpoints.down("lg"))

  const handleGetFile = useCallback(async (fileId: number) => {
    let file = null
    await axiosRequest.callRequest(async () => {
      file = await (filesRepository as RestRepository<IFile>).read(fileId)
    })
    return file
  }, [])

  /**
   * This is a beast, not sure if this can be refactored.
   */
  useEffectAsync(async () => {
    if (form !== null && data !== null) {
      const images1: Record<string, IFile> = {}
      for (const group of form.content) {
        for (const field of group.fields) {
          const value = data[field.name]
          if (field.input_type === IRiskInputType.IMAGE && value !== undefined && value !== null) {
            const file = await handleGetFile(value as number)
            if (file !== null) {
              images1[field.name] = file
            }
          }
        }
      }
      setImages(images1)
    }
  }, [form, data])

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <Typography variant="h4">{form?.title}</Typography>
      </Box>
      {form?.content.map(group => {
        return (
          <Box key={group.name} sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5">{nameToLabel(group.name, group.title)}</Typography>
              </Grid>
              {group.fields.map(field => {
                // eslint-disable-next-line eqeqeq
                if (field.show_if_name !== null && data?.[field.show_if_name] != field.show_if_value) {
                  return <></>
                }
                return (
                  <Grid key={field.name} item xs={12}>
                    <ViewProperty
                      label={nameToLabel(field.name, field.title)}
                      vertical={field.input_type === IRiskInputType.RICH_TEXT}>
                      <>
                        {field.input_type === IRiskInputType.SELECT &&
                          field.options !== null &&
                          getOptionValue(field.options, data?.[field.name] ?? "Not Set")}
                        {field.input_type === IRiskInputType.CURRENCY && (
                          <ViewCurrency>{data?.[field.name] as number}</ViewCurrency>
                        )}
                        {field.input_type === IRiskInputType.CHECKBOX && (
                          <CheckMark value={data?.[field.name]} />
                        )}
                        {field.input_type === IRiskInputType.PERCENT && (
                          <ViewPercent>{data?.[field.name] as number}</ViewPercent>
                        )}
                        {field.input_type === IRiskInputType.RICH_TEXT && (
                          <ViewRichText>{data?.[field.name] as string}</ViewRichText>
                        )}
                        {field.input_type === IRiskInputType.TEXT && (data?.[field.name] ?? "Not Set")}
                        {field.input_type === IRiskInputType.FLOAT && (data?.[field.name] ?? 0)}
                        {field.input_type === IRiskInputType.INTEGER && (data?.[field.name] ?? 0)}
                        {field.input_type === IRiskInputType.YEAR && (data?.[field.name] ?? "Not Set")}
                        {field.input_type === IRiskInputType.AREA && (
                          <ViewArea units={units}>{(data?.[field.name] as number) ?? 0}</ViewArea>
                        )}
                        {field.input_type === IRiskInputType.IMAGE && (
                          <Card sx={{ maxWidth: isSmall ? "calc(100% - 40px)" : 600 }}>
                            {images[field.name] !== undefined && (
                              <CardMedia
                                sx={{ minHeight: 300, width: 400 }}
                                image={images[field.name].file_thumbnail?.read_url}
                                title={images[field.name].caption}
                              />
                            )}
                            <CardContent>
                              <Grid container spacing={2} alignItems="center">
                                <Grid item xs>
                                  <Typography variant="body2" color="text.secondary">
                                    <TruncateText>{images[field.name]?.caption ?? "Not Set"}</TruncateText>
                                  </Typography>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        )}
                      </>
                    </ViewProperty>
                  </Grid>
                )
              })}
            </Grid>
          </Box>
        )
      })}
    </>
  )
}

export default RiskWriterFormInfo
