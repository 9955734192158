import React from "react"
import LoginLayout from "../../../shared/layouts/LoginLayout"
import LoginPage from "../../../shared/pages/auth/LoginPage"
import PasswordResetPage from "../../../shared/pages/auth/PasswordResetPage"
import {
  DASHBOARD_URL,
  INSPECTION_BUILDING_EDIT_URL,
  INSPECTION_BUILDING_VIEW_URL,
  INSPECTION_INDEX_URL,
  INSPECTION_RECOMMENDATION_ADD_URL,
  INSPECTION_RECOMMENDATION_EDIT_URL,
  INSPECTION_RECOMMENDATION_VIEW_URL,
  INSPECTION_VIEW_URL,
  LOGIN_URL,
  PASSWORD_RESET_URL,
  PROFILE_EDIT_URL,
  PROFILE_URL,
} from "./urls"
import DashboardIndexPage from "../../inspect/pages/dashboard/IndexPage"
import ProfileIndexPage from "../../../shared/pages/profile/IndexPage"
import ProfileEditPage from "../../../shared/pages/profile/EditPage"
import InspectionIndexPage from "../pages/inspections/IndexPage"
import InspectionViewPage from "../pages/inspections/ViewPage"
import InspectionRecommendationAddPage from "../../admin/pages/inspection_recommendations/AddPage"
import InspectionRecommendationViewPage from "../pages/inspection_recommendations/ViewPage"
import InspectionRecommendationEditPage from "../pages/inspection_recommendations/EditPage"
import InspectionBuildingViewPage from "../../../shared/components/inspection_buildings/ViewPage"
import InspectionBuildingEditPage from "../../../shared/components/inspection_buildings/EditPage"
import NotFound from "../../../shared/pages/misc/404"
import { createBrowserRouter, type RouteObject } from "react-router-dom"
import { addRoute, routesMerge } from "../../../shared/utilities/routing_utility"
import type { Router as RemixRouter } from "@remix-run/router/dist/router"
import DefaultLayout from "../../../shared/layouts/DefaultLayout"

const routes: RouteObject[] = [
  {
    path: "/",
    element: <LoginLayout title="Login" component={LoginPage} />,
  },
  {
    path: LOGIN_URL,
    element: <LoginLayout title="Login" component={LoginPage} />,
  },
  {
    path: `${PASSWORD_RESET_URL}/*`,
    element: <LoginLayout title="Password Reset" component={PasswordResetPage} />,
  },
  addRoute({ path: DASHBOARD_URL, title: "Dashboard", page: DashboardIndexPage, layout: DefaultLayout }),
  addRoute({ path: `${PROFILE_URL}/*`, title: "Profile", page: ProfileIndexPage, layout: DefaultLayout }),
  addRoute({
    path: `${PROFILE_EDIT_URL}/:id`,
    title: "Profile Edit",
    page: ProfileEditPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: `${INSPECTION_VIEW_URL}/:id/*`,
    title: "Inspection",
    page: InspectionViewPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: `${INSPECTION_INDEX_URL}/*`,
    title: "Inspections",
    page: InspectionIndexPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: INSPECTION_RECOMMENDATION_ADD_URL,
    title: "Recommendation Add",
    page: InspectionRecommendationAddPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: `${INSPECTION_RECOMMENDATION_VIEW_URL}/:id/*`,
    title: "Recommendation",
    page: InspectionRecommendationViewPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: `${INSPECTION_RECOMMENDATION_EDIT_URL}/:id`,
    title: "Recommendation",
    page: InspectionRecommendationEditPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: `${INSPECTION_BUILDING_VIEW_URL}/:id/*`,
    title: "Inspection Building",
    page: InspectionBuildingViewPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: `${INSPECTION_BUILDING_EDIT_URL}/:id/*`,
    title: "Inspection Building Edit",
    page: InspectionBuildingEditPage,
    layout: DefaultLayout,
  }),
  addRoute({ path: "*", title: "Not Found", page: NotFound, layout: DefaultLayout }),
]

interface ITheRouter {
  basename?: string
  addonRoutes?: RouteObject[]
}

/**
 * Initializes and returns a RemixRouter instance based on provided properties.
 *
 * @param {ITheRouter} props - An object containing configuration properties for the router.
 * @param {string} props.basename - The base URL for all locations.
 * @param {Array} [props.addonRoutes] - Additional routes to be merged with the default routes.
 *
 * @returns {RemixRouter} - The configured RemixRouter instance.
 */
export const theRouter = (props: ITheRouter): RemixRouter => {
  const { basename, addonRoutes } = props
  if (addonRoutes !== undefined) {
    return createBrowserRouter(routesMerge(routes, addonRoutes), { basename })
  }
  return createBrowserRouter(routes, { basename })
}
