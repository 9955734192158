import React from "react"
import { Link, Typography } from "@mui/material"
import { Link as RouterLink } from "react-router-dom"

interface IProps {
  children: React.JSX.Element | string[] | string
  to?: string | boolean
}

/**
 * Represents a title component for an information viewer.
 *
 * @param {React.FC<IProps>} props - The props object containing the properties for the component.
 * @param {IProps} props.children - The title text to be displayed.
 * @param {IProps} props.to - The URL to navigate to when the title is clicked.
 * @returns {React.ReactElement} The rendered title component.
 */
const ItemViewerTitle: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children, to } = props

  return (
    <Typography variant="h5" color="textSecondary">
      {to !== undefined && to !== false ? (
        <Link component={RouterLink} to={to as string} replace>
          {children}
        </Link>
      ) : (
        children
      )}
    </Typography>
  )
}

export default ItemViewerTitle
