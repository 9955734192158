import React from "react"
import { Box, Paper } from "@mui/material"
import { type SxProps } from "@mui/system"
import { type Theme } from "@mui/material/styles"

interface IProps {
  children?:
    | Array<React.JSX.Element | boolean | string | number>
    | React.JSX.Element
    | boolean
    | string
    | number
    | undefined
  sx?: SxProps<Theme>
  noPaper?: boolean
}

/**
 * Renders a Paper component with a given elevation and children.
 *
 * @param {IProps} props - The props object.
 * @returns {React.ReactElement} The rendered Paper component.
 */
const PaperLocal: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children, sx, noPaper = false } = props

  return noPaper ? (
    <Box sx={sx}>{children}</Box>
  ) : (
    <Paper elevation={2} sx={sx}>
      {children}
    </Paper>
  )
}

export default PaperLocal
