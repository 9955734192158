import React, { useCallback, useContext, useState } from "react"
import PaperLocal from "../containers/PaperLocal"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import { type ILogEntry } from "../../models/service/ILogEntry"
import TableCellDate from "../tables/TableCellDate"
import TableCellData from "../tables/TableCellData"
import TableCellChanges from "../tables/TableCellChanges"
import TableCellIsRiskWriterChanges from "../tables/TableCellIsRiskWriterChanges"
import TableCellCenter from "../tables/TableCellCenter"
import FormatDate from "../format/FormatDate"
import DialogControls from "../DialogControls"
import { DATE_FORMAT } from "../../../config/config"
import { nameToLabel } from "../../utilities/form_utility"
import RiskWriterViewer from "../risk_writer/components/RiskWriterViewer"
import { type IRiskWriterData } from "../risk_writer/models/IRiskWriterData"
import type { IRiskWriterFormat } from "../risk_writer/models/IRiskWriterFormat"
import RiskWriterProvider from "../risk_writer/context/RiskWriterProvider"
import { type IFile, INSPECTION_FILE_ENDPOINT } from "../../models/service/IFile"
import type { IUseApiPagedResultsResponse } from "../../hooks/useApiPagedLocal"
import TableData from "../tables/TableData"
import { RestRepository } from "../../repositories/RestRepository"
import { useAxiosRequest } from "../../hooks/useAxiosRequest"
import type { IMainModel } from "../../models/service/IMainModel"
import type { IPanariskAppContext } from "../../models/app/IPanariskAppContext"
import { PanariskAppContext } from "../../../app/PanariskApp"

const filesRepository = new RestRepository<IFile | IMainModel>(INSPECTION_FILE_ENDPOINT)

const RISK_WRITER_DATA_FIELD = "risk_report_writer_data"

interface IProps {
  pagingResults: IUseApiPagedResultsResponse<ILogEntry>
  format: IRiskWriterFormat | null | undefined
}

/**
 * Renders a table showing the log_entries provided in the `log_entries` prop.
 *
 * @param {IProps} props - The component props.
 * @returns {React.ReactElement} - The rendered table component.
 */
const LogEntriesTable: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { pagingResults, format } = props
  const { data } = pagingResults
  const axiosRequest = useAxiosRequest()
  const { appSettings } = useContext<IPanariskAppContext>(PanariskAppContext)
  const units = appSettings?.serverInfo?.units

  const [selectedHistory, setSelectedHistory] = useState<ILogEntry | null>(null)
  const [open, setOpen] = useState(false)

  const [showRiskWriterChanges, setShowRiskWriterChanges] = useState<boolean>(false)

  const handleOpen = useCallback((logEntry: ILogEntry) => {
    setSelectedHistory(logEntry)
    setOpen(true)
  }, [])

  const handleClose = useCallback(async () => {
    setOpen(false)
    setShowRiskWriterChanges(false)
  }, [])

  const handleToggleRiskWriterChanges = useCallback(() => {
    setShowRiskWriterChanges(show => !show)
  }, [])

  const handleGetFile = useCallback(async (fileId: number) => {
    let file = null
    await axiosRequest.callRequest(async () => {
      file = await (filesRepository as RestRepository<IFile>).read(fileId)
    })
    return file
  }, [])

  const getRiskWriterData = useCallback(
    (index: number): IRiskWriterData | null => {
      if (selectedHistory !== null) {
        return JSON.parse(selectedHistory.changes[RISK_WRITER_DATA_FIELD][index] as string) as IRiskWriterData
      }
      return null
    },
    [selectedHistory],
  )

  return (
    <>
      <PaperLocal sx={{ p: 0 }}>
        <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="xl">
          {selectedHistory !== null && (
            <>
              <DialogTitle>
                Change on <FormatDate value={selectedHistory.timestamp} format={DATE_FORMAT} />
              </DialogTitle>
              {showRiskWriterChanges && (
                <Grid container spacing={2} sx={{ pr: 3, pl: 3 }}>
                  <Grid item xs={6}>
                    <Typography variant="h5">Report Writer Changes</Typography>
                  </Grid>
                  <Grid item xs={6} sx={{ textAlign: "right" }}>
                    <Button onClick={handleToggleRiskWriterChanges}>Show All Changes</Button>
                  </Grid>
                </Grid>
              )}
              <DialogContent>
                {showRiskWriterChanges ? (
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                      <Typography variant="h6">Original</Typography>
                      {format !== undefined && (
                        <Paper>
                          <RiskWriterProvider units={units} format={format} data={getRiskWriterData(0)} getFile={handleGetFile}>
                            <RiskWriterViewer />
                          </RiskWriterProvider>
                        </Paper>
                      )}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Typography variant="h6">Change</Typography>
                      {format !== undefined && (
                        <Paper>
                          <RiskWriterProvider units={units} format={format} data={getRiskWriterData(1)} getFile={handleGetFile}>
                            <RiskWriterViewer />
                          </RiskWriterProvider>
                        </Paper>
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Field</TableCell>
                        <TableCell>Original</TableCell>
                        <TableCell>Change</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.keys(selectedHistory.changes).map((field, index) => (
                        <TableRow key={index}>
                          <TableCell>{nameToLabel(field)}</TableCell>
                          <TableCell>
                            {field !== RISK_WRITER_DATA_FIELD ? (
                              <Box dangerouslySetInnerHTML={{ __html: selectedHistory.changes[field][0] }} />
                            ) : (
                              <Button onClick={handleToggleRiskWriterChanges} size="small">
                                Show Changes
                              </Button>
                            )}
                          </TableCell>
                          <TableCell>
                            {field !== RISK_WRITER_DATA_FIELD && (
                              <Box dangerouslySetInnerHTML={{ __html: selectedHistory.changes[field][1] }} />
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </DialogContent>
              <DialogActions>
                <DialogControls onSave={handleClose} buttonLabel="Close" />
              </DialogActions>
            </>
          )}
        </Dialog>
        <TableData pagingResults={pagingResults}>
          <TableHead>
            <TableCellData field="type" pagingResults={pagingResults}>
              Type
            </TableCellData>
            <TableCellData field="where" pagingResults={pagingResults}>
              Where
            </TableCellData>
            <TableCellData field="changed_by" pagingResults={pagingResults}>
              Changed By
            </TableCellData>
            <TableCellData field="chanages" pagingResults={pagingResults}>
              Changes
            </TableCellData>
            <TableCellCenter field="is_report_writer" pagingResults={pagingResults}>
              Is Report Writer
            </TableCellCenter>
            <TableCellCenter field="date" pagingResults={pagingResults}>
              Date
            </TableCellCenter>
          </TableHead>
          <TableBody>
            {data?.results.map(logEntry => {
              return (
                <TableRow key={logEntry.id}>
                  <TableCellData field="type">{logEntry.action_name}</TableCellData>
                  <TableCellData field="where">{logEntry.model_name}</TableCellData>
                  <TableCellData field="changed_by">{logEntry.actor_name}</TableCellData>
                  <TableCellChanges onClick={handleOpen} field="chanages">
                    {logEntry}
                  </TableCellChanges>
                  <TableCellIsRiskWriterChanges field="is_report_writer">
                    {logEntry.changes}
                  </TableCellIsRiskWriterChanges>
                  <TableCellDate field="date">{logEntry.timestamp}</TableCellDate>
                </TableRow>
              )
            })}
          </TableBody>
        </TableData>
      </PaperLocal>
    </>
  )
}

export default LogEntriesTable
