import { linearProgressClasses } from "@mui/material"

export const styles = {
  progress: {
    height: 10,
    borderRadius: 5,
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
    },
  },
  locations: {
    list: {
      paddingTop: 0,
      "& li": {
        paddingTop: 0,
        "& div": {
          marginTop: 0,
        },
      },
    },
  },
  marginTopPaper: {
    padding: 2,
    marginTop: 20,
    color: "text.secondary",
  },
  languages: {
    chip: {
      marginRight: 1,
      marginBottom: 1,
    },
  },
  consultant: {
    identifier: {
      marginLeft: 3,
      marginRight: 3,
    },
  },
  index: {
    search: {
      padding: 2,
    },
    results: {
      overflow: "hidden",
    },
  },
  page: {
    padding: 2,
  },
  viewProperty: {
    marginTop: 0.3,
    marginBottom: 0.3,
  },
  tabsBox: {
    marginTop: 1,
    borderBottom: 1,
    borderColor: "divider",
  },
  tableSelect: {
    cursor: "pointer",
  },
}
