import React from "react"
import { type ChangeEvent, useCallback, useEffect, useState } from "react"
import Button from "@mui/material/Button"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import { styled } from "@mui/material/styles"
import { type IFileUpload } from "../../models/components/IFileUpload"
import { Grid } from "@mui/material"
import TruncateText from "../TruncateText"

type IUseFileUploadsResponse = [
  fileUploads: IFileUpload[],
  setFileUploads: (files: FileList | File[] | null) => IFileUpload[],
]

/**
 * Converts the given list of files to an array of file uploads.
 * Updates the state with the converted file uploads.
 *
 * @returns {Array} - The array of file uploads.
 */
export const useFileUploads = (): IUseFileUploadsResponse => {
  const [fileUploads, setFileUploads] = useState<IFileUpload[]>([])

  const convertToFileUploads = useCallback((files: FileList | File[] | null) => {
    if (files !== null && files.length > 0) {
      const fileUploads = Array.from(files).map(file => {
        const filename = file.name
        let ext = filename.split(".").pop()
        ext = ext ?? ""
        const name = filename.replace(`.${ext}`, "")
        const fullName = name + "." + ext
        const fileUpload: IFileUpload = { file, name, ext, fullName }
        return fileUpload
      })
      setFileUploads(fileUploads)
      return fileUploads
    }
    return []
  }, [])

  return [fileUploads, convertToFileUploads]
}

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
})

interface IProps {
  onChange: (fileUpload: IFileUpload[]) => void
  clear?: boolean
}

/**
 * Renders a file input element and triggers `onChange` callback when a file is selected.
 *
 * @param {IProps} props - The component props.
 * @returns {React.ReactElement} The rendered file input element.
 */
const MuiFile: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { onChange, clear = false } = props

  const [fileUploads, setFileUploads] = useFileUploads()

  const handleFileChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const fileUploads = setFileUploads(event.target.files)
      onChange(fileUploads)
    },
    [onChange, setFileUploads],
  )

  useEffect(() => {
    if (clear) {
      setFileUploads([])
    }
  }, [clear])

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item>
        <Button
          component="label"
          role={undefined}
          variant="contained"
          color="secondary"
          disableElevation
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}>
          Select Files
          <VisuallyHiddenInput type="file" onChange={handleFileChange} multiple />
        </Button>
      </Grid>
      {fileUploads !== null && fileUploads.length > 0 && (
        <Grid item>
          ({fileUploads.length})
          <TruncateText altText={`<ul>${fileUploads.map(f => `<li>${f.fullName}</li>`).join("")}</ul>`}>
            {fileUploads.map(f => f.fullName).toString()}
          </TruncateText>
        </Grid>
      )}
    </Grid>
  )
}

export default MuiFile
