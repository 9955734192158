import { type IContact } from "./IContact"
import { type IServiceUser } from "./IServiceUser"

export const VENDOR_ENDPOINT = "vendors"

export interface IVendor {
  id: number
  name: string
  lead: IServiceUser | null
  contacts: IContact[]
  notes: string
  created: string
  updated: string
}
