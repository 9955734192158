import React, { useCallback, useContext, useMemo } from "react"
import { Container, Divider, Grid, Tab } from "@mui/material"

import { useApiRead } from "../../../../shared/hooks/useApiRead"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import PageHeader from "../../../../shared/components/pages/PageHeader"
import { INSPECTION_EDIT_URL } from "../../config/urls"
import { type IInspection, INSPECTION_ENDPOINT } from "../../../../shared/models/service/IInspection"
import TabPanel, { useTabPanel } from "../../../../shared/components/tabs/TabPanel"
import ViewProperty from "../../../../shared/components/ViewProperty"
import FormatDate from "../../../../shared/components/format/FormatDate"
import RiskWriterFormView from "../../../../shared/components/risk_writer/components/RiskWriterFormView"
import TabsList from "../../../../shared/components/tabs/TabsList"
import { useOnDemandPaged } from "../../../../shared/hooks/useApiPagedLocal"
import { type IFile, INSPECTION_FILE_ENDPOINT } from "../../../../shared/models/service/IFile"
import { type IMainModel } from "../../../../shared/models/service/IMainModel"
import { type IFilter } from "../../../../shared/models/components/IFilter"
import { useParams } from "react-router-dom"
import FilesIndex from "../../../../shared/components/files/FilesIndex"
import PaperLocal from "../../../../shared/components/containers/PaperLocal"
import InspectionRecommendationIndex from "../inspection_recommendations/components/InspectionRecommendationIndex"
import {
  type IInspectionRecommendation,
  INSPECTION_RECOMMENDATION_ENDPOINT,
} from "../../../../shared/models/service/IInspectionRecommendation"
import ItemViewerDrawer, { useItemEditDialogUrl } from "../../../../shared/components/item_viewer/ItemViewerDrawer"
import LocationInfo from "../locations/components/LocationInfo"
import { ItemPrefixes } from "../../../../config/config"
import ViewListItems from "../../../../shared/components/display/ViewListItems"
import ViewMap from "../../../../shared/components/display/ViewMap"
import { useTranslation } from "react-i18next"
import ServiceUserInfo from "../service_users/components/ServiceUserInfo"
import { LoadingButton } from "@mui/lab"
import { buildApiAction } from "../../../../shared/hooks/useApiAction"
import BuildingInfo from "../buildings/components/BuildingInfo"
import PrimaryButton from "../../../../shared/components/PrimaryButton"
import InspectionBuildingIndex from "../../../../shared/components/inspection_buildings/components/InspectionBuildingIndex"
import {
  type IInspectionBuilding,
  INSPECTION_BUILDING_ENDPOINT,
} from "../../../../shared/models/service/IInspectionBuilding"
import AddBuilding from "../../../../shared/components/inspection_buildings/components/AddBuilding"
import ReportWriterFormatInfo from "../../../../shared/pages/report_format/components/ReportWriterFormatInfo"
import {
  checkHasRiskWriter,
  checkRiskWriterHasBrief,
  riskWriterBriefTitle,
  riskWriterBuildingFormTitle,
} from "../../../../shared/models/service/IReportFormat"
import InspectionBuildingInfo from "../../../../shared/components/inspection_buildings/components/InspectionBuildingInfo"
import InspectionRecInfo from "../inspection_recommendations/components/InspectionRecommendationInfo"
import ViewLoading from "../../../../shared/components/ViewLoading"
import ItemEditorDialog from "../../../../shared/components/item_editor/ItemEditorDialog"
import InspectionRecommendationEdit from "../inspection_recommendations/components/InspectionRecommendationEdit"
import InspectionRecommendationAdd from "../../../../shared/pages/inspection_recommendations/components/InspectionRecommendationAdd"
import InspectionBriefEdit from "../inspection_briefs/components/InspectionBriefEdit"
import InspectionBuildingEdit from "../../../../shared/components/inspection_buildings/components/InspectionBuildingEdit"
import { useAxiosRequest } from "../../../../shared/hooks/useAxiosRequest"
import InspectionIndex from "./components/InspectionIndex"
import LogEntriesTable from "../../../../shared/components/log_entries/LogEntriesTable"
import { type ILogEntry } from "../../../../shared/models/service/ILogEntry"
import { ImageChooserProvider } from "../../../../shared/components/files/ImageChooser"
import RiskWriterValidate from "../../../../shared/components/risk_writer_support/RiskWriterValidate"
import ViewRichText from "../../../../shared/components/display/ViewRichText"
import InspectionCopyFrom from "./components/InspectionCopyFrom"
import ViewRiskWriterInspection, { type IGeneratedContent } from "../../../../shared/components/display/ViewRiskWriterInspection"
import EditInspectionVendorMember from "../../../../shared/components/inspections/EditInspectionVendorMember"
import VendorInfo from "../vendors/components/VendorInfo"
import type { IPanariskAppContext } from "../../../../shared/models/app/IPanariskAppContext"
import { PanariskAppContext } from "../../../../app/PanariskApp"

const inspectRepository = new RestRepository<IInspection | IMainModel>(INSPECTION_ENDPOINT)
const filesRepository = new RestRepository<IFile | IMainModel>(INSPECTION_FILE_ENDPOINT)
const genContentRepository = new RestRepository<IGeneratedContent>(INSPECTION_ENDPOINT)

/**
 * Renders a page to view inspection details.
 *
 * @returns {React.ReactElement} The rendered component.
 */
const ViewPage: React.FC = (): React.ReactElement => {
  const repository = inspectRepository as RestRepository<IInspection>
  const apiRead = useApiRead<IInspection>({ apiFunction: repository.read })
  const { data } = apiRead

  const { appSettings } = useContext<IPanariskAppContext>(PanariskAppContext)
  const units = appSettings?.serverInfo?.units

  const { tab, handleTabChange } = useTabPanel()
  const { id } = useParams()
  const { t } = useTranslation()
  const axiosRequest = useAxiosRequest()

  const apiAction = buildApiAction<IInspection>(repository, id)
  const editBriefUrl = useItemEditDialogUrl(ItemPrefixes.summaryBrief, data?.id)

  const theFilter: IFilter[] = [
    {
      canBeDelete: false,
      title: "Inspection",
      display: "This Inspection",
      field: "inspection",
      value: id,
    },
  ]

  const recsFilter: IFilter[] = [
    {
      canBeDelete: false,
      title: "Inspection Location",
      display: "This Inspection Location",
      field: "inspection_location",
      value: id,
    },
    {
      canBeDelete: false,
      title: "Completed",
      display: "Not Set",
      field: "completed",
      value: "true",
    },
  ]

  const recPagingResults = useOnDemandPaged<IInspectionRecommendation>(
    INSPECTION_RECOMMENDATION_ENDPOINT,
    recsFilter,
    "RECOMMENDATIONS",
  )
  const buildingPagingResults = useOnDemandPaged<IInspectionBuilding>(
    INSPECTION_BUILDING_ENDPOINT,
    theFilter,
    "INSPECTION_BUILDING",
  )
  const filesPagingResults = useOnDemandPaged<IFile>(INSPECTION_FILE_ENDPOINT, theFilter, "FILES")

  const previousInspectionsFilter: IFilter[] = [
    {
      canBeDelete: false,
      title: "Previous Inspections",
      display: "Previous Inspections",
      field: "previous_inspections",
      value: id,
    },
  ]
  const inspectionsPagingResults = useOnDemandPaged<IInspection>(
    INSPECTION_ENDPOINT,
    previousInspectionsFilter,
    "PREVIOUS_INSPECTIONS",
  )

  const hasRiskWriter = useMemo(() => checkHasRiskWriter(data), [data])
  const hasRiskWriterBrief = useMemo(() => checkRiskWriterHasBrief(data), [data])
  const briefTitle = useMemo(() => riskWriterBriefTitle(data), [data])
  const buildingTitle = useMemo(() => riskWriterBuildingFormTitle(data), [data])
  const reportWriterFormat = data?.report_format?.report_writer_format?.data

  const handleToggleCompleted = useCallback(async () => {
    await apiAction.callAction("toggle_completed")
    await apiRead.call()
  }, [apiAction.callAction])

  const logEntriesUrl = useMemo(() => `${INSPECTION_ENDPOINT}/${id}/log_entries`, [id])
  const logEntryPagingResults = useOnDemandPaged<ILogEntry>(logEntriesUrl, [], "LOG_ENTRIES")

  return (
    <Container fixed>
      <>
        <ItemViewerDrawer title={t("Location")} prefix={ItemPrefixes.location} infoView={LocationInfo} />
        <ItemViewerDrawer title={t("Building")} prefix={ItemPrefixes.building} infoView={BuildingInfo} />
        <ItemViewerDrawer title={t("Service User")} prefix={ItemPrefixes.serviceUser} infoView={ServiceUserInfo} />
        <ItemViewerDrawer title={t("Format")} prefix={ItemPrefixes.reportFormat} infoView={ReportWriterFormatInfo} />
        <ItemViewerDrawer title={t("Vendor")} prefix={ItemPrefixes.vendor} infoView={VendorInfo} />
        <ItemViewerDrawer
          title={buildingTitle}
          prefix={ItemPrefixes.inspectionBuilding}
          infoView={InspectionBuildingInfo}
          canEdit={hasRiskWriter}
        />
        <ItemViewerDrawer
          title={t("Recommendation")}
          prefix={ItemPrefixes.inspectionRec}
          infoView={InspectionRecInfo}
          canEdit
        />
        <ItemEditorDialog
          title={t("Recommendation")}
          prefix={ItemPrefixes.inspectionRec}
          itemEdit={InspectionRecommendationEdit}
          itemAdd={InspectionRecommendationAdd}
          onSave={recPagingResults.call}
        />
        <ItemEditorDialog
          title={briefTitle}
          prefix={ItemPrefixes.summaryBrief}
          itemEdit={InspectionBriefEdit}
          onSave={apiRead.call}
        />
        <ItemEditorDialog
          title={t(buildingTitle)}
          prefix={ItemPrefixes.inspectionBuilding}
          itemEdit={InspectionBuildingEdit}
          onSave={buildingPagingResults.call}
        />
      </>
      <ViewLoading loading={axiosRequest.loading} />
      <Grid container spacing={2}>
        {data !== undefined && (
          <>
            <PageHeader
              title={t("Inspection")}
              titlePlural={`${t("Inspection")} ${data.identifier}`}
              toEdit={`${INSPECTION_EDIT_URL}/${data.id}`}
            />
            <Grid item xs={12}>
              <TabsList value={tab} onChange={handleTabChange}>
                <Tab label={t("Overview")} value={0} />
                {hasRiskWriterBrief && <Tab label={t(briefTitle)} value={1} />}
                <Tab label={t("Buildings")} value={2} />
                <Tab label={t("Recommendations")} value={3} />
                <Tab label={t("Files")} value={4} />
                <Tab label={t("Previous Inspections")} value={5} />
                <Tab label={t("History")} value={6} />
              </TabsList>
              <TabPanel value={tab} index={0}>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <PaperLocal>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <ViewProperty label={t("Priority")}>{data.priority?.name}</ViewProperty>
                            </Grid>
                            <Grid item xs={12}>
                              <ViewProperty label={t("Account")}>{data.account?.name}</ViewProperty>
                            </Grid>
                            <Grid item xs={12}>
                              <ViewProperty label={t("Location")}>
                                <ViewListItems prefix={ItemPrefixes.location}>{data.location}</ViewListItems>
                              </ViewProperty>
                            </Grid>
                            <Grid item xs={12}>
                              <ViewProperty label={t("Buildings")}>{data.buildings.length}</ViewProperty>
                            </Grid>
                            <Grid item xs={12}>
                              <ViewProperty label={t("Type")}>{data.inspection_type?.name}</ViewProperty>
                            </Grid>
                            <Grid item xs={12}>
                              <ViewProperty label={t("Report Format")}>
                                <ViewListItems prefix={ItemPrefixes.reportFormat}>{data.report_format}</ViewListItems>
                              </ViewProperty>
                            </Grid>
                          </Grid>
                        </PaperLocal>
                      </Grid>
                      {hasRiskWriter && (
                        <Grid item xs={12}>
                          <PaperLocal>
                            <ViewProperty label={t("Report Writer")}>
                              <RiskWriterValidate inspectionId={data.id} />
                              <ImageChooserProvider
                                itemId={id}
                                field="inspection"
                                fileEndpoint={INSPECTION_FILE_ENDPOINT}>
                                <ViewRiskWriterInspection
                                  data={data}
                                  reportWriterFormat={data?.report_format?.report_writer_format?.data}
                                  title={data.location.name}
                                  buildingPagingResults={buildingPagingResults}
                                  recPagingResults={recPagingResults}
                                  onReloadReport={apiRead.call}
                                  repository={inspectRepository}
                                  genContentRepository={genContentRepository}
                                  filesRepository={filesRepository}
                                />
                              </ImageChooserProvider>
                            </ViewProperty>
                          </PaperLocal>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <PaperLocal>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <ViewProperty label={t("Vendor Due Date")}>
                                <FormatDate value={data.vendor_due_date} />
                              </ViewProperty>
                            </Grid>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                            <Grid item xs={12}>
                              <ViewProperty label={t("Vendor Site Visit Date")}>
                                <FormatDate value={data.vendor_site_visit_date} />
                              </ViewProperty>
                            </Grid>
                            <Grid item xs={12}>
                              <ViewProperty label={t("Vendor Submitted Date")}>
                                <FormatDate value={data.vendor_submitted_date} />
                              </ViewProperty>
                            </Grid>
                            <Grid item xs={12}>
                              <ViewProperty label={t("Vendor Returned Date")}>
                                <FormatDate value={data.vendor_returned_date} />
                              </ViewProperty>
                            </Grid>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                            <Grid item xs={12}>
                              <ViewProperty label={t("Completed")}>
                                <LoadingButton
                                  onClick={handleToggleCompleted}
                                  color={data.completed === null ? "primary" : "error"}
                                  loading={apiAction.saving}>
                                  {data.completed === null ? "Complete" : "Reopen"}
                                </LoadingButton>
                              </ViewProperty>
                            </Grid>
                            <Grid item xs={12}>
                              <ViewProperty label="Completed By" show={data.completed !== null}>
                                <Grid container alignItems="center" spacing={1}>
                                  <Grid item xs>
                                    <FormatDate value={data.completed} />
                                  </Grid>
                                  <Grid item>
                                    <ViewListItems prefix={ItemPrefixes.serviceUser}>{data.completed_by}</ViewListItems>
                                  </Grid>
                                </Grid>
                              </ViewProperty>
                            </Grid>
                          </Grid>
                        </PaperLocal>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <PaperLocal>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <ViewProperty label={t("Vendor")}>
                                <ViewListItems prefix={ItemPrefixes.vendor}>{data.vendor}</ViewListItems>
                              </ViewProperty>
                            </Grid>
                            <Grid item xs={12}>
                              <ViewProperty label={t("Assigned To")}>
                                <Grid container alignItems="center">
                                  <Grid item>
                                    <ViewListItems prefix={ItemPrefixes.serviceUser}>
                                      {data.vendor_member}
                                    </ViewListItems>
                                  </Grid>
                                  <Grid item>
                                    {data.vendor !== null && (
                                      <EditInspectionVendorMember
                                        vendor={data.vendor}
                                        inspection={data}
                                        onChange={apiRead.call}
                                      />
                                    )}
                                  </Grid>
                                </Grid>
                              </ViewProperty>
                            </Grid>
                          </Grid>
                        </PaperLocal>
                      </Grid>
                      <Grid item xs={12}>
                        <PaperLocal>
                          <ViewMap place={data.location} places={data.buildings} />
                        </PaperLocal>
                      </Grid>
                      <Grid item xs={12}>
                        <PaperLocal>
                          <ViewProperty label={t("Notes")}>
                            <ViewRichText>{data.notes}</ViewRichText>
                          </ViewProperty>
                        </PaperLocal>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={tab} index={1}>
                {data.risk_writer_brief_data !== null && hasRiskWriterBrief ? (
                  <>
                    <Grid container sx={{ mb: 2 }}>
                      <Grid item xs />
                      <Grid item>
                        <PrimaryButton to={editBriefUrl} edit>
                          {t(briefTitle)}
                        </PrimaryButton>
                      </Grid>
                    </Grid>
                    <RiskWriterFormView
                      formType="brief"
                      units={units}
                      format={reportWriterFormat}
                      fileEndpoint={INSPECTION_FILE_ENDPOINT}>
                      {data.risk_writer_brief_data}
                    </RiskWriterFormView>
                  </>
                ) : (
                  <>{t("No inspection brief completed.")}</>
                )}
              </TabPanel>
              <TabPanel value={tab} index={2} onChange={buildingPagingResults.call}>
                <InspectionBuildingIndex
                  useInfoView
                  itemDelete
                  format={reportWriterFormat}
                  pagingResults={buildingPagingResults}
                  gotoFilters={[{ field: "inspection", value: data.id }] as IFilter[]}
                  tableActions={<AddBuilding inspection={data} onComplete={buildingPagingResults.call} />}
                />
              </TabPanel>
              <TabPanel value={tab} index={3} onChange={recPagingResults.call}>
                <InspectionRecommendationIndex
                  useInfoView
                  currentInspection={data}
                  pagingResults={recPagingResults}
                  fullPage={false}
                  itemDelete
                />
              </TabPanel>
              <TabPanel value={tab} index={4} onChange={filesPagingResults.call}>
                <FilesIndex
                  parentId={id}
                  parentFieldName="inspection"
                  repository={filesRepository}
                  pagingResults={filesPagingResults}
                />
              </TabPanel>
              <TabPanel value={tab} index={5} onChange={inspectionsPagingResults.call}>
                <InspectionIndex
                  useInfoView
                  pagingResults={inspectionsPagingResults}
                  showHeader={false}
                  parentInspection={data}
                  action={InspectionCopyFrom}
                  onActionChange={apiRead.call}
                />
              </TabPanel>
              <TabPanel value={tab} index={6} onChange={logEntryPagingResults.call}>
                <LogEntriesTable pagingResults={logEntryPagingResults} format={reportWriterFormat} />
              </TabPanel>
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  )
}

export default ViewPage
