import { type IListItem } from "../components/IListItem"

export const OCCUPANCY_ENDPOINT = "occupancies"

export interface IOccupancy extends IListItem {
  id: number
  name: string
  sic_code: string
  notes: string
  enabled: boolean
}
