import React, { useEffect } from "react"
import useAuth from "../hooks/useAuth"
import { useNavigate } from "react-router-dom"
import { LOGIN_URL } from "../../apps/admin/config/urls"

interface IProps {
  component: React.FunctionComponent<any>
  accessDeniedComponent: React.FunctionComponent<any>
  location?: any
  path?: string
  groupNames?: string[]
}

/**
 * This component will wrap a page and check to see if it is accessible.
 *
 * @param {IProps} props see IProps for details.
 * @returns {React.FC<IProps>} the component or empty fragment.
 */
const PrivateRoute: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { component: Component, accessDeniedComponent: AccessDeniedComponent, groupNames, location, ...rest } = props
  const { isLoggedIn, currentUser, isUserInGroups } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    // Check to see if the user is logged in.
    if (isLoggedIn === false && location?.pathname !== LOGIN_URL) {
      navigate(LOGIN_URL, { state: { refer: location?.href } })
    }
  }, [])

  // Check to see if the route has an access group and if the user is in that group.
  if (groupNames !== undefined && isUserInGroups?.(groupNames, currentUser) === false) {
    return <AccessDeniedComponent />
  }

  return <Component {...rest} />
}

export default PrivateRoute
