import React, { useCallback, useState } from "react"
import { type UseFormReturn } from "react-hook-form/dist/types"
import { Grid } from "@mui/material"
import FhMuiHiddenField from "../../../../../shared/components/forms/FhMuiHiddenField"
import FhMuiTextField from "../../../../../shared/components/forms/FhMuiTextField"
import { type ISetting } from "../../../../../shared/models/service/ISetting"
import { requiredRule } from "../../../../../shared/utilities/form_utility"
import useLoadFormData from "../../../../../shared/hooks/useLoadFormData"
import FhMuiCheckboxField from "../../../../../shared/components/forms/FhMuiCheckboxField"
import FhMuiColorField, { useColorField } from "../../../../../shared/components/forms/FhMuiColorField"
import FhMuiSelectField from "../../../../../shared/components/forms/FhMuiSelectField"
import { useSelectOptions } from "../../../../../shared/hooks/useSelectOptions"
import { SelectOptions } from "../../../../../config/config"
import PaperLocal from "../../../../../shared/components/containers/PaperLocal"
import FormatDate from "../../../../../shared/components/format/FormatDate"
import moment from "moment"

interface IProps {
  form: UseFormReturn
  setting?: ISetting | undefined | null
  isEdit?: boolean
}

/**
 * Use this forms to add or edit a setting.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} returns the forms.
 */
const SettingForm: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { setting, form, isEdit = false } = props

  const [dateFormat, setDateFormat] = useState<string | null>(null)

  const [primaryColor, setPrimaryColor] = useColorField({ form, field: "primary_color" })
  const [secondaryColor, setSecondaryColor] = useColorField({ form, field: "secondary_color" })
  const units = useSelectOptions(SelectOptions.UNITS_SYSTEMS)
  const currencies = useSelectOptions(SelectOptions.CURRENCY_CHOICES)

  const handleDateFormatChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setDateFormat(event.target.value)
  }, [])

  useLoadFormData<ISetting>(
    (data: ISetting) => {
      form.setValue("id", data.id)
      form.setValue("company_name", data.company_name)
      form.setValue("enable_all_emails", data.enable_all_emails)
      form.setValue("enable_username_password_login", data.enable_username_password_login)
      form.setValue("units", data.units)
      form.setValue("currency", data.currency)
      form.setValue("date_format", data.date_format)
      setDateFormat(data.date_format)
      setPrimaryColor(data.primary_color)
      setSecondaryColor(data.secondary_color)
    },
    setting,
    isEdit,
    form.setValue,
  )

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <PaperLocal>
            {isEdit && <FhMuiHiddenField control={form.control} />}
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <FhMuiTextField control={form.control} name="company_name" rules={requiredRule()} />
              </Grid>
              <Grid item xs={12}>
                <FhMuiCheckboxField control={form.control} name="enable_all_emails" />
              </Grid>
              <Grid item xs={12}>
                <FhMuiCheckboxField control={form.control} name="enable_username_password_login" />
              </Grid>
              <Grid item xs={12} lg={8}>
                <FhMuiTextField control={form.control} name="date_format" onChange={handleDateFormatChange} />
              </Grid>
              <Grid item xs={12} lg={4}>
                <small>
                  Sample: <FormatDate value={moment()} format={dateFormat} />
                </small>
              </Grid>
              <Grid item xs={12} lg={8}>
                <FhMuiSelectField control={form.control} name="units" items={units} />
              </Grid>
              <Grid item xs={12} lg={8}>
                <FhMuiSelectField control={form.control} name="currency" items={currencies} />
              </Grid>
              <Grid item xs={12} lg={8}>
                <FhMuiColorField form={form} name="primary_color" colorValue={primaryColor} />
              </Grid>
              <Grid item xs={12} lg={8}>
                <FhMuiColorField form={form} name="secondary_color" colorValue={secondaryColor} />
              </Grid>
            </Grid>
          </PaperLocal>
        </Grid>
      </Grid>
    </>
  )
}

export default SettingForm
