import React, { useCallback, useContext, useEffect, useState } from "react"
import { type IRiskWriterSection } from "../../models/IRiskWriterSection"
import { type IRiskWriterSectionData, type ISectionDataImage, SectionType } from "../../models/IRiskWriterSectionData"
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import AlertDialog from "../../../AlertDialog"
import DeleteIcon from "@mui/icons-material/Delete"
import { RiskWriterContext } from "../../context/RiskWriterProvider"
import EditIcon from "@mui/icons-material/Edit"
import type { IFile } from "../../../../models/service/IFile"
import useEffectAsync from "../../../../hooks/useEffectAsync"
import { useDrawerWithUrl } from "../../../containers/DrawerRight"
import { Link } from "react-router-dom"
import UploadIcon from "@mui/icons-material/Upload"
import HtmlToolTip from "../../../HtmlToolTip"
import { ArrowDownward, ArrowUpward } from "@mui/icons-material"
import TruncateText from "../../../TruncateText"

interface IProps {
  section: IRiskWriterSection
  content: IRiskWriterSectionData
  viewerOnly: boolean
}

/**
 * Component for editing content with an image.
 *
 * @param {IProps} props - The component props.
 * @returns {React.ReactElement} - The rendered component.
 */
const ContentImageEditor: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { section, content, viewerOnly } = props

  const [image, setImage] = useState<IFile | null>(null)
  const [caption, setCaption] = useState<string>("")

  const drawerUrl = useDrawerWithUrl()

  const {
    currentContent,
    getFile,
    fileSelected,
    clearFileSelected,
    onChangeContent,
    onDeleteContent,
    onEditContent,
    onFileRequest,
    onFileCaptionChange,
    onContentMove,
  } = useContext(RiskWriterContext)

  const isSmall = useMediaQuery(useTheme().breakpoints.down("lg"))

  const handleSaveImage = useCallback(async () => {
    if (image?.id !== undefined) {
      const image1: IFile = { ...image, caption }
      setImage(image1)
      await onFileCaptionChange?.(image1)
      const sectionContent: ISectionDataImage = { image_id: image.id }
      onChangeContent?.(content.name, sectionContent)
    } else {
      setImage(null)
      onChangeContent?.(content.name, null)
    }
  }, [image, content, caption])

  const handleDeleteContent = useCallback(async () => {
    onDeleteContent?.(content)
  }, [content])

  const handleContentMoveUp = useCallback(() => {
    onContentMove?.(content, "up")
  }, [content])

  const handleContentMoveDown = useCallback(() => {
    onContentMove?.(content, "down")
  }, [content])

  const handleEditContent = useCallback(() => {
    onEditContent?.(section, content)
  }, [section, content])

  const handleCaption = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setCaption(event.target.value)
  }, [])

  /**
   * Load image information when the image is already part of the content.
   */
  useEffectAsync(async () => {
    if (content.section_type === SectionType.IMAGE_EDITOR && getFile !== undefined && content.data !== null) {
      const image1 = await getFile((content.data as ISectionDataImage).image_id)
      if (image1 !== null) {
        setImage(image1)
        setCaption(image1.caption ?? "")
      }
    }
  }, [content.data, getFile])

  /**
   * Load image information when a new file is selected.
   */
  useEffect(() => {
    if (fileSelected?.id !== undefined && content.name === currentContent?.name) {
      setImage(fileSelected)
      setCaption(fileSelected.caption ?? "")
      clearFileSelected?.()
    }
  }, [fileSelected?.id, content.name, currentContent?.name])

  return (
    <>
      {content.section_type === SectionType.IMAGE_EDITOR && (
        <Grid container sx={{ mt: 2, mb: 2 }}>
          <Grid item xs />
          {content.name === currentContent?.name ? (
            <Grid item>
              <Card sx={{ maxWidth: isSmall ? "calc(100% - 40px)" : 600 }}>
                <CardActionArea onClick={onFileRequest}>
                  <CardMedia
                    sx={theme => ({
                      minHeight: 300,
                      width: 400,
                      border: "1px dashed",
                      borderColor: theme.palette.grey["500"],
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    })}
                    image={image?.file_thumbnail?.read_url ?? "/blank.png"}
                    title={image?.caption}>
                    <Box
                      sx={theme => ({
                        width: 400,
                        backgroundColor: theme.palette.grey["500"],
                        textAlign: "center",
                        p: 1,
                      })}>
                      Click to change.
                    </Box>
                  </CardMedia>
                </CardActionArea>
                {image?.id !== undefined && (
                  <Box sx={{ m: 2 }}>
                    <TextField fullWidth label="Caption" value={caption} onChange={handleCaption} />
                  </Box>
                )}
                <CardActions>
                  <Grid container spacing={2}>
                    <Grid item>
                      <HtmlToolTip title={"Move this image up in the section."}>
                        <IconButton color="primary" onClick={handleContentMoveUp} size="small">
                          <ArrowUpward />
                        </IconButton>
                      </HtmlToolTip>
                      <HtmlToolTip title={"Move this image down in the section."}>
                        <IconButton color="primary" onClick={handleContentMoveDown} size="small">
                          <ArrowDownward />
                        </IconButton>
                      </HtmlToolTip>
                    </Grid>
                    <Grid item xs>
                      <AlertDialog
                        buttonText="Remove"
                        buttonIcon={<DeleteIcon />}
                        onYes={handleDeleteContent}
                        message={`Are you sure you want to remove this image?`}
                      />
                    </Grid>
                    <Grid item>
                      <Button startIcon={<UploadIcon />} component={Link} to={drawerUrl("file_upload")}>
                        File Upload
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button onClick={handleSaveImage}>Done</Button>
                    </Grid>
                  </Grid>
                </CardActions>
              </Card>
            </Grid>
          ) : (
            <Grid item>
              <Card sx={{ maxWidth: isSmall ? "calc(100% - 40px)" : 600 }}>
                <CardMedia
                  sx={{ minHeight: 300, width: 400 }}
                  image={image?.file_thumbnail?.read_url ?? "/blank.png"}
                  title={image?.caption}
                />
                <CardContent>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs>
                      <Typography variant="body2" color="text.secondary">
                        <TruncateText>{image?.caption ?? "Not Set"}</TruncateText>
                      </Typography>
                    </Grid>
                    {!viewerOnly && (
                      <Grid item>
                        <IconButton size="small" onClick={handleEditContent}>
                          <EditIcon />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}
          <Grid item xs />
        </Grid>
      )}
    </>
  )
}

export default ContentImageEditor
