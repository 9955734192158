import React from "react"
import { type UseFormReturn } from "react-hook-form/dist/types"
import { Grid } from "@mui/material"
import FhMuiHiddenField from "../../../../../shared/components/forms/FhMuiHiddenField"
import { type IInspection } from "../../../../../shared/models/service/IInspection"
import useLoadFormData from "../../../../../shared/hooks/useLoadFormData"
import SelectFilteredSingle, {
  useSelectFilteredSingle,
} from "../../../../../shared/components/forms/SelectFilteredSingle"
import { type IListItem } from "../../../../../shared/models/components/IListItem"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import { ACCOUNT_ENDPOINT, type IAccount } from "../../../../../shared/models/service/IAccount"
import { type ILocation, LOCATION_ENDPOINT } from "../../../../../shared/models/service/ILocation"
import { type IServiceUser, SERVICE_USER_ENDPOINT } from "../../../../../shared/models/service/IServiceUser"
import FhMuiDateField from "../../../../../shared/components/forms/FhMuiDateField"
import PaperLocal from "../../../../../shared/components/containers/PaperLocal"
import { type IVendor, VENDOR_ENDPOINT } from "../../../../../shared/models/service/IVendor"
import { ItemPrefixes, PriorityTypes } from "../../../../../config/config"
import { type IReportFormat, REPORT_FORMAT_ENDPOINT } from "../../../../../shared/models/service/IReportFormat"
import ItemViewerDrawer from "../../../../../shared/components/item_viewer/ItemViewerDrawer"
import LocationInfo from "../../locations/components/LocationInfo"
import ServiceUserInfo from "../../service_users/components/ServiceUserInfo"
import VendorInfo from "../../vendors/components/VendorInfo"
import ReportWriterFormatInfo from "../../../../../shared/pages/report_format/components/ReportWriterFormatInfo"
import { type IPriority, PRIORITY_ENDPOINT } from "../../../../../shared/models/service/IPriority"
import FhMuiRichTextField from "../../../../../shared/components/forms/FhMuiRichTextField"
import { type IInspectionType, INSPECTION_TYPE_ENDPOINT } from "../../../../../shared/models/service/IInspectionType"

interface IProps {
  form: UseFormReturn
  inspection?: IInspection | undefined | null
  isEdit?: boolean
}

const accountRepository = new RestRepository<IAccount | IListItem>(ACCOUNT_ENDPOINT)
const priorityRepository = new RestRepository<IPriority | IListItem>(PRIORITY_ENDPOINT)
const locationRepository = new RestRepository<ILocation | IListItem>(LOCATION_ENDPOINT)
const reportFormatRepository = new RestRepository<IReportFormat | IListItem>(REPORT_FORMAT_ENDPOINT)
const vendorRepository = new RestRepository<IVendor | IListItem>(VENDOR_ENDPOINT)
const serviceUserRepository = new RestRepository<IServiceUser | IListItem>(SERVICE_USER_ENDPOINT)
const typeRepository = new RestRepository<IInspectionType | IListItem>(INSPECTION_TYPE_ENDPOINT)

/**
 * Use this forms to add or edit an inspection.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} returns the forms.
 */
const InspectionForm: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { inspection, form, isEdit = false } = props

  const [account, setAccount] = useSelectFilteredSingle("account", form)
  const [location, setLocation] = useSelectFilteredSingle("location", form)
  const [priority, setPriority] = useSelectFilteredSingle("priority", form)
  const [reportFormat, setReportFormat] = useSelectFilteredSingle("report_format", form)
  const [vendor, setVendor] = useSelectFilteredSingle("vendor", form)
  const [vendorMember, setVendorMember] = useSelectFilteredSingle("vendor_member", form)
  const [completedBy, setCompletedBy] = useSelectFilteredSingle("completed_by", form)
  const [inspectionType, setInspectionType] = useSelectFilteredSingle("inspection_type", form)

  useLoadFormData<IInspection>(
    (data: IInspection) => {
      form.setValue("id", data.id)
      form.setValue("completed", data.completed)
      form.setValue("notes", data.notes)

      form.setValue("vendor_due_date", data.vendor_due_date)
      form.setValue("vendor_site_visit_date", data.vendor_site_visit_date)
      form.setValue("vendor_submitted_date", data.vendor_submitted_date)
      form.setValue("vendor_returned_date", data.vendor_returned_date)

      setAccount(data.account)
      setLocation(data.location)
      setPriority(data.priority)
      setInspectionType(data.inspection_type)
      setReportFormat(data.report_format)

      setVendor(data.vendor)
      setVendorMember(data.vendor_member)
      setCompletedBy(data.completed_by)
    },
    inspection,
    isEdit,
    form.setValue,
  )

  return (
    <>
      {isEdit && <FhMuiHiddenField control={form.control} />}
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <ItemViewerDrawer title={"Location"} prefix={ItemPrefixes.location} infoView={LocationInfo} />
          <ItemViewerDrawer
            title={"Vendor"}
            prefix={ItemPrefixes.vendor}
            infoView={VendorInfo}
          />
          <ItemViewerDrawer title={"Service User"} prefix={ItemPrefixes.serviceUser} infoView={ServiceUserInfo} />
          <ItemViewerDrawer
            title={"Report Format"}
            prefix={ItemPrefixes.reportFormat}
            infoView={ReportWriterFormatInfo}
          />
          <PaperLocal>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SelectFilteredSingle
                  name="account"
                  defaultValue={account}
                  repository={accountRepository}
                  onChange={setAccount}
                />
              </Grid>
              <Grid item xs={12}>
                {account !== null && (
                  <SelectFilteredSingle
                    name="location"
                    defaultValue={location}
                    filters={[{ field: "accounts", value: account.id }]}
                    repository={locationRepository}
                    onChange={setLocation}
                    infoViewPrefix={ItemPrefixes.location}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <SelectFilteredSingle
                  name="inspection_type"
                  label="Type"
                  defaultValue={inspectionType}
                  repository={typeRepository}
                  onChange={setInspectionType}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectFilteredSingle
                  name="report_format"
                  defaultValue={reportFormat}
                  repository={reportFormatRepository}
                  onChange={setReportFormat}
                  infoViewPrefix={ItemPrefixes.reportFormat}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SelectFilteredSingle
                  name="priority"
                  defaultValue={priority}
                  filters={[{ field: "priority_type", value: PriorityTypes.INSPECTION }]}
                  repository={priorityRepository}
                  onChange={setPriority}
                />
              </Grid>
            </Grid>
          </PaperLocal>
          <PaperLocal sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={7}>
                <FhMuiDateField control={form.control} name="vendor_due_date" />
              </Grid>
              <Grid item xs={12} md={7}>
                <FhMuiDateField control={form.control} name="vendor_site_visit_date" />
              </Grid>
              <Grid item xs={12} md={7}>
                <FhMuiDateField control={form.control} name="vendor_submitted_date" />
              </Grid>
              <Grid item xs={12} md={7}>
                <FhMuiDateField control={form.control} name="vendor_returned_date" />
              </Grid>
            </Grid>
          </PaperLocal>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PaperLocal>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <SelectFilteredSingle
                      name="vendor"
                      defaultValue={vendor}
                      repository={vendorRepository}
                      onChange={setVendor}
                      infoViewPrefix={ItemPrefixes.vendor}
                    />
                  </Grid>
                  {vendor !== null && (
                    <Grid item xs={12}>
                      <SelectFilteredSingle
                        name="vendor_member"
                        label="Assigned To"
                        defaultValue={vendorMember}
                        filters={[{ field: "vendor_members", value: vendor.id }]}
                        repository={serviceUserRepository}
                        onChange={setVendorMember}
                        infoViewPrefix={ItemPrefixes.serviceUser}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} />
                  <Grid item xs={12} md={4}>
                    <FhMuiDateField control={form.control} name="completed" />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <SelectFilteredSingle
                      name="completed_by"
                      defaultValue={completedBy}
                      repository={serviceUserRepository}
                      onChange={setCompletedBy}
                    />
                  </Grid>
                </Grid>
              </PaperLocal>
            </Grid>
            <Grid item xs={12}>
              <PaperLocal>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FhMuiRichTextField control={form.control} name="notes" />
                  </Grid>
                </Grid>
              </PaperLocal>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default InspectionForm
