import React, { useCallback, useState } from "react"
import { useBulkDataAvailable } from "./BulkData"
import { Alert, Button, CircularProgress, Grid, List, Typography } from "@mui/material"
import ListItem from "@mui/material/ListItem"
import CheckIcon from "@mui/icons-material/Check"
import { type IFile } from "../../models/service/IFile"
import TruncateText from "../TruncateText"
import ViewLoading from "../ViewLoading"

const field = "delete"

/**
 * Deletes multiple elements in bulk.
 *
 * @returns {React.ReactElement} - The React element rendered by the method.
 */
const BulkFileDelete: React.FC = (): React.ReactElement => {
  const bulkDataContext = useBulkDataAvailable("delete", "Delete")
  const [files, setFiles] = useState<IFile[]>([])
  const [deleted, setDeleted] = useState<IFile[]>([])

  const handleDelete = useCallback(async () => {
    if (bulkDataContext?.selectedRows !== undefined && bulkDataContext.selectedRows !== null) {
      bulkDataContext.setProcessing(true)
      const filesToDelete: IFile[] = []
      setFiles([])
      setDeleted([])
      for (const selectedRow of bulkDataContext.selectedRows) {
        try {
          const file: IFile = await bulkDataContext.repository?.read(selectedRow)
          if (file !== undefined) {
            filesToDelete.push(file)
            setFiles([...filesToDelete])
          }
        } catch (error) {
          console.log(error)
        }
      }
      for (const file of filesToDelete) {
        try {
          if (file.id !== undefined) {
            await bulkDataContext.repository?.delete(file.id)
            setDeleted(deleted => [...deleted, file])
          }
        } catch (error) {
          console.log(error)
        }
      }
      setFiles([])
      bulkDataContext.setProcessing(false)
      bulkDataContext.onDone?.()
    }
  }, [bulkDataContext?.selectedRows, files, deleted])

  return (
    <>
      {bulkDataContext?.selectedBulk?.value === field && (
        <Grid container spacing={1}>
          {bulkDataContext.selectedRows?.length !== undefined && bulkDataContext.selectedRows.length > 0 ? (
            <>
              <Grid item xs={12}>
                <Alert severity="warning">
                  Are you sure you want to delete <strong>{bulkDataContext.selectedRows?.length}</strong> files?
                </Alert>
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "right" }}>
                <Button onClick={handleDelete}>Delete Files</Button>
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Alert severity="info">No files selected.</Alert>
            </Grid>
          )}
          {bulkDataContext.processing && (
            <>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography variant="h5">Deleting Files</Typography>
              </Grid>
              <Grid item xs={12}>
                <ViewLoading
                  loading={true}
                  inline
                  progress={deleted.length === 0 ? undefined : (deleted.length / files.length) * 100}
                  message="Deleting..."
                />
              </Grid>
              <Grid item xs={12}>
                <List>
                  {files.map((file: IFile) => (
                    <ListItem
                      key={file.id}
                      secondaryAction={
                        <>{deleted.some(f1 => f1.id === file.id) ? <CheckIcon /> : <CircularProgress size={20} />}</>
                      }>
                      <TruncateText>{file.name}</TruncateText>
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </>
          )}
        </Grid>
      )}
    </>
  )
}

export default BulkFileDelete
