import React, { useContext, useEffect } from "react"
import Helmet from "react-helmet"
import { PanariskAppContext } from "../../app/PanariskApp"
import { CssBaseline } from "@mui/material"
import { Navigate, useLocation } from "react-router-dom"

/**
 * Removes the trailing slash from the pathname in the current location.
 *
 * @param {object} rest - Additional props passed to the `Navigate` component.
 * @returns {React.ReactElement | null} - The modified `Navigate` component with the trailing slash removed from pathname.
 */
export const RemoveTrailingSlash: React.FC = ({ ...rest }: object): React.ReactElement | null => {
  const location = useLocation()

  // If the last character of the url is '/'
  if (location.pathname.match("/.*/$") != null) {
    return (
      <Navigate
        replace
        {...rest}
        to={{
          pathname: location.pathname.replace(/\/+$/, ""),
          search: location.search,
        }}
      />
    )
  } else return null
}

interface IProps {
  title?: string
}

/**
 * Sets the document title, adds necessary meta tags and CSS stylesheets using the Helmet library.
 *
 * @param {IProps} props - The props object containing the title property.
 * @returns {React.ReactElement} - The Helmet component with necessary tags and stylesheets.
 */
const AppHelmet: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { title } = props
  const { appSettings, siteName } = useContext(PanariskAppContext)

  useEffect(() => {
    if (title !== undefined && appSettings?.serverInfo?.company_name !== undefined) {
      window.document.title = `${siteName} | ${title}`
    }
  }, [title])

  return (
    <>
      <Helmet>
        <title>{title ?? " "}</title>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
      </Helmet>
      <CssBaseline />
      <RemoveTrailingSlash />
    </>
  )
}

export default AppHelmet
