import React, { useCallback } from "react"
import { Container, Grid } from "@mui/material"
import { useForm } from "react-hook-form"
import { RestRepository } from "../../repositories/RestRepository"
import FormHeader from "../../components/forms/FormHeader"
import ProfileForm from "./components/ProfileForm"
import { useApiRead } from "../../hooks/useApiRead"
import useApiEdit, { type IUseApiEditProps } from "../../hooks/useApiEdit"
import FormBox from "../../components/forms/FormBox"
import FormErrors from "../../components/forms/FormErrors"
import type IProfile from "../../models/users/IProfile"
import { PROFILES_ENDPOINT } from "../../models/users/IProfile"
import useAuth from "../../hooks/useAuth"
import { useTranslation } from "react-i18next"

const repository = new RestRepository<IProfile>(PROFILES_ENDPOINT)

/**
 * This page will allow editing of a profile.
 *
 * @returns {React.FC} the profile edit page.
 */
const EditPage: React.FC = (): React.ReactElement => {
  const form = useForm()
  const { i18n } = useTranslation()
  const { currentUser } = useAuth()
  const itemId = currentUser?.user.profile.id
  const apiRead = useApiRead<IProfile>({ apiFunction: repository.read, itemId })

  const props: IUseApiEditProps<IProfile> = { apiFunction: repository.edit, setError: form.setError }
  const apiEdit = useApiEdit<IProfile>(props)

  const handleSave = useCallback(
    async (profile: IProfile) => {
      await apiEdit.handleEdit(profile)
      await i18n.changeLanguage(profile.language)
    },
    [apiEdit.handleEdit],
  )

  return (
    <Container fixed>
      <Grid container spacing={2}>
        <FormHeader title="Edit Profile" loading={apiEdit.saving || apiRead.loading} errors={form.formState.errors} />
        <FormErrors apiError={apiRead.error} connectionError={apiEdit.connectionError} />
        <FormBox form={form} onSubmit={handleSave}>
          <ProfileForm profile={apiRead.data} form={form} isEdit />
        </FormBox>
      </Grid>
    </Container>
  )
}

export default EditPage
