import { type IReportFormat } from "./IReportFormat"
import { type IServiceUser } from "./IServiceUser"
import { type IMainModel } from "./IMainModel"
import { type IPriority } from "./IPriority"
import type { IRiskScoreCategory } from "./IRiskScoreCategory"
import type { IRecommendationType } from "./IRecommendationType"

export const RECOMMENDATION_ENDPOINT = "recommendations"

export interface IRecommendation extends IMainModel {
  id: number
  title: string
  body: string
  loss_estimate: string
  comment: string
  order: number

  report_format: IReportFormat | null
  owner: IServiceUser | null

  priority: IPriority | null
  type: IRecommendationType | null

  risk_score_deduction: number
  risk_score_category: IRiskScoreCategory | null

  created: string
  updated: string
}
