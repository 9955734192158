import React, { useCallback, useState } from "react"
import { Button, Grid, TextField } from "@mui/material"
import { useFilterDataAvailable } from "./FiltersData"
import AddIcon from "@mui/icons-material/Add"
import { type IFilter } from "../../models/components/IFilter"

interface IProps {
  field?: string
  children?: React.JSX.Element[]
}

/**
 * Filters a based on a keyword search.
 *
 * @param {IProps} props - The props object containing the field to be filtered.
 * @returns {React.ReactElement} - The filtered search component.
 */
const FilterSearch = (props: IProps): React.ReactElement => {
  const { field = "search" } = props
  const [value, setValue] = useState<string>("")

  const filtersDataContext = useFilterDataAvailable(field, "Search")

  const handleAddFilter = useCallback(() => {
    if (value !== null) {
      const filter: IFilter = { title: "Search", field, value, display: value }
      filtersDataContext?.addFilter(filter)
    }
  }, [field, value])

  const handleSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }, [])

  return (
    <>
      {filtersDataContext?.selectedFilter?.value === field && (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField fullWidth value={value} onChange={handleSearch} label="Search" />
          </Grid>
          <Grid item xs />
          <Grid item>
            <Button startIcon={<AddIcon />} onClick={handleAddFilter}>
              Add Filter
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default FilterSearch
