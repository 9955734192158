import React from "react"
import { styled, Tooltip, tooltipClasses, type TooltipProps } from "@mui/material"

interface IProps extends TooltipProps {
  noBackground?: boolean
}

const HtmlTooltip = styled(({ className, noBackground = false, ...props }: IProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, noBackground }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: noBackground !== undefined && noBackground ? "transparent" : "#ddd",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    maxWidth: 600,
    fontSize: theme.typography.pxToRem(14),
    border: noBackground !== undefined && noBackground ? "none" : "1px solid #cdcdcd",
    ...(noBackground !== undefined && noBackground ? { padding: 0 } : {}),
  },
}))

export default HtmlTooltip
