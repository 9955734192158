import React from "react"
import TableData from "../tables/TableData"
import TableActions from "../tables/TableActions"
import FileAddEdit from "./FileAddEdit"
import { Button, TableBody, TableHead, TableRow } from "@mui/material"
import TableCellData from "../tables/TableCellData"
import { type IFile } from "../../models/service/IFile"
import TableCellTruncate from "../tables/TableCellTruncate"
import TableCellDate from "../tables/TableCellDate"
import TableCellAction from "../tables/TableCellAction"
import FileDownloadButton from "./FileDownloadButton"
import FileDelete from "./FileDelete"
import FileViewerDialog from "./FileViewerDialog"
import { type RestRepository } from "../../repositories/RestRepository"
import { type IUseApiPagedResultsResponse } from "../../hooks/useApiPagedLocal"
import BulkData from "../bulk/BulkData"
import BulkFileDelete from "../bulk/BulkFileDelete"
import ListData from "../lists/ListData"
import ListDataItem from "../lists/ListDataItem"
import { type IMainModel } from "../../models/service/IMainModel"
import FiltersData from "../filters/FiltersData"
import FilterImages from "../filters/FilterImages"
import FilterReports from "../filters/FilterReports"
import { useDrawerWithUrl } from "../containers/DrawerRight"
import UploadIcon from "@mui/icons-material/Upload"
import { Link } from "react-router-dom"
import TableCellFileTags from "../tables/TableCellFileTags"
import type { IFilter } from "../../models/components/IFilter"
import prettyBytes from "pretty-bytes"
import TableCellCheckMark from "../tables/TableCellCheckMark"
import BulkFileCopy from "../bulk/BulkFileCopy"

interface IProps {
  parentId: number | string | undefined
  parentFieldName: string
  repository: RestRepository<IFile | IMainModel>
  pagingResults: IUseApiPagedResultsResponse<IFile> | undefined
  gotoFilters?: IFilter[]
}

/**
 * Function that renders a table to display files and perform related actions.
 *
 * @param {IProps} props - The props object containing the following properties:
 *   - parentId {string} - The ID of the parent entity.
 *   - parentFieldName {string} - The name of the field in the parent entity that contains the files.
 *   - repository {string} - The repository name where the files are stored.
 *   - pagingResults {Object} - The paging results object containing the data to be displayed.
 * @returns {React.ReactElement} - The rendered table component.
 */
const FilesIndex: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { parentId, parentFieldName, repository, pagingResults, gotoFilters } = props
  const drawerUrl = useDrawerWithUrl()

  return (
    <>
      {pagingResults !== undefined && (
        <>
          <TableData
            pagingResults={pagingResults}
            itemRepository={repository}
            storageSuffix="files"
            gotoFilters={gotoFilters}>
            <BulkData>
              <BulkFileDelete />
              <BulkFileCopy />
            </BulkData>
            <FiltersData pagingResults={pagingResults}>
              <FilterImages field="images" />
              <FilterReports />
            </FiltersData>
            <TableActions>
              <Button startIcon={<UploadIcon />} component={Link} to={drawerUrl("file_upload")}>
                File Upload
              </Button>
              <FileAddEdit
                parentId={parentId}
                useDropzone
                onChange={pagingResults.call}
                repository={repository}
                fieldName={parentFieldName}
                openCloseWithUrl={true}
              />
            </TableActions>
            <TableHead>
              <TableCellData field="id" pagingResults={pagingResults}>ID</TableCellData>
              <TableCellData field="name" pagingResults={pagingResults}>Name</TableCellData>
              <TableCellData field="ext" pagingResults={pagingResults}>Ext</TableCellData>
              <TableCellData field="size" pagingResults={pagingResults}>Size</TableCellData>
              <TableCellData field="exists" pagingResults={pagingResults}>Exists</TableCellData>
              <TableCellData field="file_tags">File Tags</TableCellData>
              <TableCellData field="caption" pagingResults={pagingResults}>Caption</TableCellData>
              <TableCellData field="created" pagingResults={pagingResults}>Created</TableCellData>
              <TableCellAction field="action">Action</TableCellAction>
            </TableHead>
            <TableBody>
              {pagingResults.data?.results.map((item: IFile) => (
                <TableRow key={item.id}>
                  <TableCellData field="id">{item.id}</TableCellData>
                  <TableCellTruncate field="name">{item.name}</TableCellTruncate>
                  <TableCellData field="ext">{item.ext}</TableCellData>
                  <TableCellCheckMark field="exists">{item.exists}</TableCellCheckMark>
                  <TableCellData field="size">{prettyBytes(item.size ?? 0)}</TableCellData>
                  <TableCellFileTags field="file_tags">{item.file_tags}</TableCellFileTags>
                  <TableCellTruncate field="caption">{item.caption}</TableCellTruncate>
                  <TableCellDate field="created">{item.created}</TableCellDate>
                  <TableCellAction field="action">
                    <FileAddEdit
                      parentId={parentId}
                      useEdit
                      selectedFile={item}
                      onChange={pagingResults.call}
                      repository={repository}
                      fieldName={parentFieldName}
                    />
                    <FileDownloadButton file={item} />
                    <FileDelete repository={repository} item={item} onChange={pagingResults.call} />
                    <FileViewerDialog selectedFile={item} files={pagingResults.data?.results} />
                  </TableCellAction>
                </TableRow>
              ))}
            </TableBody>
          </TableData>

          <ListData pagingResults={pagingResults}>
            {pagingResults.data?.results.map((item: IFile) => (
              <ListDataItem key={item.id} title={item.name}></ListDataItem>
            ))}
          </ListData>
        </>
      )}
    </>
  )
}

export default FilesIndex
