import React from "react"

interface IProps {
  children: React.JSX.Element[] | React.JSX.Element | undefined | null
}

/**
 * Renders a TableCell for action buttons.
 *
 * @param {IProps} props - The component props.
 * @returns {React.ReactElement} - The rendered TableCell with action buttons.
 */
const TableActions: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children } = props

  return <>{children}</>
}

export default TableActions
