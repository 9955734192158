import React from "react"
import { Grid } from "@mui/material"

import { useApiRead } from "../../../../../shared/hooks/useApiRead"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import { type IServiceUser, SERVICE_USER_ENDPOINT } from "../../../../../shared/models/service/IServiceUser"
import ViewProperty from "../../../../../shared/components/ViewProperty"
import { type IInfoViewProps } from "../../../../../shared/components/item_viewer/ItemViewerDrawer"
import ViewPhone from "../../../../../shared/components/display/ViewPhone"
import ViewEmail from "../../../../../shared/components/display/ViewEmail"
import ViewRichText from "../../../../../shared/components/display/ViewRichText"
import FormatDate from "../../../../../shared/components/format/FormatDate"
import CheckMark from "../../../../../shared/components/display/CheckMark"
import { SERVICE_USER_VIEW_URL } from "../../../config/urls"
import ItemViewerTitle from "../../../../../shared/components/item_viewer/ItemViewerTitle"
import useEffectAsync from "../../../../../shared/hooks/useEffectAsync"

const repository = new RestRepository<IServiceUser>(SERVICE_USER_ENDPOINT)

/**
 * Renders a page to view locations impairment details.
 *
 * @param {IInfoViewProps} props See props for details
 * @returns {React.ReactElement} The rendered component.
 */
const ServiceUserInfo: React.FC<IInfoViewProps> = (props: IInfoViewProps): React.ReactElement => {
  const { infoId } = props
  const apiRead = useApiRead<IServiceUser>({ apiFunction: repository.read, itemId: infoId })
  const { data } = apiRead

  useEffectAsync(async () => {
    if (data !== undefined && data !== null) {
      await apiRead.call()
    }
  }, [infoId])

  return (
    <>
      {data !== undefined && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ItemViewerTitle to={`${SERVICE_USER_VIEW_URL}/${infoId}`}>
              {data.first_name} {data.last_name}
            </ItemViewerTitle>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label="Phone">
              <ViewPhone ext={data.phone_ext}>{data.phone}</ViewPhone>
            </ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label="Phone 2">
              <ViewPhone ext={data.phone_2_ext}>{data.phone_2}</ViewPhone>
            </ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label="Email">
              <ViewEmail>{data.email}</ViewEmail>
            </ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label="Email 2">
              <ViewEmail>{data.email_2}</ViewEmail>
            </ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label="Last Login">
              <FormatDate value={data.last_login} />
            </ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label="Enabled User">
              <CheckMark value={data.has_enabled_user_account} />
            </ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label="Notes" vertical>
              <ViewRichText>{data.notes}</ViewRichText>
            </ViewProperty>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default ServiceUserInfo
