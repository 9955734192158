import React from "react"
import {
  type IInspectionRecommendation,
  INSPECTION_RECOMMENDATION_ENDPOINT,
} from "../../../../shared/models/service/IInspectionRecommendation"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import { type IUseApiPagedResultsProps, useApiPagedLocal } from "../../../../shared/hooks/useApiPagedLocal"
import InspectionRecommendationIndex from "./components/InspectionRecommendationIndex"
import useLocationFilter from "../../../../shared/hooks/useLocationFilter"
import { CHART_FILTER_TITLE } from "../../../../shared/components/widgets/prebuilt/ChartBuilderWidget"
import ItemViewerDrawer from "../../../../shared/components/item_viewer/ItemViewerDrawer"
import { ItemPrefixes } from "../../../../config/config"
import LocationInfo from "../locations/components/LocationInfo"
import { useTranslation } from "react-i18next"

const repository = new RestRepository<IInspectionRecommendation>(INSPECTION_RECOMMENDATION_ENDPOINT)

/**
 * Renders the index page of the application.
 *
 * @returns {React.ReactElement} - The rendered index page component.
 */
const IndexPage: React.FC = (): React.ReactElement => {
  const props: IUseApiPagedResultsProps<IInspectionRecommendation> = {
    apiFunction: repository.findAll,
    dontCallOnMount: true,
  }
  const pagingResults = useApiPagedLocal<IInspectionRecommendation>(props)
  useLocationFilter(pagingResults, CHART_FILTER_TITLE)
  const { t } = useTranslation()

  return (
    <>

      <ItemViewerDrawer title={t("Location")} prefix={ItemPrefixes.location} infoView={LocationInfo} />
      <InspectionRecommendationIndex pagingResults={pagingResults} />
    </>
  )
}

export default IndexPage
