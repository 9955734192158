import React from "react"
import { TableCell } from "@mui/material"
import { type IUseApiPagedResultsResponse } from "../../hooks/useApiPagedLocal"
import { type IMainModel } from "../../models/service/IMainModel"

interface IProps {
  children?: React.JSX.Element[] | React.JSX.Element | string | undefined | null
  field?: string
  pagingResults?: IUseApiPagedResultsResponse<IMainModel>
}

/**
 * Renders a TableCell for action buttons.
 *
 * @param {IProps} props - The component props.
 * @returns {React.ReactElement} - The rendered TableCell with action buttons.
 */
const TableCellAction: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children = "Actions" } = props

  return <TableCell sx={{ textAlign: "center" }}>{children}</TableCell>
}

export default TableCellAction
