import React, { useCallback, useState } from "react"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material"
import Top5List from "../../display/Top5List"
import { RestRepository } from "../../../repositories/RestRepository"
import { LOCATION_IMPAIRMENT_ENDPOINT } from "../../../models/service/ILocationImpairment"
import type { IPanariskWidget, IPanariskWidgetProps } from "../models/IPanariskWidgetProps"
import type { IListRecItem } from "../../../models/components/IListRecItem"
import DialogControls from "../../DialogControls"
import WidgetMenu from "../components/WidgetMenu"
import PaperLocal from "../../containers/PaperLocal"
import { type IListItem } from "../../../models/components/IListItem"
import { INSPECTION_RECOMMENDATION_ENDPOINT } from "../../../models/service/IInspectionRecommendation"
import useProfileStorage from "../../../hooks/useProfileStorage"
import { INSPECTION_ENDPOINT } from "../../../models/service/IInspection"
import { POLICY_ENDPOINT } from "../../../models/service/IPolicy"
import useEffectAsync from "../../../hooks/useEffectAsync"
import { useAxiosRequest } from "../../../hooks/useAxiosRequest"
import { type IPagedResults } from "../../../models/components/IPagedResults"
import { type IPaging } from "../../../models/components/IPaging"
import { type IMainModel } from "../../../models/service/IMainModel"
import SelectFilteredSingle from "../../forms/SelectFilteredSingle"
import { FILTER_BOOKMARK_ENDPOINT, type IFilterBookmark } from "../../../models/service/IFilterBookmark"

export const filterBookmarkRepository = new RestRepository<IListItem>(FILTER_BOOKMARK_ENDPOINT)

export enum Models {
  LOCATION_IMPAIRMENT,
  INSPECTION_RECOMMENDATION,
  INSPECTION,
  POLICY,
}

export interface ITop5ListState {
  title: string
  description: string
  model: Models | null
  filterBookmark?: IFilterBookmark | null
}

export interface ITop5ListEntry extends IMainModel, Record<string, any> {}

/**
 * Retrieves a repository based on the given model.
 *
 * @param {Models | undefined} model - The model to retrieve the repository for.
 * @returns {RestRepository<IListRecItem>} The repository for the given model, or null if not found.
 */
const getRepo = (model: Models | null): RestRepository<ITop5ListEntry> | null => {
  if (model === Models.INSPECTION_RECOMMENDATION) {
    return new RestRepository<ITop5ListEntry>(INSPECTION_RECOMMENDATION_ENDPOINT)
  }
  if (model === Models.LOCATION_IMPAIRMENT) {
    return new RestRepository<ITop5ListEntry>(LOCATION_IMPAIRMENT_ENDPOINT)
  }
  if (model === Models.INSPECTION) {
    return new RestRepository<ITop5ListEntry>(INSPECTION_ENDPOINT)
  }
  if (model === Models.POLICY) {
    return new RestRepository<ITop5ListEntry>(POLICY_ENDPOINT)
  }
  return null
}

export const AVAILABLE_MODELS: IListItem[] = [
  {
    id: Models.LOCATION_IMPAIRMENT,
    name: "Location Impairment",
  },
  { id: Models.INSPECTION_RECOMMENDATION, name: "Inspection Recommendation" },
  { id: Models.INSPECTION, name: "Inspection" },
  { id: Models.POLICY, name: "Policy" },
]

const TOP_5_LIST_KEY = "TOP_5_LIST_KEY"

/**
 * Generates a storage key based on the given ID.
 *
 * @param {string} id - The ID used to generate the storage key.
 * @returns {string} The generated storage key.
 */
const storageKey = (id: string): string => {
  return `${TOP_5_LIST_KEY}-${id}`
}

const DEFAULT_STATE: ITop5ListState = {
  title: "Top 5 List",
  description: "Build custom top 5 list.",
  model: null,
}

/**
 * Renders a widget that displays a list of top 5 list sorted by priority.
 *
 * @param {IPanariskWidgetProps} props - The properties for the widget.
 * @returns {React.ReactElement} The rendered widget.
 * @example
 * <Top5ListWidget id={123} />
 */
const Top5ListWidget: IPanariskWidget = (props: IPanariskWidgetProps): React.ReactElement => {
  const { id, onMoveUp, onMoveDown, onDelete } = props

  const profileStorage = useProfileStorage<ITop5ListState>(storageKey(id), DEFAULT_STATE)
  const [top5ListState, setTop5ListState] = profileStorage.data

  const [openDialog, setOpenDialog] = useState<boolean>(false)

  const axiosRequest = useAxiosRequest()
  const [data, setData] = useState<IPagedResults<ITop5ListEntry> | undefined>()

  const handleLoadData = useCallback(async () => {
    if (top5ListState !== null) {
      await axiosRequest.callRequest(async () => {
        const repository = getRepo(top5ListState.model)
        const paging: IPaging = { ...top5ListState.filterBookmark?.paging, limit: 5 }
        const response = await repository?.findAll(paging)
        setData(response)
      })
    }
  }, [top5ListState])

  const handleChangeState = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (top5ListState !== null) {
        setTop5ListState({
          ...top5ListState,
          [event.target.name]: event.target.value,
        })
      }
    },
    [top5ListState],
  )


  const handleFilterBookmark = useCallback(
    async (item: IListItem | null) => {
      if (item !== null) {
        const filterBookmark1 = (await filterBookmarkRepository.read(item.id)) as IFilterBookmark
        if (top5ListState !== null) {
          setTop5ListState({ ...top5ListState, filterBookmark: filterBookmark1 })
        }
      } else {
        if (top5ListState !== null) {
          setTop5ListState({ ...top5ListState, filterBookmark: null })
        }
      }
    },
    [handleChangeState, top5ListState],
  )

  const handleCloseDialog = useCallback(async () => {
    setOpenDialog(false)
    await handleLoadData()
  }, [handleLoadData])

  const handleOpenDialog = useCallback(async () => {
    setOpenDialog(true)
  }, [])

  useEffectAsync(async () => {
    if (top5ListState?.model !== undefined) {
      await handleLoadData()
    }
  }, [top5ListState?.model])

  return (
    <Grid item xs={12} md={5}>
      <PaperLocal>
        <Grid container spacing={0} alignItems="center">
          <Grid item xs>
            <h4>{top5ListState?.title}</h4>
          </Grid>
          <Grid item>
            <WidgetMenu>
              <MenuItem onClick={handleLoadData}>Refresh</MenuItem>
              <Divider />
              <MenuItem onClick={onMoveUp}>Move Up</MenuItem>
              <MenuItem onClick={onMoveDown}>Move Down</MenuItem>
              <Divider />
              <MenuItem onClick={handleOpenDialog}>Options...</MenuItem>
              <Divider />
              <MenuItem onClick={onDelete}>Delete Widget</MenuItem>
            </WidgetMenu>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">{top5ListState?.description}</Typography>
          </Grid>
        </Grid>
        {top5ListState !== null && (
          <Top5List loading={axiosRequest.loading} listState={top5ListState} data={data} />
        )}
      </PaperLocal>
      <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="md">
        <DialogTitle>Top 5 List Options</DialogTitle>
        {top5ListState !== null && (
          <DialogContent>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12} lg={5}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Title"
                      name="title"
                      value={top5ListState.title}
                      onChange={handleChangeState}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      select
                      label="Source"
                      name="model"
                      value={top5ListState.model}
                      onChange={handleChangeState}>
                      {AVAILABLE_MODELS.map(model => {
                        return (
                          <MenuItem key={model.id} value={model.id}>
                            {model.name}
                          </MenuItem>
                        )
                      })}
                    </TextField>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={7}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Description"
                      name="description"
                      value={top5ListState.description}
                      onChange={handleChangeState}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SelectFilteredSingle
                      name="filter_bookmark"
                      defaultValue={top5ListState.filterBookmark}
                      repository={filterBookmarkRepository}
                      onChange={handleFilterBookmark}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={5}>
              </Grid>
            </Grid>
          </DialogContent>
        )}
        <DialogActions>
          <DialogControls onSave={handleCloseDialog} buttonLabel="Done" />
        </DialogActions>
      </Dialog>
    </Grid>
  )
}

Top5ListWidget.nameId = "top_5_impairments"
Top5ListWidget.title = DEFAULT_STATE.title
Top5ListWidget.description = DEFAULT_STATE.description

export default Top5ListWidget
