import { Controller, type FieldValues } from "react-hook-form"
import React, { useCallback, useMemo, useState } from "react"
import { type Control } from "react-hook-form/dist/types/form"
import { Box, FormGroup, FormLabel, Grid, type Theme, Toolbar } from "@mui/material"
import {
  BtnBold,
  BtnBulletList,
  BtnClearFormatting,
  BtnItalic,
  BtnNumberedList,
  BtnRedo,
  BtnUnderline,
  BtnUndo,
  type ContentEditableEvent,
  Editor,
  EditorProvider,
  Separator,
} from "react-simple-wysiwyg"
import { cleanHtml, nameToLabel, sanitizeOptions } from "../../utilities/form_utility"
import { type ControllerRenderProps } from "react-hook-form/dist/types/controller"
import HtmlToolTip from "../HtmlToolTip"
import InfoIcon from "@mui/icons-material/Info"
import useAuth from "../../hooks/useAuth"

interface IProps {
  control: Control
  defaultValue?: string | number
  label?: string | null
  name: string
  helperText?: string
}

/**
 * A forms hook for MUI wysiwyg html editor
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the editor.
 */
const FhMuiRichTextField: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { control, defaultValue = "<p><br /></p>", name, label, helperText = null } = props
  const [focus, setFocus] = useState(false)
  const theLabel = nameToLabel(name, label)
  const { currentUser } = useAuth()

  const handleFocus = useCallback(() => {
    setFocus(true)
  }, [])

  const handleBlur = useCallback(
    (fieldBlur: () => void) => () => {
      fieldBlur()
      setFocus(false)
    },
    [],
  )

  const handleChange = useCallback(
    (field: ControllerRenderProps<FieldValues, string>) => (event: ContentEditableEvent) => {
      event.target.value = cleanHtml(event.target.value)
      field.onChange(event)
    },
    [sanitizeOptions],
  )

  const maxHeight = useMemo(() => {
    if(currentUser?.user.profile.rich_text_boxes_full_length === true) {
      return "auto"
    }
    return "40em"

  }, [currentUser])

  return (
    <Grid container>
      <Grid item xs>
        <FormGroup
          sx={(theme: Theme) => ({
            "& .MuiToolbar-root": { minHeight: "auto", p: 1 },
            "& .rsw-editor": {
              borderColor: focus ? theme.palette.primary.main : theme.palette.grey["400"],
              borderWidth: focus ? 1 : 1,
            },
          })}>
          <FormLabel sx={{ fontSize: "small", pl: 1, pt: 1 }}>
            <Box
              sx={(theme: Theme) => ({
                position: "absolute",
                mt: -2,
                p: 1,
                color: focus ? theme.palette.primary.main : "inherit",
                backgroundColor: theme.palette.background.default,
              })}>
              {theLabel}
            </Box>
          </FormLabel>
          <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ field }) => (
              <EditorProvider>
                <Editor
                  onBlur={handleBlur(field.onBlur)}
                  onFocus={handleFocus}
                  onChange={handleChange(field)}
                  style={{ maxHeight, overflow: "auto" }}
                  value={field.value}>
                  <Toolbar>
                    <BtnUndo />
                    <BtnRedo />
                    <Separator />
                    <BtnBold />
                    <BtnItalic />
                    <BtnUnderline />
                    <Separator />
                    <BtnNumberedList />
                    <BtnBulletList />
                    <Separator />
                    <BtnClearFormatting />
                    <Separator />
                  </Toolbar>
                </Editor>
              </EditorProvider>
            )}
          />
        </FormGroup>
      </Grid>
      {helperText !== null && (
        <Grid item>
          <Box sx={{ position: "relative", marginLeft: -3, marginTop: 1, cursor: "pointer" }}>
            <HtmlToolTip title={<Box dangerouslySetInnerHTML={{ __html: helperText }} />} placement="left">
              <InfoIcon color="secondary" />
            </HtmlToolTip>
          </Box>
        </Grid>
      )}
    </Grid>
  )
}

export default FhMuiRichTextField
