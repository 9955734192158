import React, { useCallback } from "react"
import { Container, Grid } from "@mui/material"
import {
  type IInspectionRecommendation,
  INSPECTION_RECOMMENDATION_ENDPOINT,
} from "../../../models/service/IInspectionRecommendation"
import { RestRepository } from "../../../repositories/RestRepository"
import { type FieldValues, type SubmitHandler, useForm } from "react-hook-form"
import FormErrors from "../../../components/forms/FormErrors"
import FormBox from "../../../components/forms/FormBox"
import InspectionRecommendationForm from "./InspectionRecommendationForm"
import { type IItemEditorProps } from "../../../components/item_editor/ItemEditorDialog"
import useApiAdd, { type IUseApiAddProps } from "../../../hooks/useApiAdd"
import { useParams } from "react-router-dom"
import { type IInspection, INSPECTION_ENDPOINT } from "../../../models/service/IInspection"
import { useApiRead } from "../../../hooks/useApiRead"
import ViewLoading from "../../../components/ViewLoading"
import useEffectAsync from "../../../hooks/useEffectAsync"

const repository = new RestRepository<IInspectionRecommendation>(INSPECTION_RECOMMENDATION_ENDPOINT)
const inspectionRepository = new RestRepository<IInspection>(INSPECTION_ENDPOINT)

/**
 * Adds a new inspection recommendation.
 *
 * @param {IItemEditorProps} props - The props object containing the necessary properties.
 * @returns {React.ReactElement} - The rendered React element.
 */
const InspectionRecommendationAdd: React.FC<IItemEditorProps> = (props: IItemEditorProps): React.ReactElement => {
  const { onSave, requestSave, setRequestSave } = props
  const form = useForm()
  const { id } = useParams()

  const apiRead = useApiRead<IInspection>({ apiFunction: inspectionRepository.read, itemId: id })

  const propsApi: IUseApiAddProps<IInspectionRecommendation> = {
    apiFunction: repository.add,
    setError: form.setError,
  }
  const apiAdd = useApiAdd<IInspectionRecommendation>(propsApi)

  const handleSave = useCallback(
    async (data: IInspectionRecommendation) => {
      const result = await apiAdd.handleAdd(data)
      if (result !== undefined) {
        onSave()
      }
    },
    [apiAdd.handleAdd],
  )

  useEffectAsync(async () => {
    if (requestSave) {
      await form?.handleSubmit(handleSave as SubmitHandler<FieldValues>)()
      setRequestSave(false)
    }
  }, [requestSave])

  return (
    <Container fixed>
      <ViewLoading loading={apiAdd.saving || apiRead.loading} />
      <Grid container spacing={2}>
        <FormErrors connectionError={apiAdd.connectionError} />
        <FormBox form={form} onSubmit={handleSave} showActionPanel={false}>
          <InspectionRecommendationForm form={form} parentInspection={apiRead.data} />
        </FormBox>
      </Grid>
    </Container>
  )
}

export default InspectionRecommendationAdd
