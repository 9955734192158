import {
  CONNECTION_ERROR,
  type IConnectionError,
  setFormConnectionErrors,
  type TSetFormError,
} from "../models/components/IConnectionError"
import { type SyntheticEvent, useCallback, useState } from "react"
import { useNavigate } from "react-router-dom"
import { type IMainModel } from "../models/service/IMainModel"
import { type AxiosError } from "axios"

interface IUseApiAddResponse<T> {
  handleAdd: (t: T, disableRedirect?: boolean) => Promise<T | undefined>
  saving: boolean
  connectionError: IConnectionError | undefined
}

export interface IUseApiAddProps<T> {
  apiFunction: (item: T) => Promise<T>
  redirectView?: string
  redirect?: boolean
  setError: TSetFormError
}

/**
 * Hook for adding REST objects.
 *
 * @param {IUseApiAddProps} props See IProps for details.
 * @returns {IUseApiAddResponse} See IUseApiEditResponse for details.
 */
const useApiAdd = <T extends IMainModel>(props: IUseApiAddProps<T>): IUseApiAddResponse<T> => {
  const { apiFunction, redirectView, setError, redirect = true } = props
  const navigate = useNavigate()
  const [saving, setSaving] = useState(false)
  const [connectionError, setConnectionError] = useState<IConnectionError | undefined>()

  const handleAdd = useCallback(
    async (t: T, disableRedirect: boolean | SyntheticEvent = false): Promise<T | undefined> => {
      setSaving(true)
      try {
        const c1 = await apiFunction(t)
        if (redirect && (typeof disableRedirect !== "boolean" || !disableRedirect)) {
          if (redirectView !== undefined) {
            navigate(`${redirectView}/${c1.id}`)
          } else {
            if (typeof window !== "undefined") {
              window.history.go(-1)
            }
          }
        } else {
          setSaving(false)
        }
        return c1
      } catch (reason) {
        if ((reason as AxiosError)?.response !== undefined) {
          if ((reason as AxiosError)?.response?.status !== 403) {
            setFormConnectionErrors((reason as AxiosError).response as IConnectionError, setError)
          } else {
            setConnectionError({ data: (reason as AxiosError).response?.data as any } satisfies IConnectionError)
          }
        } else {
          setConnectionError(CONNECTION_ERROR)
        }
        setSaving(false)
      }
    },
    [navigate],
  )

  return {
    saving,
    handleAdd,
    connectionError,
  }
}

export default useApiAdd
