import React, { useCallback } from "react"
import { TableRow } from "@mui/material"
import { useNavigate } from "react-router-dom"

interface IProps {
  to?: string | boolean
  children: React.JSX.Element
}

const TAGS = ["TD", "SPAN"]

/**
 * Select a row on table by clicking on it.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the table row selector.
 */
const TableRowSelect: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { to, children } = props

  const navigate = useNavigate()

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLTableRowElement>) => {
      if (TAGS.includes((event.target as HTMLTableRowElement)?.tagName) && to !== false) {
        navigate(to as string)
      }
    },
    [to],
  )

  return (
    <TableRow hover={to !== false} onClick={handleClick} sx={{ cursor: to !== false ? "pointer" : "inherit" }}>
      {children}
    </TableRow>
  )
}

export default TableRowSelect
