import React, { useCallback } from "react"
import { Grid } from "@mui/material"
import { useForm } from "react-hook-form"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import BuildingForm from "./components/BuildingForm"
import { BUILDING_ENDPOINT, type IBuilding } from "../../../../shared/models/service/IBuilding"
import { BUILDING_VIEW_URL } from "../../config/urls"
import useApiAdd, { type IUseApiAddProps } from "../../../../shared/hooks/useApiAdd"
import FormHeader from "../../../../shared/components/forms/FormHeader"
import FormBox from "../../../../shared/components/forms/FormBox"
import FormErrors from "../../../../shared/components/forms/FormErrors"
import { type ILocation } from "../../../../shared/models/service/ILocation"

const repository = new RestRepository<IBuilding>(BUILDING_ENDPOINT)
const redirectView = BUILDING_VIEW_URL

interface IProps {
  thisLocation?: ILocation
  onComplete?: () => Promise<void>
}

/**
 * This page will allow adding of a building.
 *
 * @param {IProps} props See props for details
 * @returns {React.FC} the building add page.
 */
const AddPage: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { thisLocation, onComplete } = props
  const form = useForm()

  const addProps: IUseApiAddProps<IBuilding> = { apiFunction: repository.add, setError: form.setError, redirectView }
  const { saving, handleAdd, connectionError } = useApiAdd<IBuilding>(addProps)

  const handleSubmit = useCallback(async (building: IBuilding) => {
    if (onComplete !== undefined) {
      await handleAdd(building, true)
      await onComplete()
    } else {
      await handleAdd(building)
    }
  }, [])

  return (
    <Grid container spacing={2}>
      <FormHeader goSmall title="Add Building" loading={saving} errors={form.formState.errors} />
      <FormErrors connectionError={connectionError} />
      <FormBox form={form} onSubmit={handleSubmit}>
        <BuildingForm goSmall form={form} thisLocation={thisLocation} />
      </FormBox>
    </Grid>
  )
}

export default AddPage
