import React from "react"
import { Container, Grid } from "@mui/material"
import { useForm } from "react-hook-form"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import FormHeader from "../../../../shared/components/forms/FormHeader"
import ServiceUserForm from "./components/ServiceUserForm"
import { useApiRead } from "../../../../shared/hooks/useApiRead"
import useApiEdit, { type IUseApiEditProps } from "../../../../shared/hooks/useApiEdit"
import { type IServiceUser, SERVICE_USER_ENDPOINT } from "../../../../shared/models/service/IServiceUser"
import { SERVICE_USER_VIEW_URL } from "../../config/urls"
import FormBox from "../../../../shared/components/forms/FormBox"
import FormErrors from "../../../../shared/components/forms/FormErrors"

const repository = new RestRepository<IServiceUser>(SERVICE_USER_ENDPOINT)
const redirectView = SERVICE_USER_VIEW_URL

/**
 * This page will allow editing of an serviceUser.
 *
 * @returns {React.FC} the serviceUser edit page.
 */
const EditPage: React.FC = (): React.ReactElement => {
  const form = useForm()
  const apiRead = useApiRead<IServiceUser>({ apiFunction: repository.read })

  const props: IUseApiEditProps<IServiceUser> = { apiFunction: repository.edit, setError: form.setError, redirectView }
  const apiEdit = useApiEdit<IServiceUser>(props)

  return (
    <Container fixed>
      <Grid container spacing={2}>
        <FormHeader
          title="Edit Service User"
          loading={apiEdit.saving || apiRead.loading}
          errors={form.formState.errors}
        />
        <FormErrors connectionError={apiEdit.connectionError} apiError={apiRead.error} />
        <FormBox form={form} onSubmit={apiEdit.handleEdit}>
          <ServiceUserForm serviceUser={apiRead.data} form={form} isEdit />
        </FormBox>
      </Grid>
    </Container>
  )
}

export default EditPage
