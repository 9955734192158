import React, { useCallback, useRef } from "react"
import { Box, Button, Grid } from "@mui/material"
import { ViewColumn } from "@mui/icons-material"
import ListItemIcon from "@mui/material/ListItemIcon"
import Checkbox from "@mui/material/Checkbox"
import ListItemText from "@mui/material/ListItemText"
import { type IColumnItem } from "../../models/components/IColumnItem"
import { HTML5Backend } from "react-dnd-html5-backend"
import { DndProvider, useDrag, useDrop } from "react-dnd"
import type { Identifier, XYCoord } from "dnd-core"
import update from "immutability-helper"
import ListItem from "@mui/material/ListItem"
import DrawerRight from "../containers/DrawerRight"
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore"
import type { DragSourceMonitor } from "react-dnd/src/types"

const ColumnItemTypes = {
  COLUMN: "column",
}

export interface IColumnEntryProps {
  id: number | string
  columnItem: IColumnItem
  onColumnToggle: (column: IColumnItem) => () => void
  index: number
  moveColumnItem: (dragIndex: number, hoverIndex: number) => void
}

interface DragItem {
  index: number
  id: string
  type: string
}

/**
 * Renders a column entry component.
 *
 * @param {IColumnEntryProps} props - The props for the component.
 * @returns {React.ReactElement} The column entry component.
 */
export const ColumnEntry: React.FC<IColumnEntryProps> = (props: IColumnEntryProps): React.ReactElement => {
  const { id, columnItem, onColumnToggle, index, moveColumnItem } = props
  const ref = useRef<HTMLDivElement>(null)
  const [{ handlerId }, drop] = useDrop<DragItem, unknown, { handlerId: Identifier | null }>({
    accept: ColumnItemTypes.COLUMN,
    // eslint-disable-next-line require-jsdoc
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    // eslint-disable-next-line require-jsdoc
    hover(item: DragItem, monitor) {
      if (ref.current == null) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      moveColumnItem(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: ColumnItemTypes.COLUMN,
    item: () => {
      return { id, index }
    },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1
  drag(drop(ref))
  return (
    <Box key={columnItem.id} ref={ref} sx={{ opacity }} data-handler-id={handlerId}>
      <ListItem>
        <ListItemIcon onClick={onColumnToggle(columnItem)}>
          <Checkbox
            edge="start"
            checked={columnItem.show}
            tabIndex={-1}
            disableRipple
            inputProps={{ "aria-labelledby": columnItem.name }}
          />
        </ListItemIcon>
        <ListItemText primary={columnItem.name} sx={{ cursor: "move" }} />
      </ListItem>
    </Box>
  )
}

interface IProps {
  foundColumns: IColumnItem[]
  onColumnOrder: (columns: IColumnItem[]) => void
  onColumnToggle: (column: IColumnItem) => () => void
  onColumnReset: () => void
}

/**
 * Renders a table with columns that can be reordered and toggled on/off.
 *
 * @param {IProps} props - The props for the TableColumns component.
 * @returns {React.ReactElement} - The rendered TableColumns component.
 */
const TableColumns: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { foundColumns, onColumnToggle, onColumnOrder, onColumnReset } = props

  const moveColumnItem = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const newColumnsDisplayed = update(foundColumns, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, foundColumns[dragIndex]],
        ],
      })
      onColumnOrder(newColumnsDisplayed)
    },
    [foundColumns],
  )

  return (
    <>
      <DrawerRight title="Columns" icon={<ViewColumn />} showButton={foundColumns.length > 0}>
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs />
          <Grid item>
            <Button startIcon={<SettingsBackupRestoreIcon />} onClick={onColumnReset}>
              Reset
            </Button>
          </Grid>
        </Grid>
        <DndProvider backend={HTML5Backend}>
          <Box>
            {foundColumns.map((columnItem, index) => (
              <ColumnEntry
                key={columnItem.id}
                index={index}
                id={columnItem.id}
                columnItem={columnItem}
                onColumnToggle={onColumnToggle}
                moveColumnItem={moveColumnItem}
              />
            ))}
          </Box>
        </DndProvider>
      </DrawerRight>
    </>
  )
}

export default TableColumns
