import { type IPaging } from "../models/components/IPaging"
import axios, { type AxiosRequestConfig } from "axios"
import type ICurrentUser from "../models/users/ICurrentUser"
import { PANARISK_USER_KEY } from "../../config/config"
import { LOGIN_URL } from "../../apps/admin/config/urls"
import i18n from "../../../support/i18n"

/**
 * Retrieves the base URL for the application from environment variables.
 *
 * This function fetches the value of the `REACT_APP_BASE_APP` environment variable
 * to determine the base URL of the application. If the environment variable is not
 * set, it defaults to an empty string.
 *
 * @returns {string} The base URL of the application or an empty string if not set.
 */
export const getBaseApp = (): string => {
  const baseApp = location.pathname.split("/")[1]
  return process.env.REACT_APP_BASE_APP ?? baseApp
}
export const BASE_URL: string = (process.env.REACT_APP_BASE_URL ?? "") + `/${getBaseApp()}`

/**
 * Remove any duplicate values in the array.
 *
 * @param {[]} array to remove duplicates from.
 * @returns {[]} the cleaned array.
 */
function uniqBySetWithArrayFrom<T>(array: T[]): T[] {
  return Array.from(new Set(array))
}

/**
 * Build search params for an url given an IPaging. Aka: convert IPaging to
 * search params string.
 *
 * @param {IPaging} paging to convert.
 * @returns {string} the search params.
 */
export const urlSearchParams = (paging: IPaging | undefined | null): string => {
  if (paging === undefined || paging === null) {
    return ""
  }
  const { limit, offset, ordering, filters } = paging
  let queryString = [] as string[]
  if (limit !== undefined) {
    queryString.push(`limit=${limit}`)
  }

  if (offset !== undefined) {
    queryString.push(`offset=${offset}`)
  }

  const filter = filters?.map(filter => {
    const value = filters
      .filter(f => filter.field === f.field)
      .map(f => f.value)
      .join(",")
    return `${filter.field}=${encodeURIComponent(value)}`
  })

  if (filter !== undefined) {
    const f = uniqBySetWithArrayFrom<string>(filter)
    queryString = queryString.concat(f)
  }

  const order =
    ordering !== undefined ? (ordering?.direction === "asc" ? ordering?.field : `-${ordering?.field}`) : null
  if (order !== null) {
    queryString.push(`ordering=${order}`)
  }

  if (queryString.length > 0) {
    return `?${queryString.join("&")}`
  }
  return ""
}

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  timeout: 300000,
})

axiosInstance.interceptors.request.use(
  config => {
    const storageKey = `${PANARISK_USER_KEY}_${getBaseApp()}`
    const riskLogicUserJson = localStorage.getItem(storageKey)
    if (riskLogicUserJson !== null) {
      const currentUser = JSON.parse(riskLogicUserJson) as ICurrentUser
      const token = currentUser?.token.access
      if (token !== undefined && config.headers !== undefined) {
        config.headers.Authorization = "Bearer " + token
      }
    }
    config.headers["Accept-Language"] = i18n.language
    return config
  },
  async error => {
    return error
  },
)

/**
 * To test this code, on the django api server, change the following setting to:
 * SIMPLE_JWT = {
 *     "ACCESS_TOKEN_LIFETIME": timedelta(minutes=1),
 *     "REFRESH_TOKEN_LIFETIME": timedelta(minutes=2),
 * }
 */
axiosInstance.interceptors.response.use(
  res => res,
  async err => {
    const originalConfig = err.config
    if (originalConfig.url !== "/rest-auth/google/" && originalConfig.url !== "/token/" && err.response !== undefined) {
      // Access Token was expired
      if (err.response.status === 401 && originalConfig._retry === undefined) {
        originalConfig._retry = true
        const storageKey = `${PANARISK_USER_KEY}_${getBaseApp()}`
        try {
          const riskLogicUserJson = localStorage.getItem(storageKey)
          const currentUser = riskLogicUserJson !== null ? (JSON.parse(riskLogicUserJson) as ICurrentUser) : null
          if (currentUser !== null) {
            const { refresh } = currentUser.token
            const rs = await axios.post(`${BASE_URL}/token/refresh/`, { refresh })
            const { access } = rs.data
            const token = {
              refresh: currentUser.token.refresh,
              access,
            }
            const riskLogicUserJson = JSON.stringify({
              user: currentUser.user,
              token,
            })
            localStorage.setItem(storageKey, riskLogicUserJson)
          }
          return await axiosInstance(originalConfig as AxiosRequestConfig)
        } catch (_error) {
          localStorage.setItem(storageKey, "null")
          window.location.href = `/admin/${LOGIN_URL}`
          return await Promise.reject(_error)
        }
      }
    }
    return await Promise.reject(err)
  },
)

export { axiosInstance }
