import { Controller } from "react-hook-form"
import React from "react"
import { type Control } from "react-hook-form/dist/types/form"

interface IProps {
  control: Control
  defaultValue?: number
  name?: string
}

/**
 * Just renders a hidden ID field for the forms hook post.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the hidden id field.
 */
const FhMuiHiddenField: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { control, defaultValue = 0, name = "id" } = props

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => <input type="hidden" {...field} />}
    />
  )
}

export default FhMuiHiddenField
