import React from "react"
import { Box } from "@mui/material"

interface IProps {
  value: number | null | undefined
  prefixUnits?: string | React.ReactNode
  suffixUnits?: string | React.ReactNode
  display?: string
  twoDecimalPlaces?: boolean
}

const numFormatOptions = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
}
const twoDecimalFormatOptions = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}

/**
 * Formats a given number into a string with currency units and decimal places.
 *
 * @param {IProps} props - The props object containing the number and formatting options.
 * @param {number} props.value - The number to be formatted.
 * @param {string} [props.prefixUnits] - The currency unit to be prefixed.
 * @param {string} [props.suffixUnits] - The currency unit to be suffixed.
 * @param {boolean} [props.twoDecimalPlaces] - Determines whether to display two decimal places.
 * @returns {string} - The formatted number string with currency units.
 */
export const formatNumber = (props: IProps): string => {
  const { value, prefixUnits = "$", suffixUnits = "", twoDecimalPlaces = false } = props

  if (value !== undefined && value !== null) {
    return `${prefixUnits as string}${Number(value).toLocaleString(undefined, twoDecimalPlaces ? twoDecimalFormatOptions : numFormatOptions)}${suffixUnits as string}`
  }

  return "Not Set"
}

/**
 * Format a number for display.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the formatted number.
 */
const FormatNumber: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { value, prefixUnits = "$", suffixUnits = "", display = "block", twoDecimalPlaces = false } = props

  return (
    <Box component="span" sx={{ display }}>
      {value !== undefined && value !== null ? (
        <>
          <>{prefixUnits}</>
          {Number(value).toLocaleString(undefined, twoDecimalPlaces ? twoDecimalFormatOptions : numFormatOptions)}
          <>{suffixUnits}</>
        </>
      ) : (
        <>Not Set</>
      )}
    </Box>
  )
}

export default FormatNumber
