import React from "react"
import { Box, Grid, IconButton, TableCell, Typography } from "@mui/material"
import { type ILocation } from "../../models/service/ILocation"
import { truncateString } from "../../utilities/format_utility"
import HtmlTooltip from "../HtmlToolTip"
import InfoIcon from "@mui/icons-material/Info"
import { Link, useLocation } from "react-router-dom"
import { ItemPrefixes } from "../../../config/config"

interface IProps {
  children: ILocation | undefined
  field?: string
}

/**
 * Renders a TableCell with formatted location.
 *
 * @param {IProps} props - The component props.
 * @returns {React.ReactElement} - The rendered TableCell with formatted location.
 */
const TableCellLocation: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children } = props
  const location = useLocation()

  return (
    <TableCell>
      {children !== undefined && children !== null ? (
        <HtmlTooltip
          placement="left"
          title={
            <Grid container spacing={1} sx={{ maxWidth: 300 }} alignItems="center">
              <Grid item xs>
                <Typography variant="h6">{children.name}</Typography>
              </Grid>
              <Grid item>
                <IconButton component={Link} to={`${location.pathname}/${ItemPrefixes.location}info/${children.id}`}>
                  <InfoIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12} sx={{ p: 0, m: 0 }} />
              {children.address !== "" && children.address !== null && (
                <Grid item xs={12}>
                  {children.address}
                </Grid>
              )}
              {children.address_2 !== "" && children.address_2 !== null && (
                <Grid item xs={12}>
                  {children.address_2}
                </Grid>
              )}
              <Grid item>{children.city}</Grid>
              <Grid item>{children.state_region}</Grid>
              <Grid item>{children.postal_code}</Grid>
              <Grid item xs={12}>
                {children.country}
              </Grid>
            </Grid>
          }>
          <Box>{truncateString(children.name)}</Box>
        </HtmlTooltip>
      ) : (
        "Not Set"
      )}
    </TableCell>
  )
}

export default TableCellLocation
