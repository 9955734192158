import * as React from "react"
import { type ChangeEvent, useCallback, useContext, useState } from "react"
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  ListItemIcon,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import prettyBytes from "pretty-bytes"
import DialogControls from "../../../shared/components/DialogControls"
import { type RestRepository } from "../../repositories/RestRepository"
import { type IFile } from "../../models/service/IFile"
import Checkbox from "@mui/material/Checkbox"
import { type IPaging } from "../../models/components/IPaging"
import { useAxiosRequest } from "../../hooks/useAxiosRequest"
import ErrorMessage from "../../../shared/components/ErrorMessage"
import { RiskWriterContext } from "../risk_writer/context/RiskWriterProvider"
import BuildIcon from "@mui/icons-material/Build"

interface IProps {
  itemId: number
  repository: RestRepository<IFile>
  fileRepository: RestRepository<IFile>
  modelName: string
}

/**
 * A dialog component to build a report.
 *
 * @param {IProps} props - The properties for the component.
 * @returns {React.ReactElement} The rendered dialog component.
 */
const BuildReportDialog: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { itemId, repository, fileRepository, modelName } = props
  const [open, setOpen] = useState(false)

  const [generatePdf, setGeneratePdf] = useState(false)

  const [recentReport, setRecentReport] = useState<IFile | null>(null)
  const [recentReportPdf, setRecentReportPdf] = useState<IFile | null>(null)

  const { loading, error, errorMessage, callRequest } = useAxiosRequest()
  const isSmall = useMediaQuery(useTheme().breakpoints.down("lg"))

  const { reportWriterDataDiff } = useContext(RiskWriterContext)

  const handleOpen = useCallback(async () => {
    setOpen(true)
    await callRequest(async () => {
      const paging: IPaging = {
        limit: 2,
        filters: [
          { field: modelName, value: itemId },
          {
            field: "is_report",
            value: "true",
          },
        ],
      }
      const files = await fileRepository.findAll(paging)
      for (const file of files.results) {
        if (file.is_report) {
          if (file.ext === "docx") {
            const docxFile = await fileRepository.read(file.id)
            setRecentReport(docxFile)
          }
          if (file.ext === "pdf") {
            const pdfFile = await fileRepository.read(file.id)
            setRecentReportPdf(pdfFile)
          }
        }
      }
    })
  }, [itemId, modelName])

  const handleClose = useCallback(async () => {
    setOpen(false)
  }, [])

  const handleGeneratePdf = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setGeneratePdf(event.target.checked)
  }, [])

  const handleBuild = useCallback(async () => {
    await callRequest(async () => {
      const paging: IPaging | null = generatePdf ? {filters: [{field: "pdf", value: "true"}]} : null
      const generatedReport1 = await repository.action<IFile>(itemId, "generate_risk_writer", paging)
      setRecentReport(generatedReport1)
      setOpen(false)
    })
  }, [itemId, generatePdf])

  return (
    <>
      {!isSmall ? (
        <Button color="inherit" onClick={handleOpen}>
          Build Report
        </Button>
      ) : (
        <MenuItem onClick={handleOpen}>
          <ListItemIcon>
            <BuildIcon />
          </ListItemIcon>
          Build Report
        </MenuItem>
      )}
      <Dialog open={open} onClose={handleClose} sx={{ zIndex: 3000 }} maxWidth="sm" fullWidth>
        <DialogTitle>Build Report</DialogTitle>
        <DialogContent>
          <Grid container alignItems="center" spacing={1}>
            {reportWriterDataDiff !== undefined && reportWriterDataDiff?.length > 0 && (
              <Grid item xs={12}>
                <Alert severity="warning">You have unsaved changes.</Alert>
              </Grid>
            )}
            {error && <ErrorMessage error={errorMessage} />}
            <Grid item xs>
              <FormControlLabel
                control={<Checkbox checked={generatePdf} onChange={handleGeneratePdf} />}
                label="Generate PDF"
              />
            </Grid>
            <Grid item>
              <Button onClick={handleBuild} fullWidth>
                Build
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Box sx={{ mt: 2 }}>
                <Divider />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">Recent Report Docx</Typography>
              {recentReport?.file?.read_url !== undefined ? (
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs>
                    <strong>Size:</strong> {recentReport.size !== null ? prettyBytes(recentReport.size) : 0}
                  </Grid>
                  <Grid item>
                    <Button fullWidth href={recentReport.file.read_url} target="__blank">
                      Download
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <>Not Created</>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">Recent Report PDF</Typography>
              {recentReportPdf?.file?.read_url !== undefined ? (
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs>
                    <strong>Size:</strong> {recentReportPdf.size !== null ? prettyBytes(recentReportPdf.size) : 0}
                  </Grid>
                  <Grid item>
                    <Button fullWidth href={recentReportPdf.file.read_url} target="__blank">
                      Download
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <>Not Created</>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogControls loading={loading} onSave={handleClose} buttonLabel="close" />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default BuildReportDialog
