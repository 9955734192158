import React, { useCallback } from "react"
import { Container, Grid } from "@mui/material"
import { type FieldValues, type SubmitHandler, useForm } from "react-hook-form"
import { RestRepository } from "../../../repositories/RestRepository"
import { useApiRead } from "../../../hooks/useApiRead"
import useApiEdit, { type IUseApiEditProps } from "../../../hooks/useApiEdit"
import FormBox from "../../../components/forms/FormBox"
import FormErrors from "../../../components/forms/FormErrors"
import { type IInspectionBuilding, INSPECTION_BUILDING_ENDPOINT } from "../../../models/service/IInspectionBuilding"
import RiskWriterForm from "../../../components/risk_writer/components/RiskWriterForm"
import { type TRiskWriterFormData } from "../../risk_writer/models/TRiskWriterFormData"
import type { IItemEditorProps } from "../../item_editor/ItemEditorDialog"
import useEffectAsync from "../../../hooks/useEffectAsync"
import ViewLoading from "../../../components/ViewLoading"
import { ImageChooserProvider } from "../../files/ImageChooser"
import { INSPECTION_FILE_ENDPOINT } from "../../../models/service/IFile"

const repository = new RestRepository<IInspectionBuilding>(INSPECTION_BUILDING_ENDPOINT)

/**
 * Edits an inspection building.
 *
 * @param {IItemEditorProps} props - The props needed for editing the inspection building.
 * @param {number} props.itemId - The ID of the inspection building.
 * @param {Function} props.onSave - A callback function to be called when the inspection building is saved.
 * @param {boolean} props.requestSave - A flag indicating if a save request has been made.
 * @param {Function} props.setRequestSave - A function to set the requestSave flag.
 * @returns {React.ReactElement} - The React element representing the inspection building editor.
 */
const InspectionBuildingEdit: React.FC<IItemEditorProps> = (props: IItemEditorProps): React.ReactElement => {
  const form = useForm()
  const { itemId, onSave, requestSave, setRequestSave } = props

  const apiRead = useApiRead<IInspectionBuilding>({ apiFunction: repository.read, itemId })

  const propsApi: IUseApiEditProps<IInspectionBuilding> = {
    apiFunction: repository.edit,
    setError: form.setError,
  }
  const apiEdit = useApiEdit<IInspectionBuilding>(propsApi)

  const reportWriterFormat = apiRead.data?.inspection.report_format?.report_writer_format?.data

  const handleSave = useCallback(
    async (data: TRiskWriterFormData) => {
      if (apiRead.data !== undefined) {
        const result = await apiEdit.handleEdit({ ...apiRead.data, data })
        if (result !== undefined) {
          onSave()
        }
      }
    },
    [apiEdit, apiRead.data],
  )

  useEffectAsync(async () => {
    if (requestSave) {
      await form?.handleSubmit(handleSave as SubmitHandler<FieldValues>)()
      setRequestSave(false)
    }
  }, [requestSave])

  return (
    <Container fixed>
      <ViewLoading loading={apiEdit.saving || apiRead.loading} />
      <Grid container spacing={2}>
        <FormErrors apiError={apiRead.error} connectionError={apiEdit.connectionError} />
        <FormBox form={form} onSubmit={handleSave} showActionPanel={false}>
          {reportWriterFormat !== undefined && reportWriterFormat !== null && (
            <ImageChooserProvider
              itemId={apiRead.data?.inspection.id}
              field="inspection"
              fileEndpoint={INSPECTION_FILE_ENDPOINT}>
              <RiskWriterForm
                data={apiRead.data?.data}
                format={reportWriterFormat}
                fileEndpoint={INSPECTION_FILE_ENDPOINT}
                formType="building"
                form={form}
                isEdit
              />
            </ImageChooserProvider>
          )}
        </FormBox>
      </Grid>
    </Container>
  )
}

export default InspectionBuildingEdit
