import { type Configuration } from "@azure/msal-browser"

// Config object to be passed to Msal on creation
/**
 * Configures the MSAL library with the provided client ID.
 *
 * @param {string} clientId - The client ID to be used for authentication.
 * @returns {Configuration} - The configuration object for MSAL.
 */
export const msalConfig = (clientId: string): Configuration => {
  return {
    auth: {
      clientId,
      authority: "https://login.microsoftonline.com/common",
      redirectUri: "/",
      postLogoutRedirectUri: "/",
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
    },
  }
}

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
}
