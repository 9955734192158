import React from "react"
import { styles } from "../../styling/general"
import PanariskPage from "../../components/PanariskPage"
import { Box } from "@mui/material"

/**
 * Displayed when the user doesn't have access to a page.
 *
 * @returns {React.FC} the access denied page.
 */
const AccessDenied: React.FC = (): React.ReactElement => {
  return (
    <PanariskPage>
      <Box sx={styles.page}>
        <>You do not have permission to access this page.</>
      </Box>
    </PanariskPage>
  )
}

export default AccessDenied
