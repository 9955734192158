import React from "react"
import { Button, Grid, IconButton } from "@mui/material"
import ViewLoading from "./ViewLoading"
import AlertDialog from "./AlertDialog"

interface IProps {
  disabled?: boolean
  loading?: boolean
  buttonLabel?: string
  cancelWithConfirm?: boolean
  cancelButtonLabel?: string
  cancelConfirmMessage?: string
  cancelButtonIcon?: React.JSX.Element
  onCancel?: (event: React.MouseEvent) => void
  onSave: (event: React.MouseEvent) => Promise<void>
}

/**
 * Basic dialog controls.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the dialog controls.
 */
const DialogControls: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const {
    loading = false,
    disabled = false,
    buttonLabel = "Save",
    cancelWithConfirm = false,
    cancelButtonLabel = "Cancel",
    cancelButtonIcon,
    cancelConfirmMessage,
    onSave,
    onCancel,
  } = props

  return (
    <Grid container spacing={2} sx={{ ml: 0, mr: 1, mb: 1 }}>
      {onCancel !== undefined && (
        <Grid item>
          {cancelWithConfirm ? (
            <AlertDialog
              buttonText={cancelButtonLabel}
              buttonIcon={cancelButtonIcon}
              onYes={onCancel}
              message={cancelConfirmMessage}
            />
          ) : (
            <>
              {cancelButtonIcon !== undefined ? (
                <IconButton onClick={onCancel}>
                  {cancelButtonIcon}
                </IconButton>
              ) : (
                <Button onClick={onCancel} color="warning">
                  {cancelButtonLabel}
                </Button>
              )}
            </>
          )}
        </Grid>
      )}
      <Grid item xs>
        <ViewLoading loading={loading} />
      </Grid>
      <Grid item>
        <Button onClick={onSave} disabled={disabled} autoFocus>
          {buttonLabel}
        </Button>
      </Grid>
    </Grid>
  )
}

export default DialogControls
