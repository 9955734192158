import React from "react"
import { Container, Grid } from "@mui/material"
import { useForm } from "react-hook-form"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import FormHeader from "../../../../shared/components/forms/FormHeader"
import InspectionRecommendationForm from "./components/InspectionRecommendationForm"
import { useApiRead } from "../../../../shared/hooks/useApiRead"
import useApiEdit, { type IUseApiEditProps } from "../../../../shared/hooks/useApiEdit"
import {
  type IInspectionRecommendation,
  INSPECTION_RECOMMENDATION_ENDPOINT,
} from "../../../../shared/models/service/IInspectionRecommendation"
import { INSPECTION_RECOMMENDATION_VIEW_URL } from "../../../admin/config/urls"
import FormBox from "../../../../shared/components/forms/FormBox"
import FormErrors from "../../../../shared/components/forms/FormErrors"

const repository = new RestRepository<IInspectionRecommendation>(INSPECTION_RECOMMENDATION_ENDPOINT)
const redirectView = INSPECTION_RECOMMENDATION_VIEW_URL

/**
 * This page will allow editing of an inspection.
 *
 * @returns {React.FC} the inspection edit page.
 */
const EditPage: React.FC = (): React.ReactElement => {
  const form = useForm()
  const apiRead = useApiRead<IInspectionRecommendation>({ apiFunction: repository.read })

  const props: IUseApiEditProps<IInspectionRecommendation> = {
    apiFunction: repository.edit,
    setError: form.setError,
    redirectView,
  }
  const apiEdit = useApiEdit<IInspectionRecommendation>(props)

  return (
    <Container fixed>
      <Grid container spacing={2}>
        <FormHeader
          title="Edit Inspection Rec"
          loading={apiEdit.saving || apiRead.loading}
          errors={form.formState.errors}
        />
        <FormErrors apiError={apiRead.error} connectionError={apiEdit.connectionError} />
        <FormBox form={form} onSubmit={apiEdit.handleEdit}>
          <InspectionRecommendationForm inspectionRecommendation={apiRead.data} form={form} isEdit />
        </FormBox>
      </Grid>
    </Container>
  )
}

export default EditPage
