import React from "react"
import { Container, Grid } from "@mui/material"
import { useForm } from "react-hook-form"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import FormHeader from "../../../../shared/components/forms/FormHeader"
import PolicyForm from "../policies/components/PolicyForm"
import { useApiRead } from "../../../../shared/hooks/useApiRead"
import useApiEdit, { type IUseApiEditProps } from "../../../../shared/hooks/useApiEdit"
import { POLICY_ENDPOINT, type IPolicy } from "../../../../shared/models/service/IPolicy"
import { POLICY_VIEW_URL } from "../../config/urls"
import FormBox from "../../../../shared/components/forms/FormBox"
import FormErrors from "../../../../shared/components/forms/FormErrors"

const repository = new RestRepository<IPolicy>(POLICY_ENDPOINT)
const redirectView = POLICY_VIEW_URL

/**
 * This page will allow editing of an policy.
 *
 * @returns {React.FC} the policy edit page.
 */
const EditPage: React.FC = (): React.ReactElement => {
  const form = useForm()
  const apiRead = useApiRead<IPolicy>({ apiFunction: repository.read })

  const props: IUseApiEditProps<IPolicy> = { apiFunction: repository.edit, setError: form.setError, redirectView }
  const apiEdit = useApiEdit<IPolicy>(props)

  return (
    <Container fixed>
      <Grid container spacing={2}>
        <FormHeader title="Edit Policy" loading={apiEdit.saving || apiRead.loading} errors={form.formState.errors} />
        <FormErrors apiError={apiRead.error} connectionError={apiEdit.connectionError} />
        <FormBox form={form} onSubmit={apiEdit.handleEdit}>
          <PolicyForm policy={apiRead.data} form={form} isEdit />
        </FormBox>
      </Grid>
    </Container>
  )
}

export default EditPage
