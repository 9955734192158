import React from "react"

/**
 * Not found page component.
 *
 * @returns {React.FC} page not found message.
 */
const NotFound: React.FC = (): React.ReactElement => {
  return <p>That page does not exist... the sadness.</p>
}

export default NotFound
