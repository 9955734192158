import CheckIcon from "@mui/icons-material/CheckRounded"
import RemoveIcon from "@mui/icons-material/RemoveCircleOutline"
import React from "react"

interface IProps {
  value: number | boolean | string | null | undefined
}

/**
 * Displays a checkmark and the value.
 *
 * @param {IProps} props See props for details.
 * @returns {React.FC<IProps>} the checkmark component.
 */
const CheckMark: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { value } = props

  return (
    <>
      {value !== undefined && value !== null && value !== false && value !== "" && value !== 0 ? (
        <CheckIcon color="primary" />
      ) : (
        <RemoveIcon color="secondary" />
      )}
    </>
  )
}

export default CheckMark
