import React, { createContext, useCallback, useEffect, useState } from "react"
import "../shared/utilities/silence_console_errors"

import { GoogleOAuthProvider } from "@react-oauth/google"
import { RouterProvider } from "react-router-dom"
import { type AxiosError } from "axios"
import { axiosInstance, BASE_URL, getBaseApp } from "../shared/utilities/request_utility"
import theme from "../shared/themes/default_theme"
import { type PaletteMode, ThemeProvider, useMediaQuery } from "@mui/material"
import ViewLoading from "../shared/components/ViewLoading"
import ErrorMessage from "../shared/components/ErrorMessage"
import { type IConnectionError } from "../shared/models/components/IConnectionError"
import { djangoOptionsToMuiSelect } from "../shared/utilities/form_utility"
import { type IPanariskAppContext, type IPanariskAppSettings } from "../shared/models/app/IPanariskAppContext"
import useEffectInit from "../shared/hooks/useEffectInit"
import { APIProvider } from "@vis.gl/react-google-maps"
import { type IPanariskWidget } from "../shared/components/widgets/models/IPanariskWidgetProps"

import { type Router as RemixRouter } from "@remix-run/router/dist/router"
import type { ISideNav } from "../shared/components/SideNavMenu"
import { PANARISK_APP_SETTINGS_KEY } from "../config/config"
import useLocalStorage from "beautiful-react-hooks/useLocalStorage"

const storageKey = `${PANARISK_APP_SETTINGS_KEY}_${getBaseApp()}`

const appSettingsInitialState: IPanariskAppSettings = { openNavMenu: true, selectOptions: {} }
const appContextInitialState: IPanariskAppContext = { appSettings: appSettingsInitialState }
export const PanariskAppContext = createContext<IPanariskAppContext>(appContextInitialState)

interface IProps {
  children?: React.JSX.Element[] | React.JSX.Element | undefined
  router: RemixRouter
  sideMenu: Array<ISideNav | null | boolean>
  addonWidgets?: IPanariskWidget[]
  siteName?: string
}

/**
 * Main app component. Handles routing.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the app component.
 */
const PanariskApp: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const [appSettings, setAppSettings] = useLocalStorage<IPanariskAppSettings>(storageKey, appSettingsInitialState)

  const [addonWidgets, setAddonWidgets] = useState<IPanariskWidget[] | null>(null)
  const [sideMenu, setSideMenu] = useState<Array<ISideNav | null | boolean> | null>(null)
  const [siteName, setSiteName] = useState<string>("Panarisk")
  const [restApiKey, setRestApiKey] = useState<string | undefined>()

  const [loading, setLoading] = useState(false)
  const [actionItem, setActionItem] = useState<React.JSX.Element | undefined>()
  const [error, setError] = useState<IConnectionError | undefined>()

  const [mode, setMode] = useState<PaletteMode>("light")
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)")

  const loadServerInfo = useCallback(async () => {
    setLoading(true)
    try {
      // todo: wrap in axios request
      const { data: djangoOptions } = await axiosInstance.get(`${BASE_URL}/lookups/select_options/`)
      const selectOptions = djangoOptionsToMuiSelect(djangoOptions as Record<string, Array<Record<string, string>>>)

      const { data: serverInfo } = await axiosInstance.get(`${BASE_URL}/lookups/server_info/`)
      setAppSettings(appSettings => ({ ...appSettings, serverInfo, selectOptions }))
    } catch (error) {
      const errorMessage1: IConnectionError = {
        objType: "NetworkError",
        data: { message: [(error as AxiosError).message] },
        code: "None",
      }
      setError(errorMessage1)
    }
    setLoading(false)
  }, [])

  useEffect(() => {
    setMode(prefersDarkMode ? "dark" : "light")
  }, [prefersDarkMode])

  useEffectInit(async () => {
    await loadServerInfo()
    setAddonWidgets(props.addonWidgets ?? null)
    setSideMenu(props.sideMenu ?? null)
    setSiteName(props.siteName ?? "Panarisk")
  }, [])

  const { serverInfo } = appSettings ?? appSettingsInitialState
  const googleMapsKey =
    appSettings?.serverInfo?.google_maps_api_key !== undefined ? appSettings.serverInfo.google_maps_api_key : ""

  const contextValue: IPanariskAppContext = {
    appSettings: appSettings ?? appSettingsInitialState,
    setAppSettings,
    actionItem,
    setActionItem,
    loadServerInfo,
    addonWidgets,
    sideMenu,
    siteName,
    restApiKey,
    setRestApiKey
  }

  return (
    <PanariskAppContext.Provider value={contextValue}>
      <ViewLoading loading={loading} />
      {error !== undefined && <ErrorMessage error={error} />}
      {serverInfo !== undefined && (
        <APIProvider apiKey={googleMapsKey}>
          <ThemeProvider theme={theme(serverInfo.primary_color, serverInfo.secondary_color, mode)}>
            <GoogleOAuthProvider clientId={serverInfo.google_client_id}>
              <RouterProvider router={props.router} />
            </GoogleOAuthProvider>
          </ThemeProvider>
        </APIProvider>
      )}
    </PanariskAppContext.Provider>
  )
}

export default PanariskApp
