import React, { useCallback } from "react"
import { Box, Grid } from "@mui/material"
import FormActionPanel from "./FormActionPanel"
import { type UseFormReturn } from "react-hook-form/dist/types"

interface IProps {
  children: React.ReactNode
  form: UseFormReturn
  onSubmit: (data: any, disableRedirect?: boolean | undefined) => Promise<any | undefined>
  showActionPanel?: boolean
  showCancel?: boolean
}

/**
 * Renders a forms in a Material-UI Box component with custom child elements and an action panel.
 *
 * @param {IProps} props - The component props.
 * @returns {React.ReactElement} - The rendered forms component.
 */
const FormBox: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children, form, onSubmit, showActionPanel = true, showCancel = true } = props

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event?.preventDefault()
      form.clearErrors()
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      await form.handleSubmit(onSubmit as any)(event)
    },
    [form, onSubmit],
  )

  return (
    <Grid item xs={12}>
      <Box component="form" onSubmit={handleSubmit} autoComplete="off">
        {children}
        {showActionPanel && <FormActionPanel showCancel={showCancel} />}
      </Box>
    </Grid>
  )
}

export default FormBox
