import React, { useCallback, useContext, useState } from "react"
import { Button, Icon, type Theme } from "@mui/material"
import type { PopupRequest } from "@azure/msal-browser/src/request/PopupRequest"
import { DASHBOARD_URL, LOGIN_URL } from "../../../apps/admin/config/urls"
import type { AxiosError } from "axios"
import { CONNECTION_ERROR, type IConnectionError } from "../../models/components/IConnectionError"
import useEffectInit from "../../hooks/useEffectInit"
import { type AuthenticationResult, type EventMessage, EventType, PublicClientApplication } from "@azure/msal-browser"
import { msalConfig } from "../../../config/msAuthConfig"
import { useLocation, useNavigate } from "react-router-dom"
import useAuth from "../../hooks/useAuth"
import { PanariskAppContext } from "../../../app/PanariskApp"

interface ILocationState {
  refer?: string
}

interface IProps {
  setLoading: (loading: boolean) => void
  setError: (loading: boolean) => void
  setErrorMessage: (errorMessage: IConnectionError | null) => void
}

/**
 * Represents a Microsoft Authentication Button component.
 *
 * @param {IProps} props - The component properties.
 * @returns {React.ReactElement} - The rendered component.
 */
const MsAuthButton: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { setLoading, setError, setErrorMessage } = props

  const [msalInstance, setMsalInstance] = useState<PublicClientApplication | null>(null)

  const { loginWithMsalToken } = useAuth()
  const { appSettings } = useContext(PanariskAppContext)

  const location = useLocation()
  const navigate = useNavigate()

  const handleMsalLogin = useCallback(async () => {
    if (msalInstance !== null) {
      if (loginWithMsalToken !== null) {
        setLoading(true)
        setError(false)
        try {
          setErrorMessage(null)

          const accessTokenRequest: PopupRequest = { scopes: ["user.read"] }
          const result = await msalInstance.acquireTokenPopup(accessTokenRequest)
          await loginWithMsalToken?.(result.accessToken)
          setTimeout(() => {
            setLoading(false)
            const refer = (location.state as ILocationState)?.refer
            if (refer?.endsWith(LOGIN_URL) === true) {
              navigate(DASHBOARD_URL)
            } else {
              navigate(refer ?? DASHBOARD_URL)
            }
          }, 1000)
        } catch (reason) {
          setError(true)
          if ((reason as AxiosError)?.response !== undefined) {
            setErrorMessage((reason as AxiosError).response as IConnectionError)
          } else {
            setErrorMessage(CONNECTION_ERROR)
          }
          setLoading(false)
        }
      }
    }
  }, [msalInstance])

  useEffectInit(async () => {
    if (appSettings.serverInfo?.msal_client_id !== undefined) {
      const msalInstance1 = new PublicClientApplication(msalConfig(appSettings.serverInfo.msal_client_id))
      await msalInstance1.initialize()
      setMsalInstance(msalInstance1)
      const accounts = msalInstance1.getAllAccounts()
      if (accounts.length > 0) {
        msalInstance1.setActiveAccount(accounts[0])
      }

      msalInstance1.addEventCallback((event: EventMessage) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload != null) {
          const payload = event.payload as AuthenticationResult
          const account = payload.account
          msalInstance1.setActiveAccount(account)
        }
      })
    }
  }, [])

  return (
    <Button
      startIcon={
        <Icon>
          <img src="/msal.svg" alt="Sign in with Microsoft" />
        </Icon>
      }
      onClick={handleMsalLogin}
      color="secondary"
      variant="outlined"
      sx={(theme: Theme) => ({
        width: "280px",
        borderWidth: "1px",
        borderRadius: 0.5,
        borderColor: theme.palette.grey["300"],
      })}>
      Sign in with Microsoft
    </Button>
  )
}

export default MsAuthButton
