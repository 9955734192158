import React from "react"
import { TableCell } from "@mui/material"
import { type IReportFormat } from "../../models/service/IReportFormat"

interface IProps {
  children: IReportFormat | null | undefined
  field?: string
}

/**
 * Renders a TableCell for a report format.
 *
 * @param {IProps} props - The component props.
 * @returns {React.ReactElement} - The rendered TableCell for a report format.
 */
const TableCellReportFormat: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children } = props

  return <TableCell>{children?.name}</TableCell>
}

export default TableCellReportFormat
