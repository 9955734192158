import React from "react"
import {
  type IInspectionRecommendation,
  INSPECTION_RECOMMENDATION_ENDPOINT,
} from "../../../../../shared/models/service/IInspectionRecommendation"
import { Grid } from "@mui/material"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import { useApiRead } from "../../../../../shared/hooks/useApiRead"
import ViewProperty from "../../../../../shared/components/ViewProperty"
import ViewRichText from "../../../../../shared/components/display/ViewRichText"
import ViewListItems from "../../../../../shared/components/display/ViewListItems"
import { type IInfoViewProps } from "../../../../../shared/components/item_viewer/ItemViewerDrawer"
import ItemViewerTitle from "../../../../../shared/components/item_viewer/ItemViewerTitle"
import { INSPECTION_RECOMMENDATION_VIEW_URL } from "../../../../admin/config/urls"
import ViewCurrency from "../../../../../shared/components/display/ViewCurrency"
import TruncateText from "../../../../../shared/components/TruncateText"

const repository = new RestRepository<IInspectionRecommendation>(INSPECTION_RECOMMENDATION_ENDPOINT)

/**
 * Renders a view page for an inspection report recommendation.
 *
 * @param {IInfoViewProps} props See IInfoViewProps for more info
 * @returns {React.ReactElement} The rendered view page.
 */
const InspectionRecommendationInfo: React.FC<IInfoViewProps> = (props: IInfoViewProps): React.ReactElement => {
  const { infoId } = props
  const apiRead = useApiRead<IInspectionRecommendation>({ apiFunction: repository.read, itemId: infoId })
  const { data } = apiRead

  return (
    <>
      {data !== undefined && (
        <>
          <Grid container spacing={2}>
            <Grid item xs>
              <ItemViewerTitle to={`${INSPECTION_RECOMMENDATION_VIEW_URL}/${infoId}`}>
                <TruncateText>{data.title}</TruncateText>
              </ItemViewerTitle>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label="Location">
                <ViewListItems>{data.location}</ViewListItems>
              </ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label="Identifier">{data.identifier}</ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label="Cost To Complete">
                <ViewCurrency>{data.cost_to_complete}</ViewCurrency>
              </ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label="Loss Estimate After">
                <ViewCurrency>{data.loss_estimate_after}</ViewCurrency>
              </ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label="Loss Estimate Before">
                <ViewCurrency>{data.loss_estimate_before}</ViewCurrency>
              </ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label="Type">{data.type?.name}</ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label="Status">{data.status?.name}</ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label="Priority">{data.priority?.name}</ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label="Risk Score Deduction">{data.risk_score_deduction}</ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label="Risk Score Category">{data.risk_score_category?.name}</ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label="Description">
                <ViewRichText>{data.body}</ViewRichText>
              </ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label="Loss Estimate">
                <ViewRichText>{data.loss_estimate}</ViewRichText>
              </ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label="Comment">
                <ViewRichText>{data.comment}</ViewRichText>
              </ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label="Reply">
                <ViewRichText>{data.reply}</ViewRichText>
              </ViewProperty>
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}

export default InspectionRecommendationInfo
