import React from "react"
import { alpha, Box, Card, CardContent, Typography } from "@mui/material"
import FormatNumber from "../format/FormatNumber"

interface IProps {
  title: string | React.ReactElement
  value: string | number
  color: string
  icon: any
}

/**
 * Renders an information value card with the specified props.
 *
 * @param {object} props - The props for the InfoValueCard component.
 * @returns {React.ReactElement} - The rendered InfoValueCard component.
 */
const InfoValueCard: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { title, value, color, icon: Icon } = props
  return (
    <>
      <Card elevation={0} sx={{ backgroundColor: alpha(color, 0.13), color }}>
        <CardContent>
          <Box sx={{ p: 2, textAlign: "center" }}>
            <Icon sx={{ fontSize: "4rem", color: alpha(color, 0.5) }} />
            <Typography variant="h4" sx={{ mt: 2 }}>
              <FormatNumber value={Number(value)} prefixUnits={false} />
            </Typography>
            <Typography variant="subtitle1">{title}</Typography>
          </Box>
        </CardContent>
      </Card>
    </>
  )
}

export default InfoValueCard
