import { Grid, Typography } from "@mui/material"
import React from "react"

interface IProps {
  label: number | string | React.JSX.Element
  children: number | string | boolean | React.JSX.Element | React.JSX.Element[] | null | undefined
  vertical?: boolean
  show?: boolean
  spacing?: number
}

/**
 * A standardized way to display a property.
 *
 * @param {IProps} props see IProps for details.
 * @class
 */
const ViewProperty: React.FunctionComponent<IProps> = (props: IProps) => {
  const { label, children, vertical = false, show = true, spacing = 1 } = props

  return show ? (
    <Grid container spacing={spacing} direction={vertical ? "column" : "row"} alignItems={vertical ? "" : "center"}>
      <Grid item xs>
        <Typography fontWeight={600}>{label}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" component="div">
          {children === undefined || children === null || children === "" ? "Not Set" : children}
        </Typography>
      </Grid>
    </Grid>
  ) : (
    <></>
  )
}

export default ViewProperty
