import React, { useMemo } from "react"
import { type TLogEntryChanges } from "../../models/service/ILogEntry"
import TableCellCheckMark from "./TableCellCheckMark"

interface IProps {
  children: TLogEntryChanges | undefined | null
  field?: string
}

/**
 * Render a table cell with truncated log entry changes title.
 *
 * @param {IProps} props - The props object containing the children.
 * @returns {React.ReactElement} - The rendered table cell component.
 */
const TableCellIsRiskWriterChanges: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children, field } = props

  const isRiskWriterChanges = useMemo((): boolean => {
    if (children !== undefined && children !== null) {
      return Object.keys(children).includes("risk_report_writer_data")
    }
    return false
  }, [children])

  return <TableCellCheckMark field={field}>{isRiskWriterChanges}</TableCellCheckMark>
}

export default TableCellIsRiskWriterChanges
