import React, { useCallback, useEffect, useMemo, useState } from "react"
import axios from "axios"
import { Alert, Button, Snackbar } from "@mui/material"
import useAuth from "../hooks/useAuth"
import { Info as InfoIcon } from "@mui/icons-material"
import { useLocation } from "react-router-dom"
import { ACCESS_TESTER_GROUP } from "../../config/permissions"

const maintenanceUrl = process.env.REACT_APP_MAINTENANCE_URL ?? ""

interface IMaintenance {
  version: string
  showUpdate: boolean
  maintenanceEnabled: boolean
  loginEnabled: boolean
  showMessage: boolean
  message: string
}

interface IProps {
  asButton?: boolean
  children?: React.ReactNode
  codeVersion?: string | undefined
}

/**
 * Checks if the site is under maintenance.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the maintenance component.
 */
const Maintenance: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { asButton = false, children, codeVersion } = props

  const [open, setOpen] = useState(false)
  const [openMessage, setOpenMessage] = useState(false)
  const [maintenance, setMaintenance] = useState<IMaintenance | null>(null)
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const { logout, currentUser } = useAuth()

  const isTester = useMemo(() => {
    return currentUser?.user.groups.some(group => group.name === ACCESS_TESTER_GROUP) === true
  }, [currentUser])

  const isLoginPage = location.pathname === "/login"

  const version: string = useMemo(() => {
    return maintenance?.version !== undefined ? maintenance.version : "unknown"
  }, [maintenance])

  useEffect(() => {
    setLoading(true)
    axios
      .get(`${maintenanceUrl}?v=${new Date().getTime()}`)
      .then(({ data }) => {
        const main = data as IMaintenance
        const hasVersion = main.version !== undefined && main.version !== null
        setMaintenance(main)
        setOpen(hasVersion && main.showUpdate && main.version !== codeVersion)
        if (main.maintenanceEnabled && !isLoginPage && !isTester) {
          if (logout !== null) {
            logout()
          }
        }
      })
      .catch(() => {
        setMaintenance(null)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [location.pathname, codeVersion, isTester, isLoginPage])

  const handleRefresh = useCallback(() => {
    if (typeof window !== "undefined") {
      window.location.reload()
    }
  }, [])

  return (
    <>
      {maintenance?.showMessage === true &&
        (asButton ? (
          <>
            <Button
              variant="contained"
              color="info"
              sx={{ ml: 2 }}
              disableElevation
              onClick={() => {
                setOpenMessage(true)
              }}
              startIcon={<InfoIcon />}>
              Notice
            </Button>
            <Snackbar
              open={openMessage}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              autoHideDuration={6000}
              sx={{ mt: 8 }}
              onClose={() => {
                setOpenMessage(false)
              }}>
              <Alert
                onClose={() => {
                  setOpenMessage(false)
                }}
                elevation={6}
                variant="filled"
                severity="info"
                sx={{ width: "100%" }}>
                {maintenance.message}
              </Alert>
            </Snackbar>
          </>
        ) : (
          <Alert severity="info" sx={{ mb: 2 }}>
            {maintenance.message}
          </Alert>
        ))}
      {isLoginPage && maintenance?.maintenanceEnabled === true && !loading && (
        <Alert severity="warning">Only test users can login at the moment.</Alert>
      )}

      {maintenance?.loginEnabled === true && !loading && <>{children}</>}

      <Snackbar
        sx={{ mt: 8 }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        action={
          <Button onClick={handleRefresh} color="secondary" size="small">
            Update Now
          </Button>
        }
        message={`Version ${version} of the site is available.`}
        onClose={() => {
          setOpen(false)
        }}
      />
    </>
  )
}

export default Maintenance
