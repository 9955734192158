import React, { useCallback, useContext, useMemo } from "react"
import { Button, Grid, Tab } from "@mui/material"

import { useApiRead } from "../../../../shared/hooks/useApiRead"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import PageHeader from "../../../../shared/components/pages/PageHeader"
import ViewProperty from "../../../../shared/components/ViewProperty"
import FormatDate from "../../../../shared/components/format/FormatDate"
import { SUMMARY_EDIT_URL } from "../../config/urls"
import PageViewBody from "../../../../shared/components/pages/PageViewBody"
import TabPanel, { useTabPanel } from "../../../../shared/components/tabs/TabPanel"
import { useOnDemandPaged } from "../../../../shared/hooks/useApiPagedLocal"
import { useParams } from "react-router-dom"
import { type IFile, SUMMARY_FILE_ENDPOINT } from "../../../../shared/models/service/IFile"
import FilesIndex from "../../../../shared/components/files/FilesIndex"
import { type IMainModel } from "../../../../shared/models/service/IMainModel"
import PaperLocal from "../../../../shared/components/containers/PaperLocal"
import ItemViewerDrawer, { useItemEditDialogUrl } from "../../../../shared/components/item_viewer/ItemViewerDrawer"
import ViewListItems from "../../../../shared/components/display/ViewListItems"
import ViewRichText from "../../../../shared/components/display/ViewRichText"
import { ItemPrefixes } from "../../../../config/config"
import { useTranslation } from "react-i18next"
import { type ISummary, SUMMARY_ENDPOINT } from "../../../../shared/models/service/ISummary"
import ReportWriterFormatInfo from "../../../../shared/pages/report_format/components/ReportWriterFormatInfo"
import InspectionIndex from "../inspections/components/InspectionIndex"
import { type IInspection, INSPECTION_ENDPOINT } from "../../../../shared/models/service/IInspection"
import type { IFilter } from "../../../../shared/models/components/IFilter"
import useEffectAsync from "../../../../shared/hooks/useEffectAsync"
import { ImageChooserProvider } from "../../../../shared/components/files/ImageChooser"
import { type IGeneratedContent } from "../../../../shared/components/display/ViewRiskWriterInspection"
import RiskWriterFormView from "../../../../shared/components/risk_writer/components/RiskWriterFormView"
import PrimaryButton from "../../../../shared/components/PrimaryButton"
import ItemEditorDialog from "../../../../shared/components/item_editor/ItemEditorDialog"
import SummaryBriefEdit from "../summary_briefs/components/SummaryBriefEdit"
import RiskWriterProvider from "../../../../shared/components/risk_writer/context/RiskWriterProvider"
import RiskWriterViewer from "../../../../shared/components/risk_writer/components/RiskWriterViewer"
import { useAxiosRequest } from "../../../../shared/hooks/useAxiosRequest"
import LocationInfo from "../locations/components/LocationInfo"
import { CloudDownload } from "@mui/icons-material"
import type { IPanariskAppContext } from "../../../../shared/models/app/IPanariskAppContext"
import { PanariskAppContext } from "../../../../app/PanariskApp"
import TabsList from "../../../../shared/components/tabs/TabsList"
import ViewRiskWriterSummary from "../../../../shared/components/display/ViewRiskWriterSummary"

const summaryRepository = new RestRepository<ISummary | IMainModel>(SUMMARY_ENDPOINT)
const filesRepository = new RestRepository<IFile | IMainModel>(SUMMARY_FILE_ENDPOINT)
const genContentRepository = new RestRepository<IGeneratedContent>(INSPECTION_ENDPOINT)

/**
 * Renders a page to view locations impairment details.
 *
 * @returns {React.ReactElement} The rendered component.
 */
const ViewPage: React.FC = (): React.ReactElement => {
  const repository = summaryRepository as RestRepository<ISummary>
  const apiRead = useApiRead<ISummary>({ apiFunction: repository.read })
  const { appSettings } = useContext<IPanariskAppContext>(PanariskAppContext)
  const units = appSettings?.serverInfo?.units
  const { data, loading } = apiRead
  const axiosRequest = useAxiosRequest()

  const { tab, handleTabChange } = useTabPanel()
  const { id } = useParams()
  const { t } = useTranslation()
  const editBriefUrl = useItemEditDialogUrl(ItemPrefixes.summaryBrief, data?.id)

  const reportWriterFormat = useMemo(() => {
    return data?.report_format?.report_writer_format?.data?.summary_formats?.find(
      format => format.name === data?.report_format_summary_name,
    )
  }, [data])

  const hasBrief = useMemo(() => {
    if (data !== undefined) {
      return (
        data.risk_writer_brief_data !== null &&
        reportWriterFormat?.brief_form !== null &&
        data.report_format?.report_writer_format?.data !== undefined
      )
    }
    return false
  }, [data, reportWriterFormat])

  const theFilter = [{ field: "summary", value: id }]
  const filesPagingResults = useOnDemandPaged<IFile>(SUMMARY_FILE_ENDPOINT, theFilter, "FILES")

  const inspectionPagingResults = useOnDemandPaged<IInspection>(INSPECTION_ENDPOINT, [], "INSPECTIONS")

  const handleGetFile = useCallback(async (fileId: number) => {
    let file = null
    await axiosRequest.callRequest(async () => {
      file = await (filesRepository as RestRepository<IFile>).read(fileId)
    })
    return file
  }, [])

  const handleRefreshTableData = useCallback(async () => {
    if (data?.id !== undefined) {
      await axiosRequest.callRequest(async () => {
        await summaryRepository.action<ISummary>(data.id, "update_risk_writer_tables")
        await apiRead.call()
      })
    }
  }, [data?.id])

  const handleBuildDataSheet = useCallback(async () => {
    if (data?.id !== undefined) {
      await axiosRequest.callRequest(async () => {
        await summaryRepository.action<ISummary>(data.id, "build_data_sheet")
        await apiRead.call()
      })
    }
  }, [data?.id])

  useEffectAsync(async () => {
    if (data?.report_format?.id !== undefined) {
      const inspectFilter: IFilter = {
        canBeDelete: false,
        title: "Report Format",
        display: data.report_format.name,
        field: "report_format",
        value: data.report_format.id,
      }
      const filters =
        inspectionPagingResults.paging?.filters !== undefined && inspectionPagingResults.paging.filters.length > 0
          ? [...inspectionPagingResults.paging.filters]
          : [inspectFilter]
      inspectionPagingResults.handleFilter(filters)
    }
  }, [data?.report_format?.id])

  return (
    <PageViewBody loading={loading}>
      {data !== undefined && (
        <>
          <PageHeader title={t("Summary")} titlePlural={data.name} toEdit={`${SUMMARY_EDIT_URL}/${data.id}`} />
          <>
            <ItemViewerDrawer
              title={t("Report Format")}
              prefix={ItemPrefixes.reportFormat}
              infoView={ReportWriterFormatInfo}
            />
            <ItemViewerDrawer title={t("Location")} prefix={ItemPrefixes.location} infoView={LocationInfo} />
            <ItemEditorDialog
              title={t("Brief")}
              prefix={ItemPrefixes.summaryBrief}
              itemEdit={SummaryBriefEdit}
              onSave={apiRead.call}
            />
          </>
          <Grid item xs={12}>
            <TabsList value={tab} onChange={handleTabChange}>
              <Tab label={t("Summary")} value={0} />
              {hasBrief && <Tab label={t("Brief")} value={1} />}
              <Tab label={t("Inspections")} value={2} />
              <Tab label={t("Files")} value={3} />
              <Tab label={t("Details")} value={4} />
            </TabsList>
          </Grid>
          <Grid item xs={12}>
            <TabPanel value={tab} index={0}>
              <Grid container spacing={2}>
                <Grid item xs />
                <Grid item>
                  <Button startIcon={<CloudDownload />} onClick={handleBuildDataSheet}>SOV</Button>
                </Grid>
                <Grid item>
                  <ImageChooserProvider itemId={id} field="summary" fileEndpoint={SUMMARY_FILE_ENDPOINT}>
                    <ViewRiskWriterSummary
                      data={data}
                      reportWriterFormat={reportWriterFormat}
                      title={data.name}
                      onReloadReport={apiRead.call}
                      onRefreshTableData={handleRefreshTableData}
                      repository={summaryRepository}
                      genContentRepository={genContentRepository}
                      filesRepository={filesRepository}
                    />
                  </ImageChooserProvider>
                </Grid>
                <Grid item xs={12}>
                  <PaperLocal>
                    <RiskWriterProvider
                      units={units}
                      format={reportWriterFormat}
                      data={data.risk_writer_data}
                      getFile={handleGetFile}>
                      <RiskWriterViewer />
                    </RiskWriterProvider>
                  </PaperLocal>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <>
                {data.risk_writer_brief_data !== null && hasBrief && (
                  <>
                    <Grid container sx={{ mb: 2 }}>
                      <Grid item xs></Grid>
                      <Grid item>
                        <PrimaryButton to={editBriefUrl} edit>
                          {t("Edit Brief")}
                        </PrimaryButton>
                      </Grid>
                    </Grid>
                    <RiskWriterFormView
                      formType="brief"
                      units={units}
                      format={reportWriterFormat}
                      fileEndpoint={SUMMARY_FILE_ENDPOINT}>
                      {data.risk_writer_brief_data}
                    </RiskWriterFormView>
                  </>
                )}
              </>
            </TabPanel>
            <TabPanel value={tab} index={2}>
              <InspectionIndex useInfoView pagingResults={inspectionPagingResults} showHeader={false} />
            </TabPanel>
            <TabPanel value={tab} index={3} onChange={filesPagingResults.call}>
              <FilesIndex
                parentId={id}
                parentFieldName="summary"
                repository={filesRepository}
                pagingResults={filesPagingResults}
              />
            </TabPanel>
            <TabPanel value={tab} index={4}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <PaperLocal>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <ViewProperty label={t("Report Format")}>
                              <ViewListItems prefix={ItemPrefixes.reportFormat}>{data.report_format}</ViewListItems>
                            </ViewProperty>
                          </Grid>
                          <Grid item xs={12}>
                            <ViewProperty label={t("Created")}>
                              <FormatDate value={data.created} />
                            </ViewProperty>
                          </Grid>
                        </Grid>
                      </PaperLocal>
                    </Grid>
                    <Grid item xs={12}>
                      <PaperLocal>
                        <ViewProperty label={t("Notes")} vertical>
                          <ViewRichText>{data.notes}</ViewRichText>
                        </ViewProperty>
                      </PaperLocal>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>
          </Grid>
        </>
      )}
    </PageViewBody>
  )
}

export default ViewPage
